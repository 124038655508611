/**
 *
 * Button
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

// STYLES
const useStyles = makeStyles((theme) => ({
    root: {
        padding: `${theme.spacing()}px ${theme.spacing() + 4}px`,
        fontFamily: theme.typography.fontFamily,
        fontSize: '12px',
        lineHeight: `${theme.spacing() + 6}px`,
        backgroundColor: theme.palette.standardWhite,
        color: theme.palette.humanBlue,
        textAlign: 'center',
        border: `1px solid ${theme.palette.humanBlue}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.humanBlue,
            color: theme.palette.standardWhite,
        },
    },
}));

function Button(props) {
    const classes = useStyles();

    return (
        <button type="button" onClick={props.onClick} {...props} className={classnames(classes.root, props.className)}>
            {props.children}
        </button>
    );
}

Button.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default Button;
