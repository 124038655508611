/*
 *
 * ColumnFilter reducer
 *
 */
import produce from 'immer';
import { Vehicle } from 'vehicles';

import { ADD_FILTER, INITIAL_FILTER, REMOVE_FILTER, RESET_FILTER } from './constants';

export const initialState = Vehicle.getAllVehicles().reduce(
    (acc, v) => ({ ...acc, [v.getVehicleKey()]: v.getDefaultColumns() }),
    {},
);

/* eslint-disable default-case, no-param-reassign */
const columnFilterReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case ADD_FILTER: {
                if (draft[action.vehicle][action.tab].indexOf(action.columnName) === -1) {
                    draft[action.vehicle][action.tab].push(action.columnName);
                }
                break;
            }
            case REMOVE_FILTER: {
                const index = draft[action.vehicle][action.tab].indexOf(action.columnName);

                if (index >= 0) {
                    draft[action.vehicle][action.tab].splice(index, 1);
                }
                break;
            }
            case RESET_FILTER: {
                draft[action.vehicle][action.tab] = initialState[action.vehicle][action.tab];
                // add campaign name in tab if onsite display stacked campaign reset
                if (action.isStackedOnsiteDisplay && action.tab !== 'product') {
                    draft[action.vehicle][action.tab] = [...draft[action.vehicle][action.tab], 'order_name'];
                }
                break;
            }
            case INITIAL_FILTER:
                // add campaign name in tab if onsite display stacked campaign initial
                if (action.isStackedCampaign && action.vehicle === 'display') {
                    draft[action.vehicle] = {
                        ...initialState[action.vehicle],
                        creative: [...initialState[action.vehicle].creative, 'order_name'],
                        placement: [...initialState[action.vehicle].placement, 'order_name'],
                    };
                } else {
                    draft[action.vehicle] = initialState[action.vehicle];
                }
                break;
        }
    });

export default columnFilterReducer;
