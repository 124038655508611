export const PAID_SEARCH_SKU_TAB_DEFAULT_KEY = 'campaign';

export const paidSearchSkuTabs = {
    campaign: {
        label: 'Campaign',
        key: 'campaign',
    },
    query: {
        label: 'Query',
        key: 'query',
    },
    device: {
        label: 'Device',
        key: 'device',
    },
    product: {
        label: 'Product',
        key: 'product',
    },
};
