import { call, put, fork, select, takeLeading } from 'redux-saga/effects';
import { fetchOrganizations, getAccountBrandsApi, shareOrgBrands, removeShareOrgBrands } from 'api/user-management';
import { getProviders, createKoddiProvider, updateKoddiProviderAccount } from 'api/providers';
import {
    createKoddiProviderSuccessAction,
    createKoddiProviderError,
    loadBrandAction,
    loadBrandErrorAction,
    loadBrandSuccessAction,
    loadOrganizationListAction,
    loadOrganizationListErrorAction,
    loadOrganizationListSuccessAction,
    loadProvidersAction,
    loadProvidersError,
    loadProvidersSuccessAction,
    updateKoddiProviderError,
    updateKoddiProviderSuccessAction,
    updateOrganizationAction,
} from './slice';
import { selectFeatureFlags } from '../../utils/featureFlags/selectors';
import { SHARE_ORG, REMOVE_SHARE_ORG } from '../App/constants';
import { enqueueSnackbar } from '../../notification/actions';
import { NOTICES } from '../../notification/constants';
import { UPDATE_KODDI_PROVIDER, CREATE_KODDI_PROVIDER } from './OrgFlyouts/SendToKoddi/constants';

export function* getOrganizationSaga() {
    try {
        const organizations = yield call(fetchOrganizations);
        yield put(loadOrganizationListSuccessAction(organizations));
    } catch (error) {
        yield put(loadOrganizationListErrorAction(error));
    }
}

export function* createKoddiProviderSaga(values) {
    try {
        const koddiProvider = yield call(createKoddiProvider, values);
        yield put(createKoddiProviderSuccessAction(koddiProvider));
        yield put(
            enqueueSnackbar({
                message: NOTICES.SEND_TO_KODDI_SUCCESS,
                options: { variant: 'success' },
            }),
        );
    } catch (error) {
        yield put(createKoddiProviderError(error));
        yield put(
            enqueueSnackbar({
                message: NOTICES.SEND_TO_KODDI_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

export function* getProvidersSaga(values) {
    try {
        const data = yield call(getProviders, values);
        yield put(loadProvidersSuccessAction(data));
    } catch (error) {
        yield put(loadProvidersError(error));
    }
}

export function* updateKoddiProviderSaga(values) {
    try {
        const data = yield call(updateKoddiProviderAccount, values);
        yield put(updateKoddiProviderSuccessAction(data));
        yield put(
            enqueueSnackbar({
                message: NOTICES.PROVIDER_UPDATED_SUCCESS,
                options: { variant: 'success' },
            }),
        );
    } catch (error) {
        yield put(updateKoddiProviderError(error));
        yield put(
            enqueueSnackbar({
                message: NOTICES.PROVIDER_UPDATED_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

export function* loadBrandsSaga() {
    try {
        const flags = yield select(selectFeatureFlags);
        const brands = yield call(getAccountBrandsApi, flags);
        yield put(loadBrandSuccessAction(brands));
    } catch (error) {
        yield put(loadBrandErrorAction(error));
    }
}

export function* shareOrgCampaigns({ sharedData }) {
    try {
        yield call(shareOrgBrands, sharedData);
        yield put(
            enqueueSnackbar({
                message: NOTICES.SHARE_ORG_CAMPAIGNS_SUCCESS,
                options: { variant: 'success' },
            }),
        );
    } catch (error) {
        yield put(
            enqueueSnackbar({
                message: NOTICES.SHARE_ORG_CAMPAIGNS_ERROR,
                options: { variant: 'error' },
            }),
        );
    }

    // Manually update org list with new share then validate info from backend
    const { sharedFrom, sharedTo, brands } = sharedData;
    const infoShared = {
        name: sharedTo.name,
        createdAt: undefined,
        userCount: 0,
        orgId: sharedTo.value,
        brands: brands.map((brand) => brand.name),
        campaignCount: 0,
    };
    const newOrg = {
        ...sharedFrom,
        shared: [...sharedFrom.sharedOrg, infoShared],
    };

    yield put(updateOrganizationAction(newOrg));
    yield fork(getOrganizationSaga);
}

export function* removeOrgCampaigns({ sharedData }) {
    try {
        yield call(removeShareOrgBrands, sharedData);
        yield put(
            enqueueSnackbar({
                message: NOTICES.REMOVE_ORG_CAMPAIGNS_SUCCESS,
                options: { variant: 'success' },
            }),
        );
        yield fork(getOrganizationSaga);
    } catch (error) {
        yield put(
            enqueueSnackbar({
                message: NOTICES.REMOVE_ORG_CAMPAIGNS_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

export default function* organizationListPageSaga() {
    yield takeLeading(loadOrganizationListAction().type, getOrganizationSaga);
    yield takeLeading(loadBrandAction().type, loadBrandsSaga);
    yield takeLeading(SHARE_ORG, shareOrgCampaigns);
    yield takeLeading(REMOVE_SHARE_ORG, removeOrgCampaigns);
    yield takeLeading(CREATE_KODDI_PROVIDER, createKoddiProviderSaga);
    yield takeLeading(loadProvidersAction().type, getProvidersSaga);
    yield takeLeading(UPDATE_KODDI_PROVIDER, updateKoddiProviderSaga);
}
