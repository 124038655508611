import React from 'react';

export const TermsContent = () => (
    <>
        <br />
        <br />
        <h3>My Ads Usage Terms</h3>
        <br />
        <b>Registration</b>
        <p>
            To register as a user for My Ads by Best Buy Ads, you must complete the registration process by providing
            Best Buy with accurate, complete and updated information as prompted by the registration form, including
            your e-mail address (username) and password. You will protect login credentials and take full responsibility
            for your own, and third party, use of your My Ads user accounts. You are solely responsible for any and all
            activities that occur under your My Ads user account. You will be responsible for damages resulting from
            sharing login credentials or otherwise permitting unauthorized access to your My Ads user account. You will
            notify Best Buy immediately via an email to{' '}
            <a href="mailto:myadssupport@bestbuy.com">MyAdsSupport@Bestbuy.com</a> upon learning of any unauthorized use
            of your My Ads user account or any other breach of security.
        </p>
        <br />
        <b>Administrator Responsibilities</b>
        <p>You should have a minimum of two administrators for your organization.</p>
        <br />
        <b>My Ads administrators, will:</b>
        <ul>
            <li>Manage My Ads user access for others within the organization(s) you represent.</li>
            <li>Be accountable for My Ads users&apos; actions.</li>
            <li>Be responsible for granting or removing My Ads access for users.</li>
            <li>
                Be responsible for promoting users to an administrator role or demoting administrators to report
                viewers.
            </li>
            <li>Communicate messages you receive to the appropriate contacts within your organization.</li>
            <li>
                Only use individual user’s email addresses for My Ads login credentials You should not use group email
                addresses for My Ads login credentials.
            </li>
            <li>Do a quarterly review and audit of My Ads administrators and other users.</li>
        </ul>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
    </>
);

export default TermsContent;
