import { downloadSponsoredRmpReport, getSponsoredCampaignDetails } from 'api/api-client';
import { Permissions } from 'shared/constants/user/permissions';
import { CAMPAIGN_TYPE_SPONSORED_RMP, SPONSORED_RMP_KEY } from 'Helpers/helpers';
import { renderSponsoredRmpTooltipContent } from 'components/CampaignReporting/Header/tooltip-content.js/sponsoredRmp';
import { ResourceParam } from 'containers/ResourcePage';
import {
    columnMap,
    dzSponsoredOptions,
    modifyColumns,
    SPONSORED_TAB_DEFAULT_KEY,
    sponsoredColumns,
    sponsoredTabs,
    defaultColumns,
    columnFilterMap,
} from '../index';
import { sponsoredRmpSheets } from './index';
import { sponsoredRmpCards } from './cards';

export const sponsoredRmpDefinitions = {
    label: 'Sponsored Products',
    vehicleKey: SPONSORED_RMP_KEY,
    campaignType: CAMPAIGN_TYPE_SPONSORED_RMP,
    groupName: 'sponsored-rmp',

    // Permissions
    viewPermissions: [Permissions.CAN_VIEW_SPONSORED_RMP_CAMPAIGNS],
    downloadPermissions: [Permissions.CAN_DOWNLOAD_SPONSORED_RMP_CAMPAIGNS],

    // Campaign Details Page
    detailsApiCall: getSponsoredCampaignDetails,
    useNewColumnFilter: true,
    defaultTabKey: SPONSORED_TAB_DEFAULT_KEY,
    columnMap,
    cards: sponsoredRmpCards,
    tabs: sponsoredTabs,
    columns: sponsoredColumns,
    modifyColumns,
    defaultColumns,
    columnFilterMap,
    visualizationOptions: dzSponsoredOptions,

    // Attribution
    tooltipRenderFn: renderSponsoredRmpTooltipContent,
    resourcesPageUrl: `/resources/${ResourceParam.SPONSORED_PRODUCTS}`,
    attributionWindowDays: 14,

    // Report Download
    reportDownloadApiCall: downloadSponsoredRmpReport,
    sheets: sponsoredRmpSheets,
};
