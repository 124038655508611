import { downloadOffsiteVideoReport, getOffsiteVideoCampaignDetails } from 'api/api-client';
import { Permissions } from 'shared/constants/user/permissions';
import { CAMPAIGN_TYPE_OFFSITE_VIDEO, OFFSITE_VIDEO_KEY } from 'Helpers/helpers';
import { renderDefaultTooltipContent } from 'components/CampaignReporting/Header/tooltip-content.js/defaultTooltip';
import { OFFSITE_TAB_DEFAULT_KEY, offsiteTabs } from '../offsiteTabs';
import { displayOptions } from '../dataviz';
import { offsiteSheets } from '../offsiteSheets';
import { offsiteCards } from '../offsiteCards';
import { columnMap, columnFilterMap, offsiteVideoColumns, modifyColumns, defaultColumns } from './index';
import { ResourceParam } from '../../../../containers/ResourcePage';

export const offsiteVideoDefinitions = {
    label: 'Offsite Video',
    vehicleKey: OFFSITE_VIDEO_KEY,
    campaignType: CAMPAIGN_TYPE_OFFSITE_VIDEO,

    // Permissions
    viewPermissions: [Permissions.CAN_VIEW_OFFSITE_VIDEO_CAMPAIGNS],
    downloadPermissions: [Permissions.CAN_DOWNLOAD_OFFSITE_VIDEO_CAMPAIGNS],

    // Campaign Details Page
    detailsApiCall: getOffsiteVideoCampaignDetails,
    useNewColumnFilter: true,
    defaultTabKey: OFFSITE_TAB_DEFAULT_KEY,
    defaultColumns,
    columnMap,
    columnFilterMap,
    cards: offsiteCards,
    tabs: offsiteTabs,
    columns: offsiteVideoColumns,
    modifyColumns,
    visualizationOptions: displayOptions('video'),

    // Attribution
    tooltipRenderFn: renderDefaultTooltipContent,
    resourcesPageUrl: `/resources/${ResourceParam.OFFSITE_DISPLAY}`,
    attributionWindowDays: 14,

    // Download Report
    reportDownloadApiCall: downloadOffsiteVideoReport,
    sheets: offsiteSheets('video'),
};
