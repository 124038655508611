/*
Possible statuses returned by invite_state and user_status fields on the user object.
user_status has additional statuses that can be found in user-status.js
*/

export const INVITE_STATUS_TYPE_SENT = 'INVITE_SENT';
export const INVITE_STATUS_TYPE_USER_CREATED = 'USER_CREATED';
export const INVITE_STATUS_TYPE_RESENT = 'INVITE_RESENT';
export const INVITE_STATUS_TYPE_ACCEPTED = 'INVITE_ACCEPTED';
export const INVITE_STATUS_TYPE_EMAIL_OTP_SENT = 'OTP_INVITE_SENT';
export const INVITE_STATUS_TYPE_PHONE_OTP_SENT = 'OTP_PHONE_SENT';
export const INVITE_STATUS_TYPE_EMAIL_OTP_ACCEPTED = 'OTP_INVITE_ACCEPTED';
export const INVITE_STATUS_TYPE_PHONE_OTP_ACCEPTED = 'OTP_PHONE_ACCEPTED';
export const INVITE_STATUS_TYPE_EMAIL_OTP_EXPIRED = 'OTP_INVITE_EXPIRED';
export const INVITE_STATUS_TYPE_PHONE_OTP_EXPIRED = 'OTP_PHONE_EXPIRED';
export const INVITE_STATUS_TYPE_INVITE_EXPIRED = 'INVITE_EXPIRED';
export const INVITE_STATUS_TYPE_USER_UNPAIRED = 'USER_UNPAIRED';
export const INVITE_STATUS_TYPE_INVITE_PROFILE_UPDATED = 'INVITE_PROFILE_UPDATED';
export const INVITE_STATUS_TYPE_PROFILE_UPDATED = 'PROFILE_UPDATED';
export const INVITE_STATUS_TYPE_PASSWORD_CHANGE_OTP_SENT = 'OTP_PASSWORD_SENT';
export const INVITE_STATUS_TYPE_PASSWORD_CHANGE_OTP_ACCEPTED = 'OTP_PASSWORD_ACCEPTED';
export const INVITE_STATUS_TYPE_PASSWORD_CHANGE_OTP_EXPIRED = 'OTP_PASSWORD_EXPIRED';
export const INVITE_STATUS_TYPE_PASSWORD_UPDATED = 'PASSWORD_UPDATED';
export const INVITE_STATUS_TYPE_COMPLETED = 'INVITE_COMPLETED';
