import { storeItem, getItemFromStorage, removeItemFromStorage } from 'utils/localStorage';

const ALERT_DISMISS_KEY = 'bestbuy-alert-dismiss';

export default {
    write(alert) {
        const dismissStatus = this.read();
        const newItem = this.setNewItem(alert);
        let result;
        if (dismissStatus && dismissStatus.length > 0) {
            const index = dismissStatus.findIndex((item) => item.id === alert.id);
            if (index === -1) {
                result = [...dismissStatus, newItem];
            } else {
                result = [...dismissStatus.slice(0, index), newItem, ...dismissStatus.slice(index + 1)];
            }
        } else {
            result = [newItem];
        }
        storeItem(ALERT_DISMISS_KEY, JSON.stringify(result));
    },

    read() {
        const dismiss = getItemFromStorage(ALERT_DISMISS_KEY);
        return JSON.parse(dismiss);
    },

    clear() {
        removeItemFromStorage(ALERT_DISMISS_KEY);
    },
    setNewItem(alert) {
        const tempAlert = {
            id: alert.id,
            date: new Date().toISOString(),
        };
        if (alert.repeatAlertHours) {
            tempAlert.repeatAlertHours = alert.repeatAlertHours;
        }
        return tempAlert;
    },
};
