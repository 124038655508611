import { CREATE_KODDI_PROVIDER, UPDATE_KODDI_PROVIDER } from './constants';

export function createKoddiProvider(values) {
    return {
        type: CREATE_KODDI_PROVIDER,
        payload: values,
    };
}

export function updateKoddiProviderAction(values) {
    return {
        type: UPDATE_KODDI_PROVIDER,
        payload: values,
    };
}
