/**
 * ScrollToTop
 *
 * By default, browsers will store the coordinates of the window location and start a user at this location
 * when navigating to a new page. In order to start a new page at the top, we need to "restore" the scroll position
 * to the top of the page.
 *
 * When navigating to a new page, this component will restore the scroll position to the top
 * of the page when used in a layout component.
 *
 * The "layout" must have a css field set for overflowY and this should be set to "auto".
 */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
    const routePath = useLocation();
    const onTop = () => {
        document.querySelector('.layout').scrollIntoView(true);
    };
    useEffect(() => {
        onTop();
    }, [routePath]);

    return null;
}
