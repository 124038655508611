export const socialCards = [
    {
        key: 'impressions',
        title: 'Impressions',
        type: 'number',
    },
    {
        key: 'clicks',
        title: 'Clicks',
        type: 'number',
    },
    {
        key: 'ctr',
        title: 'CTR',
        type: 'percent_decimal',
    },
    {
        key: 'spend',
        title: 'Delivered Spend',
        type: 'currency',
    },
    {
        key: 'engagements',
        title: 'Engagements',
        type: 'number',
    },
    {
        key: 'engagement_rate',
        title: 'Engagement Rate',
        type: 'percent_decimal',
    },
];
