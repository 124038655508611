import { call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import { compact, difference, uniq } from 'lodash';
import {
    createCampaignGroupApi,
    deleteCampaignGroupApi,
    updateCampaignGroupApi,
    getCampaignGroupListByUserApi,
    getSharedCampaignGroupListApi,
    checkCampaignGroupNameUniqueness,
} from 'api/user-management';
import { NOTICES, NAME_NOT_UNIQUE } from 'notification/constants';
import { enqueueSnackbar } from 'notification/actions';
import {
    ADD_CAMPAIGNS_TO_GROUP,
    CREATE_CAMPAIGN_GROUP,
    DELETE_CAMPAIGN_GROUP,
    GET_CAMPAIGN_GROUPS,
    GET_SHARED_CAMPAIGN_GROUPS,
    REMOVE_CAMPAIGNS_FROM_GROUP,
    SHARE_CAMPAIGN_GROUP,
    RENAME_CAMPAIGN_GROUP,
} from './constants';
import {
    getCampaignGroupsSuccess,
    getCampaignGroupsError,
    createCampaignGroupSuccess,
    createCampaignGroupError,
    deleteCampaignGroupSuccess,
    deleteCampaignGroupError,
    addCampaignsToGroupSuccess as setCampaignsToGroupLocal,
    addCampaignsToGroupError,
    removeCampaignsFromGroupSuccess,
    shareCampaignGroupError,
    shareCampaignGroupSuccess,
    getSharedCampaignGroupsSuccess,
    getSharedCampaignGroupsError,
    checkCampaignGroupNameSuccess,
    checkCampaignGroupNameError,
    renameCampaignGroupSuccess,
    renameCampaignGroupError,
    removeCampaignsFromGroup,
} from './actions';

import { makeSelectCampaignGroups } from './selectors';
import { getStackRoute, getDefaultGroupName } from './utils';

function* createCampaignGroupSaga({ userId, groupName, groupType, campaignIds }) {
    let groupData;
    try {
        groupData = yield call(createCampaignGroupApi, {
            userId,
            groupName,
            groupType,
            campaignIds,
        });
        if (groupData && (groupData.status === 2 || groupData.status === 1)) {
            throw new Error(groupData.msg);
        }
        yield put(createCampaignGroupSuccess(groupData));
        yield put(
            enqueueSnackbar({
                message: NOTICES.CREATE_STACK_REPORT_SUCCESS,
                options: { variant: 'success' },
                viewLink: getStackRoute(groupType, groupName),
            }),
        );
    } catch (error) {
        yield put(createCampaignGroupError(error));
        yield put(
            enqueueSnackbar({
                message: NOTICES.CREATE_STACK_REPORT_ERROR,
                options: { variant: 'error' },
            }),
        );
    }

    return groupData;
}

function* createCampaignGroupWithNameCheckSaga({ userId, groupName, groupType, campaignIds }) {
    try {
        const checkData = yield call(checkCampaignGroupName, { groupName }) || {};

        if (checkData.isGroupNameUnique) {
            const groupData = yield call(createCampaignGroupSaga, {
                userId,
                groupName,
                groupType,
                campaignIds,
                removeFromDefaultGroup: false,
            });
            if (groupData && !groupData.status) {
                yield put(removeCampaignsFromGroup(userId, getDefaultGroupName(groupType), groupType, campaignIds));
            }
        } else {
            throw new Error(checkData.error);
        }
    } catch (error) {
        yield put(createCampaignGroupError(error));
        if (!error || error.message !== NAME_NOT_UNIQUE(groupName)) {
            yield put(
                enqueueSnackbar({
                    message: NOTICES.CREATE_STACK_REPORT_ERROR,
                    options: { variant: 'error' },
                }),
            );
        }
    }
}

function* deleteCampaignGroupSaga({ groupId }) {
    try {
        const response = yield call(deleteCampaignGroupApi, groupId);
        if (response.status !== 0) {
            throw new Error(response.msg);
        }
        yield put(deleteCampaignGroupSuccess(groupId));
        yield put(
            enqueueSnackbar({
                message: NOTICES.DELETE_STACK_REPORT_SUCCESS,
                options: { variant: 'success' },
            }),
        );
    } catch (error) {
        yield put(deleteCampaignGroupError(error));
        yield put(
            enqueueSnackbar({
                message: NOTICES.DELETE_STACK_REPORT_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

function* getCampaignGroupsSaga({ userId, groupType }) {
    try {
        const groupsData = yield call(getCampaignGroupListByUserApi, userId, groupType);

        yield put(getCampaignGroupsSuccess(compact(groupsData.rows)));
    } catch (error) {
        yield put(getCampaignGroupsError(error));
    }
}

function* addCampaignsToGroupSaga({ userId, groupName, groupType, campaignIds }) {
    try {
        const campaignGroups = yield select(makeSelectCampaignGroups());

        // Find the group from given name and type. Otherwise, it will create a new group.
        const group = campaignGroups.find(
            (campaignGroup) =>
                campaignGroup.groupName.toLowerCase() === groupName.toLowerCase() &&
                campaignGroup.groupType === groupType,
        );

        if (!group) {
            yield call(createCampaignGroupSaga, {
                userId,
                groupName,
                groupType,
                campaignIds,
                removeFromDefaultGroup: false,
            });
        } else {
            const { groupId, shared } = group;
            const newCampaignIds = uniq([...campaignIds, ...group.campaignIds]);
            const optimisticCampaignGroupData = {
                groupId,
                userId,
                groupName,
                groupType,
                campaignIds: newCampaignIds,
            };
            yield put(setCampaignsToGroupLocal(optimisticCampaignGroupData));

            const response = yield call(updateCampaignGroupApi, {
                userId,
                groupId,
                groupName,
                groupType,
                campaignIds: newCampaignIds,
                shared,
            });
            if (response.status === 0) {
                yield put(
                    enqueueSnackbar({
                        message: NOTICES.ADD_CAMPAIGN_TO_GROUP_SUCCESS,
                        options: { variant: 'success' },
                        viewLink: getStackRoute(groupType, groupName),
                    }),
                );
            } else {
                const revertCampaignGroupData = {
                    groupId,
                    userId,
                    groupName,
                    groupType,
                    campaignIds: [...group.campaignIds],
                };
                yield put(setCampaignsToGroupLocal(revertCampaignGroupData));

                if (response.status === 1) {
                    throw new Error(NOTICES.ADD_TO_STACK_MAX_ERROR);
                } else {
                    throw new Error(response.msg);
                }
            }
        }
    } catch (error) {
        yield put(addCampaignsToGroupError(error.message));
        yield put(
            enqueueSnackbar({
                message:
                    error.message === NOTICES.ADD_TO_STACK_MAX_ERROR
                        ? NOTICES.ADD_TO_STACK_MAX_ERROR
                        : NOTICES.ADD_CAMPAIGN_TO_GROUP_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

function* shareCampaignGroupSaga({ groupId, shared }) {
    try {
        const campaignGroups = yield select(makeSelectCampaignGroups());

        // Find the group from given name and type. Otherwise, it will create a new group.
        const group = campaignGroups.find((campaignGroup) => campaignGroup.groupId === groupId);
        if (!group) {
            throw new Error('Campaign group not existed');
        } else {
            const groupPayload = {
                ...group,
                shared,
            };
            const response = yield call(updateCampaignGroupApi, groupPayload);
            if (response.status === 0) {
                yield put(shareCampaignGroupSuccess(groupPayload));
                yield put(
                    enqueueSnackbar({
                        message: shared ? NOTICES.SHARE_REPORT_SUCCESS : NOTICES.UNSHARE_REPORT_SUCCESS,
                        options: { variant: 'success' },
                    }),
                );
            } else {
                throw new Error({ message: response.msg });
            }
        }
    } catch (error) {
        yield put(shareCampaignGroupError(error));
        yield put(
            enqueueSnackbar({
                message: shared ? NOTICES.SHARE_REPORT_ERROR : NOTICES.UNSHARE_REPORT_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

// eslint-disable-next-line generator-star-spacing
function* removeCampaignsFromGroupSaga({ userId, groupName, groupType, campaignIds }) {
    try {
        const campaignGroups = yield select(makeSelectCampaignGroups());

        // Find the group from given name and type. Otherwise, it will create a new group.
        const group = campaignGroups.find(
            (campaignGroup) =>
                campaignGroup.groupName.toLowerCase() === groupName.toLowerCase() &&
                campaignGroup.groupType === groupType,
        );

        if (group) {
            const { groupId } = group;
            const newCampaignIds = uniq(difference(group.campaignIds, campaignIds));
            const response = yield call(updateCampaignGroupApi, {
                userId,
                groupId,
                groupName,
                groupType,
                campaignIds: newCampaignIds,
            });
            if (response.status === 0) {
                const groupData = {
                    groupId,
                    userId,
                    groupName,
                    groupType,
                    campaignIds: newCampaignIds,
                };

                yield put(removeCampaignsFromGroupSuccess(groupData));
                yield put(
                    enqueueSnackbar({
                        message: NOTICES.REMOVE_CAMPAIGN_FROM_GROUP_SUCCESS,
                        options: { variant: 'success' },
                    }),
                );
            } else {
                throw new Error({ message: response.msg });
            }
        } else {
            throw new Error('No group found');
        }
    } catch (error) {
        yield put(addCampaignsToGroupError(error));
        yield put(
            enqueueSnackbar({
                message: NOTICES.REMOVE_CAMPAIGN_FROM_GROUP_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

function* getSharedCampaignGroupsSaga({ groupType }) {
    try {
        const groupsData = yield call(getSharedCampaignGroupListApi, groupType);
        const sharedData = compact(groupsData.rows).filter((group) => group.shared);
        yield put(getSharedCampaignGroupsSuccess(sharedData));
    } catch (error) {
        yield put(getSharedCampaignGroupsError(error));
    }
}

function* checkCampaignGroupName({ groupName }) {
    let checkData = {};
    try {
        checkData = yield call(checkCampaignGroupNameUniqueness, groupName);
        yield put(checkCampaignGroupNameSuccess(checkData));
        if (checkData && !checkData.isGroupNameUnique) {
            yield put(
                enqueueSnackbar({
                    message: NAME_NOT_UNIQUE(groupName),
                    options: { variant: 'error' },
                }),
            );
            checkData = { ...checkData, error: NAME_NOT_UNIQUE(groupName) };
        }
    } catch (error) {
        checkData = { ...checkData, error: error.message || 'API Fail' };
        yield put(checkCampaignGroupNameError(error));
    }
    return checkData;
}

function* renameCampaignGroupSaga({ group }) {
    try {
        if (!group) {
            throw new Error('Campaign group not existed');
        } else {
            const { groupName } = group;
            const checkData = yield call(checkCampaignGroupName, { groupName });

            if (checkData.isGroupNameUnique) {
                const response = yield call(updateCampaignGroupApi, { ...group });
                if (response.status === 0) {
                    yield put(renameCampaignGroupSuccess(group));
                    yield put(
                        enqueueSnackbar({
                            message: NOTICES.RENAME_CAMPAIGN_GROUP_SUCCESS,
                            options: { variant: 'success' },
                        }),
                    );
                } else {
                    throw new Error(response.msg);
                }
            } else {
                throw new Error(checkData.error);
            }
        }
    } catch (error) {
        yield put(renameCampaignGroupError(error));
        if (!error || error.message !== NAME_NOT_UNIQUE(group.groupName)) {
            yield put(
                enqueueSnackbar({
                    message: NOTICES.RENAME_CAMPAIGN_GROUP_ERROR,
                    options: { variant: 'error' },
                }),
            );
        }
    }
}
/**
 * Root saga manages watcher lifecycle
 *
 * @returns {function} generator
 */
export default function* campaignData() {
    yield takeLatest(CREATE_CAMPAIGN_GROUP, createCampaignGroupWithNameCheckSaga);
    yield takeLatest(DELETE_CAMPAIGN_GROUP, deleteCampaignGroupSaga);
    yield takeLatest(GET_CAMPAIGN_GROUPS, getCampaignGroupsSaga);
    yield takeEvery(ADD_CAMPAIGNS_TO_GROUP, addCampaignsToGroupSaga);
    yield takeLatest(SHARE_CAMPAIGN_GROUP, shareCampaignGroupSaga);
    yield takeLatest(REMOVE_CAMPAIGNS_FROM_GROUP, removeCampaignsFromGroupSaga);
    yield takeLatest(GET_SHARED_CAMPAIGN_GROUPS, getSharedCampaignGroupsSaga);
    yield takeLatest(RENAME_CAMPAIGN_GROUP, renameCampaignGroupSaga);
}
