/**
 *
 * SummaryMultiRadio
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Vehicle } from 'vehicles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
}));

export const SummaryMultiRadio = ({ options, campaignType }) => {
    const history = useHistory();
    const classes = useStyles();

    const handleChange = (event) => {
        const newVehicle = Vehicle.getFromCampaignType(event.target.value);
        history.replace(`/campaigns/summary/${newVehicle.getVehicleKey()}`);
    };

    return (
        <RadioGroup value={campaignType} className={classes.root} onChange={handleChange}>
            {options.map(({ value, name }) => (
                <FormControlLabel
                    key={name + value}
                    control={
                        <Radio
                            value={value}
                            color="primary"
                            inputProps={{ 'data-testid': `radio-${value}`, id: `radio-${value}` }}
                        />
                    }
                    label={name}
                />
            ))}
        </RadioGroup>
    );
};

SummaryMultiRadio.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string,
        }),
    ),
    campaignType: PropTypes.string,
};

SummaryMultiRadio.defaultProps = {
    options: [],
};

export default SummaryMultiRadio;
