import React from 'react';
import PropTypes from 'prop-types';
import env from 'config/env';
import ErrorPage from './ErrorPage';

class ErrorHandler extends React.Component {
    constructor(props) {
        super(props);
        const inDevEnv = process.env.NODE_ENV === 'development' || env.BBY_ENV === 'test';
        this.state = { caughtError: env.envError, inDevEnv };
    }

    static getDerivedStateFromError(error) {
        return { caughtError: error };
    }

    render() {
        if (this.state.caughtError) {
            return (
                <ErrorPage
                    errorInfo={this.state.caughtError.toString ? this.state.caughtError.toString() : null}
                    showError={this.state.inDevEnv}
                />
            );
        }

        return this.props.children;
    }
}

ErrorHandler.propTypes = {
    children: PropTypes.node,
};

export default ErrorHandler;
