/**
 *
 * PasswordUpdateForm
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { Button, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import {
    required,
    isNotBlank,
    minLengthEqual,
    isValidPasswordForUserCreation,
    composeValidators,
    isValidWithOneCapitalCharacter,
    isValidWithOneLowerCaseCharacter,
    isValidWithOneNumber,
    isValidWithOneSpecialCharacter,
} from 'utils/validators';
import Password from 'components/Password';

const useStyles = makeStyles((theme) => ({
    formButton: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginTop: '24px',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    formButtonCancel: {
        backgroundColor: theme.palette.battleGray,
        color: theme.palette.techBlack,
        '&:hover': {
            backgroundColor: theme.palette.borderGray,
        },
    },
    dialog: {
        padding: '16px 24px',
    },
    dialogButton: {
        margin: '0',
    },
    dialogText: {
        color: theme.palette.techBlack,
    },
}));

export function PasswordUpdateForm({ onSubmit, onExit, loading }) {
    const classes = useStyles();
    const [submitDialogOpen, setSubmitDialogOpen] = useState(false);

    const onPasswordUpdateFormSubmit = () => {
        setSubmitDialogOpen(true);
    };

    const onSubmitDialogProceed = ({ password }) => {
        onSubmit(password);
        setSubmitDialogOpen(false);
        onExit();
    };

    const onSubmitDialogCancel = () => {
        setSubmitDialogOpen(false);
    };

    return (
        <Form
            onSubmit={onPasswordUpdateFormSubmit}
            validate={(values) => {
                const errors = {};
                if (values.confirmPassword !== values.password) {
                    errors.confirmPassword = 'Confirm password must match.';
                }
                return errors;
            }}
            render={({ handleSubmit, submitting, pristine, values, hasValidationErrors }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container alignItems="flex-start" spacing={3}>
                        <Grid item xs={12}>
                            <Field
                                name="password"
                                component="input"
                                type="text"
                                validate={composeValidators(
                                    required,
                                    isNotBlank,
                                    minLengthEqual(8),
                                    isValidPasswordForUserCreation,
                                    isValidWithOneCapitalCharacter,
                                    isValidWithOneLowerCaseCharacter,
                                    isValidWithOneNumber,
                                    isValidWithOneSpecialCharacter,
                                )}
                            >
                                {(props) => (
                                    <Password
                                        inputLabel="New Password"
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        disabled={loading}
                                        inputProps={{
                                            'data-testid': 'create-user-password-input',
                                            maxLength: 255,
                                        }}
                                        {...props}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="confirmPassword"
                                component="input"
                                type="text"
                                validate={composeValidators(required)}
                            >
                                {(props) => (
                                    <Password
                                        inputLabel="Confirm Password"
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        disabled={loading}
                                        inputProps={{
                                            'data-testid': 'create-user-password-input',
                                            maxLength: 255,
                                        }}
                                        {...props}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                data-testid="submit-user-form-button"
                                className={classnames(
                                    classes.formButton,
                                    (submitting || hasValidationErrors || pristine) && classes.disabled,
                                )}
                                disabled={submitting || hasValidationErrors || pristine || loading || submitDialogOpen}
                                type="submit"
                            >
                                <span>{submitting || loading ? 'Loading...' : 'Update Password'}</span>
                            </Button>
                        </Grid>
                    </Grid>
                    <Dialog open={submitDialogOpen} fullWidth maxWidth="sm">
                        <DialogTitle>Password Update</DialogTitle>
                        <DialogContent>
                            <DialogContentText className={classnames(classes.dialogText)}>
                                Are you sure you want to change password?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className={classes.dialog}>
                            <Button
                                data-testid="cancel-user-form-button"
                                className={classnames(
                                    classes.formButton,
                                    classes.dialogButton,
                                    classes.formButtonCancel,
                                )}
                                onClick={onSubmitDialogCancel}
                            >
                                <span>Cancel</span>
                            </Button>
                            <Button
                                data-testid="cancel-user-form-button"
                                className={classnames(classes.formButton, classes.dialogButton)}
                                onClick={() => onSubmitDialogProceed(values)}
                            >
                                <span>Yes, Update Password</span>
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        />
    );
}

PasswordUpdateForm.propTypes = {
    loading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onExit: PropTypes.func,
};

export default PasswordUpdateForm;
