import { downloadPaidSearchReport, getPaidSearchCampaignDetails } from 'api/api-client';
import { Permissions } from 'shared/constants/user/permissions';
import { CAMPAIGN_TYPE_PAID_SEARCH, PAID_SEARCH_KEY } from 'Helpers/helpers';
import { renderPaidSearchTooltipContent } from 'components/CampaignReporting/Header/tooltip-content.js/paidSearch';
import { ResourceParam } from 'containers/ResourcePage';
import {
    PAID_SEARCH_SKU_TAB_DEFAULT_KEY,
    columnMap,
    paidSearchSkuCards,
    paidSearchSkuTabs,
    paidSearchSkuColumns,
    modifyColumns,
    dzPaidSearchSkuOptions,
    paidSearchSkuSheets,
    defaultColumns,
} from './index';

export const paidSearchDefinitions = {
    label: 'Paid Search',
    vehicleKey: PAID_SEARCH_KEY,
    campaignType: CAMPAIGN_TYPE_PAID_SEARCH,

    // Permissions
    viewPermissions: [Permissions.CAN_VIEW_PAID_SEARCH_CAMPAIGNS],
    downloadPermissions: [Permissions.CAN_DOWNLOAD_PAID_SEARCH_CAMPAIGNS],

    // Campaign Details Page
    detailsApiCall: getPaidSearchCampaignDetails,
    useNewColumnFilter: false,
    defaultTabKey: PAID_SEARCH_SKU_TAB_DEFAULT_KEY,
    columnMap,
    cards: paidSearchSkuCards,
    tabs: paidSearchSkuTabs,
    columns: paidSearchSkuColumns,
    modifyColumns,
    defaultColumns,
    visualizationOptions: dzPaidSearchSkuOptions,

    // Attribution
    tooltipRenderFn: renderPaidSearchTooltipContent,
    resourcesPageUrl: `/resources/${ResourceParam.PAID_SEARCH}`,
    attributionWindowDays: 0,

    // Download Report
    reportDownloadApiCall: downloadPaidSearchReport,
    sheets: paidSearchSkuSheets,
};
