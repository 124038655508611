/**
 *
 * Highlight
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    sub: {
        fontWeight: 'bold',
        background: '#9cf0ff',
    },
}));

function containIgnoreCase(content, sub) {
    if (!content || !sub) {
        return false;
    }

    return String(content).toUpperCase().includes(sub.toUpperCase());
}

export const Highlight = ({ content, sub }) => {
    const classes = useStyles();
    const text = String(content);
    const isContained = containIgnoreCase(text, sub);
    let startString = '';
    let subString = '';
    let endString = '';
    if (isContained) {
        const flatString = text.toUpperCase();
        const flatSub = sub.toUpperCase();

        const index = flatString.indexOf(flatSub);
        startString = text.substring(0, index);
        subString = text.substring(index, index + sub.length);
        endString = text.substring(index + sub.length, text.length);
    }

    return (
        <span>
            {isContained ? (
                <>
                    <span>{startString}</span>
                    <span className={classes.sub}>{subString}</span>
                    <span>{endString}</span>
                </>
            ) : (
                text
            )}
        </span>
    );
};

Highlight.propTypes = {
    content: PropTypes.any.isRequired,
    sub: PropTypes.string,
};

export default Highlight;
