/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOAD_CAMPAIGNS = 'portal/App/LOAD_CAMPAIGNS';
export const LOAD_CAMPAIGNS_SUCCESS = 'portal/App/LOAD_CAMPAIGNS_SUCCESS';
export const LOAD_CAMPAIGNS_ERROR = 'portal/App/LOAD_CAMPAIGNS_ERROR';
export const LOAD_AUTHENTICATED_USER = 'portal/App/LOAD_AUTHENTICATED_USER';
export const LOGOUT = 'portal/App/LOGOUT';
export const UNLOAD_AUTHENTICATED_USER = 'portal/App/UNLOAD_AUTHENTICATED_USER';

export const CREATE_USER = 'portal/App/CREATE_USER';
export const CREATE_USER_INIT = 'portal/App/CREATE_USER_INIT';
export const CREATE_USER_SUCCESS = 'portal/App/CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'portal/App/CREATE_USER_ERROR';
export const UPDATE_USER = 'portal/App/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'portal/App/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'portal/App/UPDATE_USER_ERROR';
export const UPDATE_USER_RESET = 'portal/App/UPDATE_USER_RESET';

export const CREATE_CAMPAIGN_GROUP = 'portal/App/CREATE_CAMPAIGN_GROUP';
export const CREATE_CAMPAIGN_GROUP_SUCCESS = 'portal/App/CREATE_CAMPAIGN_GROUP_SUCCESS';
export const CREATE_CAMPAIGN_GROUP_ERROR = 'portal/App/CREATE_CAMPAIGN_GROUP_ERROR';

export const DELETE_CAMPAIGN_GROUP = 'portal/App/DELETE_CAMPAIGN_GROUP';
export const DELETE_CAMPAIGN_GROUP_SUCCESS = 'portal/App/DELETE_CAMPAIGN_GROUP_SUCCESS';
export const DELETE_CAMPAIGN_GROUP_ERROR = 'portal/App/DELETE_CAMPAIGN_GROUP_ERROR';

export const UPDATE_CAMPAIGN_GROUP = 'portal/App/UPDATE_CAMPAIGN_GROUP';
export const UPDATE_CAMPAIGN_GROUP_SUCCESS = 'portal/App/UPDATE_CAMPAIGN_GROUP_SUCCESS';
export const UPDATE_CAMPAIGN_GROUP_ERROR = 'portal/App/UPDATE_CAMPAIGN_GROUP_ERROR';

export const GET_CAMPAIGN_GROUPS = 'portal/App/GET_CAMPAIGN_GROUPS';
export const GET_CAMPAIGN_GROUPS_SUCCESS = 'portal/App/GET_CAMPAIGN_GROUPS_SUCCESS';
export const GET_CAMPAIGN_GROUPS_ERROR = 'portal/App/GET_CAMPAIGN_GROUPS_ERROR';

export const ADD_CAMPAIGNS_TO_GROUP = 'portal/App/ADD_CAMPAIGNS_TO_GROUP';
export const ADD_CAMPAIGNS_TO_GROUP_SUCCESS = 'portal/App/ADD_CAMPAIGNS_TO_GROUP_SUCCESS';
export const ADD_CAMPAIGNS_TO_GROUP_ERROR = 'portal/App/ADD_CAMPAIGNS_TO_GROUP_ERROR';

export const REMOVE_CAMPAIGNS_FROM_GROUP = 'portal/App/REMOVE_CAMPAIGNS_FROM_GROUP';
export const REMOVE_CAMPAIGNS_FROM_GROUP_SUCCESS = 'portal/App/REMOVE_CAMPAIGNS_FROM_GROUP_SUCCESS';
export const REMOVE_CAMPAIGNS_FROM_GROUP_ERROR = 'portal/App/REMOVE_CAMPAIGNS_FROM_GROUP_ERROR';

export const SHARE_CAMPAIGN_GROUP = 'portal/App/SHARE_CAMPAIGN_GROUP';
export const SHARE_CAMPAIGN_GROUP_SUCCESS = 'portal/App/SHARE_CAMPAIGN_GROUP_SUCCESS';
export const SHARE_CAMPAIGN_GROUP_ERROR = 'portal/App/SHARE_CAMPAIGN_GROUP_ERROR';

export const GET_SHARED_CAMPAIGN_GROUPS = 'portal/App/GET_SHARED_CAMPAIGN_GROUPS';
export const GET_SHARED_CAMPAIGN_GROUPS_SUCCESS = 'portal/App/GET_SHARED_CAMPAIGN_GROUPS_SUCCESS';
export const GET_SHARED_CAMPAIGN_GROUPS_ERROR = 'portal/App/GET_SHARED_CAMPAIGN_GROUPS_ERROR';

export const RESET_DONE = 'portal/App/RESET_DONE';

export const GET_USER_INFO = 'portal/App/GET_USER_INFO';
export const GET_USER_ERROR = 'portal/App/GET_USER_ERROR';
export const SHARE_ORG = 'portal/App/SHARE_ORG';
export const REMOVE_SHARE_ORG = 'portal/App/REMOVE_SHARE_ORG';
