import React from 'react';
import PropTypes from 'prop-types';
import Banner from './Banner';

const AlertBanner = ({ alertBannerData, dismissAlert }) => (
    <div data-testid="AlertBanner">
        {alertBannerData.map((alert) => (
            <Banner
                key={alert.id}
                id={alert.id}
                alertLevel={alert.alertLevel}
                dismissEnabled={alert.dismissEnabled}
                title={alert.title}
                message={alert.message}
                onClose={() => dismissAlert(alert.id)}
                url={alert.url}
            />
        ))}
    </div>
);

AlertBanner.propTypes = {
    alertBannerData: PropTypes.array,
    dismissAlert: PropTypes.func,
};

export default AlertBanner;
