import React from 'react';
import PropTypes from 'prop-types';

const SearchSvg = ({ width = 24, height = 24 }) => (
    <svg width={width} height={height} viewBox="0 0 23 23" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.2323 19.1947L15.179 14.1415C16.19 12.9103 16.7983 11.3365 16.7983 9.62263C16.7983 5.6904 13.5995 2.49097 9.66693 2.49097C5.73471 2.49097 2.53558 5.6904 2.53558 9.62263C2.53558 13.5552 5.7347 16.754 9.66693 16.754C11.3808 16.754 12.9547 16.1457 14.1858 15.1347L19.239 20.188C19.3757 20.3253 19.556 20.3939 19.7357 20.3939C19.9154 20.3939 20.0957 20.3253 20.2323 20.188C20.5068 19.9141 20.5068 19.4686 20.2323 19.1947ZM3.94126 9.62263C3.94126 6.46531 6.50961 3.89665 9.66693 3.89665C12.8246 3.89665 15.3932 6.4653 15.3932 9.62263C15.3932 12.7803 12.8246 15.3489 9.66693 15.3489C6.50961 15.3489 3.94126 12.7803 3.94126 9.62263Z"
            fill="#1d252c"
        />
    </svg>
);

SearchSvg.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SearchSvg;
