import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';

import { AppContext } from 'shared/contexts/app-context';
import session from 'services/session';
import { clearCSRFToken } from 'utils/csrf';
import { clearStorage } from 'utils/localStorage';

import Button from 'components/Button';
import FlyoutModal from 'components/FlyoutModal';
import UserDetailContent from 'components/UserDetailContent';

import { unloadAuthedUser } from 'containers/App/actions';
import IDAMPasswordUpdateContent from 'containers/IDAMUserManagement/PasswordUpdateContent';

import env from 'config/env';

function AuthedDevPage({ onUnloadAuthedUser }) {
    const history = useHistory();

    const { authedUser } = useContext(AppContext);
    const [openFlyout, setOpenFlyout] = useState(false);
    let flyoutModel = null;

    const onCloseFlyoutModal = () => {
        setOpenFlyout(false);
    };

    if (openFlyout) {
        flyoutModel = (
            <FlyoutModal title="User Profile" open={openFlyout} onClose={onCloseFlyoutModal}>
                <UserDetailContent user={authedUser} />
                {!authedUser.aid && (
                    <IDAMPasswordUpdateContent
                        userId={authedUser.userId}
                        uuid={authedUser.uuid}
                        onExit={onCloseFlyoutModal}
                    />
                )}
            </FlyoutModal>
        );
    }

    const handleIDAMUserManagement = () => {
        history.push('/management/idamuser/all');
    };
    const handleIDAMUserProfile = () => {
        setOpenFlyout(true);
    };

    const IDAMLogout = (userType) => {
        const logoutURL = `${env.GATEWAY_BASE_URL}/v1/auth/idam/${userType}/logout`;

        session.clear();
        clearStorage();
        clearCSRFToken();
        onUnloadAuthedUser();

        window.location.href = logoutURL;
    };
    return (
        <div>
            <Helmet>
                <title>My Ads Portal</title>
                <meta name="description" content="Campaigns Dashboard" />
            </Helmet>
            <div>{flyoutModel}</div>
            <div style={{ padding: '3rem' }}>
                <h2>Test IDAM User Management</h2>
                <div>
                    <Button type="submit" onClick={handleIDAMUserManagement}>
                        IDAM User Management
                    </Button>
                </div>
                <div>
                    <Button type="submit" onClick={handleIDAMUserProfile}>
                        IDAM User Profile
                    </Button>
                </div>
                <div>
                    <Button type="submit" onClick={() => IDAMLogout('internal')}>
                        IDAM Internal Logout
                    </Button>
                </div>
                <div>
                    <Button type="submit" onClick={() => IDAMLogout('external')}>
                        IDAM External Logout
                    </Button>
                </div>
            </div>
        </div>
    );
}

// export for testing
export function mapDispatchToProps(dispatch) {
    return {
        onUnloadAuthedUser: () => dispatch(unloadAuthedUser()),
    };
}

AuthedDevPage.propTypes = {
    onUnloadAuthedUser: PropTypes.func,
};
const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(AuthedDevPage);
