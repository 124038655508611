import { camelCase } from 'lodash';
import axios from 'axios';
import devEnv from './devEnv';

async function loadEnv() {
    try {
        if (process.env.NODE_ENV === 'development') {
            return devEnv;
        }

        const urlResp = await axios.get('/liveConfig');
        const config = urlResp.data;

        const { hostname } = document.location;

        let gatewayURL = config.REACT_APP_GATEWAY_API_BASE;
        if (hostname === config.REACT_APP_HOSTNAME_INTERNAL && config.REACT_APP_GATEWAY_API_INTERNAL) {
            gatewayURL = config.REACT_APP_GATEWAY_API_INTERNAL;
        } else if (hostname === config.REACT_APP_HOSTNAME_EXTERNAL && config.REACT_APP_GATEWAY_API_EXTERNAL) {
            gatewayURL = config.REACT_APP_GATEWAY_API_EXTERNAL;
        }

        const featureFlags = Object.entries(config).reduce((acc, [key, value]) => {
            if (key.startsWith('FEATURE_FLAG_')) {
                const capsFlagName = key.replace('FEATURE_FLAG_', '');
                const flagName = camelCase(capsFlagName);
                return { ...acc, [flagName]: value === true };
            }
            return acc;
        }, {});

        return {
            API_BASE_URL: config.REACT_APP_PORTAL_API_BASE,
            DATA_VIZ_BASE_URL: config.REACT_APP_VIZ_API_BASE,
            GATEWAY_BASE_URL: gatewayURL,
            APP_VERSION: config.REACT_APP_VERSION,
            BBY_ENV: config.REACT_APP_BBY_ENV,
            ASSETS_BASE_URL: config.REACT_APP_ASSETS_BASE,
            ASSETS_VERSION_NUMBER: config.REACT_APP_ASSETS_VERSION_NUMBER,
            GA_ID: config.GA_ID,
            REACT_APP_ROLLING_SESSION_REFRESH: config.REACT_APP_ROLLING_SESSION_REFRESH,
            REACT_APP_IDLE_TIMER: config.REACT_APP_IDLE_TIMER,
            REACT_APP_IDLE_STAY_TIMEOUT: config.REACT_APP_IDLE_STAY_TIMEOUT,
            REACT_APP_ALERT: config.REACT_APP_ALERT,
            FEATURE_FLAGS: featureFlags,
            ...config,
        };
    } catch {
        return {
            GA_ID: 'G-197QR6SYPR',
            GATEWAY_BASE_URL:
                'https://ad-tools-api-gateway-akamai-ad-tech-tools.east-akamai.aws.containers.bestbuy.com',
            APP_VERSION: '',
            BBY_ENV: 'production',
            ASSETS_BASE_URL: 'https://www.bestbuy.com/~assets/bby/_com',
            ASSETS_VERSION_NUMBER: '1.2.21',
            REACT_APP_ROLLING_SESSION_REFRESH: '1200000',
            REACT_APP_IDLE_TIMER: '3300000',
            REACT_APP_IDLE_STAY_TIMEOUT: '300000',
        };
    }
}

const env = loadEnv();

export default await env;
