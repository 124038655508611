import { comparator } from './sortComparator';

export const formatBrands = (orgBrands, brands) => {
    const orgBrandMatches =
        orgBrands &&
        brands
            .filter((brand) => orgBrands.includes(brand.name))
            .map(({ name, id }) => ({ name, value: id }))
            .slice()
            .sort((a, b) => comparator(a, b));
    return orgBrandMatches;
};
