/*
 *
 * RecentViewedPage slice
 *
 */
import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from 'shared/constants';

export const initialState = {
    recentViewedList: [],
    error: false,
    sortOrder: {
        column: {},
        order: '',
    },
    pageNumber: 0,
};

/* eslint-disable default-case, no-param-reassign */
const recentViewedPageSlice = createSlice({
    name: 'recentViewedPage',
    initialState,
    reducers: {
        loadRecentViewedListAction(draft) {
            draft.recentViewedListLoadState = RequestStatus.LOADING;
        },
        loadRecentViewedListSuccessAction(draft, action) {
            draft.recentViewedListLoadState = RequestStatus.DONE;
            draft.recentViewedList = action.payload;
        },
        loadRecentViewedListErrorAction(draft, action) {
            draft.recentViewedListLoadState = RequestStatus.ERROR;
            draft.recentViewedListLoadError = action.payload;
        },
        addCampaignToRecentViewedListAction(draft) {
            draft.addRecentViewedListLoadState = RequestStatus.LOADING;
        },
        addCampaignToRecentViewedListSuccessAction(draft, action) {
            draft.addRecentViewedListLoadState = RequestStatus.DONE;
            draft.addRecentViewed = action.payload;
        },
        addCampaignToRecentViewedListErrorAction(draft) {
            draft.addRecentViewedListLoadState = RequestStatus.ERROR;
            draft.error = true;
        },
        clearRecentViewedListAction(draft) {
            draft.clearRecentViewedListLoadState = RequestStatus.LOADING;
        },
        clearRecentViewedListSuccessAction(draft) {
            draft.clearRecentViewedListLoadState = RequestStatus.DONE;
            draft.recentViewedList = [];
            draft.error = false;
        },
        clearRecentViewedListErrorAction(draft) {
            draft.clearRecentViewedListLoadState = RequestStatus.ERROR;
            draft.error = true;
        },
        updatePageNumber(draft, action) {
            draft.pageNumber = action.payload;
        },
        updateRVSortOrder(draft, action) {
            draft.sortOrder.column = action.payload.column;
            draft.sortOrder.order = action.payload.order;
        },
    },
});

export const {
    loadRecentViewedListAction,
    loadRecentViewedListSuccessAction,
    loadRecentViewedListErrorAction,
    addCampaignToRecentViewedListAction,
    addCampaignToRecentViewedListSuccessAction,
    addCampaignToRecentViewedListErrorAction,
    clearRecentViewedListAction,
    clearRecentViewedListSuccessAction,
    clearRecentViewedListErrorAction,
    updatePageNumber,
    updateRVSortOrder,
} = recentViewedPageSlice.actions;
export default recentViewedPageSlice.reducer;
