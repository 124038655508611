export const REINVITE_IDAM_USERS = 'portal/ReinviteUserContent/REINVITE_IDAM_USERS';
export const REINVITE_IDAM_USERS_SUCCESS = 'portal/ReinviteUserContent/REINVITE_IDAM_USERS_SUCCESS';
export const REINVITE_IDAM_USERS_ERROR = 'portal/ReinviteUserContent/REINVITE_IDAM_USERS_ERROR';

export function reinviteIdamUser(user) {
    return {
        type: REINVITE_IDAM_USERS,
        user,
    };
}

export function reinviteIdamUserSuccess(user) {
    return {
        type: REINVITE_IDAM_USERS_SUCCESS,
        user,
    };
}

export function reinviteIdamUserError(error) {
    return {
        type: REINVITE_IDAM_USERS_ERROR,
        error,
    };
}
