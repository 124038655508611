import { hasPermission } from 'Helpers/helpers';
import { baseDefinitions } from './definitions/baseDefinitions';
import { onsiteDisplayDefinitions } from './definitions/onsite-display/onsiteDisplayDefinitions';
import { offsiteDisplayDefinitions } from './definitions/offsite/offsite-display/offsiteDisplayDefinitions';
import { offsiteVideoDefinitions } from './definitions/offsite/offsite-video/offsiteVideoDefinitions';
import { onsiteVideoDefinitions } from './definitions/onsite-video/onsiteVideoDefinitions';
import { paidSearchDefinitions } from './definitions/paidsearchsku/paidSearchDefinitions';
import { proximityDefinitions } from './definitions/proximity/proximityDefinitions';
import { socialDefinitions } from './definitions/social/socialDefinitions';
import { sponsoredRmpDefinitions } from './definitions/sponsored/sponsoredRmp/sponsoredRmpDefinitions';

const VEHICLE_DEFS = {
    [onsiteDisplayDefinitions.campaignType]: onsiteDisplayDefinitions,
    [offsiteDisplayDefinitions.campaignType]: offsiteDisplayDefinitions,
    [offsiteVideoDefinitions.campaignType]: offsiteVideoDefinitions,
    [onsiteVideoDefinitions.campaignType]: onsiteVideoDefinitions,
    [paidSearchDefinitions.campaignType]: paidSearchDefinitions,
    [proximityDefinitions.campaignType]: proximityDefinitions,
    [socialDefinitions.campaignType]: socialDefinitions,
    [sponsoredRmpDefinitions.campaignType]: sponsoredRmpDefinitions,
};

export class Vehicle {
    #definitions;

    constructor(definitions) {
        this.#definitions = { ...baseDefinitions, ...definitions };
    }

    getLabel() {
        return this.#definitions.label;
    }

    getVehicleKey() {
        return this.#definitions.vehicleKey;
    }

    getCampaignType() {
        return this.#definitions.campaignType;
    }

    getGroupName() {
        return this.#definitions.groupName;
    }

    showViewability() {
        return this.#definitions.showViewability;
    }

    // Permissions

    hasViewPermission(user) {
        return hasPermission(user, this.#definitions.viewPermissions);
    }

    hasDownloadPermissions(user) {
        return hasPermission(user, this.#definitions.downloadPermissions);
    }

    // Campaign Details Page

    get fetchCampaignDetails() {
        return this.#definitions.detailsApiCall;
    }

    useNewColumnFilter() {
        return this.#definitions.useNewColumnFilter;
    }

    getDefaultTab() {
        return this.#definitions.defaultTabKey;
    }

    getTabs() {
        return this.#definitions.tabs;
    }

    getColumns(tab) {
        if (tab) {
            return this.#definitions.columns[tab];
        }
        return this.#definitions.columns;
    }

    getDefaultColumns() {
        return this.#definitions.defaultColumns;
    }

    getModifyColumns(tab) {
        return this.#definitions.modifyColumns[tab];
    }

    getColumnMap() {
        return this.#definitions.columnMap;
    }

    getColumnFilterMap() {
        if (this.#definitions.columnFilterMap) {
            return this.#definitions.columnFilterMap;
        }

        return this.#definitions.columnMap;
    }

    getCards() {
        return this.#definitions.cards;
    }

    getVisualizationOptions() {
        return this.#definitions.visualizationOptions;
    }

    // Attribution

    getAttributionWindow() {
        return this.#definitions.attributionWindowDays;
    }

    hasAttribution() {
        return this.#definitions.attributionWindowDays > 0;
    }

    renderTooltipContent = (flags) => this.#definitions.tooltipRenderFn(this.getLabel(), flags);

    getResourcesPageUrl() {
        return this.#definitions.resourcesPageUrl;
    }

    // Downloads

    get downloadReport() {
        return this.#definitions.reportDownloadApiCall;
    }

    getSheets() {
        return this.#definitions.sheets;
    }

    static getFromCampaignData(campaign) {
        if (campaign.type) {
            return this.getFromCampaignType(campaign.type);
        }

        return this.getFromCampaignType(campaign.campaign_type);
    }

    static getFromCampaignType(campaignType) {
        const definition = VEHICLE_DEFS[campaignType];
        if (!definition) {
            return new Vehicle({});
        }
        return new Vehicle(definition);
    }

    static getFromGroupName(groupName) {
        const defs = Object.values(VEHICLE_DEFS).find((def) => def.groupName === groupName);

        if (defs) {
            return new Vehicle(defs);
        }

        return new Vehicle({});
    }

    static getFromKey(key) {
        if (key) {
            /* eslint-disable no-restricted-syntax */
            for (const def of Object.values(VEHICLE_DEFS)) {
                if (def.vehicleKey === key) {
                    return new Vehicle(def);
                }
            }
        }

        return new Vehicle({});
    }

    static getAllVehicles() {
        return Object.values(VEHICLE_DEFS).map((def) => new Vehicle(def));
    }
}
