import { downloadSocialReport, getSocialCampaignDetails } from 'api/api-client';
import { Permissions } from 'shared/constants/user/permissions';
import { CAMPAIGN_TYPE_SOCIAL, SOCIAL_KEY } from 'Helpers/helpers';
import {
    columnMap,
    modifyColumns,
    SOCIAL_TAB_DEFAULT_KEY,
    socialColumns,
    socialTabs,
    socialCards,
    dzSocialOptions,
    socialSheets,
    defaultColumns,
} from './index';

export const socialDefinitions = {
    label: 'Social',
    vehicleKey: SOCIAL_KEY,
    campaignType: CAMPAIGN_TYPE_SOCIAL,

    // Permissions
    viewPermissions: [Permissions.CAN_VIEW_SOCIAL_CAMPAIGNS],
    downloadPermissions: [Permissions.CAN_DOWNLOAD_SOCIAL_CAMPAIGNS],

    // Campaign Details Page
    detailsApiCall: getSocialCampaignDetails,
    useNewColumnFilter: false,
    defaultTabKey: SOCIAL_TAB_DEFAULT_KEY,
    columnMap,
    cards: socialCards,
    tabs: socialTabs,
    columns: socialColumns,
    modifyColumns,
    defaultColumns,
    visualizationOptions: dzSocialOptions,

    // Attribution
    attributionWindowDays: 0,

    // Report Download
    reportDownloadApiCall: downloadSocialReport,
    sheets: socialSheets,
};
