/**
 *
 * DropdownItem
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

function DropdownItem({ title, subtitle, bold }) {
    let subtitleItem;

    if (subtitle) {
        subtitleItem = (
            <Typography variant="body2" color="textSecondary">
                {subtitle}
            </Typography>
        );
    }

    return (
        <Grid container alignItems="center">
            <Grid item xs>
                <div>
                    <span style={{ fontWeight: bold ? 700 : 400 }}>{title}</span>
                    {subtitleItem}
                </div>
            </Grid>
        </Grid>
    );
}

DropdownItem.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    bold: PropTypes.bool,
};

export default memo(DropdownItem);
