import { makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { DefaultTooltipContent } from './defaultTooltip';

const useStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: 500,
    },
    attribution: {
        marginBottom: theme.spacing(2),
    },
}));

export function SponsoredRmpTooltipContent(props) {
    const classes = useStyles();
    return (
        <>
            <div className={classes.attribution}>
                <span className={classes.bold}>Attribution</span> -{' '}
                <span>Sponsored Product Attribution data can vary for the previous 5 days from today&apos;s date.</span>
            </div>
            <DefaultTooltipContent campaignTypeName={props.campaignTypeName} />
        </>
    );
}

SponsoredRmpTooltipContent.propTypes = {
    campaignTypeName: PropTypes.string,
};

export const renderSponsoredRmpTooltipContent = (campaignTypeName) => (
    <SponsoredRmpTooltipContent campaignTypeName={campaignTypeName} />
);
