/*
 *
 * UserPage reducer
 *
 */
import produce from 'immer';
import {
    LOAD_ROLES,
    LOAD_ROLES_SUCCESS,
    LOAD_ROLES_ERROR,
    LOAD_BRANDS,
    LOAD_BRANDS_SUCCESS,
    LOAD_BRANDS_ERROR,
    UPDATE_USER_FILTER,
    DEFAULT_FILTER_NAME,
    LOAD_ORG_OPTIONS,
    LOAD_ORG_OPTIONS_SUCCESS,
    LOAD_ORG_OPTIONS_ERROR,
    UPDATE_PAGE_NUMBER,
} from './constants';
import { RequestStatus } from '../../../shared/constants';

const initialFilterState = {
    loadStatus: RequestStatus.INIT,
    currentFilterName: DEFAULT_FILTER_NAME,
    userBrandOptions: [],
    userStatusOptions: ['Active', 'Code Expired', 'Invite Expired', 'Invite Sent', 'Reg. In Process', 'Unknown'],
    userTypeOptions: [],
};

export const initialState = {
    loading: false,
    error: false,
    roles: [],
    brands: {
        loading: false,
        fetched: false,
        error: false,
        values: [],
    },
    organizations: {
        loadStatus: RequestStatus.INIT,
        error: null,
        values: [],
    },
    userFilters: initialFilterState,
    pageNumber: 0,
};

/* eslint-disable default-case, no-param-reassign */
const userListPageReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case LOAD_ROLES:
                draft.loading = true;
                draft.error = false;
                break;
            case LOAD_ROLES_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.roles = action.roles;
                break;
            case LOAD_ROLES_ERROR:
                draft.loading = true;
                draft.error = action.error;
                break;
            case LOAD_BRANDS:
                draft.brands.loading = true;
                draft.brands.error = false;
                break;
            case LOAD_BRANDS_SUCCESS:
                draft.brands.loading = false;
                draft.brands.error = false;
                draft.brands.fetched = true;
                draft.brands.values = action.brands;
                break;
            case LOAD_BRANDS_ERROR:
                draft.brands.loading = true;
                draft.brands.fetched = true;
                draft.brands.error = action.error;
                break;
            case UPDATE_USER_FILTER:
                draft.userFilters = action.filter;
                break;
            case LOAD_ORG_OPTIONS:
                draft.organizations.loadStatus = RequestStatus.LOADING;
                draft.organizations.error = null;
                break;
            case LOAD_ORG_OPTIONS_SUCCESS:
                draft.organizations.loadStatus = RequestStatus.DONE;
                draft.organizations.values = action.payload.organizations;
                break;
            case LOAD_ORG_OPTIONS_ERROR:
                draft.brands.loadStatus = RequestStatus.ERROR;
                draft.brands.error = action.payload.error;
                break;
            case UPDATE_PAGE_NUMBER:
                draft.pageNumber = action.payload;
                break;
        }
    });

export default userListPageReducer;
