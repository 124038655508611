/**
 *
 * UserDetailContent
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FlyoutContent } from 'components/FlyoutModal';
import { makeStyles } from '@material-ui/core/styles';
import { allRolesLabels } from 'components/UserForm/constants';
import { getUserStatusDisplayInfo } from 'shared/constants/user/user-status';

const useStyles = makeStyles(() => ({
    userDetail: {
        fontFamily: 'humanBBYDigital',
    },
}));

function UserDetailContent({ user, displayStatusAndCreator }) {
    const classes = useStyles();

    if (!user) {
        return <span>Not able to load user</span>;
    }

    return (
        <div>
            {displayStatusAndCreator && (
                <FlyoutContent label="User Status">{getUserStatusDisplayInfo(user.user_status).text}</FlyoutContent>
            )}
            <FlyoutContent label="First Name">
                <div className={classes.userDetail}>{user.first_name}</div>
            </FlyoutContent>
            <FlyoutContent label="Last Name">
                <div className={classes.userDetail}>{user.last_name}</div>
            </FlyoutContent>
            <FlyoutContent label="Email">
                <div className={classes.userDetail}>{user.email}</div>
            </FlyoutContent>
            {user.aid && (
                <FlyoutContent label="AID">
                    <div className={classes.userDetail}>{user.aid}</div>
                </FlyoutContent>
            )}
            <FlyoutContent label="Role">
                <div className={classes.userDetail}>
                    {user.roles.map((role) => (
                        <div key={role}>{allRolesLabels[role]}</div>
                    ))}
                </div>
            </FlyoutContent>
            {user.organizationName && !user.isExtUser && (
                <FlyoutContent label="Organization">
                    <div className={classes.userDetail}>{user.organizationName}</div>
                </FlyoutContent>
            )}
            {user.brandNames?.length > 0 && (
                <FlyoutContent label="Brand">
                    <div className={classes.userDetail}>
                        {user.brandNames.map((name) => (
                            <div key={name}>{name}</div>
                        ))}
                    </div>
                </FlyoutContent>
            )}
            {displayStatusAndCreator && (
                <FlyoutContent label="Creator">
                    <div className={classes.userDetail}>{user.created_by_name}</div>
                </FlyoutContent>
            )}
        </div>
    );
}

UserDetailContent.propTypes = {
    user: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        aid: PropTypes.string,
        email: PropTypes.string.isRequired,
        roles: PropTypes.array.isRequired,
        active: PropTypes.bool,
        brandNames: PropTypes.array,
        created_by_name: PropTypes.string,
        organizationName: PropTypes.string,
        user_status: PropTypes.string,
        isExtUser: PropTypes.bool,
    }),
    displayStatusAndCreator: PropTypes.bool,
};

export default UserDetailContent;
