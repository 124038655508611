/**
 *
 * Button
 *
 */

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { v4 as uuid } from 'uuid';
// STYLES
const useStyles = makeStyles(() => ({
    root: {},
    menuItem: {
        padding: '0',
        background: 'none !important',
        cursor: 'default',

        '& button': {
            borderRadius: 0,
            fontFamily: 'HumanBBYDigital, sans-serif',
            width: '100%',
            margin: '0 !important',
            padding: '7px 20px',
            justifyContent: 'flex-start',
            textTransform: 'none',
            fontWeight: 'normal',
            transition: 'background .5s',

            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },

            '&[disabled]': {
                background: 'none !important',
                color: 'rgba(0, 0, 0, 0.26) !important',
            },
        },
    },
}));

const ITEM_HEIGHT = 48;

const VerticalMenu = ({ buttons }) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);

    return (
        <div className={classes.root}>
            <IconButton
                ref={anchorRef}
                data-testid="more"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={onOpen}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorRef.current}
                keepMounted
                open={open}
                onClose={onClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {buttons.map((button) => (
                    <MenuItem
                        key={button.key ? `${button.key}` : uuid()}
                        className={classes.menuItem}
                        // selected={index === 0}
                        onClick={onClose}
                    >
                        {button}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

VerticalMenu.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default VerticalMenu;
