/*
 *
 * IDAMLoginSuccessPage actions
 *
 */

import { IDAM_POST_LOGIN_SUCCESS, IDAM_POST_LOGIN_ERROR } from './constants';

export function IDAMPostLoginSuccess(history) {
    return {
        type: IDAM_POST_LOGIN_SUCCESS,
        history,
    };
}

export function IDAMPostLoginError(error) {
    return {
        type: IDAM_POST_LOGIN_ERROR,
        error,
    };
}
