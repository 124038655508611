/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Button, Checkbox, FormLabel, Link } from '@material-ui/core';
import classnames from 'classnames';
import { Form, Field } from 'react-final-form';
import {
    required,
    isValidPhone,
    composeValidators,
    maxLength,
    minLengthEqual,
    isNotWhitespace,
} from 'utils/validators';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Input from 'components/BrandedField';
import useFeatureFlags from 'utils/featureFlags/useFeatureFlags';
import useStyles from '../formStyle';

const normalizePhone = (value) => {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 3) return onlyNums;
    if (onlyNums.length <= 7) return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 7)}`;
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
};

export function UserUpdatePage({ onSubmit = () => {}, firstName, lastName, email }) {
    const classes = useStyles();
    const [termsAndConditions, setTermsAndConditions] = useState(false);

    const featureFlags = useFeatureFlags();

    return (
        <>
            <Grid item xs={12} className={classes.bodyContainer}>
                <Grid item xs={12}>
                    <div className={classes.bodyLeft}>
                        <div className={classes.formTitle}>Customer Profile.</div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.bodyRight}>
                        <div className={classes.formContainer} id="otp-form-container">
                            <Form
                                onSubmit={onSubmit}
                                subscription={{
                                    submitting: true,
                                    pristine: true,
                                    submitError: true,
                                    hasValidationErrors: true,
                                }}
                                initialValues={{
                                    first: firstName,
                                    last: lastName,
                                    email,
                                }}
                                render={({ handleSubmit, form: { reset }, hasValidationErrors, submitError }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className={classnames(classes.prompt)}>
                                            Review your profile information.
                                        </div>
                                        <div>
                                            <Field
                                                validate={composeValidators(
                                                    required,
                                                    maxLength(50),
                                                    minLengthEqual(2),
                                                    isNotWhitespace,
                                                )}
                                                name="first"
                                                type="text"
                                                inputLabel="First Name"
                                                reset={reset}
                                                id="user-update-first-name"
                                            >
                                                {Input}
                                            </Field>
                                        </div>
                                        <div>
                                            <Field
                                                validate={composeValidators(
                                                    required,
                                                    maxLength(50),
                                                    minLengthEqual(2),
                                                    isNotWhitespace,
                                                )}
                                                name="last"
                                                type="text"
                                                inputLabel="Last Name"
                                                reset={reset}
                                                id="user-update-last-name"
                                            >
                                                {Input}
                                            </Field>
                                        </div>
                                        <div className={classes.formStaticField}>
                                            <Field type="text" name="email" inputLabel="Email" disabled>
                                                {Input}
                                            </Field>
                                        </div>
                                        {featureFlags.showIdamPhone && (
                                            <>
                                                <div className={classnames(classes.prompt)}>
                                                    Optional: Add your mobile # to receive a security code.
                                                </div>
                                                <div>
                                                    <Field
                                                        validate={composeValidators(isValidPhone)}
                                                        name="phone"
                                                        type="code"
                                                        inputLabel="Mobile #"
                                                        reset={reset}
                                                        parse={normalizePhone}
                                                    >
                                                        {Input}
                                                    </Field>
                                                </div>
                                            </>
                                        )}
                                        <div className={classes.termsAndConditionsBlock}>
                                            <Checkbox
                                                checked={termsAndConditions}
                                                onChange={() => setTermsAndConditions(!termsAndConditions)}
                                                className={classes.termsAndConditionsCheckbox}
                                                id="terms-conditions-checkbox"
                                            />
                                            <FormLabel
                                                className={classes.checkboxLabel}
                                                htmlFor="terms-conditions-checkbox"
                                            >
                                                I agree to the&nbsp;
                                                <Link
                                                    underline="always"
                                                    className={classes.link}
                                                    target="_blank"
                                                    href="https://www.bestbuy.com/site/help-topics/terms-and-conditions/pcmcat204400050067.c?id=pcmcat204400050067"
                                                    rel="noopener"
                                                >
                                                    Terms and Conditions
                                                </Link>
                                                &nbsp;and&nbsp;
                                                <Link
                                                    underline="always"
                                                    className={classes.link}
                                                    target="_blank"
                                                    href="/terms"
                                                    rel="noopener"
                                                >
                                                    MyAds Usage Terms
                                                </Link>
                                                .
                                            </FormLabel>
                                        </div>
                                        {submitError && (
                                            <div className={classnames(classes.submitError, classes.errorStyle)}>
                                                <span data-testid="user-update-submit-error">{submitError}</span>
                                            </div>
                                        )}
                                        <div>
                                            {/* SUBMISSION BUTTON START */}
                                            <Button
                                                data-testid="user-update-form-button"
                                                className={classnames(classes.formButton)}
                                                disabled={hasValidationErrors || !termsAndConditions}
                                                type="submit"
                                                endIcon={<ArrowForwardIcon className={classes.arrowIcon} />}
                                            >
                                                Submit
                                            </Button>
                                            {/* SUBMISSION BUTTON END */}
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

UserUpdatePage.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string.isRequired,
};

export default UserUpdatePage;
