// eslint-disable-next-line import/no-cycle
import { hasPermission } from 'Helpers/helpers';

// TODO: move to common lib
export const Permissions = {
    CAN_APPROVE_CAMPAIGNS: 'CAN_APPROVE_CAMPAIGNS',
    CAN_APPROVE_CREATIVES: 'CAN_APPROVE_CREATIVES',
    CAN_CREATE_CAMPAIGNS: 'CAN_CREATE_CAMPAIGNS',
    CAN_CREATE_USERS_ON_ACCOUNT: 'CAN_CREATE_USERS_ON_ACCOUNT',
    CAN_DELETE_CAMPAIGNS: 'CAN_DELETE_CAMPAIGNS',
    CAN_DOWNLOAD_OFFSITE_VIDEO_CAMPAIGNS: 'CAN_DOWNLOAD_OFFSITE_VIDEO_CAMPAIGNS',
    CAN_DOWNLOAD_OFFSITE_DISPLAY_CAMPAIGNS: 'CAN_DOWNLOAD_OFFSITE_DISPLAY_CAMPAIGNS',
    CAN_DOWNLOAD_ONSITE_DISPLAY_CAMPAIGNS: 'CAN_DOWNLOAD_ONSITE_DISPLAY_CAMPAIGNS',
    CAN_DOWNLOAD_ONSITE_VIDEO_CAMPAIGNS: 'CAN_DOWNLOAD_ONSITE_VIDEO_CAMPAIGNS',
    CAN_DOWNLOAD_PAID_SEARCH_CAMPAIGNS: 'CAN_DOWNLOAD_PAID_SEARCH_CAMPAIGNS',
    CAN_DOWNLOAD_PROXIMITY_CAMPAIGNS: 'CAN_DOWNLOAD_PROXIMITY_CAMPAIGNS',
    CAN_DOWNLOAD_SOCIAL_CAMPAIGNS: 'CAN_DOWNLOAD_SOCIAL_CAMPAIGNS',
    CAN_DOWNLOAD_SPONSORED_RMP_CAMPAIGNS: 'CAN_DOWNLOAD_SPONSORED_RMP_CAMPAIGNS',
    CAN_DOWNLOAD_SPONSORED_RSX_CAMPAIGNS: 'CAN_DOWNLOAD_SPONSORED_RSX_CAMPAIGNS',
    CAN_INVITE_USERS: 'CAN_INVITE_USERS',
    CAN_UPDATE_CAMPAIGNS: 'CAN_UPDATE_CAMPAIGNS',
    CAN_CREATE_USER: 'CAN_CREATE_USER',
    CAN_MANAGE_ACCOUNT: 'CAN_MANAGE_ACCOUNT',
    CAN_UPDATE_USERS: 'CAN_UPDATE_USERS',
    CAN_USE_CAMPAIGN_FORECASTING: 'CAN_USE_CAMPAIGN_FORECASTING',
    CAN_USE_CAMPAIGN_INV_MGMT: 'CAN_USE_CAMPAIGN_INV_MGMT',
    CAN_USE_CAMPAIGN_MODELING: 'CAN_USE_CAMPAIGN_MODELING',
    CAN_USE_CAMPAIGN_PRICING: 'CAN_USE_CAMPAIGN_PRICING',
    CAN_USE_CREATIVE_DEVELOPMENT_TOOL: 'CAN_USE_CREATIVE_DEVELOPMENT_TOOL',
    CAN_USE_SNIPPET_BUILDER: 'CAN_USE_SNIPPET_BUILDER',
    CAN_USE_URL_CREATION_TOOL: 'CAN_USE_URL_CREATION_TOOL',
    CAN_VIEW_ACCOUNT_BILLING: 'CAN_VIEW_ACCOUNT_BILLING',
    CAN_VIEW_CAMPAIGN_REPORTS: 'CAN_VIEW_CAMPAIGN_REPORTS', // TODO: this is DEPRECATED, should be removed after new permissions are updated in Production
    CAN_VIEW_INVOICING: 'CAN_VIEW_INVOICING',
    CAN_VIEW_OFFSITE_VIDEO_CAMPAIGNS: 'CAN_VIEW_OFFSITE_VIDEO_CAMPAIGNS',
    CAN_VIEW_OFFSITE_DISPLAY_CAMPAIGNS: 'CAN_VIEW_OFFSITE_DISPLAY_CAMPAIGNS',
    CAN_VIEW_ONSITE_DISPLAY_CAMPAIGNS: 'CAN_VIEW_ONSITE_DISPLAY_CAMPAIGNS',
    CAN_VIEW_ONSITE_VIDEO_CAMPAIGNS: 'CAN_VIEW_ONSITE_VIDEO_CAMPAIGNS',
    CAN_VIEW_PAID_SEARCH_CAMPAIGNS: 'CAN_VIEW_PAID_SEARCH_CAMPAIGNS',
    CAN_VIEW_PROXIMITY_CAMPAIGNS: 'CAN_VIEW_PROXIMITY_CAMPAIGNS',
    CAN_VIEW_SOCIAL_CAMPAIGNS: 'CAN_VIEW_SOCIAL_CAMPAIGNS',
    CAN_VIEW_SPONSORED_RMP_CAMPAIGNS: 'CAN_VIEW_SPONSORED_RMP_CAMPAIGNS',
    CAN_SHARE_REPORT: 'CAN_SHARE_REPORT',
};

export const canManageAccount = (authedUser) => hasPermission(authedUser, [Permissions.CAN_MANAGE_ACCOUNT]);

export const canShareReport = (authedUser) => hasPermission(authedUser, [Permissions.CAN_SHARE_REPORT]);

export const UserManagePermissions = [
    Permissions.CAN_CREATE_USER,
    Permissions.CAN_UPDATE_USERS,
    Permissions.CAN_CREATE_USERS_ON_ACCOUNT,
    Permissions.CAN_INVITE_USERS,
];

export const canManageUsers = (authedUser) => hasPermission(authedUser, UserManagePermissions);

export const PERMISSIONS_LIST = Object.values(Permissions);
