import { RequestStatus } from 'shared/constants';
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    organizationLoadState: RequestStatus.INIT,
    updateProviderLoadState: RequestStatus.INIT,
    getProvidersLoadState: RequestStatus.INIT,
    createKoddiProviderLoadState: RequestStatus.INIT,
    organizationLoadError: null,
    organizations: [],
    organizationFilter: {
        keyword: '',
        includes: [
            { name: 'name', label: 'Organizations', checked: true },
            { name: 'brands', label: 'Brands', checked: true },
        ],
    },
    brandLoadState: RequestStatus.INIT,
    brandLoadError: null,
    brands: [],
    providers: [],
    pageNumbers: { organization: 0, brand: 0, account: 0 },
};

const organizationPageSlice = createSlice({
    name: 'organizationPage',
    initialState,
    reducers: {
        loadOrganizationListAction(draft) {
            draft.organizationLoadState = RequestStatus.LOADING;
        },
        loadOrganizationListSuccessAction(draft, action) {
            draft.organizationLoadState = RequestStatus.DONE;
            draft.organizations = action.payload;
        },
        loadOrganizationListErrorAction(draft, action) {
            draft.organizationLoadState = RequestStatus.ERROR;
            draft.organizationLoadError = action.payload;
        },
        updateFilteredOrganizations(draft, action) {
            draft.organizationFilter = action.payload;
        },
        loadBrandAction(draft) {
            draft.brandLoadState = RequestStatus.LOADING;
        },
        loadBrandSuccessAction(draft, action) {
            draft.brandLoadState = RequestStatus.DONE;
            draft.brands = action.payload;
        },
        loadBrandErrorAction(draft, action) {
            draft.brandLoadState = RequestStatus.ERROR;
            draft.organizationLoadError = action.payload;
        },
        updateOrganizationPageNumber(draft, action) {
            draft.pageNumbers.organization = action.payload;
        },
        updateOrganizationAction(draft, action) {
            const newOrgList = draft.organizations.map((org) => {
                if (org.orgId === action.payload.orgId) {
                    return action.payload;
                }
                return org;
            });
            draft.organizations = newOrgList;
        },
        loadProvidersAction(draft) {
            draft.getProvidersLoadState = RequestStatus.LOADING;
        },
        loadProvidersSuccessAction(draft, action) {
            draft.getProvidersLoadState = RequestStatus.DONE;
            const isDuplicateProvider = draft.providers.filter(
                (provider) => action.payload.providerId === provider?.providerId,
            );
            if (isDuplicateProvider.length === 0) {
                draft.providers = [...draft.providers, action.payload];
            }
        },
        loadProvidersError(draft, action) {
            draft.getProvidersLoadState = RequestStatus.ERROR;
            draft.getProvidersLoadError = action.payload;
        },
        createKoddiProviderSuccessAction(draft, action) {
            draft.createKoddiProviderLoadState = RequestStatus.DONE;
            draft.providers = [...draft.providers, action.payload];
        },
        createKoddiProviderError(draft, action) {
            draft.createKoddiProviderLoadState = RequestStatus.ERROR;
            draft.createKoddiProviderError = action.payload;
        },
        updateKoddiProviderSuccessAction(draft, action) {
            draft.updateKoddiProviderLoadState = RequestStatus.DONE;
            draft.providers = [...draft.providers, action.payload];
        },
        updateKoddiProviderError(draft, action) {
            draft.updateKoddiProviderLoadState = RequestStatus.ERROR;
            draft.updateKoddiProviderLoadError = action.payload;
        },
    },
});

export const {
    loadOrganizationListAction,
    loadOrganizationListErrorAction,
    loadOrganizationListSuccessAction,
    updateFilteredOrganizations,
    loadBrandAction,
    loadBrandSuccessAction,
    loadBrandErrorAction,
    updateOrganizationPageNumber,
    updateOrganizationAction,
    loadProvidersAction,
    loadProvidersSuccessAction,
    loadProvidersError,
    updateKoddiProviderSuccessAction,
    updateKoddiProviderError,
    createKoddiProviderSuccessAction,
    createKoddiProviderError,
} = organizationPageSlice.actions;
export default organizationPageSlice.reducer;
