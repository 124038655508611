import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Tooltip, Typography } from '@material-ui/core';
import classnames from 'classnames';
import { VALIDATE_ON_MODIFIED, VALIDATE_ON_SUBMIT } from 'utils/constants';
import { VisibilityOutlined, VisibilityOffOutlined, Check, Close } from '@material-ui/icons';
import { IconButton } from 'material-ui';
import { MuiThemeProvider } from 'material-ui/styles';
import {
    atLeastOneCapitalRegex,
    atLeastOneLowerCaseRegex,
    atLeastOneSpecialCharacterRegex,
    atLeastOneNumberRegex,
    atLeastEight,
} from 'utils/validators';
import Labels from './Labels';

const useStyles = makeStyles((theme) => ({
    maxWidth: {},
    inputStyle: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: 14,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        background: '#FFFFFF',
        borderRadius: '4px',
        marginBottom: '1em',
    },
    successStyle: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: `2px solid ${theme.palette.successGreen} !important`,
                color: theme.palette.successGreen,
                fontFamily: theme.typography.fontFamily,
                fontWeight: 500,
            },
            '& svg': {
                fill: theme.palette.successGreen,
            },
        },
    },
    fieldErrorStyle: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: `2px solid ${theme.palette.valueRed} !important`,
                color: theme.palette.valueRed,
                fontFamily: theme.typography.fontFamily,
                fontWeight: 500,
            },
            '& svg': {
                fill: theme.palette.valueRed,
            },
        },
        '&.MuiFormHelperText-contained': {
            margin: '8px 0px 0',
        },
    },
    greenCheck: {
        fill: theme.palette.successGreen,
        paddingBottom: 0,
        paddingRight: 3,
        fontSize: 'medium',
    },
    redX: {
        fill: theme.palette.valueRed,
        paddingBottom: 0,
        paddingRight: 3,
        fontSize: 'medium',
    },
    validationLine: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    visibilityIcon: {
        fill: 'black !important',
        // validation overwrites otherwise
    },
    tooltip: {
        border: '1px solid #1d252c',
        backgroundColor: theme.palette.standardWhite,
        maxWidth: '270px',
    },
    arrow: {
        '&:before': {
            border: '1px solid #1d252c',
        },
        color: theme.palette.standardWhite,
    },
}));

const idamStyles = makeStyles((theme) => ({
    maxWidth: {
        flex: 1,
        minWidth: 350,
    },
    inputStyle: {
        margin: '0px 0px 0px 0px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: 18,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        flex: 1,
        '& .MuiInputBase-input': {
            color: theme.palette.standardWhite,
            fontSize: 22,
            paddingBottom: 16,
        },
        '& .MuiFormLabel-root': {
            color: theme.palette.labelGrey,
            fontSize: 34,
            fontWeight: 500,
        },
        '& .Mui-focused': {
            fontSize: 18,
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: theme.palette.labelGrey,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.standardWhite,
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: theme.palette.standardWhite,
            borderWidth: 2,
        },
    },
    successStyle: {
        '& .MuiFormLabel-root': {
            fontSize: 18,
        },
    },
    fieldErrorStyle: {
        '&.MuiFormHelperText-contained': {
            margin: '8px 0px 0',
        },
        '& .MuiFormHelperText-root.Mui-error': {
            margin: 0,
            color: theme.palette.valueRed,
            paddingTop: '4px',
            paddingLeft: '8px',
        },
        '& .Mui-error': {
            color: theme.palette.valueRed,
            fontSize: 18,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.valueRed,
            borderWidth: 2,
        },
    },
    greenCheck: {
        fill: theme.palette.successGreen,
        paddingBottom: 0,
        paddingRight: 3,
        fontSize: 'medium',
    },
    redX: {
        fill: theme.palette.valueRed,
        paddingBottom: 0,
        paddingRight: 3,
        fontSize: 'medium',
    },
    validationLine: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    visibilityIcon: {
        fill: '#9D9DA0 !important',
        // validation overwrites otherwise
    },
    tooltip: {
        border: '1px solid #1d252c',
        backgroundColor: theme.palette.standardWhite,
        maxWidth: '270px',
        boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.0)',
    },
    arrow: {
        '&:before': {
            border: '1px solid #1d252c',
        },
        color: theme.palette.standardWhite,
    },
}));

// This component is specifically an abstracted component to work with React Final Form to reduce some redundancy
const Password = ({
    meta: { touched, error, modified, submitFailed } = {
        touched: false,
        error: undefined,
    },
    input,
    inputLabel,
    inputSublabel,
    labelSpaceStyle,
    reset,
    inputProps,
    validateOn,
    idam,
    tooltipPlacement = 'left-end',
    ...props
}) => {
    const baseClasses = useStyles();
    const idamClasses = idamStyles();
    const classes = idam ? idamClasses : baseClasses;
    const [showPassword, setShowPassword] = React.useState(false);
    const [value, setValue] = React.useState('');

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const getValidateState = () => {
        switch (validateOn) {
            case VALIDATE_ON_SUBMIT:
                return submitFailed;
            case VALIDATE_ON_MODIFIED:
                return modified;
            default:
                return touched;
        }
    };
    const shouldValidate = getValidateState();

    const GreenCheck = () => <Check className={classes.greenCheck} />;
    const RedX = () => <Close className={classes.redX} />;

    const PasswordTooltip = () => (
        <div>
            <Typography variant="body1">Strong Password</Typography>
            <span className={classes.validationLine}>
                {value.match(atLeastEight) ? <GreenCheck /> : <RedX />}
                <Typography display="inline" variant="body2">
                    at least 8 characters
                </Typography>
            </span>
            <span className={classes.validationLine}>
                {value.match(atLeastOneCapitalRegex) ? <GreenCheck /> : <RedX />}
                <Typography display="inline" variant="body2">
                    at least one capital letter
                </Typography>
            </span>
            <span className={classes.validationLine}>
                {value.match(atLeastOneLowerCaseRegex) ? <GreenCheck /> : <RedX />}
                <Typography display="inline" variant="body2">
                    at least one lower case letter
                </Typography>
            </span>
            <span className={classes.validationLine}>
                {value.match(atLeastOneSpecialCharacterRegex) ? <GreenCheck /> : <RedX />}
                <Typography display="inline" variant="body2">
                    at least one special character (@*#!$)
                </Typography>
            </span>
            <span className={classes.validationLine}>
                {value.match(atLeastOneNumberRegex) ? <GreenCheck /> : <RedX />}
                <Typography display="inline" variant="body2">
                    at least one number
                </Typography>
            </span>
        </div>
    );

    const handleValueChange = (e, changeFunc) => {
        setValue(e.target.value);
        changeFunc(e);
    };

    return (
        <div className={classes.maxWidth}>
            {!idam && (
                <Labels inputLabel={inputLabel} inputSublabel={inputSublabel} labelSpaceStyle={labelSpaceStyle} />
            )}
            <Tooltip
                disableHoverListener
                title={<PasswordTooltip />}
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                placement={tooltipPlacement}
                arrow
                sx={{ boxShadow: 0 }}
            >
                <TextField
                    InputProps={{
                        ...inputProps,
                        endAdornment: (
                            <MuiThemeProvider>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? (
                                        <VisibilityOutlined className={classes.visibilityIcon} />
                                    ) : (
                                        <VisibilityOffOutlined className={classes.visibilityIcon} />
                                    )}
                                </IconButton>
                            </MuiThemeProvider>
                        ),
                    }}
                    {...input}
                    {...props}
                    fullWidth
                    label={idam && inputLabel}
                    className={classnames(
                        classes.inputStyle,
                        shouldValidate && !error && classes.successStyle,
                        shouldValidate && error && classes.fieldErrorStyle,
                    )}
                    error={!!(shouldValidate && error)}
                    helperText={shouldValidate && error}
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => handleValueChange(e, input.onChange)}
                />
            </Tooltip>
        </div>
    );
};

Password.propTypes = {
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
        valid: PropTypes.bool,
        modified: PropTypes.bool,
    }),
    inputProps: PropTypes.shape({
        'data-testid': PropTypes.string.isRequired,
    }),
    input: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        checked: PropTypes.bool,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
    }),
    inputLabel: PropTypes.string,
    inputSublabel: PropTypes.string,
    labelSpaceStyle: PropTypes.bool,
    reset: PropTypes.func,
    validateOn: PropTypes.string,
    idam: PropTypes.bool,
    tooltipPlacement: PropTypes.string,
};

export default Password;
