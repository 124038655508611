import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Form, Field } from 'react-final-form';
import { Button, Grid } from '@material-ui/core';
import { required, isNotBlank, composeValidators, isValidEmail } from 'utils/validators';
import { ArrowForwardSharp } from '@material-ui/icons';
import Input from 'components/BrandedField';
import useStyles from './formStyle';

export function ResetPasswordForm({ onSubmit }) {
    const classes = useStyles();

    return (
        <Grid item xs={12} className={classes.bodyContainer}>
            <Grid item xs={12}>
                <div className={classes.bodyLeft}>
                    <span className={classes.formTitleLine}>
                        <div className={classes.formTitle}>Reset</div>
                    </span>
                    <div className={classes.formTitle}>password.</div>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.bodyRight}>
                    <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit, pristine, hasValidationErrors }) => (
                            <form onSubmit={handleSubmit}>
                                <div className={classes.formFieldTitle}>Enter email to receive a temporary code</div>
                                <Field
                                    type="text"
                                    name="email"
                                    initialValue=""
                                    inputLabel="Email"
                                    validate={composeValidators(required, isNotBlank, isValidEmail)}
                                >
                                    {Input}
                                </Field>
                                <Button
                                    data-testid="submit-user-form-button"
                                    className={classnames(
                                        classes.formButton,
                                        (hasValidationErrors || pristine) && classes.disabled,
                                    )}
                                    disabled={hasValidationErrors || pristine}
                                    type="submit"
                                    variant="text"
                                    endIcon={<ArrowForwardSharp />}
                                >
                                    Submit
                                </Button>
                            </form>
                        )}
                    />
                </div>
            </Grid>
        </Grid>
    );
}

ResetPasswordForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
