import request, { formatPostOptions as postOptions, formatGetOptions as getOptions } from 'utils/request';
import env from 'config/env';
import { compact } from 'lodash';

const RECENT_BASE_URL = `${env.GATEWAY_BASE_URL}/v1`;

/**
 * Get recent viewed campaign list from user uuid
 *
 * @param {string} uuid uuid
 *
 * @returns {object} response data or error based upon status
 */
export const getRecentViewListApi = async (uuid) => {
    const options = getOptions();
    options.headers = { 'Content-Type': 'application/json' };
    const data = await request(`${RECENT_BASE_URL}/recent/getRecent?uuid=${uuid}`, options);

    if (data && data.payload && data.payload.result) {
        // eslint-disable-next-line no-restricted-syntax
        if (Array.isArray(data.payload.result)) {
            data.payload.result = compact(data.payload.result);
        }
    }

    return data;
};

/**
 * add recent viewed campaign in list from user uuid
 *
 * @param {string} uuid uuid
 *
 * @returns {object} response data or error based upon status
 */
export const addCampaignToRecentViewListApi = async (uuid, campaignId) => {
    const options = postOptions();

    const data = await request(`${RECENT_BASE_URL}/recent/addRecent?uuid=${uuid}&campaignId=${campaignId}`, options);

    return data;
};

/**
 * clear recent viewed campaign list from user uuid
 *
 * @param {string} uuid uuid
 *
 * @returns {object} response data or error based upon status
 */
export const clearRecentViewListApi = async (uuid) => {
    const options = postOptions();

    const data = await request(`${RECENT_BASE_URL}/recent/clearRecent?uuid=${uuid}`, options);

    return data;
};
