import { useState } from 'react';
import Fuse from 'fuse.js';

function fuzzySearch({ data, term, fuse }) {
    const result = fuse.search(`${term}`);

    return term ? result : data;
}

/**
 *
 * @param {Object} param0 contains the dataset to search over and custom fusejs options
 *
 * @returns {Object} contains an array of results, search (sets search term), term (current value), reset (resets current value)
 *
 * A custom React Hook to do a in-memory fuzzy text search using Fuse.js.
 *
 */
function useFuse({ data, options }) {
    const [term, setTerm] = useState('');

    const fuseOptions = {
        threshold: 0.2,
        ...options,
    };

    const fuse = new Fuse(data, fuseOptions);

    let result = fuzzySearch({ data, term, fuse });

    const reset = () => setTerm('');

    if (term === '') {
        result = [];
    }

    return { result, search: setTerm, term, reset };
}

export default useFuse;
