export const creativeDimensions = [
    ['970x66', { apiSize: { aspectRatio: false, width: '970', height: '66' } }],
    ['160x600', { apiSize: { aspectRatio: false, width: '160', height: '600' } }],
    ['300x250', { apiSize: { aspectRatio: false, width: '300', height: '250' } }],
    ['320x50', { apiSize: { aspectRatio: false, width: '320', height: '50' } }],
    ['320x100', { apiSize: { aspectRatio: false, width: '320', height: '100' } }],
    ['728x90', { apiSize: { aspectRatio: false, width: '728', height: '90' } }],
    ['970x33', { apiSize: { aspectRatio: false, width: '970', height: '33' } }],
    ['300x600', { apiSize: { aspectRatio: false, width: '300', height: '600' } }],
    ['320x165', { apiSize: { aspectRatio: false, width: '320', height: '165' } }],
    ['352x82', { apiSize: { aspectRatio: false, width: '352', height: '82' } }],
    ['640x100', { apiSize: { aspectRatio: false, width: '640', height: '100' } }],
    ['640x200', { apiSize: { aspectRatio: false, width: '640', height: '200' } }],
];
