import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = ({ size, center = true, children, ...rest }) => {
    const WrapperDiv = styled.div`
        margin: ${center && `2em auto`};
        width: ${size}px;
        height: ${size}px;
        position: relative;
    `;

    return <WrapperDiv {...rest}>{children}</WrapperDiv>;
};

Wrapper.defaultProps = {
    size: 40,
};

Wrapper.propTypes = {
    size: PropTypes.number,
    children: PropTypes.any,
    center: PropTypes.bool,
};

export default Wrapper;
