import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import { SidebarTabView, SidebarTab } from 'components/TabSidebar';
import Footer from 'components/Footer';
import {
    ResourceAbout,
    ResourceOnsiteDisplay,
    ResourcePaidSearch,
    ResourceSponsoredProducts,
    ResourceOffsiteDisplay,
} from './content';

export const ResourceParam = {
    ABOUT: 'about',
    ONSITE_DISPLAY: 'onsite-display',
    SPONSORED_PRODUCTS: 'sponsored-products',
    PAID_SEARCH: 'paid-search',
    OFFSITE_DISPLAY: 'offsite-display',
};

function TabPanel(props) {
    return (
        <div role="tabpanel" {...props}>
            <Box p={4}>
                <Typography>{props.children}</Typography>
            </Box>
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        left: '0px',
        display: 'flex',
        width: '100%',
        height: '100%',
        marginTop: `-${theme.spacing(5)}px`,
        backgroundColor: theme.palette.standardWhite,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        justifyContent: 'start',
        minWidth: '240px',
        maxWidth: '240px',
        backgroundColor: '#F0F2F4',
    },
    item: {
        height: 48,
        paddingLeft: '32px',
        color: theme.palette.techBlack,
        fontSize: '14px',
        fontWeight: theme.typography.fontWeightMedium,
        opacity: 1,
        backgroundColor: theme.palette.standardWhite,
        fill: theme.palette.humanBlue,
        textTransform: 'none',
        '&.active, &:hover, &.Mui-selected': {
            color: theme.palette.standardWhite,
            backgroundColor: theme.palette.humanBlue,
            '& path': {
                fill: theme.palette.standardWhite,
            },
        },
        '&.Mui-selected:hover': {
            backgroundColor: theme.palette.darkGray,
        },
        justifyContent: 'start',
        textAlign: 'left',
        '& span': {
            'align-items': 'flex-start',
        },
    },
    tabPanel: {
        flexGrow: 1,
        overflowY: 'auto',
        height: 'calc(100% - 155px)',

        '& .MuiTypography-root, & .MuiTypography-body1': {
            lineHeight: 1.65,
            maxWidth: '976px',
        },

        '& .MuiBox-root': {
            padding: '0 32px',
        },
    },
    customizedHtml: {
        '& h3': {
            fontSize: '28px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            margin: 0,
        },
        '& p': {
            margin: 0,
        },
        '& ul, & ol, & ol ul': {
            margin: 0,
            paddingLeft: '1em',
        },
        '& ol ul': {
            listStyleType: 'disc',
        },
        '& ol li::marker': {
            fontWeight: 'bold',
        },
        '& video': {
            width: '90%',
        },
    },
}));

const getTabValue = (tabParam) => {
    const tab = Object.values(ResourceParam).find((val) => val === tabParam?.toLowerCase());

    return tab || ResourceParam.ABOUT;
};

function ResourcePage() {
    const classes = useStyles();
    const { tab: tabParam } = useParams();

    const tabValue = getTabValue(tabParam);

    return (
        <div className={classes.root}>
            <SidebarTabView startingValue={tabValue}>
                <SidebarTab label="About" value={ResourceParam.ABOUT} pathname={`/resources/${ResourceParam.ABOUT}`}>
                    <TabPanel className={classNames(classes.tabPanel, classes.customizedHtml)}>
                        <ResourceAbout />
                        <Footer />
                    </TabPanel>
                </SidebarTab>
                <SidebarTab
                    label="Onsite Display & Video"
                    value={ResourceParam.ONSITE_DISPLAY}
                    pathname={`/resources/${ResourceParam.ONSITE_DISPLAY}`}
                >
                    <TabPanel className={classNames(classes.tabPanel, classes.customizedHtml)}>
                        <ResourceOnsiteDisplay />
                        <Footer />
                    </TabPanel>
                </SidebarTab>
                <SidebarTab
                    label="Sponsored Products"
                    value={ResourceParam.SPONSORED_PRODUCTS}
                    pathname={`/resources/${ResourceParam.SPONSORED_PRODUCTS}`}
                >
                    <TabPanel className={classNames(classes.tabPanel, classes.customizedHtml)}>
                        <ResourceSponsoredProducts />
                        <Footer />
                    </TabPanel>
                </SidebarTab>
                <SidebarTab
                    label="Paid Search"
                    value={ResourceParam.PAID_SEARCH}
                    pathname={`/resources/${ResourceParam.PAID_SEARCH}`}
                >
                    <TabPanel className={classNames(classes.tabPanel, classes.customizedHtml)}>
                        <ResourcePaidSearch />
                        <Footer />
                    </TabPanel>
                </SidebarTab>
                <SidebarTab
                    label="Offsite Display & Video"
                    value={ResourceParam.OFFSITE_DISPLAY}
                    pathname={`/resources/${ResourceParam.OFFSITE_DISPLAY}`}
                >
                    <TabPanel className={classNames(classes.tabPanel, classes.customizedHtml)}>
                        <ResourceOffsiteDisplay />
                        <Footer />
                    </TabPanel>
                </SidebarTab>
            </SidebarTabView>
        </div>
    );
}

export default ResourcePage;
