import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { logout as logoutAction } from 'containers/App/actions';
import { LoginSvg } from 'components/Svg';

const useStyles = makeStyles((theme) => ({
    userProfileContainer: {
        zIndex: 1,
    },
    userProfileButton: {
        paddingRight: 0,
    },
    name: {
        fontFamily: 'humanBBYDigital, sans-serif',
        fontSize: '14px',
        paddingLeft: theme.spacing(1),
    },
}));

const UserMenu = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    const { label, logout, onClickProfile } = props;
    const open = Boolean(anchorEl);

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
        }
    };

    const handleMenu = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleLogout = () => {
        handleClose();
        logout();
    };

    const onProfileClick = () => {
        handleClose();
        onClickProfile();
    };

    return (
        <div className={classes.userProfileContainer}>
            <IconButton
                aria-label={label}
                aria-owns={open ? 'menu-appbar' : null}
                aria-haspopup
                color="inherit"
                className={classes.userProfileButton}
                onClick={handleMenu}
            >
                <LoginSvg width={20} />
                {props.authedUser && (
                    <span className={classes.name}>
                        {props.authedUser.first_name} {props.authedUser.last_name}
                    </span>
                )}
            </IconButton>
            <Popper open={open} anchorEl={anchorEl} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={onProfileClick}>Profile</MenuItem>
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

UserMenu.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string,
    icon: PropTypes.node,
    authedUser: PropTypes.object,
    logout: PropTypes.func,
    onClickProfile: PropTypes.func,
    passwordUpdateDone: PropTypes.bool,
    passwordUpdateError: PropTypes.object,
    onPasswordUpdateInit: PropTypes.func,
};

UserMenu.defaultProps = {
    label: 'Account Menu',
    icon: <AccountCircle />,
};

const mapStateToProps = null;
function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logoutAction()),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(UserMenu);
