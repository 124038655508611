export const SOCIAL_TAB_DEFAULT_KEY = 'publisher';

export const socialTabs = {
    publisher: {
        label: 'Publisher',
        key: 'publisher',
    },
    post: {
        label: 'Post',
        key: 'post',
    },
    audience: {
        label: 'Audience',
        key: 'audience',
    },
};
