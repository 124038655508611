/*
 *
 * WatchCampaignsTab actions
 *
 */

import {
    FETCH_CAMPAIGNS_WATCHING_START,
    FETCH_CAMPAIGNS_WATCHING_SUCCESS,
    FETCH_CAMPAIGNS_WATCHING_ERROR,
    REMOVE_CAMPAIGNS_FROM_WATCH,
    REMOVE_CAMPAIGNS_FROM_WATCH_SUCCESS,
    REMOVE_CAMPAIGNS_FROM_WATCH_ERROR,
    CREATE_CAMPAIGN_WATCH,
    CREATE_CAMPAIGN_WATCH_SUCCESS,
    CREATE_CAMPAIGN_WATCH_ERROR,
    ADD_CAMPAIGNS_TO_WATCH,
    ADD_CAMPAIGNS_TO_WATCH_SUCCESS,
    ADD_CAMPAIGNS_TO_WATCH_ERROR,
} from './constants';

export function fetchCampaignsWatchingStart(userId) {
    return {
        type: FETCH_CAMPAIGNS_WATCHING_START,
        userId,
    };
}
export function fetchCampaignsWatchingSuccess(watchlists) {
    return {
        type: FETCH_CAMPAIGNS_WATCHING_SUCCESS,
        watchlists,
    };
}
export function fetchCampaignsWatchingError(error) {
    return {
        type: FETCH_CAMPAIGNS_WATCHING_ERROR,
        error,
    };
}

export function removeCampaignsFromWatch(userId, watchName, campaignIds) {
    return {
        type: REMOVE_CAMPAIGNS_FROM_WATCH,
        userId,
        watchName,
        campaignIds,
    };
}

export function removeCampaignsFromWatchSuccess(data) {
    return {
        type: REMOVE_CAMPAIGNS_FROM_WATCH_SUCCESS,
        data,
    };
}

export function removeCampaignsFromWatchError(error) {
    return {
        type: REMOVE_CAMPAIGNS_FROM_WATCH_ERROR,
        error,
    };
}

export function createCampaignWatch(userId, watchName, campaignIds) {
    return {
        type: CREATE_CAMPAIGN_WATCH,
        userId,
        watchName,
        campaignIds,
    };
}

export function createCampaignWatchSuccess(data) {
    return {
        type: CREATE_CAMPAIGN_WATCH_SUCCESS,
        data,
    };
}

export function createCampaignWatchError(error) {
    return {
        type: CREATE_CAMPAIGN_WATCH_ERROR,
        error,
    };
}

export function addCampaignsToWatch(userId, watchName, campaignIds) {
    return {
        type: ADD_CAMPAIGNS_TO_WATCH,
        userId,
        watchName,
        campaignIds,
    };
}

export function addCampaignsToWatchSuccess(data) {
    return {
        type: ADD_CAMPAIGNS_TO_WATCH_SUCCESS,
        data,
    };
}

export function addCampaignsToWatchError(error) {
    return {
        type: ADD_CAMPAIGNS_TO_WATCH_ERROR,
        error,
    };
}
