import { call, put, select, takeLeading } from 'redux-saga/effects';
import { makeSelectAuthedUser } from 'containers/App/selectors';
import { getRecentViewListApi, addCampaignToRecentViewListApi, clearRecentViewListApi } from 'api/recent_campaign';
import { NOTICES } from 'notification/constants';
import { enqueueSnackbar } from 'notification/actions';
import { makeSelectRecentViewList } from './selectors';
import {
    loadRecentViewedListAction,
    loadRecentViewedListSuccessAction,
    loadRecentViewedListErrorAction,
    addCampaignToRecentViewedListAction,
    addCampaignToRecentViewedListSuccessAction,
    addCampaignToRecentViewedListErrorAction,
    clearRecentViewedListSuccessAction,
    clearRecentViewedListErrorAction,
    clearRecentViewedListAction,
} from './slice';

export function* getRecentViewList({ payload: { uuid } }) {
    try {
        if (uuid) {
            const data = yield call(getRecentViewListApi, uuid);
            if (data.responseCode === '200 OK') {
                yield put(loadRecentViewedListSuccessAction(data.payload.result));
            } else {
                throw new Error({ message: data.error });
            }
        }
    } catch (error) {
        yield put(loadRecentViewedListErrorAction(error.message));
    }
}

export function* saveRecent({ payload: { campaignId } }) {
    try {
        const recentList = yield select(makeSelectRecentViewList());
        const user = yield select(makeSelectAuthedUser());
        const data = yield call(addCampaignToRecentViewListApi, user.uuid, campaignId);
        if (data.responseCode === '200 OK') {
            yield put(addCampaignToRecentViewedListSuccessAction(recentList));
        }
    } catch (error) {
        yield put(addCampaignToRecentViewedListErrorAction(error.message));
    }
}

export function* clearRecentsSaga() {
    try {
        const user = yield select(makeSelectAuthedUser());

        const data = yield call(clearRecentViewListApi, user.uuid);
        if (data.responseCode === '200 OK') {
            yield put(clearRecentViewedListSuccessAction());
            yield put(
                enqueueSnackbar({
                    message: NOTICES.DELETE_RECENT_VIEWED_CAMPAIGNS_SUCCESS,
                    options: { variant: 'success' },
                }),
            );
        }
    } catch (err) {
        yield put(clearRecentViewedListErrorAction(err));
        yield put(
            enqueueSnackbar({
                message: NOTICES.DELETE_RECENT_VIEWED_CAMPAIGNS_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

// Individual exports for testing
export default function* recentViewedPageSaga() {
    yield takeLeading(loadRecentViewedListAction, getRecentViewList);
    yield takeLeading(addCampaignToRecentViewedListAction, saveRecent);
    yield takeLeading(clearRecentViewedListAction, clearRecentsSaga);
}
