import {
    CAMPAIGN_TYPE_DISPLAY,
    CAMPAIGN_TYPE_ONSITE_VIDEO,
    CAMPAIGN_TYPE_SPONSORED_RMP,
    CAMPAIGN_TYPE_PAID_SEARCH,
    CAMPAIGN_TYPE_SOCIAL,
    CAMPAIGN_TYPE_PROXIMITY,
    CAMPAIGN_TYPE_OFFSITE_DISPLAY,
    CAMPAIGN_TYPE_OFFSITE_VIDEO,
} from '../../../Helpers/helpers';

export const campaignTypeOrder = [
    CAMPAIGN_TYPE_DISPLAY,
    CAMPAIGN_TYPE_ONSITE_VIDEO,
    CAMPAIGN_TYPE_SPONSORED_RMP,
    CAMPAIGN_TYPE_PAID_SEARCH,
    CAMPAIGN_TYPE_OFFSITE_DISPLAY,
    CAMPAIGN_TYPE_OFFSITE_VIDEO,
    CAMPAIGN_TYPE_SOCIAL,
    CAMPAIGN_TYPE_PROXIMITY,
];

export function sortCampaignType(items) {
    const result = items.sort((a, b) => {
        const indexA = campaignTypeOrder.indexOf(a.campaignType);
        const indexB = campaignTypeOrder.indexOf(b.campaignType);

        if (indexA === -1) {
            return 1;
        }
        if (indexB === -1) {
            return -1;
        }

        return indexA - indexB;
    });

    return result;
}

export const getBrandIds = (brandData, filteredBrands) => {
    const brandsWithIds = brandData?.filter((brand) => filteredBrands?.find((name) => brand.name === name));

    return brandsWithIds?.map((brand) => brand.id);
};
