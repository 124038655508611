export function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join(''),
    );

    return JSON.parse(jsonPayload);
}

export function base64ToBlob(contentType, dataStr) {
    const bytes = atob(dataStr);
    const byteArrays = [];

    for (let offset = 0; offset < bytes.length; offset += 512) {
        const chunk = bytes.slice(offset, offset + 512);
        const chrVals = chunk.split('').map((chr) => chr.charCodeAt(0));
        byteArrays.push(new Uint8Array(chrVals));
    }

    return new Blob(byteArrays, { type: contentType });
}
