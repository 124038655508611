import { downloadOffsiteDisplayReport, getOffsiteDisplayCampaignDetails } from 'api/api-client';
import { Permissions } from 'shared/constants/user/permissions';
import { DefaultTooltipContent } from 'components/CampaignReporting/Header/tooltip-content.js/defaultTooltip';
import { CAMPAIGN_TYPE_OFFSITE_DISPLAY, OFFSITE_DISPLAY_KEY } from 'Helpers/helpers';
import { OFFSITE_TAB_DEFAULT_KEY, offsiteTabs } from '../offsiteTabs';
import { displayOptions } from '../dataviz';
import { offsiteSheets } from '../offsiteSheets';
import { offsiteCards } from '../offsiteCards';
import { columnMap, columnFilterMap, offsiteDisplayColumns, modifyColumns, defaultColumns } from './index';
import { ResourceParam } from '../../../../containers/ResourcePage';

export const offsiteDisplayDefinitions = {
    label: 'Offsite Display',
    campaignType: CAMPAIGN_TYPE_OFFSITE_DISPLAY,
    vehicleKey: OFFSITE_DISPLAY_KEY,

    // Permissions
    viewPermissions: [Permissions.CAN_VIEW_OFFSITE_DISPLAY_CAMPAIGNS],
    downloadPermissions: [Permissions.CAN_DOWNLOAD_OFFSITE_DISPLAY_CAMPAIGNS],

    // Campaign Details Page
    detailsApiCall: getOffsiteDisplayCampaignDetails,
    useNewColumnFilter: true,
    defaultTabKey: OFFSITE_TAB_DEFAULT_KEY,
    columnMap,
    columnFilterMap,
    cards: offsiteCards,
    tabs: offsiteTabs,
    columns: offsiteDisplayColumns,
    defaultColumns,
    modifyColumns,
    visualizationOptions: displayOptions('display'),

    // Attribution
    tooltipRenderFn: DefaultTooltipContent,
    resourcesPageUrl: `/resources/${ResourceParam.OFFSITE_DISPLAY}`,
    attributionWindowDays: 14,

    // Download Report
    reportDownloadApiCall: downloadOffsiteDisplayReport,
    sheets: offsiteSheets('display'),
};
