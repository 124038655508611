/*
 *
 * WatchCampaignsTab constants
 *
 */
export const DEFAULT_WATCH_NAME = 'watch';
export const FETCH_CAMPAIGNS_WATCHING_START =
    'portal/StackedCampaignsPage/WatchCampaignsTab/FETCH_CAMPAIGNS_WATCHING_START';
export const FETCH_CAMPAIGNS_WATCHING_SUCCESS =
    'portal/StackedCampaignsPage/WatchCampaignsTab/FETCH_CAMPAIGNS_WATCHING_SUCCESS';
export const FETCH_CAMPAIGNS_WATCHING_ERROR =
    'portal/StackedCampaignsPage/WatchCampaignsTab/FETCH_CAMPAIGNS_WATCHING_ERROR';

export const REMOVE_CAMPAIGNS_FROM_WATCH = 'portal/StackedCampaignsPage/WatchCampaignsTab/REMOVE_CAMPAIGNS_FROM_WATCH';
export const REMOVE_CAMPAIGNS_FROM_WATCH_SUCCESS =
    'portal/StackedCampaignsPage/WatchCampaignsTab/REMOVE_CAMPAIGNS_FROM_WATCH_SUCCESS';
export const REMOVE_CAMPAIGNS_FROM_WATCH_ERROR =
    'portal/StackedCampaignsPage/WatchCampaignsTab/REMOVE_CAMPAIGNS_FROM_WATCH_ERROR';

export const RESET_DONE = 'portal/StackedCampaignsPage/WatchCampaignsTab/RESET_DONE';

export const CREATE_CAMPAIGN_WATCH = 'portal/StackedCampaignsPage/WatchCampaignsTab/CREATE_CAMPAIGN_WATCH';
export const CREATE_CAMPAIGN_WATCH_SUCCESS =
    'portal/StackedCampaignsPage/WatchCampaignsTab/CREATE_CAMPAIGN_WATCH_SUCCESS';
export const CREATE_CAMPAIGN_WATCH_ERROR = 'portal/StackedCampaignsPage/WatchCampaignsTab/CREATE_CAMPAIGN_WATCH_ERROR';

export const ADD_CAMPAIGNS_TO_WATCH = 'portal/StackedCampaignsPage/WatchCampaignsTab/ADD_CAMPAIGNS_TO_WATCH';
export const ADD_CAMPAIGNS_TO_WATCH_SUCCESS =
    'portal/StackedCampaignsPage/WatchCampaignsTab/ADD_CAMPAIGNS_TO_WATCH_SUCCESS';
export const ADD_CAMPAIGNS_TO_WATCH_ERROR =
    'portal/StackedCampaignsPage/WatchCampaignsTab/ADD_CAMPAIGNS_TO_WATCH_ERROR';
