/**
 *
 * MultiSelect
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEmpty } from 'lodash';

const SEARCH_BRAND_CAP_LIMIT = 25;
const useStyles = makeStyles((theme) => ({
    root: {},
    chips: {
        marginTop: 10,
        overflowY: 'scroll',
        maxHeight: 156,
        '& .MuiChip-root': {
            borderRadius: 4,
            margin: 4,
        },
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.shadowGray,
            borderRadius: '18px',
        },
    },
}));

export const MultiSelect = ({ placeholder, options, selectedOptions, onChange, id }) => {
    const classes = useStyles();
    const onAutocompleteChange = (event, values) => {
        const newValues = values.slice();
        if (values.length > 1) {
            newValues.unshift(newValues.pop());
        }
        onChange(newValues);
    };

    const onDelete = (value) => {
        const newSelectedOptions = selectedOptions.filter((option) => option.value !== value);
        onChange(newSelectedOptions);
    };

    return (
        <div className={classes.root}>
            <Autocomplete
                multiple
                disabled={selectedOptions.length >= SEARCH_BRAND_CAP_LIMIT}
                options={options}
                value={selectedOptions}
                getOptionLabel={(option) => option.name}
                getOptionDisabled={(option) => !isEmpty(selectedOptions.filter(({ name }) => option.name === name))}
                filterSelectedOptions
                renderTags={() => {}}
                renderInput={(params) => <TextField {...params} variant="outlined" placeholder={placeholder} />}
                onChange={onAutocompleteChange}
                id={id}
            />
            <div className={classes.chips}>
                {selectedOptions.map(({ name, value }) => (
                    <Chip key={name + value} variant="outlined" label={name} onDelete={() => onDelete(value)} />
                ))}
            </div>
        </div>
    );
};

MultiSelect.propTypes = {
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string,
        }),
    ),
    selectedOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string,
        }),
    ),
    onChange: PropTypes.func,
    id: PropTypes.string,
    disabled: PropTypes.bool,
};

MultiSelect.defaultProps = {
    placeholder: '',
    options: [],
    selectedOptions: [],
    onChange: () => {},
};

export default MultiSelect;
