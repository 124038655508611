/**
 *
 * Status
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 8,
        height: 8,
        marginTop: -4,
        marginRight: 10,
        backgroundColor: theme.palette.lightGray,
    },
    statusText: {
        outline: 'none',
        boxShadow: 'none',
    },
    active: {
        backgroundColor: theme.palette.activeGreen,
    },
    paused: {
        backgroundColor: theme.palette.pauseYellow,
    },
    complete: {
        backgroundColor: theme.palette.darkBlue,
    },
}));

function Status({ status, handleClick }) {
    const classes = useStyles();

    const statusClass = classnames(classes.root, {
        [classes.active]: status === 'Active',
        [classes.paused]: status === 'Paused',
        [classes.complete]: status === 'Complete',
    });

    if (handleClick) {
        return (
            <>
                <Chip className={statusClass} />
                <span
                    className={classes.statusText}
                    role="button"
                    tabIndex={0}
                    onClick={handleClick}
                    onKeyPress={handleClick}
                >
                    {status}
                </span>
            </>
        );
    }

    return (
        <>
            <Chip className={statusClass} />
            <span>{status}</span>
        </>
    );
}

Status.propTypes = {
    status: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
};

export default Status;
