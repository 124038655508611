/**
 *
 * IDAM Login Page
 *
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import env from 'config/env';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from '@reduxjs/toolkit';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import session from 'services/session';
// eslint-disable-no-unresolved
import { selectIsMaintenanceNow } from 'layouts/AlertBanner/selector';
import ErrorWithContact from 'containers/IDAMInvite/ErrorWithContact';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    inner: {
        width: '316px',
        marginRight: '175px',
    },
    title: {
        fontFamily: ['HumanBBYDigital', 'sans-serif'],
        fontSize: '18px',
        lineHeight: '22px',
        color: theme.palette.borderGray,
        paddingBottom: '16px',
    },
    button: {
        marginLeft: 0,
    },
    formButton: {
        padding: 0,
        backgroundColor: '#1d252c',
        color: '#fff200',
        fontFamily: ['HumanBBYDigital', 'sans-serif'],
        fontWeight: 'bold',
        fontSize: 22,
        textAlign: 'left',
        textTransform: 'none',
        '&:hover': {
            color: '#ffce00',
        },
        '&:disabled': {
            backgroundColor: '#1d252c',
            color: '#AFA712',
            opacity: '0.6',
            cursor: 'default',
        },
    },
    error: {
        color: theme.palette.valueRed,
        fontFamily: ['HumanBBYDigital', 'sans-serif'],
        fontSize: '18px',
        lineHeight: '22px',
        paddingBottom: '16px',
    },
}));

export function IDAMLoginPage({ isMaintenanceNow, error }) {
    const classes = useStyles();
    const welcomeMessage = 'Welcome to My Ads by Best Buy Ads';
    const maintenanceMessage = 'Performing system maintenance';
    const inactiveMessage = 'We did not recognize the username or password entered.';
    const apiUsersNotAllowed = 'Interactive sessions not allowed for API Users';
    const contactSupport = 'Please Contact Support.';
    const INTERNAL = 'internal';
    const EXTERNAL = 'external';
    const INTERNAL_STAGE = 'internal-stage';
    const EXTERNAL_STAGE = 'external-stage';
    const NO_PERMISSION_PARAM = 'ERR_NO_LOGIN_PERMISSION';
    const API_USER_PARAM = 'ERR_API_USER';
    const userType = useRef(EXTERNAL);

    const getURLFromQueryString = (key) => {
        const params = new URLSearchParams(window.location.search);
        if (!params.get(key)) {
            return null;
        }
        if (params.get(key).toLowerCase() === INTERNAL) {
            return INTERNAL;
        }
        if (params.get(key).toLowerCase() === INTERNAL_STAGE) {
            return INTERNAL_STAGE;
        }
        if (params.get(key).toLowerCase() === EXTERNAL_STAGE) {
            return EXTERNAL_STAGE;
        }
        if (params.get(key) === NO_PERMISSION_PARAM) {
            return NO_PERMISSION_PARAM;
        }
        if (params.get(key) === API_USER_PARAM) {
            return API_USER_PARAM;
        }
        return EXTERNAL;
    };

    const errorMessage = () => {
        const errorFromQuery = getURLFromQueryString('error');

        if (errorFromQuery === 'ERR_API_USER') {
            return apiUsersNotAllowed;
        }

        return inactiveMessage;
    };

    const showErrorMessage = errorMessage();

    const handleClick = () => {
        const fromQuery = getURLFromQueryString('userType');

        if (fromQuery) {
            userType.current = fromQuery;
            if (env.BBY_ENV === 'local') {
                session.write({
                    isLoggedIn: true,
                    localUserType: fromQuery,
                });
                window.location.assign('/campaigns');
                return;
            }
        } else if (!env.GATEWAY_BASE_URL.includes('bestbuyads')) {
            userType.current = INTERNAL;
        } else {
            userType.current = EXTERNAL;
        }
        const bbyAuthUrl = `${env.GATEWAY_BASE_URL}/v1/auth/idam/${userType.current}/login`;
        window.location.replace(bbyAuthUrl);
    };

    return (
        <div>
            <div className={classes.inner}>
                <div className={classes.title}>{isMaintenanceNow ? maintenanceMessage : welcomeMessage}</div>
                <Button
                    disabled={isMaintenanceNow}
                    data-testid="login-form-button"
                    className={classNames(classes.formButton, classes.button)}
                    type="submit"
                    onClick={handleClick}
                    endIcon={<ArrowForwardIcon className={classes.arrowIcon} />}
                >
                    Login
                </Button>
            </div>
            <br />
            {error && (
                <div className={classes.error}>
                    {showErrorMessage}
                    <div className={classes.title} data-testid="loginErrorMessage">
                        {ErrorWithContact(contactSupport, 'Contact Support', classes)}
                    </div>
                </div>
            )}
        </div>
    );
}

IDAMLoginPage.propTypes = {
    isMaintenanceNow: PropTypes.bool,
    error: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
    isMaintenanceNow: selectIsMaintenanceNow,
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect)(IDAMLoginPage);
