/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { logout as logoutAction } from 'containers/App/actions';
import { userRefreshSession as refresh, getUserInfo } from 'api/user-management';
import env from 'config/env';
import useFeatureFlags from '../utils/featureFlags/useFeatureFlags';

const useStyles = makeStyles((theme) => ({
    formButton: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginTop: '24px',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    formButtonCancel: {
        backgroundColor: theme.palette.battleGray,
        color: theme.palette.techBlack,
        '&:hover': {
            backgroundColor: theme.palette.borderGray,
        },
    },
    dialog: {
        padding: '16px 24px',
    },
    dialogButton: {
        margin: '0',
    },
    dialogText: {
        color: theme.palette.techBlack,
    },
}));

const RollingSessionRefresh = ({ ...rest }) => {
    const classes = useStyles();
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
    const flags = useFeatureFlags();

    const rollingSessionRefreshTimeout = env.REACT_APP_ROLLING_SESSION_REFRESH;

    useEffect(() => {
        startRollingRefreshTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const startRollingRefreshTimer = () => {
        getUserInfo(flags).then((response) => {
            if (response.exp) {
                // a current session exists
                const expireTime = response.exp * 1000;
                const timeTillExpiry = expireTime - Date.now();
                const oneMinute = 60000;
                if (timeTillExpiry > rollingSessionRefreshTimeout + oneMinute) {
                    // session will not expire soon, check again later, one minute for padding
                    setTimeout(startRollingRefreshTimer, rollingSessionRefreshTimeout);
                } else if (expireTime >= Date.now()) {
                    // session will expire soon
                    refresh().catch(() => {
                        openSessionExpiredLogout();
                    });
                    setTimeout(startRollingRefreshTimer, rollingSessionRefreshTimeout);
                } else if (expireTime < Date.now()) {
                    // session has expired
                    openSessionExpiredLogout();
                }
            } else {
                // no session exists
                openSessionExpiredLogout();
            }
        });
    };

    const onLogout = () => {
        setLogoutDialogOpen(false);
        rest.logout();
    };

    const openSessionExpiredLogout = () => {
        setLogoutDialogOpen(true);
    };

    return (
        <Dialog open={logoutDialogOpen} fullWidth maxWidth="xs">
            <DialogTitle>Your session has expired</DialogTitle>
            <DialogContent>
                <DialogContentText className={classnames(classes.dialogText)}>
                    Please login to start a new sesson.
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialog}>
                <Button
                    data-testid="rolling-session-refresh-logout-button"
                    className={classnames(classes.formButton, classes.dialogButton)}
                    onClick={onLogout}
                >
                    <span>Continue to Login</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

RollingSessionRefresh.defaultProps = {
    logout: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logoutAction()),
    };
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(RollingSessionRefresh);
