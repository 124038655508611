import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import request, { formatPostOptions as postOptions } from 'utils/request';
import { OTPPage } from 'containers/IDAMInvite/otp';
import CreatePasswordForm from 'components/CreatePasswordForm';
import { NOTICES } from 'notification/constants';
import { RECENT_PASSWORD_ERR_CODE } from 'shared/constants';
import ResetPasswordForm from './ResetPasswordForm';
import PasswordUpdated from './PasswordUpdated';
import { BASE_URL_V2 } from '../../api/api-service/Url_Constants';

export function IDAMResetPasswordPage() {
    const stages = {
        request: 'request',
        otp: 'otp',
        changePassword: 'changePassword',
        passwordUpdated: 'passwordUpdated',
    };
    const [stage, setStage] = useState(stages.request);
    const [otpCodeError, setOTPCodeError] = useState(undefined);
    const [passwordError, setPasswordError] = useState(undefined);
    const [idamSesssionId, setIDAMSessionId] = useState(undefined);

    const userEmail = useRef(undefined);

    const sendResetPasswordRequest = async ({ email }) => {
        const options = postOptions({
            email,
            mode: 'EMAIL',
            pairingData: email,
        });

        userEmail.current = email;

        try {
            const response = await request(`${BASE_URL_V2}/idam/ui/forgotPassword`, options);
            if (response.responseCode === '200 OK') {
                setIDAMSessionId(response.payload);
                setStage(stages.otp);
            }
            return null;
        } catch (e) {
            // key for the return object needs to match react-final-form field name in order-
            // for the meta:submitError obj to populate direct BE error res to render at Form Input/TextField level.
            if (e.response.status === 400) {
                return { email: 'Something went wrong, contact support.' };
            }
            return { email: 'Something went wrong, please try again or contact support.' };
        }
    };

    const submitOtp = async (code) => {
        const options = postOptions({
            sessionId: idamSesssionId,
            otp: code,
        });
        try {
            const response = await request(`${BASE_URL_V2}/idam/ui/forgotPasswordOtp`, options);
            if (response.responseCode === '200 OK') {
                setStage(stages.changePassword);
            }
        } catch (e) {
            const errorStatus = e.response.status;
            if (errorStatus === 400) {
                setOTPCodeError('Invalid Code');
            }
        }
    };

    const setPassword = async (password) => {
        const options = postOptions({
            email: userEmail.current,
            password,
        });
        try {
            const response = await request(`${BASE_URL_V2}/idam/ui/forgotPasswordConfirmation`, options);
            if (response.responseCode === '200 OK') {
                setStage(stages.passwordUpdated);
            }
        } catch (e) {
            const httpStatus = e.response.status;
            let message = NOTICES.UPDATE_PASSWORD_ERROR_IDAM;

            // http status 400 for errors and 4001 in paylod is recent passwords error and 4002 is generic error
            if (httpStatus === 400) {
                const errorStatus = e.response.data.payload.status;
                if (errorStatus === RECENT_PASSWORD_ERR_CODE) {
                    message = NOTICES.UPDATE_PASSWORD_ERROR_PREVIOUS_IDAM;
                }
            }
            setPasswordError(message);
        }
    };

    switch (stage) {
        case stages.otp:
            return (
                <OTPPage
                    onSubmit={async ({ code }) => {
                        await submitOtp(code);
                    }}
                    onSendCode={() => sendResetPasswordRequest({ email: userEmail.current })}
                    status={{
                        loading: false,
                        error: otpCodeError,
                    }}
                    type="reset"
                />
            );
        case stages.changePassword:
            return (
                <CreatePasswordForm
                    onSubmit={async (password) => {
                        await setPassword(password);
                    }}
                    submitError={passwordError}
                />
            );
        case stages.passwordUpdated:
            return <PasswordUpdated />;
        default:
            // stages.request
            return <ResetPasswordForm onSubmit={sendResetPasswordRequest} />;
    }
}

IDAMResetPasswordPage.propTypes = {};

const withConnect = connect(null, null);

export default compose(withConnect)(IDAMResetPasswordPage);
