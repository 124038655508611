import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import App from 'containers/App';
import history from 'utils/history';
import { initializeAnalyticsContext, AnalyticsContextProvider } from 'shared/contexts/analyticsContext';
import ErrorHandler from 'components/ErrorHandler';
import { theme } from './theme';
import { unregister } from './serviceWorker';
import { appStore } from './configureStore';

const initialAnalyticsContext = initializeAnalyticsContext(history);
const MOUNT_NODE = document.getElementById('root');
const ROOT_NODE = createRoot(MOUNT_NODE);

ROOT_NODE.render(
    <MuiThemeProvider theme={theme}>
        <ErrorHandler>
            <AnalyticsContextProvider value={initialAnalyticsContext}>
                <Provider store={appStore}>
                    <App history={history} />
                </Provider>
            </AnalyticsContextProvider>
        </ErrorHandler>
    </MuiThemeProvider>,
);
if (module.hot) {
    // Hot reloadable React components and translation json files
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    module.hot.accept(['./containers/App'], () => {
        ROOT_NODE.unmount();
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
