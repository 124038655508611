/**
 *
 * MultiCheckbox
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { cloneDeep } from 'lodash';

const useStyles = makeStyles(() => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
}));

export const MultiCheckbox = ({ options, optionsType, onChange, isSorted }) => {
    const classes = useStyles();

    const [checkboxOptions, setCheckboxOptions] = useState([]);

    const onCheckboxChange = (value, checked) => {
        const newCheckboxOptions = cloneDeep(checkboxOptions);
        newCheckboxOptions.map((checkbox) => {
            if (checkbox.value === value) {
                // eslint-disable-next-line no-param-reassign
                checkbox.checked = checked;
            }

            return checkbox;
        });

        setCheckboxOptions(newCheckboxOptions);

        onChange(newCheckboxOptions);
    };

    useEffect(() => {
        if (options && isSorted) {
            const sorted = cloneDeep(options).sort((a, b) => {
                const x = a.name.toLowerCase();
                const y = b.name.toLowerCase();

                if (x < y) {
                    return -1;
                }
                if (x > y) {
                    return 1;
                }
                return 0;
            });
            setCheckboxOptions(sorted);
        } else if (options) {
            setCheckboxOptions(options);
        }
    }, [isSorted, options, optionsType]);

    return (
        <FormGroup className={classes.root}>
            {checkboxOptions.map(({ value, name, checked }) => (
                <FormControlLabel
                    key={name + value}
                    control={
                        <Checkbox
                            checked={checked || false}
                            name={value}
                            color="primary"
                            inputProps={{ 'data-testid': `checkbox-${value}`, id: `checkbox-${value}` }}
                            onChange={(event, c) => onCheckboxChange(value, c)}
                        />
                    }
                    label={name}
                />
            ))}
        </FormGroup>
    );
};

MultiCheckbox.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            name: PropTypes.string,
        }),
    ),
    optionsType: PropTypes.string,
    onChange: PropTypes.func,
    isSorted: PropTypes.bool,
};

MultiCheckbox.defaultProps = {
    options: [],
    onChange: () => {},
};

export default MultiCheckbox;
