import { downloadDisplayReport, getDisplayCampaignDetails } from 'api/api-client';
import { Permissions } from 'shared/constants/user/permissions';
import { CAMPAIGN_TYPE_DISPLAY, DISPLAY_KEY } from 'Helpers/helpers';
import { renderDefaultTooltipContent } from 'components/CampaignReporting/Header/tooltip-content.js/defaultTooltip';
import { ResourceParam } from 'containers/ResourcePage';
import {
    columnMap,
    modifyColumns,
    DISPLAY_TAB_DEFAULT_KEY,
    displayCards,
    displayTabs,
    displayColumns,
    dzDisplayOptions,
    displaySheets,
    defaultColumns,
    columnFilterMap,
} from '.';

export const onsiteDisplayDefinitions = {
    label: 'Onsite Display',
    vehicleKey: DISPLAY_KEY,
    campaignType: CAMPAIGN_TYPE_DISPLAY,
    groupName: 'display',

    // Permissions
    viewPermissions: [Permissions.CAN_VIEW_ONSITE_DISPLAY_CAMPAIGNS],
    downloadPermissions: [Permissions.CAN_DOWNLOAD_ONSITE_DISPLAY_CAMPAIGNS],

    // Campaign Details Page
    detailsApiCall: getDisplayCampaignDetails,
    useNewColumnFilter: true,
    columnMap,
    cards: displayCards,
    defaultTabKey: DISPLAY_TAB_DEFAULT_KEY,
    tabs: displayTabs,
    columns: displayColumns,
    visualizationOptions: dzDisplayOptions,
    modifyColumns,
    defaultColumns,
    columnFilterMap,
    showViewability: true,

    // Attribution
    tooltipRenderFn: renderDefaultTooltipContent,
    resourcesPageUrl: `/resources/${ResourceParam.ONSITE_DISPLAY}`,
    attributionWindowDays: 14,

    // Download Report
    reportDownloadApiCall: downloadDisplayReport,
    sheets: displaySheets,
};
