import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import { initialState } from './slice';
import { checkIsMaintenanceNow, findAlertsToShow, findAlertsToShowForReport, findMaintenanceAlerts } from './util';

const selectAlertsDomain = (state) => state.alertsDomain || initialState;

// /**
//  * Other specific selectors
//  */

// no need for memoization on these.
export const selectAlerts = (state) => selectAlertsDomain(state).alerts;

export const selectCampaignType = (state) => selectAlertsDomain(state).campaignType;

export const selectCurrentAlerts = createSelector(selectAlertsDomain, (domain) => domain.currentAlerts);

// memoized selector, depends on alerts and window.location.pathname
export const selectAlertsToShow = createSelector(
    selectCurrentAlerts,
    () => window.location.pathname,
    selectCampaignType,
    (alerts, pathname, campaignType) =>
        campaignType ? findAlertsToShowForReport(alerts, pathname, campaignType) : findAlertsToShow(alerts, pathname),
);

// memoized selector, depends on alerts and current time (can only be cached sub-millisecond)
export const selectMaintenanceAlerts = createSelector(selectAlerts, () => moment().valueOf(), findMaintenanceAlerts);

// memoized selector, depends on alerts and current time (can only be cached sub-millisecond)
export const selectIsMaintenanceNow = createSelector(selectMaintenanceAlerts, checkIsMaintenanceNow);
