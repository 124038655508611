/**
 *
 * OrganizationListTable
 *
 */
import React, { useState, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { brandsFormatter, campaignDateFormatter, userCountFormatter } from 'Helpers/table-formatter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { filterOrgs } from 'utils/filterOrgs';
import { compact } from 'lodash';
import { FlyoutAction } from 'shared/constants';
import Table from '../../../components/@shared/Table';
import { ListSearch } from '../../../components/ListSearch/ListSearch';
import reducer, { updateFilteredOrganizations } from '../slice';
import { selectOrganizationFilter } from '../selectors';
import OrganizationListFilter from '../OrganizationListFilter';
import saga from '../saga';
import { CampaignsDialogBox } from '../../CampaignManagement/CampaignDialogBox';
import { removeShareOrg as removeOrg, shareOrg } from '../../App/actions';
import { DialogAction, formatSubmitValues } from '../utils';
import useFeatureFlags from '../../../utils/featureFlags/useFeatureFlags';
import { useAuthedUser } from '../../../utils/user';
import { canManageAccount } from '../../../shared/constants/user/permissions';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        border: `solid 1px ${theme.palette.battleGray}`,
    },
    top: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: theme.spacing(5),
        border: `solid 1px ${theme.palette.battleGray}`,
        borderBottom: 'none',
        position: 'relative',
        backgroundColor: 'white',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headline: {
        margin: '0',
        fontSize: '28px',
        fontFamily: 'HumanBBYDigital, sans-serif',
        paddingLeft: '1rem',
    },
    row: {
        '&:nth-child(odd)': {
            backgroundColor: 'rgba(197, 203, 213, 0.06)',
        },
    },
    cell: {
        color: '#55555a',
    },
    cellWithCollapse: {
        padding: '0 16px 0 12px',
    },
    viewRemovedUsers: {
        marginBottom: theme.spacing(1),
        marginLeft: 0,

        '& span[class*="label"]': {
            fontSize: '14px',
        },
    },
    headerFlexBox: {
        padding: '15px',
        display: 'flex',
        alignItems: 'center',
    },
    search: {
        marginTop: '-25px',
    },
    strong: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 14,
        fontWeight: 'bold',
        paddingRight: 16,
        color: '#1D252C',
    },
}));

const campaignColumns = [
    {
        field: 'name',
        label: 'Organization',
        sortable: true,
        width: '208px',
    },
    {
        field: 'brands',
        label: 'Brand',
        sortable: true,
        width: '432px',
        format: brandsFormatter,
    },
    {
        field: 'type',
        label: 'Type',
        sortable: true,
    },
    {
        field: 'createdAt',
        label: 'Created on',
        sortable: true,
        format: campaignDateFormatter,
    },
    {
        field: 'userCount',
        type: 'number',
        label: 'Active Users',
        sortable: true,
        format: userCountFormatter,
    },
    {
        field: 'campaignCount',
        type: 'number',
        label: 'Campaigns',
        sortable: true,
    },
];

function containIgnoreCase(object, fields, skw) {
    if (skw === '') {
        return true;
    }
    if (fields) {
        // eslint-disable-next-line no-restricted-syntax
        for (const field of fields) {
            if (field === 'name') {
                const string = `${object[field]}`;
                if (string.toUpperCase().includes(skw.toUpperCase())) {
                    return true;
                }
            } else if (field === 'brands') {
                const brands = object[field];
                for (let i = 0; i < brands.length; i++) {
                    if (brands[i].toUpperCase().includes(skw.toUpperCase())) {
                        return true;
                    }
                }
            }
        }
    }
    return false;
}

export function OrganizationListTable({
    organizations,
    brands,
    onUpdatePageNumber,
    pageNumber,
    onCampaignShareSubmit,
    removeShareOrg,
    organizationFilter,
    updateFilteredOrgs,
    currentOrgFilter,
    onFilterUpdate,
    setFlyoutStatus,
    setSelectedItem,
}) {
    useInjectReducer({ key: 'organizationPage', reducer });
    useInjectSaga({ key: 'organizationPage', saga });

    const featureFlags = useFeatureFlags();

    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogRemoveOpen, setDialogRemoveOpen] = useState(false);
    const [dialogBoxAction, setDialogBoxAction] = useState('');
    const [submitValues, setSubmitValues] = useState(null);

    const authedUser = useAuthedUser();
    const { isExtUser } = authedUser;

    const { keyword: searchKeyword, includes: searchIncludes } = organizationFilter;

    const canSendToKoddi = () => {
        const hasManagePermissions = canManageAccount(authedUser);

        if (!isExtUser && hasManagePermissions && featureFlags.sendToKoddi) {
            return true;
        }
        return false;
    };

    const showActionSendToKoddi = canSendToKoddi();

    const onSearch = (keyword, includes) => {
        if (keyword !== searchKeyword) {
            onUpdatePageNumber(0);
        }
        updateFilteredOrgs(keyword, includes);
    };

    const onCloseFlyoutModal = () => {
        setFlyoutStatus(FlyoutAction.NONE);
        setSubmitValues(null);
    };

    const onShareActionClick = (flyoutAction, organization) => {
        setSelectedItem(organization);
        setFlyoutStatus(flyoutAction);
    };

    const tableActions = (organization) =>
        compact([
            {
                label: 'Share Campaign',
                onClick: () => {
                    onShareActionClick(FlyoutAction.SHARE, organization);
                    setSelectedItem(organization);
                },
            },
            {
                label: 'View Details',
            },
            showActionSendToKoddi && {
                label: 'Send to Koddi',
                onClick: () => {
                    setFlyoutStatus(FlyoutAction.SEND_TO_KODDI);
                    setSelectedItem(organization);
                },
            },
        ]);

    const onSubmitCampaignsProceed = () => {
        switch (dialogBoxAction) {
            case DialogAction.SHARE:
                onCampaignShareSubmit(submitValues);
                setDialogOpen(false);
                onCloseFlyoutModal();
                break;
            case DialogAction.UNSHARE:
                removeShareOrg(submitValues);
                setDialogRemoveOpen(false);
                setSubmitValues(null);
                break;
            default:
                break;
        }
    };

    const onSubmitCampaignsCancel = () => {
        setDialogOpen(false);
        setDialogRemoveOpen(false);
        setDialogBoxAction('');
        setSubmitValues(null);
    };

    const childTableActions = (organization) => {
        const removeShare = {
            label: 'Remove Share',
            onClick: (event, orgInfo) => {
                setDialogRemoveOpen(true);
                setDialogBoxAction(DialogAction.UNSHARE);
                const values = {
                    organizations: [organization],
                    brands: [{ name: orgInfo.brands[0], brandId: orgInfo.brandId }],
                };
                const removeOrgFormatted = formatSubmitValues(orgInfo.sharedFromOrg, values);
                setSubmitValues(removeOrgFormatted);
            },
        };

        return [removeShare];
    };

    const onOrgListFilterChange = (filter) => {
        onFilterUpdate(filter);
        onUpdatePageNumber(0);
    };

    const searchedAndFilteredOrgs = useCallback(
        (orgArray, orgsSearched, searchedKeyword) => {
            if (searchedKeyword) {
                onUpdatePageNumber(0);
                return orgsSearched;
            }
            return orgArray;
        },
        [onUpdatePageNumber],
    );

    const searchedOrgs = useMemo(() => {
        const filteredSearchFields = searchIncludes
            ?.filter((include) => include.checked)
            .map((include) => include.name);
        const organizationsToShow = organizations.filter((org) =>
            containIgnoreCase(org, filteredSearchFields, searchKeyword),
        );
        return organizationsToShow;
    }, [searchIncludes, organizations, searchKeyword]);

    const { filteredOrgs, ...filterStats } = useMemo(
        () => filterOrgs(searchedAndFilteredOrgs(organizations, searchedOrgs, searchKeyword), currentOrgFilter),
        [searchedAndFilteredOrgs, organizations, searchedOrgs, searchKeyword, currentOrgFilter],
    );

    let dialogBox = null;

    if (organizations) {
        switch (dialogBoxAction) {
            case DialogAction.SHARE:
                dialogBox = (
                    <CampaignsDialogBox
                        open={dialogOpen}
                        cancel={onSubmitCampaignsCancel}
                        submitValues={submitValues}
                        onSubmit={onSubmitCampaignsProceed}
                        dialogBoxAction={dialogBoxAction}
                    />
                );
                break;
            case DialogAction.UNSHARE:
                dialogBox = (
                    <CampaignsDialogBox
                        open={dialogRemoveOpen}
                        cancel={onSubmitCampaignsCancel}
                        submitValues={submitValues}
                        onSubmit={onSubmitCampaignsProceed}
                        dialogBoxAction={dialogBoxAction}
                    />
                );
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <div className={classes.header}>
                <Typography component="h2" variant="h3" align="left" className={classes.headline}>
                    Organizations ({filteredOrgs.length})
                </Typography>
            </div>
            <ListSearch
                className={classes.search}
                onSearch={onSearch}
                keyword={searchKeyword}
                includes={searchIncludes}
            />
            <div>
                <OrganizationListFilter
                    organizations={organizations}
                    brands={brands}
                    filterStats={filterStats}
                    onOrgFilterChange={onOrgListFilterChange}
                    currentOrgFilter={currentOrgFilter}
                />
            </div>
            <div>{dialogBox}</div>
            <Table
                data={filteredOrgs}
                columns={campaignColumns}
                actions={tableActions}
                childActions={childTableActions}
                pageNumber={pageNumber}
                onUpdatePageNumber={onUpdatePageNumber}
                // same org can share different brands so need to include brandId to make unique
                keyFn={(item) => (item?.brandId ? item.orgId + item.brandId : item.orgId)}
            />
        </div>
    );
}

OrganizationListTable.propTypes = {
    organizations: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            userCount: PropTypes.number.isRequired,
            campaignCount: PropTypes.number.isRequired,
            brands: PropTypes.array.isRequired,
            orgId: PropTypes.string.isRequired,
        }),
    ).isRequired,
    brands: PropTypes.array,
    onUpdatePageNumber: PropTypes.func,
    pageNumber: PropTypes.number,
    removeShareOrg: PropTypes.func,
    onCampaignShareSubmit: PropTypes.func,
    organizationFilter: PropTypes.shape({
        keyword: PropTypes.string,
        includes: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                label: PropTypes.string,
            }),
        ),
    }),
    updateFilteredOrgs: PropTypes.func,
    onFilterUpdate: PropTypes.func,
    currentOrgFilter: PropTypes.object,
    setFlyoutStatus: PropTypes.func,
    setSelectedItem: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    organizationFilter: selectOrganizationFilter,
});

export function mapDispatchToProps(dispatch) {
    return {
        updateFilteredOrgs: (keyword, includes) =>
            dispatch(
                updateFilteredOrganizations({
                    keyword,
                    includes,
                }),
            ),
        onCampaignShareSubmit: (sharedData) => {
            dispatch(shareOrg(sharedData));
        },
        removeShareOrg: (organization) => {
            dispatch(removeOrg(organization));
        },
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OrganizationListTable);
