import React from 'react';
import PropTypes from 'prop-types';

const LoginSvg = ({ width = 24, height = 24 }) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.42506 7.3512C7.42506 5.93917 8.57984 4.7896 10 4.7896C11.4202 4.7896 12.5749 5.93917 12.5749 7.3512C12.5749 8.76323 11.4202 9.91281 10 9.91281C8.57984 9.91281 7.42506 8.76323 7.42506 7.3512ZM16.7124 15.5545V14.7181C16.7124 13.0122 15.967 11.3983 14.6664 10.2913C14.3964 10.0615 13.9916 10.0942 13.7618 10.3642C13.5326 10.6337 13.5647 11.0385 13.8347 11.2683C14.8484 12.1309 15.4298 13.3884 15.4298 14.7181V16.8079C13.9385 17.9995 12.053 18.7174 10 18.7174C7.94763 18.7174 6.06147 17.9995 4.57016 16.8079V14.7181C4.57016 12.7719 5.84859 11.0268 7.68809 10.422C8.33372 10.9049 9.13216 11.1954 10 11.1954C12.1276 11.1954 13.8575 9.47129 13.8575 7.3512C13.8575 5.23111 12.1276 3.50646 10 3.50646C7.87297 3.50646 6.14196 5.23111 6.14196 7.3512C6.14196 8.10825 6.36592 8.81223 6.74618 9.4083C4.67923 10.3292 3.28706 12.4161 3.28706 14.7181V15.5539C2.03663 14.0445 1.2831 12.1087 1.2831 10.0003C1.2831 5.1932 5.19363 1.28314 10 1.28314C14.8064 1.28314 18.7169 5.1932 18.7169 10.0003C18.7169 12.1093 17.9634 14.0451 16.7124 15.5545ZM10 0C4.48618 0 0 4.48573 0 10.0003C0 15.5143 4.48618 20 10 20C15.5138 20 20 15.5143 20 10.0003C20 4.48573 15.5138 0 10 0Z"
            fill="white"
        />
    </svg>
);

LoginSvg.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LoginSvg;
