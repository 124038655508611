export const DISPLAY_TAB_DEFAULT_KEY = 'creative';

export const displayTabs = {
    creative: {
        label: 'Creative',
        key: 'creative',
    },
    placement: {
        label: 'Placement',
        key: 'placement',
    },
    product: {
        label: 'Product',
        key: 'product',
    },
};
