import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: false,
};

const campaignReportingPageSlice = createSlice({
    name: 'campaignReportingPage',
    initialState,
    reducers: {
        downloadReportStart(draft) {
            draft.loading = true;
            draft.error = false;
        },
        downloadReportSuccess(draft) {
            draft.loading = false;
            draft.error = false;
        },
        downloadReportError(draft) {
            draft.loading = false;
            draft.error = true;
        },
    },
});

export const { downloadReportStart, downloadReportError, downloadReportSuccess } = campaignReportingPageSlice.actions;
export default campaignReportingPageSlice.reducer;
