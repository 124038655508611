import React from 'react';
import PropTypes from 'prop-types';

const QuestionHoverSvg = ({ width = 20, height = 24 }) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0011 18.5716C5.27486 18.5716 1.42969 14.7259 1.42969 9.99972C1.42969 5.27354 5.27486 1.42871 10.0011 1.42871C14.7274 1.42871 18.5725 5.27354 18.5725 9.99972C18.5725 14.7259 14.7274 18.5716 10.0011 18.5716Z"
            stroke="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4319 15.3566C11.4319 15.9482 10.9516 16.428 10.3605 16.428C9.76938 16.428 9.28906 15.9482 9.28906 15.3566C9.28906 14.7649 9.76938 14.2852 10.3605 14.2852C10.9516 14.2852 11.4319 14.7649 11.4319 15.3566Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5725 6.79704C13.5725 8.27754 12.5699 9.06033 11.7636 9.68947C11.1287 10.1851 10.7397 10.5117 10.7397 10.9645V12.2267C10.7397 12.5749 10.4415 12.857 10.0738 12.857C9.70609 12.857 9.40786 12.5749 9.40786 12.2267V10.9645C9.40786 9.89405 10.2095 9.26855 10.9165 8.71637C11.6271 8.16195 12.2413 7.68282 12.2413 6.79704C12.2413 6.77745 12.2164 4.83237 10.0738 4.83237C7.98584 4.83237 7.76681 6.83315 7.75854 6.91822C7.72484 7.2647 7.39616 7.51966 7.03437 7.48803C6.66815 7.45613 6.39859 7.14911 6.43258 6.80236C6.54135 5.68485 7.44256 3.57129 10.0738 3.57129C12.6409 3.5713 13.5725 5.50042 13.5725 6.79704Z"
            fill="white"
        />
    </svg>
);

QuestionHoverSvg.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default QuestionHoverSvg;
