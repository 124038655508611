/**
 *
 * FlyoutModal
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, IconButton, Typography } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

const FlyoutContentStyles = makeStyles(() => ({
    root: {},
    label: {
        fontSize: '14px',
        paddingBottom: '9px',
    },
    content: {
        paddingBottom: '40px',
    },
}));

export const FlyoutContent = ({ label, children }) => {
    const classes = FlyoutContentStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h6" className={classes.label}>
                {label}
            </Typography>
            <div className={classes.content}>{children}</div>
        </div>
    );
};

FlyoutContent.propTypes = {
    label: PropTypes.string,
    children: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        margin: '5px',
    },
    top: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(3),
    },
    title: {
        fontSize: '26px',
    },
    body: {
        padding: `0 ${theme.spacing(3)}px`,
        width: theme.spacing(46),
    },
    bottom: {
        padding: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
}));

const FlyoutModal = ({ title, open, onClose, bottom, children }) => {
    const classes = useStyles();

    return (
        <Drawer
            open={open}
            onClose={onClose}
            variant="temporary"
            anchor="right"
            BackdropProps={{ style: { backgroundColor: 'grey' } }}
            classes={{ root: classes.root }}
        >
            <div className={classes.top}>
                <span className={classes.title}>{title}</span>
                <IconButton aria-label="closeFlyout" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={classes.body}>{children}</div>
            <div className={classes.bottom}>{bottom}</div>
        </Drawer>
    );
};

FlyoutModal.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    bottom: PropTypes.node,
    children: PropTypes.node,
};

FlyoutModal.defaultProps = {
    title: '',
};

export default FlyoutModal;
