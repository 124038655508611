import React, { memo } from 'react';
import PropTypes from 'prop-types';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { isNil } from 'lodash';
import { numberWithCommas } from 'Helpers/string-formatter';
import Highlight from 'components/ListSearch/Highlight';
import { TableFormatter } from 'Helpers/table-formatter';

import { ColumnPropType, ColumnTypes } from './constants';

export const TableCellText = ({ item, column, filterValue, hidden }) => {
    const value = item[column.field];

    if (hidden) {
        return '';
    }

    if (column.format) {
        const formatedValue = column.format(value);
        if (formatedValue instanceof TableFormatter) {
            return !isNil(formatedValue.render) ? formatedValue.render : formatedValue.value;
        }
        throw Error('Column format should return an instance of TableFormatter');
    }

    if (column.type === 'boolean') {
        return value ? <CheckIcon color="primary" /> : <CloseIcon color="error" />;
    }

    if (ColumnTypes.includes(column.type)) {
        return numberWithCommas(value, column.type);
    }

    if (filterValue) {
        return <Highlight content={value} sub={filterValue} />;
    }

    return value;
};

TableCellText.propTypes = {
    item: PropTypes.object.isRequired,
    column: PropTypes.shape(ColumnPropType).isRequired,
    filterValue: PropTypes.string,
    hidden: PropTypes.bool,
};

export default memo(TableCellText);
