/**
 *
 * OrganizationListFilter
 *
 */
import React, { useState, useMemo } from 'react';
import { map, isEmpty, filter } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider, IconButton } from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import PropTypes from 'prop-types';
import MultiSelect from 'components/FlyoutModal/MultiSelect';
import { comparator } from 'utils/sortComparator';
import MultiCheckbox from 'components/FlyoutModal/MultiCheckbox';
import MultiCheckSelect from 'components/MultiCheckSelect';
import { orgTypeOptionsArray, orgSharingOptionsArray } from './constants';
import FlyoutModal, { FlyoutContent } from '../../../components/FlyoutModal';

const useStyles = makeStyles((theme) => ({
    top: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: theme.spacing(5),
        border: `solid 1px ${theme.palette.battleGray}`,
        borderBottom: 'none',
        position: 'relative',
        backgroundColor: 'white',
    },
    headerFlexBox: {
        padding: '15px',
        display: 'flex',
        alignItems: 'center',
    },
    strong: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 18,
        fontWeight: 'bold',
        paddingRight: 2,
        color: '#1D252C',
    },
    buttonSecondary: {
        border: 'none',
        backgroundColor: 'transparent',
        color: '#0046BE',
        textTransform: 'capitalize',
        alignSelf: 'start',
        '&:hover': {
            background: 'none',
            color: 'theme.palette.darkBlue',
        },
        marginTop: '25px',
        marginLeft: '-8px',
    },
}));

export function orgsFilterValidation(validOrgs, orgsFilter) {
    const validOrgNames = validOrgs.map((orgObj) => orgObj.name);
    const validSet = new Set(validOrgNames);
    const parsedOrgs = orgsFilter.filter((org) => validSet.has(org));
    return validOrgNames?.length === parsedOrgs?.length;
}

function createSelectionInfo(selectableOptions, selectedOptions, currentOptionCounts) {
    if (selectableOptions && selectedOptions) {
        const enrichedItems = selectableOptions.map((option) => {
            const count = currentOptionCounts[option.name];
            return {
                ...option,
                name: `${option.name} (${count || 0})`,
                value: option.name,
            };
        });

        return map(enrichedItems, (item) => ({
            ...item,
            checked: selectedOptions.has(item.value),
            value: item.value,
        }));
    }
    return null;
}

export function OrganizationListFilter({ organizations, brands, currentOrgFilter, onOrgFilterChange, filterStats }) {
    const classes = useStyles();
    const orgTypeOptions = orgTypeOptionsArray;
    const orgSharingOptions = orgSharingOptionsArray;

    const { brandStats, orgTypeStats, orgSharingStats } = filterStats;

    const { selectedOrgSharing, selectedOrgTypes, selectedBrands, selectedOrgs } = useMemo(() => {
        const newOrgFilter = currentOrgFilter ? new Set(currentOrgFilter.organizations) : new Set([]);
        const newBrandFilter = currentOrgFilter ? new Set(currentOrgFilter.brands) : new Set([]);
        const newTypeFilter = currentOrgFilter ? new Set(currentOrgFilter.orgTypeOptions) : new Set([]);
        const newOrgShareFilter = currentOrgFilter ? new Set(currentOrgFilter.orgSharingOptions) : new Set([]);
        return {
            selectedOrgs: newOrgFilter,
            selectedBrands: newBrandFilter,
            selectedOrgTypes: newTypeFilter,
            selectedOrgSharing: newOrgShareFilter,
        };
    }, [currentOrgFilter]);

    const [openFlyoutModal, setOpenFlyoutModal] = useState(false);

    const onOpenFlyoutModalClick = () => setOpenFlyoutModal(true);
    const onCloseFlyoutModal = () => {
        setOpenFlyoutModal(false);
    };

    let brandOptions = map(Object.values(brands), (brand) => ({ name: brand.name, value: brand.name }));
    if (selectedOrgs.size > 0) {
        // Handle brands based on orgFilter
        const getOrgWithBrands = organizations.filter((org) => selectedOrgs.has(org.name)) || [];
        const orgBrands = getOrgWithBrands.map((org) => org.brands).flat();

        // De-duplicate brands from orgs and convert to expected format
        brandOptions = [...new Set(orgBrands)].map((brand) => ({ name: brand, value: brand }));
    }

    const onFilteredOrgsChange = (orgSelectionState) => {
        const newOrgFilter = map(orgSelectionState, 'name').slice(0, 1);

        if (newOrgFilter.length === 0) {
            // If no org is selected, leave brands alone, since all brands are valid
            onOrgFilterChange({ ...currentOrgFilter, organizations: [] });
        } else {
            const selectedOrgInfo = organizations.filter((org) => newOrgFilter.indexOf(org.name) !== -1) || [];
            const possibleBrands = selectedOrgInfo.reduce((acc, org) => acc.concat(org.brands), []);
            const newBrands = currentOrgFilter.brands?.filter(
                (currentBrand) => possibleBrands.indexOf(currentBrand) !== -1,
            );
            onOrgFilterChange({ ...currentOrgFilter, organizations: newOrgFilter, brands: newBrands });
        }
    };

    const onFilteredBrandsChange = (brandSelectionState) => {
        const newBrandFilter = map(brandSelectionState, 'value');
        onOrgFilterChange({ ...currentOrgFilter, brands: newBrandFilter });
    };

    const onFilteredTypesChange = (typeSelectionState) => {
        const newTypeFilter = typeSelectionState.filter((state) => state.checked).map((state) => state.value);
        onOrgFilterChange({ ...currentOrgFilter, orgTypeOptions: newTypeFilter });
    };

    const onFilteredSharingChange = (shareSelectionState) => {
        const newSharingFilter = shareSelectionState
            .filter((sharingOption) => sharingOption.checked)
            .map((state) => state.value);
        onOrgFilterChange({ ...currentOrgFilter, orgSharingOptions: newSharingFilter });
    };

    const filteredOrgs = isEmpty(selectedOrgs)
        ? []
        : filter(organizations, (organization) => selectedOrgs.has(organization.name)).map((org) => ({
              ...org,
              name: `${org.name}`,
              value: org.name,
          }));

    const filteredBrands = isEmpty(selectedBrands)
        ? []
        : filter(brandOptions, ({ value }) => selectedBrands.has(value)).map((b) => {
              const count = brandStats[b.name];
              return { ...b, name: `${b.name} (${count || 0})` };
          });

    const orgTypeSelections = createSelectionInfo(orgTypeOptions, selectedOrgTypes, orgTypeStats);
    const orgSharingSelections = createSelectionInfo(orgSharingOptions, selectedOrgSharing, orgSharingStats);

    const onResetAllClick = () => {
        onOrgFilterChange({
            organizations: [],
            brands: [],
            orgTypeOptions: [],
            orgSharingOptions: [],
        });
    };

    return (
        <div className={classes.top}>
            <div className={classes.headerFlexBox}>
                <span className={classes.strong}> Filter by: </span>
                <IconButton onClick={onOpenFlyoutModalClick} data-testid="orgFilterFlyoutButton">
                    <TuneIcon />
                </IconButton>
            </div>
            <FlyoutModal
                title="Organization Filters"
                open={openFlyoutModal}
                onClose={onCloseFlyoutModal}
                aria-label="organization-filter"
                bottom={
                    <>
                        <Divider />
                        <Button
                            className={classes.buttonSecondary}
                            onClick={onResetAllClick}
                            data-testid="resetAllOrgFilter"
                        >
                            Reset All
                        </Button>
                    </>
                }
            >
                <FlyoutContent label="Organizations">
                    <MultiSelect
                        placeholder="Search by Organization..."
                        options={organizations.slice().sort((a, b) => comparator(a, b))}
                        selectedOptions={filteredOrgs}
                        onChange={onFilteredOrgsChange}
                    />
                </FlyoutContent>
                <FlyoutContent label="Brands">
                    <MultiCheckSelect
                        multiple
                        checkbox={false}
                        placeholder="Search by Brands..."
                        options={brandOptions.slice().sort((a, b) => comparator(a, b))}
                        values={filteredBrands.slice().sort((a, b) => comparator(a, b))}
                        onChange={onFilteredBrandsChange}
                    />
                </FlyoutContent>
                <FlyoutContent label="Org. Types">
                    <MultiCheckbox
                        options={orgTypeSelections}
                        optionsType="orgType"
                        onChange={onFilteredTypesChange}
                        isSorted
                    />
                </FlyoutContent>
                <FlyoutContent label="Sharing Campaigns?">
                    <MultiCheckbox
                        options={orgSharingSelections}
                        optionsType="orgShare"
                        onChange={onFilteredSharingChange}
                        isSorted
                    />
                </FlyoutContent>
            </FlyoutModal>
        </div>
    );
}

OrganizationListFilter.propTypes = {
    organizations: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            userCount: PropTypes.number.isRequired,
            campaignCount: PropTypes.number.isRequired,
            brands: PropTypes.array.isRequired,
            orgId: PropTypes.string.isRequired,
        }),
    ),
    brands: PropTypes.array,
    currentOrgFilter: PropTypes.object,
    onOrgFilterChange: PropTypes.func,
    filterStats: PropTypes.shape({
        brandStats: PropTypes.object,
        orgTypeStats: PropTypes.object,
        orgSharingStats: PropTypes.object,
    }).isRequired,
};

OrganizationListFilter.defaultProps = {
    organizations: [],
    brands: [],
    onOrgFilterChange: () => {},
};

export default OrganizationListFilter;
