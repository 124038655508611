/*
 *
 * UserListPage actions
 *
 */

import {
    LOAD_ROLES,
    LOAD_ROLES_SUCCESS,
    LOAD_ROLES_ERROR,
    LOAD_IDAM_USERS,
    LOAD_IDAM_USERS_SUCCESS,
    LOAD_IDAM_USERS_ERROR,
    LOAD_BRANDS,
    LOAD_BRANDS_SUCCESS,
    LOAD_BRANDS_ERROR,
    UPDATE_USER_FILTER,
    UPDATE_USER_FILTER_ERROR,
    LOAD_ORG_OPTIONS,
    LOAD_ORG_OPTIONS_SUCCESS,
    LOAD_ORG_OPTIONS_ERROR,
    UPDATE_PAGE_NUMBER,
} from './constants';

export function loadRoles() {
    return {
        type: LOAD_ROLES,
    };
}

export function loadRolesSuccess(roles) {
    return {
        type: LOAD_ROLES_SUCCESS,
        roles,
    };
}

export function loadRolesError(error) {
    return {
        type: LOAD_ROLES_ERROR,
        error,
    };
}

export function loadBrands() {
    return {
        type: LOAD_BRANDS,
    };
}

export function loadBrandsSuccess(brands) {
    return {
        type: LOAD_BRANDS_SUCCESS,
        brands,
    };
}

export function loadBrandsError(error) {
    return {
        type: LOAD_BRANDS_ERROR,
        error,
    };
}

export function loadIdamUsers() {
    return {
        type: LOAD_IDAM_USERS,
    };
}

export function loadIdamUsersSuccess(users) {
    return {
        type: LOAD_IDAM_USERS_SUCCESS,
        users,
    };
}

export function loadIdamUsersError(error) {
    return {
        type: LOAD_IDAM_USERS_ERROR,
        error,
    };
}

export function updateUserFilter(filter) {
    return {
        type: UPDATE_USER_FILTER,
        filter,
    };
}

export function updateUserFilterError(error) {
    return {
        type: UPDATE_USER_FILTER_ERROR,
        error,
    };
}

export function loadOrgOptions() {
    return {
        type: LOAD_ORG_OPTIONS,
    };
}

export function loadOrgOptionsSuccess(organizations) {
    return {
        type: LOAD_ORG_OPTIONS_SUCCESS,
        payload: {
            organizations,
        },
    };
}

export function loadOrgOptionsError(error) {
    return {
        type: LOAD_ORG_OPTIONS_ERROR,
        payload: {
            error,
        },
    };
}

export function updatePageNumber(pageNumber) {
    return {
        type: UPDATE_PAGE_NUMBER,
        payload: pageNumber,
    };
}
