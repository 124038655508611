import { ResourceParam } from 'containers/ResourcePage';
import { downloadOnsiteVideoReport, getOnsiteVideoCampaignDetails } from '../../../api/api-client';
import { Permissions } from '../../../shared/constants/user/permissions';
import { CAMPAIGN_TYPE_ONSITE_VIDEO, ONSITE_VIDEO_KEY } from '../../../Helpers/helpers';
import { renderDefaultTooltipContent } from '../../../components/CampaignReporting/Header/tooltip-content.js/defaultTooltip';
import { ONSITE_TAB_DEFAULT_KEY, onsiteTabs } from './onsiteTabs';
import { onsiteSheets } from './onsiteSheets';
import { onsiteCards } from './onsiteCards';
import { columnMap, columnFilterMap, onsiteVideoColumns, modifyColumns, defaultColumns } from './columns';
import { onsiteVideoDataVizOptions } from './dataviz';

export const onsiteVideoDefinitions = {
    label: 'Onsite Video',
    vehicleKey: ONSITE_VIDEO_KEY,
    campaignType: CAMPAIGN_TYPE_ONSITE_VIDEO,

    // Permissions
    viewPermissions: [Permissions.CAN_VIEW_ONSITE_VIDEO_CAMPAIGNS],
    downloadPermissions: [Permissions.CAN_DOWNLOAD_ONSITE_VIDEO_CAMPAIGNS],

    // Campaign Details Page
    detailsApiCall: getOnsiteVideoCampaignDetails,
    useNewColumnFilter: true,
    defaultTabKey: ONSITE_TAB_DEFAULT_KEY,
    defaultColumns,
    columnMap,
    columnFilterMap,
    cards: onsiteCards,
    tabs: onsiteTabs,
    columns: onsiteVideoColumns,
    modifyColumns,
    visualizationOptions: onsiteVideoDataVizOptions,

    // Attribution
    tooltipRenderFn: renderDefaultTooltipContent,
    resourcesPageUrl: `/resources/${ResourceParam.ONSITE_DISPLAY}`,
    attributionWindowDays: 14,

    // Download Report
    reportDownloadApiCall: downloadOnsiteVideoReport,
    sheets: onsiteSheets('video'),
};
