export const sponsoredRmpCards = [
    {
        key: 'impressions',
        title: 'Impressions',
        type: 'number',
    }, // number
    {
        key: 'clicks',
        title: 'Clicks',
        type: 'number',
    }, // number
    {
        key: 'ctr',
        title: 'CTR',
        type: 'percent',
    }, // percent
    {
        key: 'spend',
        title: 'Delivered Spend',
        type: 'currency',
    }, // currency
    {
        key: 'roas',
        title: 'ROAS ($)',
        type: 'currency',
    }, // currency
    {
        key: 'revenue',
        title: 'Revenue',
        type: 'currency',
    }, // currency
];
