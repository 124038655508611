import { Button, ButtonGroup, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

// STYLES
const useStyles = makeStyles((theme) => ({
    button: {
        background: 'white',
        border: '1px solid #0046be !important',

        '&:hover': {
            backgroundColor: '#0046be',
            boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',

            '& span': {
                color: 'white',
            },

            '& span[class*="endIcon"]': {
                backgroundColor: 'rgb(0, 49, 133)',
            },
        },
    },
    buttonLabel: {
        color: 'rgb(0, 70, 190)',
        textTransform: 'capitalize',
        fontSize: '12px',
        fontFamily: 'HumanBBYDigital',
    },
    buttonText: {
        padding: '6px 0 6px 16px',
    },
    buttonEndIcon: {
        color: 'white',
        height: '33px',
        width: '44px',
        margin: '-6px 0 -6px 16px',
        backgroundColor: '#0046be',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& svg': {
            fontSize: '1.5rem !important',
        },
    },
    menu: {
        minWidth: '164px',
        marginTop: '50px',

        '& button': {
            width: '100%',
            borderRadius: '0',
            padding: '6px 16px',
            justifyContent: 'left',
        },

        '& span': {
            fontSize: '14px',
            padding: '0',
            textTransform: 'capitalize',
            fontWeight: 'normal',
        },
    },
    menuitemroot: {
        justifyContent: 'center',
        fontFamily: 'humanBBYDigital',
        fontSize: '1em',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.5em',
        letterSpacing: 'normal',
        color: theme.palette.techBlack,
        width: '100%',
        '&:hover': {
            backgroundColor: theme.palette.humanBlue,
            color: theme.palette.standardWhite,
        },
        '& .MuiButtonBase-root': {
            color: 'inherit',
        },
    },
    menuItem: {
        padding: '0',
    },
}));

export const SplitButton = ({ label, buttons = [], menuWidth = '200px', onButtonClick, ...rest }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const anchorRef = useRef(null);

    const onClose = () => setOpen(false);
    const onToggle = () => setOpen(!open);

    const isSeparatedButton = !isNil(onButtonClick);

    return (
        <div className={rest.className}>
            {isSeparatedButton ? (
                <ButtonGroup ref={anchorRef} variant="contained" color="primary" style={{ boxShadow: 'none' }}>
                    <Button
                        classes={{
                            root: classes.button,
                            label: classes.buttonLabel,
                        }}
                        onClick={onButtonClick}
                    >
                        {label}
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="menu"
                        onClick={onToggle}
                    >
                        <KeyboardArrowDownIcon />
                    </Button>
                </ButtonGroup>
            ) : (
                <Button
                    ref={anchorRef}
                    classes={{
                        root: classes.button,
                        label: classes.buttonLabel,
                        text: classes.buttonText,
                        endIcon: classes.buttonEndIcon,
                    }}
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="menu"
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={onButtonClick || onToggle}
                >
                    {label}
                </Button>
            )}
            <Menu
                classes={{ paper: classes.menu }}
                anchorEl={anchorRef.current}
                keepMounted
                open={open}
                onClose={onClose}
                PaperProps={{
                    style: {
                        width: menuWidth,
                    },
                }}
            >
                {buttons.map((button) => (
                    <MenuItem
                        key={`${button.key}`}
                        classes={{
                            root: classes.menuitemroot,
                        }}
                        onClick={onClose}
                    >
                        {button}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

SplitButton.propTypes = {
    label: PropTypes.string.isRequired,
    buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
    menuWidth: PropTypes.string,
    onButtonClick: PropTypes.func,
};
