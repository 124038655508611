/**
 *
 * PageNotFound
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AlertSvg from 'components/Svg/Alert';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    item: {
        margin: '32px',
    },
}));

export default function PageNotFound() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.item}>
                <AlertSvg width={64} height={64} />
            </div>
            <div className={classes.item}>
                <h1>404. Page Error</h1>
                <h2>The requested URL was not found on this server.</h2>
            </div>
        </div>
    );
}
