import React from 'react';
import PropTypes from 'prop-types';

const AlertSvg = ({ width = 24, height = 24 }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.14 20.27H4.75004C3.75004 20.27 2.86004 19.75 2.36004 18.88C1.86004 18.01 1.87004 16.98 2.38004 16.12L9.62004 3.85C10.12 3.01 11 2.5 11.99 2.5H12C12.99 2.5 13.87 3.01 14.37 3.87L21.48 16.09C22.01 16.93 22.03 17.97 21.54 18.86C21.05 19.75 20.15 20.27 19.14 20.27ZM11.99 4C11.54 4 11.14 4.23 10.91 4.62L3.68004 16.88C3.45004 17.27 3.45004 17.74 3.67004 18.14C3.90004 18.53 4.30004 18.77 4.76004 18.77H19.14C19.6 18.77 20.01 18.53 20.23 18.13C20.45 17.73 20.44 17.25 20.19 16.86L13.07 4.62C12.84 4.23 12.44 4 11.99 4Z"
            fill="#1D252C"
        />
        <path
            d="M12 13.66C11.58 13.66 11.25 13.32 11.25 12.9L11.29 7.74C11.3 7.33 11.63 7 12.04 7C12.04 7 12.04 7 12.05 7C12.46 7 12.8 7.34 12.79 7.76L12.75 12.92C12.75 13.33 12.41 13.66 12 13.66Z"
            fill="#1D252C"
        />
        <path
            d="M12 15.25C11.45 15.25 11 15.7 11 16.25C11 16.8 11.45 17.25 12 17.25C12.55 17.25 13 16.8 13 16.25C13 15.7 12.55 15.25 12 15.25Z"
            fill="#1D252C"
        />
    </svg>
);

AlertSvg.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AlertSvg;
