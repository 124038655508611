import {
    currencyFormatter,
    numberFormatter,
    percentFormatter,
    campaignTypeFormatter,
    summaryDashboardViewAction,
    stringFormatter,
} from '../../Helpers/table-formatter';

export const columns = [
    {
        field: 'campaignType',
        label: 'Campaign Type',
        sortable: true,
        format: campaignTypeFormatter,
    },
    {
        field: 'campaignCount',
        label: 'Campaigns',
        type: 'number',
        sortable: true,
        format: numberFormatter,
    },
    {
        field: 'impressions',
        label: 'Impressions',
        type: 'number',
        sortable: true,
        format: numberFormatter,
    },
    {
        field: 'clicks',
        label: 'Clicks',
        type: 'number',
        sortable: true,
        format: numberFormatter,
    },
    {
        field: 'ctr',
        label: 'CTR',
        type: 'percent',
        sortable: true,
        format: percentFormatter,
    },
    {
        field: 'deliveredSpend',
        label: 'Delivered Spend',
        type: 'currency',
        sortable: true,
        format: currencyFormatter,
    },
    {
        field: 'revenue',
        label: 'Revenue',
        type: 'currency',
        sortable: true,
        format: currencyFormatter,
    },
    {
        field: 'roas',
        label: 'ROAS',
        type: 'currency',
        sortable: true,
        format: currencyFormatter,
    },
    {
        field: 'campaignType',
        label: 'Action',
        sortable: false,
        format: summaryDashboardViewAction,
    },
];

export const totalColumns = [
    {
        field: 'campaignType',
        label: 'Campaign Type',
        sortable: true,
        format: campaignTypeFormatter,
    },
    {
        field: 'campaignCount',
        label: 'Campaigns',
        type: 'number',
        sortable: true,
        format: numberFormatter,
    },
    {
        field: 'impressions',
        label: 'Impressions',
        type: 'number',
        sortable: true,
        format: numberFormatter,
    },
    {
        field: 'clicks',
        label: 'Clicks',
        type: 'number',
        sortable: true,
        format: numberFormatter,
    },
    {
        field: 'ctr',
        label: 'CTR',
        type: 'percent',
        sortable: true,
        format: percentFormatter,
    },
    {
        field: 'deliveredSpend',
        label: 'Delivered Spend',
        type: 'currency',
        sortable: true,
        format: currencyFormatter,
    },
    {
        field: 'revenue',
        label: 'Revenue',
        type: 'string',
        sortable: true,
        format: stringFormatter,
    },
    {
        field: 'roas',
        label: 'ROAS',
        type: 'string',
        sortable: true,
        format: stringFormatter,
    },
    {
        field: 'campaignType',
        label: 'Action',
        sortable: false,
        format: summaryDashboardViewAction,
    },
];
