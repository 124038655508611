import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } from './constants';
export const initialState = {
    notifications: [],
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ENQUEUE_SNACKBAR:
            return {
                notifications: [
                    ...state.notifications,
                    {
                        key: action.key,
                        ...action.notification,
                    },
                ],
            };

        case CLOSE_SNACKBAR: {
            const updatedNotification = state.notifications.map((notification) =>
                action.dismissAll || notification.key === action.key
                    ? { ...notification, dismissed: true }
                    : { ...notification },
            );

            return {
                notifications: updatedNotification,
            };
        }

        case REMOVE_SNACKBAR:
            return {
                notifications: state.notifications.filter((notification) => notification.key !== action.key),
            };

        default:
            return state;
    }
};

export default notificationReducer;
