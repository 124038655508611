import React from 'react';
import { CircularProgress, FormControl, Chip, Grid, InputLabel } from '@material-ui/core';
import { Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { required, isNotBlank, composeValidators } from 'utils/validators';
import MultiCheckSelect from 'components/MultiCheckSelect/Loadable';
import { RequestStatus } from 'shared/constants';
import PropTypes from 'prop-types';
import { useAuthedUser } from 'utils/user';
import { userIsExternal } from 'Helpers/helpers';
import { comparator } from 'utils/sortComparator';

const useStyles = makeStyles((theme) => ({
    chip: {
        borderRadius: 4,
        margin: '8px 4px',
    },
    error: {
        color: theme.palette.errorRed,
        margin: '0 14px 0 14px',
        fontSize: 12,
    },
    placeholder: {
        opacity: '0.435',
    },
    loadingContainer: {
        textAlign: 'center',
        paddingTop: theme.spacing(2),
    },
    inputLabel: {
        height: 14,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
    },
}));

const PLACEHOLDER = 'Search by Organization...';

export function OrganizationSelect({ organizations, organizationLoadState, allowSelect, label, parentComp }) {
    const classes = useStyles();
    const authedUser = useAuthedUser();

    if (userIsExternal(authedUser)) {
        return null;
    }

    const orgOptions = () => {
        if (parentComp === 'idamUserForm') {
            return organizations
                .slice()
                .sort((a, b) => comparator(a, b))
                .map((org) => ({ name: org.name, value: org.id }));
        }
        return organizations
            .slice()
            .sort((a, b) => comparator(a, b))
            .map((org) => ({ name: org.name, value: org.orgId }));
    };

    if (organizationLoadState !== RequestStatus.DONE) {
        return (
            <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>Organization</InputLabel>
                <div className={classes.loadingContainer}>
                    <CircularProgress size={22} />
                </div>
            </Grid>
        );
    }

    if (!allowSelect) {
        return (
            <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>Organization</InputLabel>
                <div>
                    <Chip
                        className={classes.chip}
                        key={`OrgSelect${organizations[0]}`}
                        variant="outlined"
                        label={organizations[0]}
                    />
                </div>
            </Grid>
        );
    }

    return (
        <Field name="organizations" component="select" type="text" validate={composeValidators(required, isNotBlank)}>
            {({ input, meta }) => (
                <Grid item xs={12}>
                    <InputLabel className={classes.inputLabel}>{label}</InputLabel>
                    <div>
                        <FormControl margin="dense" variant="outlined" fullWidth>
                            <MultiCheckSelect
                                {...input}
                                multiple={false}
                                checkbox={false}
                                placeholder={PLACEHOLDER}
                                values={input.value || []}
                                options={orgOptions()}
                                onBlur={(event) => input.onBlur(event)}
                            />
                        </FormControl>
                        {meta.error && meta.submitFailed && (
                            <span className={classes.error}>Please select an organization.</span>
                        )}
                    </div>
                </Grid>
            )}
        </Field>
    );
}

OrganizationSelect.propTypes = {
    organizations: PropTypes.array,
    organizationLoadState: PropTypes.string,
    allowSelect: PropTypes.bool,
    setResetBrands: PropTypes.func,
    label: PropTypes.string,
    parentComp: PropTypes.string,
};
