export const dzSponsoredOptions = [
    // Impressions
    { unit: 'integer', label: 'Impressions', key: 'impressions' },
    // Clicks
    { unit: 'integer', label: 'Clicks', key: 'clicks' },
    // Spend
    { unit: 'currency', label: 'Delivered Spend', key: 'spend' },
    // Revenue
    { unit: 'currency', label: 'Revenue', key: 'revenue' },
];
