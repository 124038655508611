/*
 *
 * ColumnFilter constants
 *
 */

export const ADD_FILTER = 'portal/ColumnFilter/ADD_FILTER';
export const REMOVE_FILTER = 'portal/ColumnFilter/REMOVE_FILTER';
export const RESET_FILTER = 'portal/ColumnFilter/RESET_FILTER';
export const INITIAL_FILTER = 'portal/ColumnFilter/INITIAL_FILTER';
