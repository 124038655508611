import PropTypes from 'prop-types';
import useFeatureFlags from './useFeatureFlags';

function FeatureFlag({ showWhen, children }) {
    const flags = useFeatureFlags();
    if (showWhen(flags)) {
        return children;
    }

    return null;
}

FeatureFlag.propTypes = {
    showWhen: PropTypes.func,
    children: PropTypes.any,
};

export default FeatureFlag;
