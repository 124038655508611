import React from 'react';
import PropTypes from 'prop-types';
import FlyoutModal from 'components/FlyoutModal';
import { FlyoutAction } from '../../../shared/constants';
import SendToKoddiFlyout from '../OrgFlyouts/SendToKoddi/SendToKoddiFlyout';
import ShareCampaignFlyout from '../OrgFlyouts/ShareCampaignFlyout';

export function OrgFlyoutContainer({
    organizations,
    orgLoadState,
    brands,
    brandsLoading,
    flyoutStatus,
    selectedItem,
    onCloseOrgFlyoutModal,
}) {
    let title = '';
    let flyoutContent = null;
    let isOpen = true;

    switch (flyoutStatus) {
        case FlyoutAction.SEND_TO_KODDI:
            title = 'Send To Koddi';
            flyoutContent = (
                <SendToKoddiFlyout
                    closeFlyout={onCloseOrgFlyoutModal}
                    brands={brands}
                    loading={brandsLoading}
                    organization={selectedItem}
                />
            );
            break;
        case FlyoutAction.SHARE:
            title = 'Share Campaigns';
            flyoutContent = (
                <ShareCampaignFlyout
                    organization={selectedItem}
                    orgLoadState={orgLoadState}
                    orgOptions={organizations}
                    brands={brands}
                    closeFlyout={onCloseOrgFlyoutModal}
                />
            );
            break;
        default:
            isOpen = false;
            break;
    }

    return (
        <FlyoutModal title={title} open={isOpen} onClose={onCloseOrgFlyoutModal}>
            {flyoutContent}
        </FlyoutModal>
    );
}

OrgFlyoutContainer.propTypes = {
    flyoutStatus: PropTypes.string,
    selectedItem: PropTypes.object,
    onCloseOrgFlyoutModal: PropTypes.func,
    brands: PropTypes.array,
    brandsLoading: PropTypes.bool,
    organizations: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            userCount: PropTypes.number.isRequired,
            campaignCount: PropTypes.number.isRequired,
            brands: PropTypes.array.isRequired,
            orgId: PropTypes.string.isRequired,
        }),
    ).isRequired,
    orgLoadState: PropTypes.string,
};
