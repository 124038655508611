export const dzProximityOptions = [
    // Unique Reach
    { unit: 'integer', label: 'Unique Reach', key: 'unique_reach' },
    // Total Taps (Notification Taps plus Banner Taps)
    { unit: 'integer', label: 'Taps', key: 'total_taps' },
    // Tap Rate (Total Taps divided by Total Unique Reach)
    { unit: 'percentage', label: 'Tap Rate', key: 'tap_rate' },
    // Revenue  (Total In Store Revenue plus Total Online Revenue - "View" Revenue)
    { unit: 'currency', label: 'Revenue', key: 'total_revenue' },
];
