import withAuth from 'api/auth-helpers/with-auth';

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @returns {object}           The response data
 */
export default function request(url, options) {
    return withAuth.request(url, options).then((resp) => (resp.data !== undefined ? resp.data : null));
}

/**
 * Returns a formatted query paramter string
 *
 * @param {object} params query params formatted based on kv name { yo: test, hi: 123 } = yo=test&hi=123
 *
 * @returns {string}      query param string
 */
export const serialize = (params = {}) => {
    if (typeof params !== 'object' && params === null) {
        throw new Error('formatQueryParams: Invalid parameter');
    }

    const keys = Object.keys(params);
    const parameters = keys.map((key) => {
        const param = encodeURIComponent(key);
        const value = encodeURIComponent(params[key]);
        return `${param}=${value}`;
    });

    return parameters.join('&');
};

/**
 * Returns default options for POST to portal api
 *
 * @param {object} body optional extra body parameters
 * @param {object} headers optional extra header parameters
 *
 * @returns {object}    general post options with pagination
 */
export const formatPostOptions = (body = {}, headers = {}, ops = {}) => ({
    ...ops,
    method: 'post',
    headers: {
        ...headers,
        'Content-Type': 'application/json',
    },
    data: body,
});

/**
 * Returns default options for PUT to portal api
 *
 * @param {object} body optional extra body parameters
 * @param {object} headers optional extra header parameters
 *
 * @returns {object}    general post options with pagination
 */
export const formatPutOptions = (body = {}, headers = {}) => ({
    method: 'put',
    headers: {
        ...headers,
        'Content-Type': 'application/json',
    },
    data: body,
});

/**
 * Returns default options for DELETE to portal api
 *
 * @param {object} body optional extra body parameters
 * @param {object} headers optional extra header parameters
 *
 * @returns {object}    general post options with pagination
 */
export const formatDeleteOptions = (body = {}, headers = {}) => ({
    method: 'delete',
    headers: {
        ...headers,
        'Content-Type': 'application/json',
    },
    data: body,
});

/**
 * Returns default options for GET method
 *
 * @param {object} headers optional request header
 *
 * @returns {object} general get options
 */
export const formatGetOptions = (headers = {}) => ({
    method: 'get',
    headers: {
        ...headers,
        'Content-Type': 'application/json',
    },
});
