/*
 *
 * Summary Dashboard constants
 *
 */

export const campaignStatusOptionsArray = [
    { name: 'Active', value: 'Active' },
    { name: 'Complete', value: 'Complete' },
];
