import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TabContext from './TabSidebarContext';

function getKey(value, label) {
    if (value === undefined) {
        return label;
    }

    return value;
}

function SidebarTab({ value, label, children, pathname }) {
    const { currentTab } = useContext(TabContext);
    const history = useHistory();

    useEffect(() => {
        if (getKey(value, label) === currentTab && pathname && history.location.pathname !== pathname) {
            history.push(pathname);
        }
    }, [pathname, value, label, currentTab, history]);

    if (getKey(value, label) === currentTab) {
        return children;
    }

    return null;
}

SidebarTab.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    children: PropTypes.node,
    pathname: PropTypes.string,
};

export { SidebarTab };
