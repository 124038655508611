/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGlobal = (state) => state.global || initialState;

const selectRouter = (state) => state.router;

const makeSelectAuthedUser = () => createSelector(selectGlobal, (globalState) => globalState.authedUser);

const makeSelectLoading = () => createSelector(selectGlobal, (globalState) => globalState.loading);

const makeSelectError = () => createSelector(selectGlobal, (globalState) => globalState.error);

const makeSelectCampaigns = () => createSelector(selectGlobal, (globalState) => globalState.campaigns);

const selectCampaignLoadStatus = createSelector(selectGlobal, (globalState) => globalState.campaignLoadStatus);

const makeSelectCampaignsHashmap = () =>
    createSelector(selectGlobal, (globalState) =>
        Object.fromEntries(globalState.campaigns.map((c) => [c.campaign_id, c])),
    );

const makeSelectCampaignFilters = () => createSelector(selectGlobal, (globalState) => globalState.campaignFilters);

const makeSelectCampaignBrands = () =>
    createSelector(selectGlobal, (globalState) => Object.keys(globalState.campaignFilters?.brand_name || {}));

const makeSelectLocation = () => createSelector(selectRouter, (routerState) => routerState.location);

const makeSelectFeatureFlag = () => createSelector(selectGlobal, (globalState) => globalState.featureFlag);

const makeSelectCreatedUser = () => createSelector(selectGlobal, (globalState) => globalState.createdUser);

const makeSelectUsers = () => createSelector(selectGlobal, (globalState) => globalState.users);

const makeSelectUsersLoading = () => createSelector(selectGlobal, (globalState) => globalState.usersLoading);

const makeSelectUsersError = () => createSelector(selectGlobal, (globalState) => globalState.usersLoading);

const makeSelectUserFilters = () => createSelector(selectGlobal, (globalState) => globalState.userFilters);

const makeSelectUpdateUserLoading = () => createSelector(selectGlobal, (globalState) => globalState.updateUserLoading);

const makeSelectUpdateUserError = () => createSelector(selectGlobal, (globalState) => globalState.updateUserError);

const makeSelectUpdateUserDone = () => createSelector(selectGlobal, (globalState) => globalState.updateUserDone);

const makeSelectCreateUserLoading = () => createSelector(selectGlobal, (globalState) => globalState.createUserLoading);

const makeSelectCreateUserError = () => createSelector(selectGlobal, (globalState) => globalState.createUserError);

const makeSelectCampaignGroups = () => createSelector(selectGlobal, (globalState) => globalState.campaignGroups);

const makeSelectSharedCampaignGroups = () =>
    createSelector(selectGlobal, (globalState) => globalState.sharedCampaignGroups);

const makeSelectCampaignGroupsLoading = () =>
    createSelector(selectGlobal, (globalState) => globalState.campaignGroupsLoading);

const makeSelectCampaignGroupsError = () =>
    createSelector(selectGlobal, (globalState) => globalState.campaignGroupsError);

const makeSelectAddCampaignsToGroupLoading = () =>
    createSelector(selectGlobal, (globalState) => globalState.addCampaignsToGroupLoading);

const makeSelectAddCampaignsToGroupError = () =>
    createSelector(selectGlobal, (globalState) => globalState.addCampaignsToGroupError);

const makeSelectCreateCampaignGroupLoading = () =>
    createSelector(selectGlobal, (globalState) => globalState.createCampaignGroupLoading);

const makeSelectCreateCampaignGroupError = () =>
    createSelector(selectGlobal, (globalState) => globalState.createCampaignGroupError);

const makeSelectRemoveCampaignFromGroupDone = () =>
    createSelector(selectGlobal, (globalState) => globalState.removeCampaignFromGroupDone);

const makeSelectAddCampaignsToGroupDone = () =>
    createSelector(selectGlobal, (globalState) => globalState.addCampaignsToGroupDone);

const makeSelectUpdateCampaignGroupDone = () =>
    createSelector(selectGlobal, (globalState) => globalState.updateCampaignGroupDone);

const makeSelectCreateCampaignGroupDone = () =>
    createSelector(selectGlobal, (globalState) => globalState.createCampaignGroupDone);

const makeSelectShareCampaignGroupDone = () =>
    createSelector(selectGlobal, (globalState) => globalState.shareCampaignGroupDone);

const makeSelectUnshareCampaignGroupDone = () =>
    createSelector(selectGlobal, (globalState) => globalState.unshareCampaignGroupDone);

const makeSelectDeleteCampaignGroupDone = () =>
    createSelector(selectGlobal, (globalState) => globalState.deleteCampaignGroupDone);

export {
    selectGlobal,
    makeSelectAuthedUser,
    makeSelectLoading,
    makeSelectError,
    makeSelectCampaigns,
    selectCampaignLoadStatus,
    makeSelectCampaignsHashmap,
    makeSelectCampaignFilters,
    makeSelectCampaignBrands,
    makeSelectLocation,
    makeSelectCreatedUser,
    makeSelectUsers,
    makeSelectUsersLoading,
    makeSelectUsersError,
    makeSelectUserFilters,
    makeSelectUpdateUserLoading,
    makeSelectUpdateUserError,
    makeSelectUpdateUserDone,
    makeSelectCreateUserLoading,
    makeSelectCreateUserError,
    makeSelectSharedCampaignGroups,
    makeSelectCampaignGroups,
    makeSelectCampaignGroupsLoading,
    makeSelectCampaignGroupsError,
    makeSelectAddCampaignsToGroupLoading,
    makeSelectAddCampaignsToGroupError,
    makeSelectCreateCampaignGroupLoading,
    makeSelectCreateCampaignGroupError,
    makeSelectRemoveCampaignFromGroupDone,
    makeSelectAddCampaignsToGroupDone,
    makeSelectUpdateCampaignGroupDone,
    makeSelectCreateCampaignGroupDone,
    makeSelectShareCampaignGroupDone,
    makeSelectUnshareCampaignGroupDone,
    makeSelectDeleteCampaignGroupDone,
    makeSelectFeatureFlag,
};
