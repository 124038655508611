import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import TextDisabled from 'components/TextDisabled';
import { ProductCatalogSelect } from 'components/ProductCatalogSelect';
import { createStructuredSelector } from 'reselect';
import { FlyoutContent } from '../../../../components/FlyoutModal';
import { createKoddiProvider, updateKoddiProviderAction } from './actions';
import { loadProvidersAction } from '../../slice';
import { selectGetProviderLoadState, selectProviders } from '../../selectors';
import { MyadsBrandSelect } from './MyadsBrandSelect';
import { formatBrands } from '../../../../utils/orgUtils';
import { findKoddiProviderId, advertiserIdJsx } from '../../utils';

const useStyles = makeStyles((theme) => ({
    submitButton: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginBottom: '24px',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
        '&:hover:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
}));

export function SendToKoddiFlyout({
    organization,
    onSendToKoddiSubmit,
    brands,
    providers,
    getProviders,
    getProvidersLoadState,
    updateKoddiProvider,
}) {
    const classes = useStyles();

    const initialValues = { myadsBrands: '', productCatalogBrands: [], organization };
    const [selectedBrandId, setSelectedBrandId] = useState('');

    const { orgId } = organization;
    const orgName = organization?.name;

    const organizationBrandOptions = useMemo(() => formatBrands(organization?.brands, brands), [
        brands,
        organization?.brands,
    ]);

    // koddi providerId is advertiserId on FE
    const advertiserId = findKoddiProviderId(providers, orgId, selectedBrandId);

    const onBrandChange = (values) => {
        setSelectedBrandId(values.myadsBrandId);

        const doesKoddiProviderIdExistForBrand = findKoddiProviderId(providers, orgId, values.myadsBrandId);

        if (!doesKoddiProviderIdExistForBrand) {
            getProviders(values);
        }
    };

    const buttonLabel = advertiserId ? 'Update' : 'Submit';

    const onSubmit = (values) => {
        const koddiData = {
            orgId,
            myadsBrandId: values.myadsBrands,
            productCatalogBrands: values.productCatalogBrands,
        };

        if (advertiserId) {
            koddiData.providerAccountId = advertiserId;
            updateKoddiProvider(koddiData);
        } else {
            onSendToKoddiSubmit(koddiData);
        }
    };

    const advertiserIdText = advertiserIdJsx(getProvidersLoadState, advertiserId);

    return (
        <Form
            keepDirtyOnReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, pristine, submitSucceeded, modifiedSinceLastSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <FlyoutContent label="Organization">
                        <TextDisabled name="organization" id="selectedOrganization" text={orgName} />
                    </FlyoutContent>
                    <FlyoutContent label="MyAds Brand">
                        <MyadsBrandSelect
                            brands={organizationBrandOptions}
                            orgId={orgId}
                            onBrandChange={onBrandChange}
                        />
                    </FlyoutContent>
                    {advertiserIdText}
                    <FlyoutContent label="Product Catalog Brands (select at least one)">
                        <ProductCatalogSelect />
                    </FlyoutContent>
                    <Button
                        data-testid="submit-user-form-button"
                        className={classes.submitButton}
                        type="submit"
                        disabled={submitting || pristine || (submitSucceeded && !modifiedSinceLastSubmit)}
                    >
                        {buttonLabel}
                    </Button>
                </form>
            )}
        />
    );
}

SendToKoddiFlyout.propTypes = {
    props: PropTypes.shape({
        options: PropTypes.array,
    }),
    organization: PropTypes.object,
    onSendToKoddiSubmit: PropTypes.func,
    getProviders: PropTypes.func,
    updateKoddiProvider: PropTypes.func,
    brands: PropTypes.array,
    providers: PropTypes.array,
    getProvidersLoadState: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    providers: selectProviders,
    getProvidersLoadState: selectGetProviderLoadState,
});

export function mapDispatchToProps(dispatch) {
    return {
        onSendToKoddiSubmit: (values) => dispatch(createKoddiProvider(values)),
        getProviders: (values) => dispatch(loadProvidersAction(values)),
        updateKoddiProvider: (values) => dispatch(updateKoddiProviderAction(values)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SendToKoddiFlyout);
