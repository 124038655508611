import request, { formatPostOptions, serialize } from 'utils/request';
import env from 'config/env';
import { DISPLAY_KEY, SOCIAL_KEY, SPONSORED_KEY } from 'Helpers/helpers';

import moment from 'moment';
import { BASE_URL_V6 } from './api-service/Url_Constants';
import {
    campaignMap,
    cardDataMap,
    summaryChartMap,
    summaryItemMap,
    summaryUnitMap,
    summaryTotalsMap,
} from './api-service/maps';

const getReportServiceUrl = () => `${env.GATEWAY_BASE_URL}/v5/campaigns/report`;

/**
 * Gets all the campaigns
 *
 * @param {number} start     default: 0, cursor of start index/row to retrieve data from
 * @param {number} rows      default: -1, number of rows to return in the response. (max is 10,000)
 * @param {object} body      defaultL {}, post body for request
 * @param {object} flags     default {}, flags set on config
 *
 * @returns {object}    response data or error based upon status
 */

export const getAllCampaigns = (start = 0, rows = -1, body = {}) => {
    const queryString = serialize({
        start,
        rows,
    });

    return request(`${BASE_URL_V6}/campaigns/all?${queryString}`, formatPostOptions(body));
};

export async function fetchSummaryOverview(startDate, endDate, filters = {}) {
    const postOptions = formatPostOptions({
        startDate,
        endDate,
        campaignTypes: filters?.campaignTypes,
        brands: filters?.brands?.map((brand) => brand.value),
        categories: filters?.categories,
        status: filters.status?.length === 1 ? filters.status[0] : '',
    });

    const { rows, totals } = await request(`${BASE_URL_V6}/campaigns/summary/level1/details`, postOptions);

    const items = rows?.map(summaryItemMap);
    const itemTotals = totals && summaryTotalsMap(totals);

    return { items, itemTotals };
}

export async function fetchSummaryOverviewChart(startDate, endDate, filters = {}) {
    const postOptions = formatPostOptions({
        startDate,
        endDate,
        campaignTypes: filters?.campaignTypes,
        brands: filters.brands?.map((brand) => brand.value),
        categories: filters?.categories,
        status: filters.status?.length === 1 ? filters.status[0] : '',
    });

    const { rows, totals, totalRows, units } = await request(
        `${BASE_URL_V6}/visualization/summaryL1/data`,
        postOptions,
    );

    const vizRows = rows?.map(summaryChartMap);
    const rowTotals = totals && summaryTotalsMap(totals);
    const chartUnits = summaryUnitMap(units);

    return { rows: vizRows, totals: rowTotals, totalRows, units: chartUnits };
}

export async function fetchSummaryL2Chart(startDate, endDate, campaignType, filters = {}) {
    const postOptions = formatPostOptions({
        startDate,
        endDate,
        campaignType,
        brands: filters.brands?.map((brand) => brand.value),
        categories: filters.categories,
        status: filters.status?.length === 1 ? filters.status[0] : '',
    });

    const { rows, totals, totalRows, units } = await request(
        `${BASE_URL_V6}/visualization/summaryL2/data`,
        postOptions,
    );

    const vizRows = rows?.map(summaryChartMap);
    const rowTotals = totals && summaryChartMap(totals);
    const chartUnits = units && summaryUnitMap(units);

    return { rows: vizRows, totals: rowTotals, totalRows, units: chartUnits };
}

export async function fetchSummaryCampaignList(startDate, endDate, campaignType, filters = {}) {
    const postOptions = formatPostOptions({
        startDate,
        endDate,
        campaignType,
        brands: filters.brands?.map((brand) => brand.value),
        categories: filters.categories,
        status: filters.status?.length === 1 ? filters.status[0] : '',
    });
    const { rows, totals } = await request(`${BASE_URL_V6}/campaigns/summary/level2/details`, postOptions);

    const campaigns = rows.map(campaignMap);
    const rowTotals = totals && campaignMap(totals);

    return { campaigns, totals: rowTotals };
}

export function fetchCampaignInfo(slug) {
    const postOptions = formatPostOptions();
    return request(`${BASE_URL_V6}/campaigns/info/${slug}`, postOptions);
}

export function fetchOffsiteVideoCampaignInfo({ id, startDate, endDate }) {
    const postOptions = formatPostOptions({
        ids: Array.isArray(id) ? [...id] : [id],
    });

    return request(`${BASE_URL_V6}/campaigns/offsiteVideo/info?startDate=${startDate}&endDate=${endDate}`, postOptions);
}

export async function getDisplayCampaignDetails({ id, tab, viewabilityType, startDate, endDate }) {
    const postOptions = formatPostOptions({
        ids: Array.isArray(id) ? [...id] : [id],
    });

    const { rows, totals } = await request(
        `${BASE_URL_V6}/campaigns/display/details?` +
            `channel=${DISPLAY_KEY}-${tab}&` +
            `type=${viewabilityType}&` +
            'start=0&' +
            'rows=-1&' +
            `startDate=${startDate}&` +
            `endDate=${endDate}&` +
            'by=raw',
        postOptions,
    );

    return { rows, totals };
}

export async function getOffsiteDisplayCampaignDetails({ id, tab, startDate, endDate }) {
    const postOptions = formatPostOptions({
        ids: Array.isArray(id) ? [...id] : [id],
    });

    const { rows, totals } = await request(
        `${BASE_URL_V6}/campaigns/displayOffsite/details?` +
            `channel=display-offsite-${tab}&` +
            'start=0&' +
            'rows=-1&' +
            `startDate=${startDate}&` +
            `endDate=${endDate}&`,
        postOptions,
    );

    return { rows, totals };
}

export async function getOffsiteVideoCampaignDetails({ id, tab, startDate, endDate }) {
    const postOptions = formatPostOptions({
        ids: Array.isArray(id) ? [...id] : [id],
    });

    const { rows, totals } = await request(
        `${BASE_URL_V6}/campaigns/offsiteVideo/details?` +
            `startDate=${startDate}&` +
            `endDate=${endDate}&` +
            `channel=offsite-video-${tab}`,
        postOptions,
    );

    return { rows, totals };
}

export async function getOnsiteVideoCampaignDetails({ id, tab, startDate, endDate }) {
    const postOptions = formatPostOptions({
        ids: Array.isArray(id) ? [...id] : [id],
    });

    const { rows, totals } = await request(
        `${BASE_URL_V6}/campaigns/onsiteVideo/details?` +
            `startDate=${startDate}&` +
            `endDate=${endDate}&` +
            `channel=onsite-video-${tab}`,
        postOptions,
    );

    return { rows, totals };
}

export async function getPaidSearchCampaignDetails({ id, tab, startDate, endDate }) {
    const postOptions = formatPostOptions({
        ids: Array.isArray(id) ? [...id] : [id],
    });

    const { rows, totals } = await request(
        `${BASE_URL_V6}/campaigns/paidSearchSku/details?` +
            `channel=paid-search-sku-${tab}&` +
            'start=0&' +
            'rows=-1&' +
            `startDate=${startDate}&` +
            `endDate=${endDate}&`,
        postOptions,
    );

    return { rows, totals };
}

export async function getProximityCampaignDetails({ id, tab, startDate, endDate }) {
    const postOptions = formatPostOptions({
        ids: Array.isArray(id) ? [...id] : [id],
    });

    const { rows, totals } = await request(
        `${BASE_URL_V6}/campaigns/proximity/details?` +
            `by=${tab}&` +
            'start=0&' +
            'rows=-1&' +
            `startDate=${startDate}&` +
            `endDate=${endDate}&`,
        postOptions,
    );

    return { rows, totals };
}

export async function getSocialCampaignDetails({ id, tab, startDate, endDate }) {
    const postOptions = formatPostOptions({
        ids: Array.isArray(id) ? [...id] : [id],
    });

    const { rows, totals } = await request(
        `${BASE_URL_V6}/campaigns/social/details?` +
            `channel=${SOCIAL_KEY}-${tab}&` +
            'start=0&' +
            'rows=-1&' +
            `startDate=${startDate}&` +
            `endDate=${endDate}&`,
        postOptions,
    );

    return { rows, totals };
}

export async function getSponsoredCampaignDetails({ id, tab, startDate, endDate, vehicleKey }) {
    const postOptions = formatPostOptions({
        ids: Array.isArray(id) ? [...id] : [id],
    });

    const { rows, totals } = await request(
        `${BASE_URL_V6}/campaigns/${vehicleKey}/details?` +
            `by=${tab === SPONSORED_KEY ? 'product' : tab}&` +
            'start=0&' +
            'rows=-1&' +
            `startDate=${startDate}&` +
            `endDate=${endDate}&`,
        postOptions,
    );

    return { rows, totals };
}

export function fetchDetailsChartData({ id, type, startDate, endDate, vehicle }) {
    const postOptions = formatPostOptions({
        ids: Array.isArray(id) ? [...id] : [id],
    });

    return request(
        `${BASE_URL_V6}/visualization/${vehicle}/data?` +
            'interval=day&' +
            `type=${type}&` +
            `startDate=${startDate}&` +
            `endDate=${endDate}`,
        postOptions,
    );
}

export async function fetchSummaryCardData({ id, type, startDate, endDate, vehicle }) {
    const postOptions = formatPostOptions({
        ids: Array.isArray(id) ? [...id] : [id],
    });

    const responseData = await request(
        `${BASE_URL_V6}/campaigns/${vehicle.getVehicleKey()}/info?` +
            `type=${type}&` +
            `startDate=${startDate}&` +
            `endDate=${endDate}`,
        postOptions,
    );

    const data = cardDataMap(responseData);

    const definitions = vehicle.getCards();

    const cardData = definitions
        .filter((header) => data[header.key] !== undefined)
        .map((cardDefinition) => [cardDefinition, data[cardDefinition.key]]);

    return cardData;
}

export async function listCampaignTypeSummary(uuid, campaignType) {
    // startDate and endDate will need to be added as props once date comp is made
    const startDate = moment().format('YYYY-MM-DD');
    const endDate = moment().add(30, 'day').format('YYYY-MM-DD');

    const postOptions = formatPostOptions({
        campaignType,
        startDate,
        endDate,
    });

    const campaignTypeSummaryList = await request(`${BASE_URL_V6}/campaigns/summary/level2/details`, postOptions);

    return campaignTypeSummaryList;
}

/**
 * Returns the downloadable xlsx sheet for the order id specified
 *
 * @param [string] ids        to get the report for
 * @param {string} vehicle    vehicle type
 * @param {string} customFileName   campaign report name
 * @param {string} startDate start date to gather campaign report for. Must be in YYYY-MM-DD format.
 * @param {string} endDate   end date to gather campaign report for. Must be in YYYY-MM-DD format.
 * @param {string} sheets    object that specifies which sheets should be generated with properties:
                               columnsToShow: If present, limits which columns that should be shown to those in the list. If not present, then all columns will be shown.
                               columnsToHide: After determining which column, this list is used to override what should be shown.
 * @param {string} by        default: default, determines how the data is grouped i.e raw or default
 * @param {number} type      default: 0, determines how some metrics are calculated i.e active or default
 * @param {boolean} raw     default: false, determines format of the data report i.e formatted or raw
 *
 * @returns {object}    response data or error based upon status
 */
export const downloadReport = (
    idPayload,
    campaign,
    startDate,
    endDate,
    sheets,
    flags,
    by = 'default',
    type = 'default',
    raw = false,
) => {
    const { customFileName } = campaign;

    const queryString = serialize({
        startDate,
        endDate,
        by,
        type,
        customFileName,
        raw,
    });

    const opts = formatPostOptions(
        {
            ...sheets,
            ...idPayload,
        },
        {},
    );

    const baseUrl = getReportServiceUrl(flags);

    return request(`${baseUrl}/download?${queryString}`, opts);
};

export const downloadDisplayReport = (campaign, ...args) => {
    const idPayload = { displayIds: campaign.ids };
    return downloadReport(idPayload, campaign, ...args);
};

export const downloadOffsiteDisplayReport = (campaign, ...args) => {
    const idPayload = { displayOffsiteIds: campaign.ids };
    return downloadReport(idPayload, campaign, ...args);
};

export const downloadProximityReport = (campaign, ...args) => {
    const idPayload = { proximityIds: campaign.ids };
    return downloadReport(idPayload, campaign, ...args);
};

export const downloadSponsoredRsxReport = (campaign, ...args) => {
    const idPayload = { sponsoredRsxIds: campaign.ids };
    return downloadReport(idPayload, campaign, ...args);
};

export const downloadSponsoredRmpReport = (campaign, ...args) => {
    const idPayload = { sponsoredRmpIds: campaign.ids };
    return downloadReport(idPayload, campaign, ...args);
};

export const downloadPaidSearchReport = (campaign, ...args) => {
    const idPayload = { paidSearchSkuIds: campaign.ids };
    return downloadReport(idPayload, campaign, ...args);
};

export const downloadSocialReport = (campaign, ...args) => {
    const idPayload = { socialIds: campaign.ids };
    return downloadReport(idPayload, campaign, ...args);
};

export const downloadOffsiteVideoReport = (campaign, ...args) => {
    const idPayload = { offsiteVideoIds: campaign.ids };
    return downloadReport(idPayload, campaign, ...args);
};

export const downloadOnsiteVideoReport = (campaign, ...args) => {
    const idPayload = { onsiteVideoIds: campaign.ids };
    return downloadReport(idPayload, campaign, ...args);
};
