import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import { ConnectedRouter } from 'connected-react-router';
import { RoutedContent } from 'routes';
import { SnackbarProvider } from 'notistack';
import { useInjectSaga } from 'redux-injectors';
import { makeStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import classnames from 'classnames';

import {
    loadCampaigns as loadCampaignsAction,
    resetDone as resetDoneAction,
    getUserInfo as getUserInfoAction,
    logout as logoutAction,
} from 'containers/App/actions';
import { loadRoles as loadRolesAction } from 'containers/IDAMUserManagement/UserListPage/actions';
import { getSharedCampaignGroups, getCampaignGroups } from 'containers/StackedCampaignsPage/actions';
import { fetchCampaignsWatchingStart } from 'containers/StackedCampaignsPage/WatchCampaignsTab/actions';
import { loadRecentViewedListAction } from 'containers/RecentViewedPage/slice';
import { makeSelectAuthedUser } from 'containers/App/selectors';
import recentViewedPageSaga from 'containers/RecentViewedPage/saga';
import campaignReportingPageSaga from 'containers/CampaignReportingPage/saga';
import saga from 'containers/HomePage/saga';
import organizationListPageSaga from 'containers/OrganizationPage/saga';
import { loadOrganizationListAction, loadBrandAction } from 'containers/OrganizationPage/slice';
import StackedCampaignsPageSaga from 'containers/StackedCampaignsPage/saga';
import WatchCampaignsTabSaga from 'containers/StackedCampaignsPage/WatchCampaignsTab/saga';
import session from 'services/session';
import { AppContext } from 'shared/contexts/app-context';
import { canShareReport } from 'shared/constants/user/permissions';
import { RequestStatus } from 'shared/constants';
// import { getCSRFToken, retrieveCSRFToken } from 'utils/csrf';
import AlertBannerLayout from 'layouts/AlertBanner/AlertBannerLayout';

const useStyles = makeStyles(() => ({
    snackbar: {
        backgroundColor: 'black',
        color: 'white',
        width: '328px',
        flexWrap: 'nowrap',
        '& [class*="action"]': {
            padding: 0,
        },
        '& button span': {
            color: '#FFE000',
            fontSize: '12px',
            '& a': {
                color: 'inherit',
                fontSize: 'inherti',
                textDecoration: 'none',
            },
        },
    },
    snackbarError: {
        '& svg': {
            paddingRight: '10px',
        },
    },
}));

const AppClient = ({
    history,
    authedUser,
    loadWatchlist,
    loadRecentViewedList,
    loadCampaigns,
    loadOrganizations,
    getUserInfo,
    loadCampaignGroups,
    loadSharedCampaignGroups,
    logout,
    loadBrands,
}) => {
    /* We have to inject these sagas at the root of the app.
     *  If a user refreshes the page on a page other than the homePage that saga has not
     *  yet been injected and there will be no campaigns, thus breaking the search bar functionality
     *  eventually when we do a server side fetch this will not matter
     */
    useInjectSaga({ key: 'recentViewedPage', saga: recentViewedPageSaga });
    useInjectSaga({ key: 'stackedCampaignsPage', saga: StackedCampaignsPageSaga });
    useInjectSaga({ key: 'watchCampaignsTab', saga: WatchCampaignsTabSaga });
    useInjectSaga({ key: 'campaignReportingPage', saga: campaignReportingPageSaga });
    useInjectSaga({ key: 'homePage', saga });
    useInjectSaga({ key: 'organizationPage', saga: organizationListPageSaga });

    const classes = useStyles();
    const notistackRef = React.createRef();
    const isLoggedIn = session.isAuthenticated();

    const appContextData = useMemo(() => ({ authedUser }), [authedUser]);

    useEffect(() => {
        if (isLoggedIn) {
            // if (getCSRFToken() === null) {
            //     retrieveCSRFToken();
            // }

            // This is for returning user
            if (authedUser?.loadState === RequestStatus.INIT) {
                getUserInfo();
            }

            if (authedUser?.loadState === RequestStatus.DONE) {
                const { uuid } = authedUser;
                loadCampaigns();
                loadOrganizations();
                loadBrands();
                loadCampaignGroups(uuid);
                loadWatchlist(uuid);
                loadRecentViewedList(uuid);
                if (canShareReport(authedUser)) {
                    loadSharedCampaignGroups();
                }
            } else if (authedUser?.loadState === RequestStatus.ERROR) {
                logout();
            }
        }
    }, [
        isLoggedIn,
        authedUser,
        loadBrands,
        getUserInfo,
        loadCampaigns,
        loadOrganizations,
        loadCampaignGroups,
        loadWatchlist,
        loadRecentViewedList,
        loadSharedCampaignGroups,
        logout,
    ]);

    return (
        <ConnectedRouter history={history}>
            <SnackbarProvider
                ref={notistackRef}
                maxSnack={5}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                iconVariant={{
                    error: <ErrorIcon fontSize="small" />,
                }}
                classes={{
                    variantSuccess: classes.snackbar,
                    variantError: classnames(classes.snackbar, classes.snackbarError),
                    variantWarning: classes.snackbar,
                    variantInfo: classes.snackbar,
                }}
                onClick={() => notistackRef.current.closeSnackbar()}
            >
                <AppContext.Provider value={appContextData}>
                    <AlertBannerLayout>
                        <RoutedContent />
                    </AlertBannerLayout>
                </AppContext.Provider>
            </SnackbarProvider>
        </ConnectedRouter>
    );
};

export const mapStateToProps = createStructuredSelector({
    authedUser: makeSelectAuthedUser(),
});

// export for testing
export function mapDispatchToProps(dispatch) {
    return {
        loadWatchlist: (userId) => dispatch(fetchCampaignsWatchingStart(userId)),
        loadRecentViewedList: (uuid) => dispatch(loadRecentViewedListAction({ uuid })),
        loadCampaigns: () => dispatch(loadCampaignsAction()),
        loadOrganizations: () => dispatch(loadOrganizationListAction()),
        loadRoles: () => dispatch(loadRolesAction()),
        getUserInfo: () => dispatch(getUserInfoAction()),
        loadCampaignGroups: (userId, groupType) => dispatch(getCampaignGroups(userId, groupType)),
        loadSharedCampaignGroups: (groupType) => dispatch(getSharedCampaignGroups(groupType)),
        resetDone: () => dispatch(resetDoneAction()),
        logout: () => dispatch(logoutAction()),
        loadBrands: () => dispatch(loadBrandAction()),
    };
}

AppClient.propTypes = {
    history: PropTypes.shape({
        action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,
        block: PropTypes.func.isRequired,
        createHref: PropTypes.func.isRequired,
        go: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
        goForward: PropTypes.func.isRequired,
        length: PropTypes.number,
        listen: PropTypes.func.isRequired,
        location: PropTypes.shape({
            hash: PropTypes.string.isRequired,
            key: PropTypes.string,
            pathname: PropTypes.string.isRequired,
            search: PropTypes.string.isRequired,
            state: PropTypes.oneOfType([
                PropTypes.array,
                PropTypes.bool,
                PropTypes.number,
                PropTypes.object,
                PropTypes.string,
            ]),
        }).isRequired,
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
    }),
    authedUser: PropTypes.object,
    loadCampaigns: PropTypes.func,
    getUserInfo: PropTypes.func,
    loadCampaignGroups: PropTypes.func,
    loadOrganizations: PropTypes.func,
    loadSharedCampaignGroups: PropTypes.func,
    loadWatchlist: PropTypes.func,
    loadRecentViewedList: PropTypes.func,
    logout: PropTypes.func,
    loadBrands: PropTypes.func,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(AppClient);
