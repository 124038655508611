import {
    INVITE_STATUS_TYPE_ACCEPTED,
    INVITE_STATUS_TYPE_COMPLETED,
    INVITE_STATUS_TYPE_EMAIL_OTP_ACCEPTED,
    INVITE_STATUS_TYPE_EMAIL_OTP_EXPIRED,
    INVITE_STATUS_TYPE_EMAIL_OTP_SENT,
    INVITE_STATUS_TYPE_INVITE_EXPIRED,
    INVITE_STATUS_TYPE_INVITE_PROFILE_UPDATED,
    INVITE_STATUS_TYPE_PASSWORD_CHANGE_OTP_ACCEPTED,
    INVITE_STATUS_TYPE_PASSWORD_CHANGE_OTP_EXPIRED,
    INVITE_STATUS_TYPE_PASSWORD_CHANGE_OTP_SENT,
    INVITE_STATUS_TYPE_PASSWORD_UPDATED,
    INVITE_STATUS_TYPE_PHONE_OTP_ACCEPTED,
    INVITE_STATUS_TYPE_PHONE_OTP_EXPIRED,
    INVITE_STATUS_TYPE_PHONE_OTP_SENT,
    INVITE_STATUS_TYPE_PROFILE_UPDATED,
    INVITE_STATUS_TYPE_RESENT,
    INVITE_STATUS_TYPE_SENT,
    INVITE_STATUS_TYPE_USER_CREATED,
    INVITE_STATUS_TYPE_USER_UNPAIRED,
} from './invite-state';

/*
In addition to the below statuses, the user_status field of the user object can return any of
the statuses found in invite-state.js
*/

export const USER_STATUS_ACTIVE = 'active';
export const USER_STATUS_INACTIVE = 'inactive';
export const USER_STATUS_ARCHIVED = 'archived';
export const USER_STATUS_UNKNOWN = 'unknown';

export const UserStatusDisplayInfo = {
    inviteSent: {
        text: 'Invite Sent',
        showReinvite: true,
        description:
            "The registration email is in the user's inbox but the getting started link has not yet been clicked",
    },

    inviteExpired: {
        text: 'Invite Expired',
        showReinvite: true,
        description: 'The invite link has expired before a user could complete the registration process',
    },

    codeExpired: {
        text: 'Code Expired',
        showReinvite: true,
        description: 'The OTP code has expired',
    },

    registrationInProgress: {
        text: 'Registration in progress',
        showReinvite: true,
        description:
            'The invited users has at least click "Getting Started" in the registration email, and coiuld be in any subsequent point in the process',
    },

    active: {
        text: 'Active',
        showReinvite: false,
        description: 'A user has completed the registration process and has access to My Ads',
    },

    inactive: {
        text: 'Inactive',
        showReinvite: false,
        description: 'A user is inactive and does not have access to My Ads',
    },

    archived: {
        text: 'Archived',
        showReinvite: false,
        description: 'A user has been archived',
    },

    unknown: {
        text: 'Unknown',
        showReinvite: true,
        description: 'Unknown user status',
    },
};

export function getUserStatusDisplayInfo(userStatus) {
    switch (userStatus) {
        case INVITE_STATUS_TYPE_SENT:
        case INVITE_STATUS_TYPE_USER_CREATED:
        case INVITE_STATUS_TYPE_RESENT:
            return UserStatusDisplayInfo.inviteSent;

        case INVITE_STATUS_TYPE_ACCEPTED:
        case INVITE_STATUS_TYPE_EMAIL_OTP_SENT:
        case INVITE_STATUS_TYPE_PHONE_OTP_SENT:
        case INVITE_STATUS_TYPE_EMAIL_OTP_ACCEPTED:
        case INVITE_STATUS_TYPE_PHONE_OTP_ACCEPTED:
        case INVITE_STATUS_TYPE_INVITE_PROFILE_UPDATED:
            return UserStatusDisplayInfo.registrationInProgress;

        case INVITE_STATUS_TYPE_PHONE_OTP_EXPIRED:
        case INVITE_STATUS_TYPE_EMAIL_OTP_EXPIRED:
            return UserStatusDisplayInfo.codeExpired;

        case INVITE_STATUS_TYPE_INVITE_EXPIRED:
        case INVITE_STATUS_TYPE_USER_UNPAIRED:
            return UserStatusDisplayInfo.inviteExpired;

        case INVITE_STATUS_TYPE_PROFILE_UPDATED:
        case INVITE_STATUS_TYPE_PASSWORD_CHANGE_OTP_SENT:
        case INVITE_STATUS_TYPE_PASSWORD_CHANGE_OTP_ACCEPTED:
        case INVITE_STATUS_TYPE_PASSWORD_CHANGE_OTP_EXPIRED:
        case INVITE_STATUS_TYPE_PASSWORD_UPDATED:
        case INVITE_STATUS_TYPE_COMPLETED:
        case USER_STATUS_ACTIVE:
            return UserStatusDisplayInfo.active;

        case USER_STATUS_INACTIVE:
            return UserStatusDisplayInfo.inactive;

        case USER_STATUS_ARCHIVED:
            return UserStatusDisplayInfo.archived;

        case USER_STATUS_UNKNOWN:
        default:
            return UserStatusDisplayInfo.unknown;
    }
}
