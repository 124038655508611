export const clearStorage = () => localStorage.clear();

/**
 * Gets an item by the key value and deserializes it
 *
 * @param  {string} key key of item to retrive
 *
 * @returns {string} The parsed json from the retrival
 */
export const getItemFromStorage = (key) => {
    let value;

    if (!localStorage) return null;

    try {
        value = JSON.parse(localStorage.getItem(key));
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`Error getting item ${key} from localStoragee`, err);
    }

    return value;
};

/**
 * Set item by key value and serializes the item
 *
 * @param  {string} key key of item to retrive
 * @param  {any} item what to store under key value
 *
 * @returns {undefined}
 */
export const storeItem = (key, item) => {
    let value;

    if (!localStorage) return null;

    try {
        value = localStorage.setItem(key, JSON.stringify(item));
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`Error storing item ${key} to localStorage`, err);
    }

    return value;
};

/**
 * Removed item by its key
 *
 * @param  {string} key key of item to retrive
 *
 * @returns {undefined}
 */
export const removeItemFromStorage = (key) => {
    let value;

    if (!localStorage) return null;

    try {
        value = localStorage.removeItem(key);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`Error removing item ${key} from localStorage`, err);
    }
    return value;
};

export const removeNoRepeatAlerts = (key) => {
    const alerts = getItemFromStorage(key);
    const alertsToShow = JSON.parse(alerts);

    const repeatAlerts = alertsToShow?.filter((repeatAlert) => repeatAlert.repeatAlertHours);

    clearStorage();

    // if there are any alerts with repeatAlertHours
    if (repeatAlerts) {
        storeItem(key, JSON.stringify(repeatAlerts));
    }
};
