import moment from 'moment-timezone';
import { Vehicle } from '../vehicles';
import { filterMatch, updateStat } from './filterUtils';

function campaignWithInDates(dateFilter, campaign) {
    // campaigns filtered by start date and attribution date
    const campaignVehicle = Vehicle.getFromCampaignData(campaign);
    const attributionDate = moment(campaign.end_date)
        .add(campaignVehicle.getAttributionWindow(), 'days')
        .utc()
        .format('MMMM DD, YYYY');

    const endDateWithinRange = !dateFilter.startDate || moment(attributionDate).isSameOrAfter(dateFilter.startDate);
    const startDateWithinRange = !dateFilter.endDate || moment(campaign.start_date).isSameOrBefore(dateFilter.endDate);

    return startDateWithinRange && endDateWithinRange;
}

function sanitizeFilter(filterChoices, metadata) {
    if (Array.isArray(filterChoices) && metadata) {
        const validOptions = new Set(Object.keys(metadata));
        return filterChoices.filter((choice) => validOptions.has(choice));
    }

    return [];
}

function createFilterChecks(campaignFilter, filterMetadata) {
    const sanitizedOrgFilter = sanitizeFilter(campaignFilter?.organizations, filterMetadata?.org_name);
    const sanitizedBrandFilter = sanitizeFilter(campaignFilter?.brands, filterMetadata?.brand_name);
    const sanitizedStatusFilter = sanitizeFilter(campaignFilter?.status, filterMetadata?.status);
    const sanitizedCategoryFilter = sanitizeFilter(campaignFilter?.categories, filterMetadata?.category_groups);

    // Vehicles filter is deprecated, but accepted so old saved fitlers still work
    let sanitizedCampaignTypeFilter;
    if (campaignFilter?.campaignTypes) {
        sanitizedCampaignTypeFilter = sanitizeFilter(campaignFilter?.campaignTypes, filterMetadata?.campaign_types);
    } else {
        sanitizedCampaignTypeFilter = sanitizeFilter(campaignFilter?.vehicles, filterMetadata?.campaign_types);
    }

    const organizations = new Set(sanitizedOrgFilter);
    const brands = new Set(sanitizedBrandFilter);
    const campaignTypes = new Set(sanitizedCampaignTypeFilter);
    const categories = new Set(sanitizedCategoryFilter);
    const status = new Set(sanitizedStatusFilter);

    const hasOrganization = (campaign) => sanitizedOrgFilter.length === 0 || organizations.has(campaign.org_name);
    const hasBrand = (campaign) => sanitizedBrandFilter.length === 0 || brands.has(campaign.brand_name);
    const hasCampaignType = (campaign) =>
        sanitizedCampaignTypeFilter.length === 0 || campaignTypes.has(campaign.campaign_type);
    const hasCategory = (campaign) => sanitizedCategoryFilter.length === 0 || categories.has(campaign.category_group);
    const hasStatus = (campaign) => sanitizedStatusFilter.length === 0 || status.has(campaign.status);

    return { hasOrganization, hasBrand, hasCampaignType, hasCategory, hasStatus };
}

// Date filtering is somewhat slow, so it is filtered separately so it
// can can be memoized indepnedently
export function filterByDate(campaigns, dateFilter) {
    return campaigns.filter((campaign) => campaignWithInDates(dateFilter, campaign));
}

// This function is performance sensitive. When in the forEach loop, try to mutate objects
// in place wherever possible rather than creating new ones on each iteration
export function filterCampaigns(campaigns, campaignFilter, filterMetadata) {
    let filteredCampaigns = campaigns;
    const brandStats = {};
    const campaignTypeStats = {};
    const statusStats = {};
    const categoryStats = {};
    if (campaigns) {
        const { hasOrganization, hasBrand, hasCampaignType, hasCategory, hasStatus } = createFilterChecks(
            campaignFilter,
            filterMetadata,
        );

        filteredCampaigns = [];

        campaigns.forEach((campaign) => {
            brandStats[campaign.brand_name] = updateStat(campaign, brandStats[campaign.brand_name], [
                hasOrganization,
                hasCategory,
                hasStatus,
            ]);
            campaignTypeStats[campaign.campaign_type] = updateStat(
                campaign,
                campaignTypeStats[campaign.campaign_type],
                [hasOrganization, hasBrand, hasCategory, hasStatus],
            );
            categoryStats[campaign.category_group] = updateStat(campaign, categoryStats[campaign.category_group], [
                hasOrganization,
                hasBrand,
                hasCampaignType,
                hasStatus,
            ]);
            statusStats[campaign.status] = updateStat(campaign, statusStats[campaign.status], [
                hasOrganization,
                hasBrand,
                hasCampaignType,
                hasCategory,
            ]);

            if (filterMatch(campaign, [hasOrganization, hasBrand, hasCampaignType, hasCategory, hasStatus])) {
                filteredCampaigns.push(campaign);
            }
        });
    }

    return {
        filteredCampaigns,
        brandStats,
        campaignTypeStats,
        categoryStats,
        statusStats,
    };
}
