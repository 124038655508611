export const offsiteDisplayColumns = {
    platform: [
        'platform_name',
        'impressions',
        'clicks',
        'ctr',
        'spend',
        'view_revenue_online',
        'view_revenue_instore',
        'view_revenue_total',
        'view_roas_online',
        'view_roas_instore',
        'view_roas_total',
        'view_roas_online_percent',
        'view_roas_instore_percent',
        'view_roas_total_percent',
        'view_units_online',
        'view_units_instore',
        'view_units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_roas_online',
        'click_roas_instore',
        'click_roas_total',
        'click_roas_online_percent',
        'click_roas_instore_percent',
        'click_roas_total_percent',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
        'view_click_revenue_online',
        'view_click_revenue_instore',
        'view_click_revenue_total',
        'view_click_roas_online',
        'view_click_roas_instore',
        'view_click_roas_total',
        'view_click_roas_online_percent',
        'view_click_roas_instore_percent',
        'view_click_roas_total_percent',
        'view_click_units_online',
        'view_click_units_instore',
        'view_click_units_total',
    ],
    publisher: [
        'publisher',
        'impressions',
        'clicks',
        'ctr',
        'spend',
        'view_revenue_online',
        'view_revenue_instore',
        'view_revenue_total',
        'view_roas_online',
        'view_roas_instore',
        'view_roas_total',
        'view_roas_online_percent',
        'view_roas_instore_percent',
        'view_roas_total_percent',
        'view_units_online',
        'view_units_instore',
        'view_units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_roas_online',
        'click_roas_instore',
        'click_roas_total',
        'click_roas_online_percent',
        'click_roas_instore_percent',
        'click_roas_total_percent',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
        'view_click_revenue_online',
        'view_click_revenue_instore',
        'view_click_revenue_total',
        'view_click_roas_online',
        'view_click_roas_instore',
        'view_click_roas_total',
        'view_click_roas_online_percent',
        'view_click_roas_instore_percent',
        'view_click_roas_total_percent',
        'view_click_units_online',
        'view_click_units_instore',
        'view_click_units_total',
    ],
    audience: [
        'audience_name',
        'impressions',
        'clicks',
        'ctr',
        'spend',
        'view_revenue_online',
        'view_revenue_instore',
        'view_revenue_total',
        'view_roas_online',
        'view_roas_instore',
        'view_roas_total',
        'view_roas_online_percent',
        'view_roas_instore_percent',
        'view_roas_total_percent',
        'view_units_online',
        'view_units_instore',
        'view_units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_roas_online',
        'click_roas_instore',
        'click_roas_total',
        'click_roas_online_percent',
        'click_roas_instore_percent',
        'click_roas_total_percent',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
        'view_click_revenue_online',
        'view_click_revenue_instore',
        'view_click_revenue_total',
        'view_click_roas_online',
        'view_click_roas_instore',
        'view_click_roas_total',
        'view_click_roas_online_percent',
        'view_click_roas_instore_percent',
        'view_click_roas_total_percent',
        'view_click_units_online',
        'view_click_units_instore',
        'view_click_units_total',
    ],
    product: [
        'sku',
        'sku_description',
        'brand',
        'dept_id',
        'dept_name',
        'class_id',
        'class_name',
        'subclass_id',
        'subclass_name',
        'view_revenue_online',
        'view_revenue_instore',
        'view_revenue_total',
        'view_units_online',
        'view_units_instore',
        'view_units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
        'revenue_online',
        'revenue_instore',
        'revenue_total',
        'units_online',
        'units_instore',
        'units_total',
    ],
};

export const defaultColumns = {
    platform: [
        'platform_name',
        'impressions',
        'clicks',
        'ctr',
        'spend',
        'view_revenue_online',
        'view_revenue_instore',
        'view_revenue_total',
        'view_roas_online',
        'view_roas_instore',
        'view_roas_total',
        'view_units_online',
        'view_units_instore',
        'view_units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_roas_online',
        'click_roas_instore',
        'click_roas_total',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
        'view_click_revenue_online',
        'view_click_revenue_instore',
        'view_click_revenue_total',
        'view_click_roas_online',
        'view_click_roas_instore',
        'view_click_roas_total',
        'view_click_units_online',
        'view_click_units_instore',
        'view_click_units_total',
    ],
    publisher: [
        'publisher',
        'impressions',
        'clicks',
        'ctr',
        'spend',
        'view_revenue_online',
        'view_revenue_instore',
        'view_revenue_total',
        'view_roas_online',
        'view_roas_instore',
        'view_roas_total',
        'view_units_online',
        'view_units_instore',
        'view_units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_roas_online',
        'click_roas_instore',
        'click_roas_total',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
        'view_click_revenue_online',
        'view_click_revenue_instore',
        'view_click_revenue_total',
        'view_click_roas_online',
        'view_click_roas_instore',
        'view_click_roas_total',
        'view_click_units_online',
        'view_click_units_instore',
        'view_click_units_total',
    ],
    audience: [
        'audience_name',
        'impressions',
        'clicks',
        'ctr',
        'spend',
        'view_revenue_online',
        'view_revenue_instore',
        'view_revenue_total',
        'view_roas_online',
        'view_roas_instore',
        'view_roas_total',
        'view_units_online',
        'view_units_instore',
        'view_units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_roas_online',
        'click_roas_instore',
        'click_roas_total',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
        'view_click_revenue_online',
        'view_click_revenue_instore',
        'view_click_revenue_total',
        'view_click_roas_online',
        'view_click_roas_instore',
        'view_click_roas_total',
        'view_click_units_online',
        'view_click_units_instore',
        'view_click_units_total',
    ],
    product: [
        'sku',
        'sku_description',
        'view_revenue_online',
        'view_revenue_instore',
        'view_revenue_total',
        'view_units_online',
        'view_units_instore',
        'view_units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
        'revenue_online',
        'revenue_instore',
        'revenue_total',
        'units_online',
        'units_instore',
        'units_total',
    ],
};

export const modifyColumns = {
    platform: [
        'impressions',
        'ctr',
        'clicks',
        'spend',
        'total',
        'total_online',
        'total_instore',
        'view_total',
        'view_online',
        'view_instore',
        'click_total',
        'click_online',
        'click_instore',
    ],
    publisher: [
        'impressions',
        'ctr',
        'clicks',
        'spend',
        'total',
        'total_online',
        'total_instore',
        'view_total',
        'view_online',
        'view_instore',
        'click_total',
        'click_online',
        'click_instore',
    ],
    audience: [
        'impressions',
        'ctr',
        'clicks',
        'spend',
        'total',
        'total_online',
        'total_instore',
        'view_total',
        'view_online',
        'view_instore',
        'click_total',
        'click_online',
        'click_instore',
    ],
    product: [
        'sku_description',
        'brand',
        'dept_id',
        'dept_name',
        'class_id',
        'class_name',
        'subclass_id',
        'subclass_name',
        'product_total',
        'product_online',
        'product_instore',
        'product_view_total',
        'product_view_online',
        'product_view_instore',
        'product_click_total',
        'product_click_online',
        'product_click_instore',
    ],
};

export const columnMap = {
    click_revenue_total: {
        label: 'Click Total Revenue',
        type: 'currency',
        field: 'click_revenue_total',
        sortable: true,
        lineBreak: false,
    },
    view_revenue_online: {
        label: 'View Through Online Revenue',
        type: 'currency',
        field: 'view_revenue_online',
        sortable: true,
        lineBreak: false,
    },
    view_revenue_instore: {
        label: 'View Through In Store Revenue',
        type: 'currency',
        field: 'view_revenue_instore',
        sortable: true,
        lineBreak: false,
    },
    view_revenue_total: {
        label: 'View Through Total Revenue',
        type: 'currency',
        field: 'view_revenue_total',
        sortable: true,
        lineBreak: false,
    },
    click_units_total: {
        label: 'Click Total Units',
        type: 'decimal',
        field: 'click_units_total',
        sortable: true,
        lineBreak: false,
    },
    view_units_instore: {
        label: 'View Through In Store Units',
        type: 'decimal',
        field: 'view_units_instore',
        sortable: true,
        lineBreak: false,
    },
    view_units_online: {
        label: 'View Through Online Units',
        type: 'decimal',
        field: 'view_units_online',
        sortable: true,
        lineBreak: false,
    },
    view_units_total: {
        label: 'View Through Total Units',
        type: 'decimal',
        field: 'view_units_total',
        sortable: true,
        lineBreak: false,
    },
    view_roas_instore_percent: {
        label: 'View Through In Store ROAS (%)',
        type: 'percent_decimal',
        field: 'view_roas_instore_percent',
        sortable: true,
        lineBreak: false,
    },
    view_roas_online_percent: {
        label: 'View Through Online ROAS (%)',
        type: 'percent_decimal',
        field: 'view_roas_online_percent',
        sortable: true,
        lineBreak: false,
    },
    view_roas_total_percent: {
        label: 'View Through Total ROAS (%)',
        type: 'percent_decimal',
        field: 'view_roas_total_percent',
        sortable: true,
        lineBreak: false,
    },
    click_roas_instore_percent: {
        label: 'Click In Store ROAS (%)',
        type: 'percent_decimal',
        field: 'click_roas_instore_percent',
        sortable: true,
        lineBreak: false,
    },
    click_roas_online_percent: {
        label: 'Click Online ROAS (%)',
        type: 'percent_decimal',
        field: 'click_roas_online_percent',
        sortable: true,
        lineBreak: false,
    },
    click_roas_total_percent: {
        label: 'Click Total ROAS (%)',
        type: 'percent_decimal',
        field: 'click_roas_total_percent',
        sortable: true,
        lineBreak: false,
    },
    sku: {
        label: 'SKU',
        type: 'string',
        field: 'sku',
        sortable: true,
        lineBreak: false,
    },
    click_roas_online: {
        label: 'Click Online ROAS',
        type: 'currency',
        field: 'click_roas_online',
        sortable: true,
        lineBreak: false,
    },
    click_roas_instore: {
        label: 'Click In Store ROAS',
        type: 'currency',
        field: 'click_roas_instore',
        sortable: true,
        lineBreak: false,
    },
    click_roas_total: {
        label: 'Click Total ROAS',
        type: 'currency',
        field: 'click_roas_total',
        sortable: true,
        lineBreak: false,
    },
    view_roas_online: {
        label: 'View Through Online ROAS',
        type: 'currency',
        field: 'view_roas_online',
        sortable: true,
        lineBreak: false,
    },
    view_roas_instore: {
        label: 'View Through In Store ROAS',
        type: 'currency',
        field: 'view_roas_instore',
        sortable: true,
        lineBreak: false,
    },
    view_roas_total: {
        label: 'View Through Total ROAS',
        type: 'currency',
        field: 'view_roas_total',
        sortable: true,
        lineBreak: false,
    },
    audience_name: {
        label: 'Audience Name',
        type: 'description',
        field: 'audience_name',
        sortable: true,
        lineBreak: false,
    },
    platform_name: {
        label: 'Device',
        type: 'description',
        field: 'platform_name',
        sortable: true,
        lineBreak: false,
    },
    units_total: {
        label: 'Total Units',
        type: 'decimal',
        field: 'units_total',
        sortable: true,
        lineBreak: false,
    },
    view_click_revenue_online: {
        label: 'Total Online Revenue',
        type: 'currency',
        field: 'view_click_revenue_online',
        sortable: true,
        lineBreak: false,
    },
    // Total In Store Revenue
    view_click_revenue_instore: {
        label: 'Total In Store Revenue',
        type: 'currency',
        field: 'view_click_revenue_instore',
        sortable: true,
        lineBreak: false,
    },
    view_click_revenue_total: {
        label: 'Total Revenue',
        type: 'currency',
        field: 'view_click_revenue_total',
        sortable: true,
        lineBreak: false,
    },
    // Total Online ROAS
    view_click_roas_online: {
        label: 'Total Online ROAS',
        type: 'currency',
        field: 'view_click_roas_online',
        sortable: true,
        lineBreak: false,
    },
    // Total In Store ROAS
    view_click_roas_instore: {
        label: 'Total In Store ROAS',
        type: 'currency',
        field: 'view_click_roas_instore',
        sortable: true,
        lineBreak: false,
    },
    // Total ROAS
    view_click_roas_total: {
        label: 'Total ROAS',
        type: 'currency',
        field: 'view_click_roas_total',
        sortable: true,
        lineBreak: false,
    },
    // Total Online Units
    view_click_units_online: {
        label: 'Total Online Units',
        type: 'decimal',
        field: 'view_click_units_online',
        sortable: true,
        lineBreak: false,
    },
    // Total In Store Units
    view_click_units_instore: {
        label: 'Total In Store Units',
        type: 'decimal',
        field: 'view_click_units_instore',
        sortable: true,
        lineBreak: false,
    },
    // Total Units
    view_click_units_total: {
        label: 'Total Units',
        type: 'decimal',
        field: 'view_click_units_total',
        sortable: true,
        lineBreak: false,
    },
    // (Not Default) Total Online ROAS(%)
    view_click_roas_online_percent: {
        label: 'Total Online ROAS (%)',
        type: 'percent_decimal',
        field: 'view_click_roas_online_percent',
        sortable: true,
        lineBreak: false,
    },
    // (Not Default) Total In Store ROAS(%)
    view_click_roas_instore_percent: {
        label: 'Total In Store ROAS (%)',
        type: 'percent_decimal',
        field: 'view_click_roas_instore_percent',
        sortable: true,
        lineBreak: false,
    },
    // (Not Default) Total ROAS(%)
    view_click_roas_total_percent: {
        label: 'Total ROAS (%)',
        type: 'percent_decimal',
        field: 'view_click_roas_total_percent',
        sortable: true,
        lineBreak: false,
    },
    impressions: {
        label: 'Impressions',
        type: 'number',
        field: 'impressions',
        sortable: true,
        lineBreak: false,
    },
    clicks: {
        label: 'Clicks',
        type: 'number',
        field: 'clicks',
        sortable: true,
        lineBreak: false,
    },
    ctr: {
        label: 'CTR',
        type: 'percent_decimal',
        field: 'ctr',
        sortable: true,
        lineBreak: false,
    },
    spend: {
        label: 'Delivered Spend',
        type: 'currency',
        field: 'spend',
        sortable: true,
        lineBreak: false,
    },
    click_revenue_online: {
        label: 'Click Online Revenue',
        type: 'currency',
        field: 'click_revenue_online',
        sortable: true,
        lineBreak: false,
    },
    click_revenue_instore: {
        label: 'Click In Store Revenue',
        type: 'currency',
        field: 'click_revenue_instore',
        sortable: true,
        lineBreak: false,
    },
    click_units_instore: {
        label: 'Click In Store Units',
        type: 'decimal',
        field: 'click_units_instore',
        sortable: true,
        lineBreak: false,
    },
    click_units_online: {
        label: 'Click Online Units',
        type: 'decimal',
        field: 'click_units_online',
        sortable: true,
        lineBreak: false,
    },
    publisher: {
        label: 'Publisher',
        type: 'description',
        field: 'publisher',
        sortable: true,
        lineBreak: false,
    },
    sku_description: {
        label: 'Description',
        type: 'description',
        field: 'sku_description',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    brand: {
        label: 'Brand',
        type: 'description',
        field: 'brand',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    dept_id: {
        label: 'Department ID',
        type: 'description',
        field: 'dept_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    dept_name: {
        label: 'Department Name',
        type: 'description',
        field: 'dept_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    class_id: {
        label: 'Class ID',
        type: 'description',
        field: 'class_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    class_name: {
        label: 'Class Name',
        type: 'description',
        field: 'class_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    subclass_id: {
        label: 'SubClass ID',
        type: 'description',
        field: 'subclass_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    subclass_name: {
        label: 'SubClass Name',
        type: 'description',
        field: 'subclass_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    revenue_online: {
        label: 'Total Online Revenue',
        type: 'currency',
        field: 'revenue_online',
        sortable: true,
        lineBreak: false,
    },
    revenue_instore: {
        label: 'Total In Store Revenue',
        type: 'currency',
        field: 'revenue_instore',
        sortable: true,
        lineBreak: false,
    },
    revenue_total: {
        label: 'Total Revenue',
        type: 'currency',
        field: 'revenue_total',
        sortable: true,
        lineBreak: false,
    },
    units_online: {
        label: 'Total Online Units',
        type: 'decimal',
        field: 'units_online',
        sortable: true,
        lineBreak: false,
    },
    units_instore: {
        label: 'Total In Store Units',
        type: 'decimal',
        field: 'units_instore',
        sortable: true,
        lineBreak: false,
    },
};

export const columnFilterMap = {
    impressions: {
        label: 'Impressions',
        column: 'impressions',
    },
    ctr: {
        label: 'CTR',
        column: 'ctr',
    },
    clicks: {
        label: 'Clicks',
        column: 'clicks',
    },
    spend: {
        label: 'Delivered Spend',
        column: 'spend',
    },
    total: {
        label: 'TOTAL:',
        children: [
            {
                label: 'Units',
                column: 'view_click_units_total',
            },
            {
                label: 'Revenue',
                column: 'view_click_revenue_total',
            },
            {
                label: 'ROAS',
                column: 'view_click_roas_total',
            },
            {
                label: 'ROAS (%)',
                column: 'view_click_roas_total_percent',
            },
        ],
    },
    total_online: {
        label: 'Online',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'view_click_units_online',
            },
            {
                label: 'ROAS (%)',
                column: 'view_click_roas_online_percent',
            },
            {
                label: 'ROAS',
                column: 'view_click_roas_online',
            },
            {
                label: 'Revenue',
                column: 'view_click_revenue_online',
            },
        ],
    },
    total_instore: {
        label: 'In Store',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'view_click_units_instore',
            },
            {
                label: 'ROAS (%)',
                column: 'view_click_roas_instore_percent',
            },
            {
                label: 'ROAS',
                column: 'view_click_roas_instore',
            },
            {
                label: 'Revenue',
                column: 'view_click_revenue_instore',
            },
        ],
    },
    view_total: {
        label: 'VIEW-THROUGH TOTAL:',
        children: [
            {
                label: 'Units',
                column: 'view_units_total',
            },
            {
                label: 'Revenue',
                column: 'view_revenue_total',
            },
            {
                label: 'ROAS',
                column: 'view_roas_total',
            },
            {
                label: 'ROAS (%)',
                column: 'view_roas_total_percent',
            },
        ],
    },
    view_online: {
        label: 'Online',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'view_units_online',
            },
            {
                label: 'ROAS (%)',
                column: 'view_roas_online_percent',
            },
            {
                label: 'ROAS',
                column: 'view_roas_online',
            },
            {
                label: 'Revenue',
                column: 'view_revenue_online',
            },
        ],
    },
    view_instore: {
        label: 'In Store',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'view_units_instore',
            },
            {
                label: 'ROAS (%)',
                column: 'view_roas_instore_percent',
            },
            {
                label: 'ROAS',
                column: 'view_roas_instore',
            },
            {
                label: 'Revenue',
                column: 'view_revenue_instore',
            },
        ],
    },
    click_total: {
        label: 'CLICK-THROUGH TOTAL:',
        children: [
            {
                label: 'Units',
                column: 'click_units_total',
            },
            {
                label: 'Revenue',
                column: 'click_revenue_total',
            },
            {
                label: 'ROAS',
                column: 'click_roas_total',
            },
            {
                label: 'ROAS (%)',
                column: 'click_roas_total_percent',
            },
        ],
    },
    click_online: {
        label: 'Online',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'click_units_online',
            },
            {
                label: 'ROAS (%)',
                column: 'click_roas_online_percent',
            },
            {
                label: 'ROAS',
                column: 'click_roas_online',
            },
            {
                label: 'Revenue',
                column: 'click_revenue_online',
            },
        ],
    },
    click_instore: {
        label: 'In Store',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'click_units_instore',
            },
            {
                label: 'ROAS (%)',
                column: 'click_roas_instore_percent',
            },
            {
                label: 'ROAS',
                column: 'click_roas_instore',
            },
            {
                label: 'Revenue',
                column: 'click_revenue_instore',
            },
        ],
    },
    sku_description: {
        label: 'Description',
        column: 'sku_description',
    },
    brand: {
        label: 'Brand',
        column: 'brand',
    },
    dept_id: {
        label: 'Department ID',
        column: 'dept_id',
    },
    dept_name: {
        label: 'Department Name',
        column: 'dept_name',
    },
    class_id: {
        label: 'Class ID',
        column: 'class_id',
    },
    class_name: {
        label: 'Class Name',
        column: 'class_name',
    },
    subclass_id: {
        label: 'SubClass ID',
        column: 'subclass_id',
    },
    subclass_name: {
        label: 'SubClass Name',
        column: 'subclass_name',
    },
    product_total: {
        label: 'TOTAL:',
        children: [
            {
                label: 'Units',
                column: 'units_total',
            },
            {
                label: 'Revenue',
                column: 'revenue_total',
            },
        ],
    },
    product_online: {
        label: 'Online',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'units_online',
            },
            {
                label: 'Revenue',
                column: 'revenue_online',
            },
        ],
    },
    product_instore: {
        label: 'In Store',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'units_instore',
            },
            {
                label: 'Revenue',
                column: 'revenue_instore',
            },
        ],
    },
    product_view_total: {
        label: 'VIEW-THROUGH TOTAL:',
        children: [
            {
                label: 'Units',
                column: 'view_units_total',
            },
            {
                label: 'Revenue',
                column: 'view_revenue_total',
            },
        ],
    },
    product_view_online: {
        label: 'Online',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'view_units_online',
            },
            {
                label: 'Revenue',
                column: 'view_revenue_online',
            },
        ],
    },
    product_view_instore: {
        label: 'In Store',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'view_units_instore',
            },
            {
                label: 'Revenue',
                column: 'view_revenue_instore',
            },
        ],
    },
    product_click_total: {
        label: 'CLICK-THROUGH TOTAL:',
        children: [
            {
                label: 'Units',
                column: 'click_units_total',
            },
            {
                label: 'Revenue',
                column: 'click_revenue_total',
            },
        ],
    },
    product_click_online: {
        label: 'Online',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'click_units_online',
            },
            {
                label: 'Revenue',
                column: 'click_revenue_online',
            },
        ],
    },
    product_click_instore: {
        label: 'In Store',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'click_units_instore',
            },
            {
                label: 'Revenue',
                column: 'click_revenue_instore',
            },
        ],
    },
};
