export const PROXIMITY_TAB_DEFAULT_KEY = 'device';

export const proximityTabs = {
    device: {
        label: 'Operating System',
        key: 'device', // Keeping the old key here as changing it may break the flow
    },
    product: {
        label: 'Product',
        key: 'product',
    },
    creative: {
        label: 'Creative',
        key: 'creative',
    },
};
