import React from 'react';
import PropTypes from 'prop-types';

const QuestionDefaultSvg = ({ width = 20, height = 24 }) => (
    <svg width={width} height={height} fill="currentColor" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.00112 17.5716C4.27486 17.5716 0.429688 13.7259 0.429688 8.99972C0.429688 4.27354 4.27486 0.428711 9.00112 0.428711C13.7274 0.428711 17.5725 4.27354 17.5725 8.99972C17.5725 13.7259 13.7274 17.5716 9.00112 17.5716Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.4319 14.3566C10.4319 14.9482 9.9516 15.428 9.36049 15.428C8.76938 15.428 8.28906 14.9482 8.28906 14.3566C8.28906 13.7649 8.76938 13.2852 9.36049 13.2852C9.9516 13.2852 10.4319 13.7649 10.4319 14.3566Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5725 5.79704C12.5725 7.27754 11.5699 8.06033 10.7636 8.68947C10.1287 9.18511 9.73973 9.51172 9.73973 9.96455V11.2267C9.73973 11.5749 9.4415 11.857 9.0738 11.857C8.70609 11.857 8.40786 11.5749 8.40786 11.2267V9.96455C8.40786 8.89405 9.20947 8.26855 9.91649 7.71637C10.6271 7.16195 11.2413 6.68282 11.2413 5.79704C11.2413 5.77745 11.2164 3.83237 9.0738 3.83237C6.98584 3.83237 6.76681 5.83315 6.75854 5.91822C6.72484 6.2647 6.39616 6.51966 6.03437 6.48803C5.66815 6.45613 5.39859 6.14911 5.43258 5.80236C5.54135 4.68485 6.44256 2.57129 9.0738 2.57129C11.6409 2.5713 12.5725 4.50042 12.5725 5.79704Z"
            fill="black"
        />
    </svg>
);

QuestionDefaultSvg.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default QuestionDefaultSvg;
