/* eslint-disable react/prop-types */
import session from 'services/session';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Redirect, Route } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { logout as logoutAction } from 'containers/App/actions';
import { AnalyticsContext } from 'shared/contexts/analyticsContext';
import { AppContext } from 'shared/contexts/app-context';
import env from 'config/env';
import { ANALYTICS_USER_TYPE, userIsExternal } from 'Helpers/helpers';
import { getAnalyticsIdForUser } from 'utils/analytics';
import RollingSessionRefresh from './RollingSessionRefresh';

const useStyles = makeStyles((theme) => ({
    formButton: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginTop: '24px',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    formButtonCancel: {
        backgroundColor: theme.palette.battleGray,
        color: theme.palette.techBlack,
        '&:hover': {
            backgroundColor: theme.palette.borderGray,
        },
    },
    dialog: {
        padding: '16px 24px',
    },
    dialogButton: {
        margin: '0',
    },
    dialogText: {
        color: theme.palette.techBlack,
    },
}));

const PrivateRoute = ({ component: Component, hasLayout, layout: Layout, permissions, ...rest }) => {
    const classes = useStyles();
    const [promptDialogOpen, setPromptDialogOpen] = useState(false);

    // TODO: Retrieve timeout from config
    const timeout = env.REACT_APP_IDLE_TIMER;
    const promptTimeout = env.REACT_APP_IDLE_STAY_TIMEOUT;

    const handleOnIdle = () => {
        onLogout();
    };

    const handleOnPrompt = () => {
        setPromptDialogOpen(true);
    };

    const onLogout = () => {
        setPromptDialogOpen(false);
        rest.logout();
    };

    const onStay = () => {
        setPromptDialogOpen(false);
        reset();
    };

    const { reset } = useIdleTimer({
        timeout,
        promptTimeout,
        onPrompt: handleOnPrompt,
        onIdle: handleOnIdle,
        debounce: 500,
    });

    const { analytics } = useContext(AnalyticsContext);
    const { authedUser } = useContext(AppContext);

    const isAuthenticated = () => {
        const isAuthed = session.isAuthenticated();

        const userType = userIsExternal(authedUser) ? ANALYTICS_USER_TYPE.EXTERNAL : ANALYTICS_USER_TYPE.INTERNAL;
        if (isAuthed && authedUser && authedUser.uuid) {
            analytics.set({ userId: getAnalyticsIdForUser(authedUser) });
            analytics.gtag('set', 'user_properties', {
                USER_TYPE: userType,
                MY_ADS_ANALYTICS_ID: getAnalyticsIdForUser(authedUser),
            });
        }
        return isAuthed;
    };

    return isAuthenticated() ? (
        <Route
            {...rest}
            render={(routeProps) => (
                <div>
                    {hasLayout && Layout ? (
                        <Layout permissions={permissions}>
                            <Component {...routeProps} />
                        </Layout>
                    ) : (
                        <Component {...routeProps} />
                    )}
                    <Dialog open={promptDialogOpen} fullWidth maxWidth="sm">
                        <DialogTitle>Your session has expired</DialogTitle>
                        <DialogContent>
                            <DialogContentText className={classnames(classes.dialogText)}>
                                Would you like to Continue working or Logout?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className={classes.dialog}>
                            <Button
                                data-testid="logout-button"
                                className={classnames(
                                    classes.formButton,
                                    classes.dialogButton,
                                    classes.formButtonCancel,
                                )}
                                onClick={onLogout}
                            >
                                <span>Logout</span>
                            </Button>
                            <Button
                                data-testid="stay-button"
                                className={classnames(classes.formButton, classes.dialogButton)}
                                onClick={onStay}
                            >
                                <span>Continue working</span>
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <RollingSessionRefresh />
                </div>
            )}
        />
    ) : (
        <Redirect
            to={{
                pathname: '/login',
                // we want to capture where the user was trying to go before redirected to login
                // so upon successful login we can redirect to desired page
                state: { from: rest.location },
            }}
        />
    );
};

PrivateRoute.defaultProps = {
    hasLayout: true,
    logout: PropTypes.func,
    roles: [],
};

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logoutAction()),
    };
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(PrivateRoute);
