/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
    },
    circle: {
        width: 23,
        height: 23,
        backgroundColor: theme.palette.techYellow,
        display: 'block',
        position: 'relative',
        borderRadius: '100%',
        marginRight: 12,
        marginTop: '30px',
    },
    inputStyle: {
        margin: '0px 0px 24px 0px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: 18,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        '& .MuiInputBase-input': {
            color: theme.palette.standardWhite,
            fontSize: 22,
            paddingBottom: 16,
        },
        '& .MuiFormLabel-root': {
            color: theme.palette.labelGrey,
            fontSize: 34,
            fontWeight: 500,
        },
        '& .Mui-focused': {
            fontSize: 18,
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: theme.palette.labelGrey,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.standardWhite,
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: theme.palette.standardWhite,
            borderWidth: 2,
        },
    },
    successStyle: {
        '& .MuiFormLabel-root': {
            fontSize: 18,
        },
    },
    fieldErrorStyle: {
        '&.MuiFormHelperText-contained': {
            margin: '8px 0px 0',
        },
        '& .MuiFormHelperText-root.Mui-error': {
            margin: 0,
            color: theme.palette.valueRed,
            paddingTop: '4px',
            paddingLeft: '8px',
        },
        '& .Mui-error': {
            color: theme.palette.valueRed,
            fontSize: 18,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.valueRed,
            borderWidth: 2,
        },
    },
}));

const Input = ({
    meta: { touched, error, submitError } = {
        touched: false,
        error: undefined,
    },
    input: { ...textFieldProps },
    inputLabel,
    reset,
    ...props
}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <span className={classes.circle} />
            <TextField
                label={inputLabel}
                inputProps={{
                    'data-testid': `login-input-${textFieldProps.name}`,
                }}
                {...textFieldProps}
                {...props}
                fullWidth
                className={classnames(
                    classes.inputStyle,
                    touched && !error && !submitError && classes.successStyle,
                    ((touched && error) || submitError) && classes.fieldErrorStyle,
                )}
                margin="normal"
                variant="standard"
                error={!!((touched && error) || submitError)}
                helperText={(touched && error) || submitError}
            />
        </div>
    );
};

Input.propTypes = {
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
        valid: PropTypes.bool,
        modified: PropTypes.bool,
    }),
    input: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        checked: PropTypes.bool,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        type: PropTypes.string,
    }),
    inputLabel: PropTypes.string,
    reset: PropTypes.func,
};

export default Input;
