import session from 'services/session';

export default {
    API_BASE_URL: process.env.REACT_APP_PORTAL_API_BASE,
    DATA_VIZ_BASE_URL: process.env.REACT_APP_VIZ_API_BASE,
    APP_VERSION: process.env.REACT_APP_VERSION,
    BBY_ENV: process.env.REACT_APP_BBY_ENV,
    ASSETS_BASE_URL: process.env.REACT_APP_ASSETS_BASE,
    ASSETS_VERSION_NUMBER: process.env.REACT_APP_ASSETS_VERSION_NUMBER,
    GA_ID: 'G-2R2PRVLV13',
    REACT_APP_ROLLING_SESSION_REFRESH: 1200000,
    REACT_APP_IDLE_TIMER: 3300000,
    REACT_APP_IDLE_STAY_TIMEOUT: 300000,
    REACT_APP_ALERT:
        '[{ "id": 1, "active": true, "alertLevel": 0, "title": "Maintenance Message", "message":"Maintenance alert message", "dismissEnabled": true, "startTime": "1 JAN 2023 00:00:01 EST", "endTime": "31 JAN 2023 11:59:59 EST", "loginRequired": false, "page": 1 }]',
    get GATEWAY_BASE_URL() {
        const sessionInfo = session.read();
        if (sessionInfo?.localUserType === 'internal') {
            return process.env.REACT_APP_GATEWAY_API_BASE_INTERNAL;
        }
        if (sessionInfo?.localUserType === 'internal-stage') {
            return process.env.REACT_APP_GATEWAY_API_BASE_INTERNAL_STAGE;
        }
        if (sessionInfo?.localUserType === 'external-stage') {
            return process.env.REACT_APP_GATEWAY_API_BASE_EXTERNAL_STAGE;
        }

        return process.env.REACT_APP_GATEWAY_API_BASE_EXTERNAL;
    },
};
