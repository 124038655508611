/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import campaignsWatchingReducer from 'containers/StackedCampaignsPage/WatchCampaignsTab/reducer';
import recentViewedPageReducer from 'containers/RecentViewedPage/slice';
import stackedCampaignsPageReducer from 'containers/StackedCampaignsPage/reducer';
import userListPageReducer from 'containers/IDAMUserManagement/UserListPage/reducer';
import featureFlagReducer from 'utils/featureFlags/reducers';
import organizationReducer from 'containers/OrganizationPage/slice';
import alertsDomainReducer from 'layouts/AlertBanner/slice';
import notificationReducer from './notification/reducers';
import globalReducer from './containers/App/reducer';
import summaryDashboardPageReducer from './containers/SummaryDashboardPage/reducer';
import { UNLOAD_AUTHENTICATED_USER } from './containers/App/constants';

// import languageProviderReducer from 'containers/LanguageProvider/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 *
 * @param {object} injectedReducers Combined reducers
 *
 * @returns {function} Reducer function
 */
export default function createReducer(injectedReducers = {}) {
    const rootReducer = (state, action) => {
        if (action.type === UNLOAD_AUTHENTICATED_USER) {
            const { alertsDomain } = state;
            return appReducer({ alertsDomain }, action);
        }
        return appReducer(state, action);
    };

    const appReducer = combineReducers({
        router: connectRouter(history),
        global: globalReducer,
        // language: languageProviderReducer,
        recentViewedPage: recentViewedPageReducer,
        watchCampaignsTab: campaignsWatchingReducer,
        stackedCampaignsPage: stackedCampaignsPageReducer,
        featureFlags: featureFlagReducer,
        notificationState: notificationReducer,
        alertsDomain: alertsDomainReducer,
        userListPage: userListPageReducer,
        organizationPage: organizationReducer,
        summaryDashboardPage: summaryDashboardPageReducer,
        ...injectedReducers,
    });

    return rootReducer;
}
