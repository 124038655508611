import { takeLatest, call, put, select } from 'redux-saga/effects';
import { userPasswordUpdateApi } from 'api/user-idam';
import get from 'lodash/get';
import { NOTICES } from 'notification/constants';
import { enqueueSnackbar } from 'notification/actions';

import { selectFeatureFlags } from 'utils/featureFlags/selectors';
import { RECENT_PASSWORD_ERR_CODE } from 'shared/constants';
import { passwordUpdateSuccess, passwordUpdateError } from './actions';
import { PASSWORD_RESET } from './constants';

export function* passwordUpdate({ payload }) {
    try {
        const flags = yield select(selectFeatureFlags);
        const response = yield call(userPasswordUpdateApi, payload, flags);
        if (response.code === 200 || response.status === 0) {
            yield put(passwordUpdateSuccess());
            yield put(
                enqueueSnackbar({
                    message: NOTICES.UPDATE_PASSWORD_SUCCESS,
                    options: { variant: 'success' },
                }),
            );
        } else {
            throw new Error({ message: response.msg });
        }
    } catch (error) {
        yield handleError(error, passwordUpdateError);
    }
}

function* handleError(error, putFn) {
    const httpStatus = get(error, 'response.status');
    let err = error;
    let message = NOTICES.UPDATE_PASSWORD_ERROR_IDAM;

    // http status 400 for errors and 4001 in paylod is recent passwords error and 4002 is generic error
    if (httpStatus === 400) {
        const errorStatus = get(error, 'response.data.idam.payload.status');
        if (errorStatus === RECENT_PASSWORD_ERR_CODE) {
            message = NOTICES.UPDATE_PASSWORD_ERROR_PREVIOUS_IDAM;
        }
        err = yield call(() => error.toJSON());
    }
    yield put(putFn(err));
    yield put(
        enqueueSnackbar({
            message,
            options: { variant: 'error' },
        }),
    );
}

// Individual exports for testing
export default function* passwordUpdatePageSaga() {
    yield takeLatest(PASSWORD_RESET, passwordUpdate);
}
