/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
    LOAD_CAMPAIGNS,
    LOAD_CAMPAIGNS_SUCCESS,
    LOAD_CAMPAIGNS_ERROR,
    LOAD_AUTHENTICATED_USER,
    UNLOAD_AUTHENTICATED_USER,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    GET_CAMPAIGN_GROUPS,
    GET_CAMPAIGN_GROUPS_SUCCESS,
    GET_CAMPAIGN_GROUPS_ERROR,
    CREATE_CAMPAIGN_GROUP,
    CREATE_CAMPAIGN_GROUP_ERROR,
    CREATE_CAMPAIGN_GROUP_SUCCESS,
    DELETE_CAMPAIGN_GROUP,
    DELETE_CAMPAIGN_GROUP_SUCCESS,
    DELETE_CAMPAIGN_GROUP_ERROR,
    UPDATE_CAMPAIGN_GROUP,
    UPDATE_CAMPAIGN_GROUP_SUCCESS,
    UPDATE_CAMPAIGN_GROUP_ERROR,
    ADD_CAMPAIGNS_TO_GROUP,
    ADD_CAMPAIGNS_TO_GROUP_SUCCESS,
    ADD_CAMPAIGNS_TO_GROUP_ERROR,
    REMOVE_CAMPAIGNS_FROM_GROUP,
    REMOVE_CAMPAIGNS_FROM_GROUP_SUCCESS,
    REMOVE_CAMPAIGNS_FROM_GROUP_ERROR,
    SHARE_CAMPAIGN_GROUP,
    SHARE_CAMPAIGN_GROUP_SUCCESS,
    SHARE_CAMPAIGN_GROUP_ERROR,
    GET_SHARED_CAMPAIGN_GROUPS,
    GET_SHARED_CAMPAIGN_GROUPS_SUCCESS,
    GET_SHARED_CAMPAIGN_GROUPS_ERROR,
    RESET_DONE,
    LOGOUT,
    GET_USER_INFO,
    GET_USER_ERROR,
    SHARE_ORG,
    REMOVE_SHARE_ORG,
} from './constants';

/**
 * Load the campaigns, this action starts the request saga
 *
 * @returns {object} An action object with a type of LOAD_CAMPAIGNS
 */
export function loadCampaigns() {
    return {
        type: LOAD_CAMPAIGNS,
    };
}

/**
 * Dispatched when the campaigns are loaded by the request saga
 *
 * @param  {array} campaigns The campaigns data
 * @param  {object} campaignFilters The campaigns filter metadata
 *
 * @returns {object}      An action object with a type of LOAD_CAMPAIGNS_SUCCESS passing the campaigns
 */
export function campaignsLoaded(campaigns, campaignFilters) {
    return {
        type: LOAD_CAMPAIGNS_SUCCESS,
        campaigns,
        campaignFilters,
    };
}

/**
 * Dispatched when loading the campaigns fails
 *
 * @param  {object} error The error
 *
 * @returns {object}       An action object with a type of LOAD_CAMPAIGNS_ERROR passing the error
 */
export function campaignsLoadingError(error) {
    return {
        type: LOAD_CAMPAIGNS_ERROR,
        error,
    };
}

export function loadAuthedUser(authedUser = {}) {
    return {
        type: LOAD_AUTHENTICATED_USER,
        authedUser,
    };
}

export function unloadAuthedUser() {
    return {
        type: UNLOAD_AUTHENTICATED_USER,
    };
}

export function logout() {
    return {
        type: LOGOUT,
    };
}

export function createUser(user) {
    return {
        type: CREATE_USER,
        user,
    };
}

export function createUserSuccess(user) {
    return {
        type: CREATE_USER_SUCCESS,
        user,
    };
}

export function createUserError(error) {
    return {
        type: CREATE_USER_ERROR,
        error,
    };
}

export function updateUser(user, brandsToUpdate) {
    return {
        type: UPDATE_USER,
        user,
        brandsToUpdate,
    };
}

export function updateUserSuccess(user) {
    return {
        type: UPDATE_USER_SUCCESS,
        user,
    };
}

export function updateUserError(error) {
    return {
        type: UPDATE_USER_ERROR,
        error,
    };
}

export function createCampaignGroup(userId, groupName, groupType, campaignIds) {
    return {
        type: CREATE_CAMPAIGN_GROUP,
        userId,
        groupName,
        groupType,
        campaignIds,
    };
}

export function createCampaignGroupSuccess(data) {
    return {
        type: CREATE_CAMPAIGN_GROUP_SUCCESS,
        data,
    };
}

export function createCampaignGroupError(error) {
    return {
        type: CREATE_CAMPAIGN_GROUP_ERROR,
        error,
    };
}

export function deleteCampaignGroup(groupId) {
    return {
        type: DELETE_CAMPAIGN_GROUP,
        groupId,
    };
}

export function deleteCampaignGroupSuccess(groupId) {
    return {
        type: DELETE_CAMPAIGN_GROUP_SUCCESS,
        groupId,
    };
}

export function deleteCampaignGroupError(error) {
    return {
        type: DELETE_CAMPAIGN_GROUP_ERROR,
        error,
    };
}

export function getCampaignGroups(userId, groupType) {
    return {
        type: GET_CAMPAIGN_GROUPS,
        userId,
        groupType,
    };
}

export function getCampaignGroupsSuccess(data) {
    return {
        type: GET_CAMPAIGN_GROUPS_SUCCESS,
        data,
    };
}

export function getCampaignGroupsError(error) {
    return {
        type: GET_CAMPAIGN_GROUPS_ERROR,
        error,
    };
}

export function addCampaignsToGroup(userId, groupName, groupType, campaignIds) {
    return {
        type: ADD_CAMPAIGNS_TO_GROUP,
        userId,
        groupName,
        groupType,
        campaignIds,
    };
}

export function addCampaignsToGroupSuccess(data) {
    return {
        type: ADD_CAMPAIGNS_TO_GROUP_SUCCESS,
        data,
    };
}

export function addCampaignsToGroupError(error) {
    return {
        type: ADD_CAMPAIGNS_TO_GROUP_ERROR,
        error,
    };
}

export function removeCampaignsFromGroup(userId, groupName, groupType, campaignIds) {
    return {
        type: REMOVE_CAMPAIGNS_FROM_GROUP,
        userId,
        groupName,
        groupType,
        campaignIds,
    };
}

export function removeCampaignsFromGroupSuccess(data) {
    return {
        type: REMOVE_CAMPAIGNS_FROM_GROUP_SUCCESS,
        data,
    };
}

export function removeCampaignsFromGroupError(error) {
    return {
        type: REMOVE_CAMPAIGNS_FROM_GROUP_ERROR,
        error,
    };
}

export function updateCampaignGroup(group) {
    return {
        type: UPDATE_CAMPAIGN_GROUP,
        group,
    };
}

export function updateCampaignGroupSuccess(data) {
    return {
        type: UPDATE_CAMPAIGN_GROUP_SUCCESS,
        data,
    };
}

export function updateCampaignGroupError(error) {
    return {
        type: UPDATE_CAMPAIGN_GROUP_ERROR,
        error,
    };
}

export function shareCampaignGroup(groupId, shared) {
    return {
        type: SHARE_CAMPAIGN_GROUP,
        groupId,
        shared,
    };
}

export function shareCampaignGroupSuccess(data) {
    return {
        type: SHARE_CAMPAIGN_GROUP_SUCCESS,
        data,
    };
}

export function shareCampaignGroupError(error) {
    return {
        type: SHARE_CAMPAIGN_GROUP_ERROR,
        error,
    };
}

export function getSharedCampaignGroups(groupType) {
    return {
        type: GET_SHARED_CAMPAIGN_GROUPS,
        groupType,
    };
}

export function getSharedCampaignGroupsSuccess(data) {
    return {
        type: GET_SHARED_CAMPAIGN_GROUPS_SUCCESS,
        data,
    };
}

export function getSharedCampaignGroupsError(error) {
    return {
        type: GET_SHARED_CAMPAIGN_GROUPS_ERROR,
        error,
    };
}

export const resetDone = () => ({
    type: RESET_DONE,
});

export const getUserInfo = () => ({
    type: GET_USER_INFO,
});

export const getUserError = (error) => ({
    type: GET_USER_ERROR,
    error: error.toString(),
});

export const shareOrg = (sharedData) => ({
    type: SHARE_ORG,
    sharedData,
});

export function removeShareOrg(sharedData) {
    return {
        type: REMOVE_SHARE_ORG,
        sharedData,
    };
}
