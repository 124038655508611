/*
 *
 * PasswordUpdatePage actions
 *
 */

import { PASSWORD_RESET, PASSWORD_RESET_INIT, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_ERROR } from './constants';

export function passwordUpdate(payload) {
    return {
        type: PASSWORD_RESET,
        payload,
    };
}

export function passwordUpdateInit() {
    return {
        type: PASSWORD_RESET_INIT,
    };
}

export function passwordUpdateSuccess() {
    return {
        type: PASSWORD_RESET_SUCCESS,
    };
}

export function passwordUpdateError(error) {
    return {
        type: PASSWORD_RESET_ERROR,
        error,
    };
}
