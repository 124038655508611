/**
 *
 * Organizationpage
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from '@reduxjs/toolkit';
import { makeStyles } from '@material-ui/core/styles';
import { useInjectSaga } from 'redux-injectors';
import { FlyoutAction, RequestStatus } from 'shared/constants';
import { removeShareOrg as removeOrg } from '../App/actions';
import OrganizationListTable from './OrganizationListTable';

import saga from './saga';
import {
    loadOrganizationListAction,
    loadBrandAction,
    updateOrganizationPageNumber,
    updateFilteredOrganizations,
} from './slice';
import {
    selectOrganizations,
    selectOrganizationLoadState,
    selectBrands,
    selectBrandsLoadState,
    selectPageNumbers,
    selectOrganizationFilter,
} from './selectors';
import { OrgFlyoutContainer } from './OrgFlyoutContainer';

const useStyles = makeStyles((theme) => ({
    container: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },

    userLoading: {
        fontFamily: 'humanBBYDigital',
        marginTop: '15px',
    },
}));

export function OrganizationListPage({
    organizations,
    orgLoadState,
    brandLoadState,
    loadOrganizations,
    brands,
    loadBrands,
    onUpdateOrganizationPageNumber,
    pageNumbers,
    removeShareOrg,
    onFilterUpdateAction,
    orgFilter,
}) {
    useInjectSaga({ key: 'organizationPage', saga });

    const classes = useStyles();

    const location = window?.location;

    const queryParams = new URLSearchParams(location?.search);
    const flyoutQuery = queryParams.get('flyoutStatus');

    const [flyoutStatus, setFlyoutStatus] = useState(flyoutQuery || '');
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        loadOrganizations();
        loadBrands();
    }, [loadOrganizations, loadBrands]);

    // duplicate function that exists in OrganizationListTable
    const onCloseOrgFlyoutModal = () => {
        setFlyoutStatus(FlyoutAction.NONE);
    };

    let content = null;
    if (orgLoadState === RequestStatus.LOADING) {
        content = <div className={classes.userLoading}>Loading organization list...</div>;
    } else if (organizations) {
        content = (
            <>
                <OrganizationListTable
                    organizations={organizations}
                    brands={brands}
                    updatePage={onUpdateOrganizationPageNumber}
                    pageNumber={pageNumbers.organization}
                    onUpdatePageNumber={onUpdateOrganizationPageNumber}
                    removeShareOrg={removeShareOrg}
                    onFilterUpdate={onFilterUpdateAction}
                    currentOrgFilter={orgFilter}
                    setFlyoutStatus={setFlyoutStatus}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                />
                <OrgFlyoutContainer
                    organizations={organizations}
                    orgLoadState={orgLoadState}
                    brands={brands}
                    brandsLoading={brandLoadState !== RequestStatus.DONE}
                    flyoutStatus={flyoutStatus}
                    selectedItem={selectedItem}
                    onCloseOrgFlyoutModal={onCloseOrgFlyoutModal}
                />
            </>
        );
    } else {
        content = <div className={classes.userLoading}>Not able to load organization list</div>;
    }

    return (
        <div className={classes.container}>
            <div className={classes.userlistWrapper}>{content}</div>
        </div>
    );
}

OrganizationListPage.propTypes = {
    orgLoadState: PropTypes.string,
    brandLoadState: PropTypes.string,
    organizations: PropTypes.array.isRequired,
    brands: PropTypes.array.isRequired,
    loadBrands: PropTypes.func,
    loadOrganizations: PropTypes.func,
    createdUser: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        aid: PropTypes.string,
        email: PropTypes.string.isRequired,
        roles: PropTypes.array.isRequired,
    }),
    onUpdateOrganizationPageNumber: PropTypes.func,
    pageNumbers: PropTypes.shape({
        organization: PropTypes.number,
        brand: PropTypes.number,
        account: PropTypes.number,
    }),
    removeShareOrg: PropTypes.func,
    onFilterUpdateAction: PropTypes.func,
    orgFilter: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    orgLoadState: selectOrganizationLoadState,
    brandLoadState: selectBrandsLoadState,
    organizations: selectOrganizations,
    brands: selectBrands,
    pageNumbers: selectPageNumbers,
    orgFilter: selectOrganizationFilter,
});

// export for testing
export function mapDispatchToProps(dispatch) {
    return {
        loadOrganizations: () => dispatch(loadOrganizationListAction()),
        loadBrands: () => dispatch(loadBrandAction()),
        onUpdateOrganizationPageNumber: (pageNumber) => dispatch(updateOrganizationPageNumber(pageNumber)),
        removeShareOrg: (organization) => {
            dispatch(removeOrg(organization));
        },
        onFilterUpdateAction: (filter) => {
            dispatch(updateFilteredOrganizations(filter));
        },
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OrganizationListPage);
