import { call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import { difference, uniq } from 'lodash';

import { getCampaignWatchListByUserApi, createCampaignWatchApi, updateCampaignWatchApi } from 'api/user-management';
import { enqueueSnackbar } from 'notification/actions';
import { NOTICES } from 'notification/constants';
import {
    fetchCampaignsWatchingSuccess,
    fetchCampaignsWatchingError,
    removeCampaignsFromWatchSuccess,
    removeCampaignsFromWatchError,
    createCampaignWatchSuccess,
    createCampaignWatchError,
    addCampaignsToWatchSuccess,
    addCampaignsToWatchError,
} from './actions';

import {
    FETCH_CAMPAIGNS_WATCHING_START,
    REMOVE_CAMPAIGNS_FROM_WATCH,
    CREATE_CAMPAIGN_WATCH,
    ADD_CAMPAIGNS_TO_WATCH,
} from './constants';
import { makeSelectDefaultWatch } from './selectors';

function* fetchCampaignsWatching({ userId }) {
    try {
        const { rows } = yield call(getCampaignWatchListByUserApi, userId);

        yield put(fetchCampaignsWatchingSuccess(rows));
    } catch (error) {
        yield put(fetchCampaignsWatchingError(error));
    }
}

function* removeCampaignsFromWatchSaga({ userId, watchName, campaignIds }) {
    try {
        const watch = yield select(makeSelectDefaultWatch());

        if (watch) {
            const { watchId } = watch;
            const newCampaignIds = uniq(difference(watch.campaignIds, campaignIds));
            const response = yield call(updateCampaignWatchApi, {
                userId,
                watchId,
                watchName,
                campaignIds: newCampaignIds,
            });
            if (response.status === 0) {
                const watchData = {
                    userId,
                    watchId,
                    watchName,
                    campaignIds: newCampaignIds,
                };

                yield put(removeCampaignsFromWatchSuccess(watchData));
                yield put(
                    enqueueSnackbar({
                        message: NOTICES.REMOVE_FROM_WATCH_SUCCESS,
                        options: { variant: 'success' },
                    }),
                );
            } else {
                throw new Error({ message: response.msg });
            }
        } else {
            throw new Error('No watch found');
        }
    } catch (error) {
        yield put(removeCampaignsFromWatchError(error));
        yield put(
            enqueueSnackbar({
                message: NOTICES.REMOVE_FROM_WATCH_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

function* createCampaignWatchSaga({ userId, watchName, campaignIds }) {
    let watchData;
    try {
        watchData = yield call(createCampaignWatchApi, {
            userId,
            watchName,
            campaignIds,
        });

        if (watchData.status === 2) {
            throw new Error(watchData.msg);
        }

        yield put(createCampaignWatchSuccess(watchData));
        yield put(
            enqueueSnackbar({
                message: NOTICES.CREATE_WATCH_SUCCESS,
                options: { variant: 'success' },
                viewLink: '/campaigns/stacked/watching',
            }),
        );
    } catch (error) {
        yield put(createCampaignWatchError(error));
        yield put(
            enqueueSnackbar({
                message: NOTICES.CREATE_WATCH_ERROR,
                options: { variant: 'error' },
            }),
        );
    }

    return watchData;
}

export function* addCampaignsToWatchSaga({ userId, watchName, campaignIds }) {
    try {
        const watch = yield select(makeSelectDefaultWatch());

        if (!watch) {
            yield call(createCampaignWatchSaga, {
                userId,
                watchName,
                campaignIds,
            });
        } else {
            const { watchId, shared } = watch;
            const newCampaignIds = uniq([...campaignIds, ...watch.campaignIds]);
            const optimisticGroupData = {
                watchId,
                userId,
                watchName,
                campaignIds: newCampaignIds,
            };
            yield put(addCampaignsToWatchSuccess(optimisticGroupData));
            const response = yield call(updateCampaignWatchApi, {
                userId,
                watchId,
                watchName,
                campaignIds: newCampaignIds,
                shared,
            });
            if (response.status === 0) {
                yield put(
                    enqueueSnackbar({
                        message: NOTICES.ADD_TO_WATCH_SUCCESS,
                        options: { variant: 'success' },
                        viewLink: '/campaigns/stacked/watching',
                    }),
                );
            } else {
                const revertGroupData = {
                    watchId,
                    userId,
                    watchName,
                    campaignIds: [...watch.campaignIds],
                };
                yield put(addCampaignsToWatchSuccess(revertGroupData));

                if (response.status === 1) {
                    throw new Error(NOTICES.ADD_TO_WATCH_MAX_ERROR);
                } else {
                    throw new Error(response.msg);
                }
            }
        }
    } catch (error) {
        yield put(addCampaignsToWatchError(error.message));
        yield put(
            enqueueSnackbar({
                message:
                    error.message === NOTICES.ADD_TO_WATCH_MAX_ERROR
                        ? NOTICES.ADD_TO_WATCH_MAX_ERROR
                        : NOTICES.ADD_TO_WATCH_ERROR,
                options: { variant: 'error' },
            }),
        );
    }
}

// Individual exports for testing
export default function* watchCampaignsTabSaga() {
    yield takeLatest(FETCH_CAMPAIGNS_WATCHING_START, fetchCampaignsWatching);
    yield takeLatest(REMOVE_CAMPAIGNS_FROM_WATCH, removeCampaignsFromWatchSaga);
    yield takeLatest(CREATE_CAMPAIGN_WATCH, createCampaignWatchSaga);
    yield takeEvery(ADD_CAMPAIGNS_TO_WATCH, addCampaignsToWatchSaga);
}
