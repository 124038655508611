import {
    BUILD_ONSITE_DISPLAY_GROUP_NAME,
    BUILD_PAID_SEARCH_GROUP_NAME,
    BUILD_SPONSORED_RMP_GROUP_NAME,
    BUILD_SPONSORED_RSX_GROUP_NAME,
    DISPLAY_GROUP_TYPE,
    PAID_SEARCH_GROUP_TYPE,
    SPONSORED_RMP_GROUP_TYPE,
    SPONSORED_RSX_GROUP_TYPE,
} from '../containers/StackedCampaignsPage/constants';
import { getGroupTypeAndName } from '../containers/StackedCampaignsPage/utils';
import { DEFAULT_WATCH_NAME } from '../containers/StackedCampaignsPage/WatchCampaignsTab/constants';

export const tableActionService = {
    isInBuildReportGroup: (campaignId, campaignGroups, user) => {
        const buildDisplayGroup = campaignGroups.find(
            (campaignGroup) =>
                campaignGroup.groupName === BUILD_ONSITE_DISPLAY_GROUP_NAME &&
                campaignGroup.groupType === DISPLAY_GROUP_TYPE &&
                campaignGroup.userId === user.uuid,
        );
        const buildSponsoredGroup = campaignGroups.find(
            (campaignGroup) =>
                campaignGroup.groupName === BUILD_SPONSORED_RSX_GROUP_NAME &&
                campaignGroup.groupType === SPONSORED_RSX_GROUP_TYPE &&
                campaignGroup.userId === user.uuid,
        );
        const buildSponsoredRMPGroup = campaignGroups.find(
            (campaignGroup) =>
                campaignGroup.groupName === BUILD_SPONSORED_RMP_GROUP_NAME &&
                campaignGroup.groupType === SPONSORED_RMP_GROUP_TYPE &&
                campaignGroup.userId === user.uuid,
        );
        const buildPaidSearchGroup = campaignGroups.find(
            (campaignGroup) =>
                campaignGroup.groupName === BUILD_PAID_SEARCH_GROUP_NAME &&
                campaignGroup.groupType === PAID_SEARCH_GROUP_TYPE &&
                campaignGroup.userId === user.uuid,
        );

        return (
            (buildDisplayGroup && buildDisplayGroup.campaignIds.includes(campaignId)) ||
            (buildSponsoredGroup && buildSponsoredGroup.campaignIds.includes(campaignId)) ||
            (buildSponsoredRMPGroup && buildSponsoredRMPGroup.campaignIds.includes(campaignId)) ||
            (buildPaidSearchGroup && buildPaidSearchGroup.campaignIds.includes(campaignId))
        );
    },

    isInWatchList: (campaignId, watchlists) => {
        const watch = watchlists.find((watchlist) => watchlist.campaign_id === campaignId);
        return watch;
    },

    onAddToStack: (campaignId, campaign, user, onAddCampaignsToGroup) => {
        const userId = user.uuid;
        const { groupType, groupName } = getGroupTypeAndName(campaign);

        onAddCampaignsToGroup(userId, groupName, groupType, [campaignId]);
    },

    onAddToWatch: (campaignId, user, onAddCampaignsToWatch) => {
        const userId = user.uuid;
        const watchName = DEFAULT_WATCH_NAME;
        onAddCampaignsToWatch(userId, watchName, [campaignId]);
    },
};
