/*
 *
 * OrgPage constants
 *
 */

export const orgTypeOptionsArray = [
    { name: 'Agency', value: 'Agency' },
    { name: 'Vendor', value: 'Vendor' },
    { name: 'Vendor Rep', value: 'Vendor Rep' },
];

export const orgSharingOptionsArray = [
    { name: 'Sharing', value: 'Sharing' },
    { name: 'Not Sharing', value: 'Not Sharing' },
];
