import { createSlice } from '@reduxjs/toolkit';
import { mockAlerts, MOCK_ALERT_KEY } from 'containers/DevPage/mockAlerts';
import { getItemFromStorage } from 'utils/localStorage';
import { checkActive, checkDismissStatus } from './util';

export const initialState = {
    currentAlerts: [],
    alerts: getItemFromStorage(MOCK_ALERT_KEY) ? mockAlerts : [],
    campaignType: null,
};

const alertDomainSlice = createSlice({
    name: 'alertsDomain',
    initialState,
    reducers: {
        loadAlertsAction(draft, action) {
            const alerts = action.payload;
            const currentAlerts = alerts.filter((alert) => checkActive(alert) && !checkDismissStatus(alert));
            draft.alerts = alerts;
            draft.currentAlerts = currentAlerts;
        },
        setCurrentAlerts(draft, action) {
            draft.currentAlerts = action.payload;
        },
        setCampaignType(draft, action) {
            draft.campaignType = action.payload;
        },
        forceBannerUpdate(draft) {
            // Forces the current alerts to update, which can be used to cause a rerender if needed
            const currentAlerts = draft.alerts.filter((alert) => checkActive(alert) && !checkDismissStatus(alert));
            draft.currentAlerts = currentAlerts;
        },
    },
});

export const { loadAlertsAction, setCampaignType, setCurrentAlerts, forceBannerUpdate } = alertDomainSlice.actions;
export default alertDomainSlice.reducer;
