import { makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    bold: {
        fontWeight: 500,
    },
    alertContainer: {
        marginTop: 8,
    },
}));

export function PaidSearchTooltipContent({ flags }) {
    const classes = useStyles();

    if (flags.displayPaidSearchAlert) {
        return (
            <div className={classes.alertContainer}>
                <span className={classes.bold}>In Store Revenue </span>
                <span>for Dec 2021 - Jan 2022 will be low/zero due to known data issue.</span>
            </div>
        );
    }

    return null;
}

PaidSearchTooltipContent.propTypes = {
    flags: PropTypes.shape({
        displayPaidSearchAlert: PropTypes.bool,
    }),
};

export const renderPaidSearchTooltipContent = (campaignTypeName, flags) => <PaidSearchTooltipContent flags={flags} />;
