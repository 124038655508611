import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Skeleton from '@material-ui/lab/Skeleton';
import { sortCampaignType } from '../../containers/SummaryDashboardPage/helpers/helpers';
import { formatSubtitle } from '../../utils/formatData';
import { Vehicle } from '../../vehicles';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gridColumn: 10,
        rowGap: 10,
        padding: '0 24px',
        flexWrap: 'wrap',
    },
    cards: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginLeft: '-1px',
        minWidth: '200px',
    },
    textCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
    },
    textContent: {
        textAlign: 'left',
    },
    pipe: {
        display: 'inline-block',
        width: 1,
        height: 42,
        backgroundColor: theme.palette.borderGray,
    },
    noPipe: {
        display: 'inline-block',
    },
    title: {
        fontSize: 14,
        fontFamily: 'humanBBYDigital',
        color: theme.palette.techBlack,
    },
    subtitle: {
        fontFamily: 'humanBBYDigital',
        fontSize: '24px',
        fontWeight: 500,
        color: theme.palette.techBlack,
    },
}));

export default function SummaryDashboardCards({ summaryData = [], category, visualOptions, vehicleLabel, isLoading }) {
    const classes = useStyles();

    const [cardData, setCardData] = useState([]);

    useEffect(() => {
        if (summaryData.length > 0) {
            setCardData(sortCampaignType(summaryData));
        }
    }, [summaryData]);

    const displayCard = (campaign) => {
        const cardTitle = vehicleLabel || Vehicle.getFromCampaignType(campaign.campaignType).getLabel();
        return (
            <div key={`${cardTitle}`} className={classes.cards}>
                <Box key={`startPipe-${campaign.campaignType}`} className={classes.pipe} />
                <div key={`textCard-${cardTitle}`} className={classes.textCard}>
                    <Box key={`textContentContainer-${cardTitle}`} className={classes.textContent}>
                        <Typography key={`title-${cardTitle}`} className={classes.title}>
                            {isLoading ? (
                                <Skeleton animation="wave" variant="text" width={140} height={19} />
                            ) : (
                                `${cardTitle}`
                            )}
                        </Typography>
                        <Typography key={`subtitle-${cardTitle}`} className={classes.subtitle}>
                            {isLoading ? (
                                <Skeleton animation="wave" variant="text" width={75} height={50} />
                            ) : (
                                formatSubtitle(campaign[category], visualOptions, category)
                            )}
                        </Typography>
                    </Box>
                </div>
                <Box key={`endPipe-${campaign.campaignType}`} className={classes.pipe} />
            </div>
        );
    };

    return (
        <div
            className={
                isLoading ? classes.cardContainer : classNames(classes.cardContainerLoaded, classes.cardContainer)
            }
        >
            {cardData.map((campaign, index) => displayCard(campaign, index))}
        </div>
    );
}

SummaryDashboardCards.propTypes = {
    summaryData: PropTypes.arrayOf(
        PropTypes.shape({
            campaignType: PropTypes.string,
            campaigns: PropTypes.number,
            impressions: PropTypes.number,
            clicks: PropTypes.number,
            ctr: PropTypes.number,
            deliveredSpend: PropTypes.number,
            revenue: PropTypes.number,
            roas: PropTypes.number,
        }),
    ),
    category: PropTypes.string,
    visualOptions: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.string,
            unit: PropTypes.string,
        }),
    ),
    vehicleLabel: PropTypes.string,
    isLoading: PropTypes.bool,
};
