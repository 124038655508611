export const CREATE_CAMPAIGN_GROUP = 'portal/StackedCampaignsPage/CREATE_CAMPAIGN_GROUP';
export const CREATE_CAMPAIGN_GROUP_SUCCESS = 'portal/StackedCampaignsPage/CREATE_CAMPAIGN_GROUP_SUCCESS';
export const CREATE_CAMPAIGN_GROUP_ERROR = 'portal/StackedCampaignsPage/CREATE_CAMPAIGN_GROUP_ERROR';

export const DELETE_CAMPAIGN_GROUP = 'portal/StackedCampaignsPage/DELETE_CAMPAIGN_GROUP';
export const DELETE_CAMPAIGN_GROUP_SUCCESS = 'portal/StackedCampaignsPage/DELETE_CAMPAIGN_GROUP_SUCCESS';
export const DELETE_CAMPAIGN_GROUP_ERROR = 'portal/StackedCampaignsPage/DELETE_CAMPAIGN_GROUP_ERROR';

export const UPDATE_CAMPAIGN_GROUP = 'portal/StackedCampaignsPage/UPDATE_CAMPAIGN_GROUP';
export const UPDATE_CAMPAIGN_GROUP_SUCCESS = 'portal/StackedCampaignsPage/UPDATE_CAMPAIGN_GROUP_SUCCESS';
export const UPDATE_CAMPAIGN_GROUP_ERROR = 'portal/StackedCampaignsPage/UPDATE_CAMPAIGN_GROUP_ERROR';

export const GET_CAMPAIGN_GROUPS = 'portal/StackedCampaignsPage/GET_CAMPAIGN_GROUPS';
export const GET_CAMPAIGN_GROUPS_SUCCESS = 'portal/StackedCampaignsPage/GET_CAMPAIGN_GROUPS_SUCCESS';
export const GET_CAMPAIGN_GROUPS_ERROR = 'portal/StackedCampaignsPage/GET_CAMPAIGN_GROUPS_ERROR';

export const ADD_CAMPAIGNS_TO_GROUP = 'portal/StackedCampaignsPage/ADD_CAMPAIGNS_TO_GROUP';
export const ADD_CAMPAIGNS_TO_GROUP_SUCCESS = 'portal/StackedCampaignsPage/ADD_CAMPAIGNS_TO_GROUP_SUCCESS';
export const ADD_CAMPAIGNS_TO_GROUP_ERROR = 'portal/StackedCampaignsPage/ADD_CAMPAIGNS_TO_GROUP_ERROR';

export const REMOVE_CAMPAIGNS_FROM_GROUP = 'portal/StackedCampaignsPage/REMOVE_CAMPAIGNS_FROM_GROUP';
export const REMOVE_CAMPAIGNS_FROM_GROUP_SUCCESS = 'portal/StackedCampaignsPage/REMOVE_CAMPAIGNS_FROM_GROUP_SUCCESS';
export const REMOVE_CAMPAIGNS_FROM_GROUP_ERROR = 'portal/StackedCampaignsPage/REMOVE_CAMPAIGNS_FROM_GROUP_ERROR';

export const SHARE_CAMPAIGN_GROUP = 'portal/StackedCampaignsPage/SHARE_CAMPAIGN_GROUP';
export const SHARE_CAMPAIGN_GROUP_SUCCESS = 'portal/StackedCampaignsPage/SHARE_CAMPAIGN_GROUP_SUCCESS';
export const SHARE_CAMPAIGN_GROUP_ERROR = 'portal/StackedCampaignsPage/SHARE_CAMPAIGN_GROUP_ERROR';

export const GET_SHARED_CAMPAIGN_GROUPS = 'portal/StackedCampaignsPage/GET_SHARED_CAMPAIGN_GROUPS';
export const GET_SHARED_CAMPAIGN_GROUPS_SUCCESS = 'portal/StackedCampaignsPage/GET_SHARED_CAMPAIGN_GROUPS_SUCCESS';
export const GET_SHARED_CAMPAIGN_GROUPS_ERROR = 'portal/StackedCampaignsPage/GET_SHARED_CAMPAIGN_GROUPS_ERROR';

export const CHECK_CAMPAIGN_GROUP_NAME = 'portal/StackedCampaignsPage/CHECK_CAMPAIGN_GROUP_NAME';
export const CHECK_CAMPAIGN_GROUP_NAME_SUCCESS = 'portal/StackedCampaignsPage/CHECK_CAMPAIGN_GROUP_NAME_SUCCESS';
export const CHECK_CAMPAIGN_GROUP_NAME_ERROR = 'portal/StackedCampaignsPage/CHECK_CAMPAIGN_GROUP_NAME_ERROR';

export const RENAME_CAMPAIGN_GROUP = 'portal/StackedCampaignsPage/RENAME_CAMPAIGN_GROUP';
export const RENAME_CAMPAIGN_GROUP_SUCCESS = 'portal/StackedCampaignsPage/RENAME_CAMPAIGN_GROUP_SUCCESS';
export const RENAME_CAMPAIGN_GROUP_ERROR = 'portal/StackedCampaignsPage/RENAME_CAMPAIGN_GROUP_ERROR';

export const RESET_DONE = 'portal/StackedCampaignsPage/RESET_DONE';

export const SPONSORED_GROUP_TYPE = 'sponsored';
export const SPONSORED_RSX_GROUP_TYPE = 'sponsored-rsx';
export const SPONSORED_RMP_GROUP_TYPE = 'sponsored-rmp';
export const DISPLAY_GROUP_TYPE = 'display';
export const PAID_SEARCH_GROUP_TYPE = 'paid search';
export const BUILD_SPONSORED_RSX_GROUP_NAME = 'Build Sponsored';
export const BUILD_SPONSORED_RMP_GROUP_NAME = 'Build Sponsored Rmp';
export const BUILD_DISPLAY_GROUP_NAME = 'Build Display';
export const BUILD_ONSITE_DISPLAY_GROUP_NAME = 'Build Onsite Display';
export const BUILD_PAID_SEARCH_GROUP_NAME = 'Build Paid Search';
