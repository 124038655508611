import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog } from '@material-ui/core';
import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    formButton: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        margin: '0',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    formButtonCancel: {
        backgroundColor: theme.palette.battleGray,
        color: theme.palette.techBlack,
        '&:hover': {
            backgroundColor: theme.palette.borderGray,
        },
    },
    dialog: {
        padding: '8px 24px 24px 24px',
    },
    dialogButtonRemove: {
        color: '#fff',
        backgroundColor: theme.palette.destructive?.main,
        '&:hover': {
            backgroundColor: theme.palette.destructive?.hover,
        },
    },
    content: {
        marginRight: '60px',
        color: theme.palette.techBlack,
    },
    subHeader: {
        fontWeight: 'bold',
    },
}));

export const textConstants = {
    SHARE_TITLE: 'Share Campaigns',
    UNSHARE_TITLE: 'Remove Shared Campaigns',
    SHARE_CONTENT: 'Are you sure you want to share campaigns with this Org?',
    UNSHARE_CONTENT: 'This will remove shared campaigns from all users within the "Share To Org".',
    SHARE_BUTTON: 'Yes, Share Campaigns',
    UNSHARE_BUTTON: 'Yes, Remove Share',
};

export function CampaignsDialogBox(props) {
    const { open, cancel, submitValues, onSubmit, dialogBoxAction } = props;
    const classes = useStyles();

    const sharedFromOrg = submitValues?.sharedFrom?.name || '';
    const sharedToOrg = submitValues?.sharedTo?.name || '';
    const brandsList = submitValues?.brands?.map((b) => b.name).join(', ');

    return (
        <Dialog data-testid="share-campaigns-dialog-box" open={open} fullWidth maxWidth="sm">
            <DialogTitle data-testid="share-campaigns-dialog-box-title">
                {dialogBoxAction === 'share' ? `${textConstants.SHARE_TITLE}` : `${textConstants.UNSHARE_TITLE}`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText data-testid="share-campaigns-dialog-box-content" className={classes.content}>
                    {dialogBoxAction === 'share'
                        ? `${textConstants.SHARE_CONTENT}`
                        : `${textConstants.UNSHARE_CONTENT}`}
                </DialogContentText>
                <DialogContentText className={classnames(classes.subHeader, classes.content)}>
                    {submitValues ? `${sharedFromOrg} > ${brandsList} > ${sharedToOrg}` : ''}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialog}>
                <Button
                    data-testid="cancel-share-form-button"
                    className={classnames(classes.formButton, classes.formButtonCancel)}
                    onClick={cancel}
                >
                    <span>Cancel</span>
                </Button>
                <Button
                    data-testid="confirm-share-form-button"
                    className={classnames(
                        classes.formButton,
                        dialogBoxAction === 'unshare' ? classes.dialogButtonRemove : '',
                    )}
                    onClick={onSubmit}
                >
                    <span>
                        {dialogBoxAction === 'share'
                            ? `${textConstants.SHARE_BUTTON}`
                            : `${textConstants.UNSHARE_BUTTON}`}
                    </span>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

CampaignsDialogBox.propTypes = {
    cancel: PropTypes.func,
    open: PropTypes.bool,
    submitValues: PropTypes.shape({
        sharedFrom: PropTypes.shape({
            name: PropTypes.string,
        }),
        sharedTo: PropTypes.shape({
            name: PropTypes.string,
        }),
        brands: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            }),
        ),
    }),
    onSubmit: PropTypes.func,
    dialogBoxAction: PropTypes.string,
};
