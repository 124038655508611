/**
 *
 * DetailsChart
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
    VictoryVoronoiContainer,
    VictoryTheme,
    VictoryChart,
    VictoryLine,
    VictoryAxis,
    VictoryGroup,
    VictoryArea,
    VictoryScatter,
    VictoryTooltip,
} from 'victory';
import { makeStyles } from '@material-ui/core/styles';

import { nFormatter } from 'Helpers/string-formatter';

const useStyles = makeStyles(() => ({
    noData: {
        fontFamily: 'humanBBYDigital, sans-serif',
        textAlign: 'center',
        padding: '70px 0',
        color: '#70757D',
    },
}));

// Takes a date object created with new Date()
const formatDate = (dateObj, includeYears) =>
    includeYears
        ? `${dateObj.getUTCDate()} ${dateObj.toLocaleString('en-US', {
              month: 'short',
              timeZone: 'UTC',
          })} ${dateObj.getUTCFullYear()}`
        : `${dateObj.getUTCDate()} ${dateObj.toLocaleString('en-US', {
              month: 'short',
              timeZone: 'UTC',
          })}`;

function DetailsChart({ data, category, loading, width, padding, styles, includeYears = true }) {
    const classes = useStyles();
    const formatXAxis = (tick /* , _index, _ticks */) => {
        const d = new Date(tick);

        return formatDate(d, includeYears);
    };

    const formatYAxis = (amount /* _index, _ticks */) => {
        if (Number.isNaN(amount)) {
            return '';
        }

        if (data && data.units[category] === 'string') {
            return amount ? `${amount}` : '';
        }

        if (data && data.units[category] === 'currency') {
            return amount > 0 ? `$${nFormatter(amount, 2)}` : '';
        }

        if (data && (data.units[category] === 'percent' || data.units[category] === 'percentage')) {
            return amount ? `${amount}%` : '';
        }

        return amount ? `${nFormatter(amount, 2)}` : '';
    };

    const formatLabel = ({ datum }) => {
        const value = datum[category];
        const { date } = datum;

        const dateObject = new Date(date);
        const formattedDate = formatDate(dateObject);
        const formattedValue = value && nFormatter(value, 2);

        // Check if the type of chart is relevant to currency
        // Return with '$' prefix in the label value
        if (data.units[category] === 'currency') {
            return `${formattedDate}\n\n$${formattedValue}`;
        }

        // Return without '$' prefix in the label value
        return `${formattedDate}\n\n${formattedValue}`;
    };

    const renderContainer = () => (
        <VictoryVoronoiContainer
            voronoiBlacklist={['area']}
            labels={formatLabel}
            labelComponent={
                <VictoryTooltip
                    flyoutStyle={{
                        fill: 'white',
                        stroke: '#C5CBD5',
                        strokeWidth: 1,
                    }}
                    flyoutPadding={8}
                    cornerRadius={6}
                    style={[
                        { fontSize: 8, fontFamily: 'humanBBYDigital', lineHeight: 1 },
                        { fontSize: 0, fill: 'white' }, // because it uses tspan svgs this is a hack to provide padding between the first and second line
                        { fontSize: 12, fontFamily: 'humanBBYDigital', lineHeight: 1 },
                    ]}
                />
            }
        />
    );

    return (
        <div>
            {data && data.totalRows !== 0 && !loading ? (
                <VictoryChart
                    padding={padding}
                    width={width}
                    theme={VictoryTheme.material}
                    containerComponent={renderContainer()}
                >
                    {/* X AXIS START */}
                    <VictoryAxis tickFormat={formatXAxis} style={styles.xAxis} fixLabelOverlap />
                    {/* X AXIS END */}

                    {/* Y AXIS START */}
                    <VictoryAxis
                        dependentAxis
                        style={styles.yAxis}
                        tickFormat={formatYAxis}
                        theme={VictoryTheme.material}
                        // label={`${DATA.metric}`}
                    />
                    {/* Y AXIS END */}
                    {
                        <VictoryGroup>
                            {/* LINE IN CHART START */}
                            <VictoryLine style={styles.line} data={data && data.rows} x="date" y={category} />
                            {/* LINE IN CHART END */}
                            {data.rows.length === 1 && (
                                <VictoryScatter
                                    name="area"
                                    style={styles.scatter}
                                    size={5}
                                    data={data && data.rows}
                                    x="date"
                                    y={category}
                                />
                            )}
                            {/* AREA IN CHART START */}
                            <VictoryArea
                                name="area"
                                style={styles.area}
                                data={data && data.rows}
                                x="date"
                                y={category}
                            />
                            {/* AREA IN CHART END */}
                        </VictoryGroup>
                    }
                </VictoryChart>
            ) : (
                <div className={classes.noData}> No Data Available</div>
            )}
        </div>
    );
}

DetailsChart.defaultProps = {
    data: null,
};

DetailsChart.propTypes = {
    data: PropTypes.shape({
        units: PropTypes.object,
        rows: PropTypes.array,
        totalRows: PropTypes.number,
    }),
    category: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    width: PropTypes.number,
    padding: PropTypes.number,
    styles: PropTypes.object,
    includeYears: PropTypes.bool,
};

export default memo(DetailsChart);
