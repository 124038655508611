import { takeLatest, call, put, select } from 'redux-saga/effects';
import saveAs from 'file-saver';

import { makeSelectAllFilters } from 'containers/ColumnFilter/selectors';
import { selectFeatureFlags } from 'utils/featureFlags/selectors';

import { generateFileName } from 'Helpers/helpers';
import { Vehicle } from 'vehicles';
import { base64ToBlob } from 'utils/data';

import { downloadReportSuccess, downloadReportError, downloadReportStart } from './slice';

/**
 * Triggered by action DOWNLOAD_REPORT_START
 * downloads the xlsx report based on filter parameters given from redux store
 *
 * @returns {object}      generator
 */
export function* downloadReport({ payload: { campaign, startDate, endDate, viewability, raw } }) {
    const groupBy = 'raw';

    try {
        let { customFileName } = campaign;
        if (raw) {
            customFileName += ' - raw';
        }

        const flags = yield select(selectFeatureFlags);
        const filters = yield select(makeSelectAllFilters());

        // convert boolean to actual parameter
        const viewabilityType = viewability ? 'active' : 'default';

        const vehicle = Vehicle.getFromCampaignData(campaign);

        const vehicleKey = vehicle.getVehicleKey();

        const sheets = vehicle.getSheets();
        const sheetsWithFilters = [];

        sheets.forEach(({ type, filtersName }) => {
            let columnsToShow;
            if (filtersName) {
                const columns = vehicle.getColumns();
                columnsToShow = columns[filtersName].filter(
                    (field) => filters[vehicleKey][filtersName].indexOf(field) !== -1,
                );
            }

            const reportOpts = {
                type,
                columnsToShow,
            };

            sheetsWithFilters.push(reportOpts);
        });
        const sheetsPayload = {
            sheets: sheetsWithFilters,
        };

        const reportData = yield call(
            vehicle.downloadReport,
            campaign,
            startDate,
            endDate,
            sheetsPayload,
            flags,
            groupBy,
            viewabilityType,
            raw,
        );

        const {
            payload: { byteArray },
        } = reportData;

        // application/vnd.ms-excel is the standard MIME type for an Excel document
        const file = base64ToBlob('application/vnd.ms-excel', byteArray);

        const fileName = yield call(generateFileName, customFileName);

        yield call(saveAs, file, fileName);
        yield put(downloadReportSuccess());
    } catch (error) {
        yield put(downloadReportError(error));
    }
}

// Individual exports for testing
export default function* campaignReportingPageSaga() {
    yield takeLatest(downloadReportStart, downloadReport);
}
