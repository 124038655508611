/*
 *
 * IdamLoginSuccessPage reducer
 *
 */
import produce from 'immer';

import { IDAM_POST_LOGIN_ERROR, IDAM_POST_LOGIN_SUCCESS } from './constants';

export const initialState = {
    error: null,
};

/* eslint-disable default-case, no-param-reassign */
const idamLoginSuccessPageReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case IDAM_POST_LOGIN_SUCCESS:
                draft.error = null;
                break;
            case IDAM_POST_LOGIN_ERROR:
                draft.error = action.error;
                break;
        }
    });

export default idamLoginSuccessPageReducer;
