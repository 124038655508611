export const onsiteVideoColumns = {
    creative: [
        'order_name',
        'creative_name',
        'num_impressions',
        'num_clicks',
        'ctr',
        'ad_spend',
        'pdp_page_views',
        'cart_additions',
        'revenue_online',
        'revenue_instore',
        // Total Revenue
        'revenue_total',
        'online_roas',
        'instore_roas',
        // Total Roas
        'total_roas',
        'online_roas_percent',
        'instore_roas_percent',
        // Total Roas Percent
        'total_roas_percent',
        'units_online',
        'units_instore',
        // Total Units
        'units_total',
        'click_revenue_online',
        'click_revenue_instore',
        // Click-through Total Revenue
        'click_revenue_total',
        'click_online_roas',
        'click_instore_roas',
        // Click-through Total Roas
        'click_total_roas',
        'click_online_roas_percent',
        'click_instore_roas_percent',
        // Click-through Total Roas Percent
        'click_total_roas_percent',
        'click_units_online',
        'click_units_instore',
        // Click-through Total Units
        'click_units_total',
    ],
    placement: [
        'order_name',
        'placement_name',
        'num_impressions',
        'num_clicks',
        'ctr',
        'ad_spend',
        'pdp_page_views',
        'cart_additions',
        'revenue_online',
        'revenue_instore',
        'revenue_total',
        'online_roas',
        'instore_roas',
        'total_roas',
        'online_roas_percent',
        'instore_roas_percent',
        'total_roas_percent',
        'units_online',
        'units_instore',
        'units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_online_roas',
        'click_instore_roas',
        'click_total_roas',
        'click_online_roas_percent',
        'click_instore_roas_percent',
        'click_total_roas_percent',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
    ],
    product: [
        'sku_id',
        'sku_description',
        'brand',
        'dept_id',
        'dept_name',
        'class_id',
        'class_name',
        'subclass_id',
        'subclass_name',
        'pdp_page_views',
        'cart_additions',
        'revenue_online',
        'revenue_instore',
        'revenue_total',
        'units_online',
        'units_instore',
        'units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
    ],
};

export const defaultColumns = {
    creative: [
        'creative_name',
        'num_impressions',
        'num_clicks',
        'ctr',
        'ad_spend',
        'pdp_page_views',
        'cart_additions',
        'revenue_online',
        'revenue_instore',
        'revenue_total',
        'online_roas',
        'instore_roas',
        'total_roas',
        'units_online',
        'units_instore',
        'units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_online_roas',
        'click_instore_roas',
        'click_total_roas',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
    ],
    placement: [
        'placement_name',
        'num_impressions',
        'num_clicks',
        'ctr',
        'ad_spend',
        'pdp_page_views',
        'cart_additions',
        'revenue_online',
        'revenue_instore',
        'revenue_total',
        'online_roas',
        'instore_roas',
        'total_roas',
        'units_online',
        'units_instore',
        'units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_online_roas',
        'click_instore_roas',
        'click_total_roas',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
    ],
    product: [
        'sku_id',
        'sku_description',
        'pdp_page_views',
        'cart_additions',
        'revenue_online',
        'revenue_instore',
        'revenue_total',
        'units_online',
        'units_instore',
        'units_total',
        'click_revenue_online',
        'click_revenue_instore',
        'click_revenue_total',
        'click_units_online',
        'click_units_instore',
        'click_units_total',
    ],
};

export const modifyColumns = {
    creative: [
        'num_impressions',
        'num_clicks',
        'ctr',
        'ad_spend',
        'pdp_page_views',
        'cart_additions',
        'view_total',
        'view_online',
        'view_instore',
        'click_total',
        'click_online',
        'click_instore',
    ],
    placement: [
        'num_impressions',
        'num_clicks',
        'ctr',
        'ad_spend',
        'pdp_page_views',
        'cart_additions',
        'view_total',
        'view_online',
        'view_instore',
        'click_total',
        'click_online',
        'click_instore',
    ],
    product: [
        'sku_description',
        'brand',
        'dept_id',
        'dept_name',
        'class_id',
        'class_name',
        'subclass_id',
        'subclass_name',
        'pdp_page_views',
        'cart_additions',
        'product_view_total',
        'product_view_online',
        'product_view_instore',
        'product_click_total',
        'product_click_online',
        'product_click_instore',
    ],
};

export const columnMap = {
    order_name: {
        label: 'Campaign Name',
        type: 'description',
        field: 'order_name',
        sortable: true,
        lineBreak: false,
    },
    creative_name: {
        label: 'Creative Name',
        type: 'description',
        field: 'creative_name',
        sortable: true,
        lineBreak: false,
    },
    num_impressions: {
        label: 'Impressions',
        type: 'number',
        field: 'num_impressions',
        sortable: true,
        lineBreak: false,
    },
    ad_spend: {
        label: 'Delivered Spend',
        type: 'currency',
        field: 'ad_spend',
        sortable: true,
        lineBreak: false,
    },
    num_clicks: {
        label: 'Clicks',
        type: 'number',
        field: 'num_clicks',
        sortable: true,
        lineBreak: false,
    },
    ctr: {
        label: 'CTR',
        type: 'percent_decimal',
        field: 'ctr',
        sortable: true,
        lineBreak: false,
    },
    pdp_page_views: {
        label: 'PDP Views',
        type: 'decimal',
        field: 'pdp_page_views',
        sortable: true,
        lineBreak: false,
    },
    cart_additions: {
        label: 'Add to Cart',
        type: 'decimal',
        field: 'cart_additions',
        sortable: true,
        lineBreak: false,
    },
    revenue_group: {
        label: 'Revenue',
        type: 'currency',
        field: 'revenue',
        sortable: true,
        lineBreak: false,

        child: {
            click_instore: 'click_revenue_instore',
            click_online: 'click_revenue_online',
            view_through_online: 'revenue_online',
            view_through_instore: 'revenue_instore',
        },
    },
    revenue_instore: {
        label: 'View-through In Store Revenue',
        type: 'currency',
        field: 'revenue_instore',
        sortable: true,
        lineBreak: false,
    },
    revenue_online: {
        label: 'View-through Online Revenue',
        type: 'currency',
        field: 'revenue_online',
        sortable: true,
        lineBreak: false,
    },
    click_revenue_online: {
        label: 'Click-through Online Revenue',
        type: 'currency',
        field: 'click_revenue_online',
        sortable: true,
        lineBreak: false,
    },
    click_revenue_instore: {
        label: 'Click-through In Store Revenue',
        type: 'currency',
        field: 'click_revenue_instore',
        sortable: true,
        lineBreak: false,
    },
    num_units: {
        label: 'Units',
        type: 'number',
        field: 'num_units',
        sortable: true,
        lineBreak: false,

        child: {
            click_instore: 'click_units_instore',
            click_online: 'click_units_online',
            view_through_online: 'units_online',
            view_through_instore: 'units_instore',
        },
    },
    click_units_instore: {
        label: 'Click-through In Store Units',
        type: 'decimal',
        field: 'click_units_instore',
        sortable: true,
        lineBreak: false,
    },
    click_units_online: {
        label: 'Click-through Online Units',
        type: 'decimal',
        field: 'click_units_online',
        sortable: true,
        lineBreak: false,
    },
    units_online: {
        label: 'View-through Online Units',
        type: 'decimal',
        field: 'units_online',
        sortable: true,
        lineBreak: false,
    },
    units_instore: {
        label: 'View-through In Store Units',
        type: 'decimal',
        field: 'units_instore',
        sortable: true,
        lineBreak: false,
    },
    roas_percent: {
        label: 'ROAS (%)',
        type: 'percent',
        field: 'roas_percent',
        sortable: true,
        lineBreak: false,

        child: {
            click_instore: 'click_instore_roas_percent',
            click_online: 'click_online_roas_percent',
            view_through_online: 'online_roas_percent',
            view_through_instore: 'instore_roas_percent',
        },
    },
    instore_roas_percent: {
        label: 'View-through In Store ROAS (%)',
        type: 'percent_decimal',
        field: 'instore_roas_percent',
        sortable: true,
        lineBreak: false,
    },
    online_roas_percent: {
        label: 'View-through Online ROAS (%)',
        type: 'percent_decimal',
        field: 'online_roas_percent',
        sortable: true,
        lineBreak: false,
    },
    click_roas_instore_percent: {
        label: 'Click-through In Store ROAS (%)',
        type: 'percent_decimal',
        field: 'click_roas_instore_percent',
        sortable: true,
        lineBreak: false,
    },
    click_roas_online_percent: {
        label: 'Click-through Online ROAS (%)',
        type: 'percent_decimal',
        field: 'click_roas_online_percent',
        sortable: true,
        lineBreak: false,
    },
    roas_group: {
        label: 'ROAS ($)',
        type: 'currency',
        field: 'roas',
        sortable: true,
        lineBreak: false,

        child: {
            click_instore: 'click_instore_roas',
            click_online: 'click_online_roas',
            view_through_online: 'online_roas',
            view_through_instore: 'instore_roas',
        },
    },
    instore_roas: {
        label: 'View-through In Store ROAS',
        type: 'currency',
        field: 'instore_roas',
        sortable: true,
        lineBreak: false,
    },
    online_roas: {
        label: 'View-through Online ROAS',
        type: 'currency',
        field: 'online_roas',
        sortable: true,
        lineBreak: false,
    },
    click_instore_roas: {
        label: 'Click-through In Store ROAS',
        type: 'currency',
        field: 'click_instore_roas',
        sortable: true,
        lineBreak: false,
    },
    click_online_roas: {
        label: 'Click-through Online ROAS',
        type: 'currency',
        field: 'click_online_roas',
        sortable: true,
        lineBreak: false,
    },
    placement_name: {
        label: 'Placement Name',
        type: 'description',
        field: 'placement_name',
        sortable: true,
        lineBreak: false,
    },
    sku_id: {
        label: 'SKU',
        type: 'string',
        field: 'sku_id',
        sortable: true,
        lineBreak: false,
    },
    sku_description: {
        label: 'Description',
        type: 'description',
        field: 'sku_description',
        sortable: true,
        lineBreak: false,
    },
    brand: {
        label: 'Brand',
        type: 'description',
        field: 'brand',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    dept_id: {
        label: 'Department ID',
        type: 'description',
        field: 'dept_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    dept_name: {
        label: 'Department Name',
        type: 'description',
        field: 'dept_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    class_id: {
        label: 'Class ID',
        type: 'description',
        field: 'class_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    class_name: {
        label: 'Class Name',
        type: 'description',
        field: 'class_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    subclass_id: {
        label: 'SubClass ID',
        type: 'description',
        field: 'subclass_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    subclass_name: {
        label: 'SubClass Name',
        type: 'description',
        field: 'subclass_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    click_online_roas_percent: {
        label: 'Click-through Online ROAS (%)',
        type: 'percent_decimal',
        field: 'click_online_roas_percent',
        sortable: true,
        lineBreak: false,
    },
    click_instore_roas_percent: {
        label: 'Click-through In Store ROAS (%)',
        type: 'percent_decimal',
        field: 'click_instore_roas_percent',
        sortable: true,
        lineBreak: false,
    },
    revenue_total: {
        label: 'View-through Total Revenue',
        type: 'currency',
        field: 'revenue_total',
        sortable: true,
        lineBreak: false,
    },
    total_roas: {
        label: 'View-through Total ROAS',
        type: 'currency',
        field: 'total_roas',
        sortable: true,
        lineBreak: false,
    },
    total_roas_percent: {
        label: 'View-through Total ROAS (%)',
        type: 'percent_decimal',
        field: 'total_roas_percent',
        sortable: true,
        lineBreak: false,
    },
    units_total: {
        label: 'View-through Total Units',
        type: 'decimal',
        field: 'units_total',
        sortable: true,
        lineBreak: false,
    },
    click_revenue_total: {
        label: 'Click-through Total Revenue',
        type: 'currency',
        field: 'click_revenue_total',
        sortable: true,
        lineBreak: false,
    },
    click_total_roas: {
        label: 'Click-through Total ROAS',
        type: 'currency',
        field: 'click_total_roas',
        sortable: true,
        lineBreak: false,
    },
    click_total_roas_percent: {
        label: 'Click-through Total ROAS (%)',
        type: 'percent_decimal',
        field: 'click_total_roas_percent',
        sortable: true,
        lineBreak: false,
    },
    click_units_total: {
        label: 'Click-through Total Units',
        type: 'decimal',
        field: 'click_units_total',
        sortable: true,
        lineBreak: false,
    },
};

export const columnFilterMap = {
    order_name: {
        label: 'Campaign Name',
        column: 'order_name',
    },
    creative_name: {
        label: 'Creative Name',
        column: 'creative_name',
    },
    num_impressions: {
        label: 'Impressions',
        column: 'num_impressions',
    },
    num_clicks: {
        label: 'Clicks',
        column: 'num_clicks',
    },
    ctr: {
        label: 'CTR',
        column: 'ctr',
    },
    ad_spend: {
        label: 'Delivered Spend',
        column: 'ad_spend',
    },
    pdp_page_views: {
        label: 'PDP Views',
        column: 'pdp_page_views',
    },
    cart_additions: {
        label: 'Add to Cart',
        column: 'cart_additions',
    },
    view_total: {
        label: 'VIEW-THROUGH TOTAL:',
        children: [
            {
                label: 'Units',
                column: 'units_total',
            },
            {
                label: 'Revenue',
                column: 'revenue_total',
            },
            {
                label: 'ROAS',
                column: 'total_roas',
            },
            {
                label: 'ROAS (%)',
                column: 'total_roas_percent',
            },
        ],
    },
    view_online: {
        label: 'Online',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'units_online',
            },
            {
                label: 'ROAS (%)',
                column: 'online_roas_percent',
            },
            {
                label: 'ROAS',
                column: 'online_roas',
            },
            {
                label: 'Revenue',
                column: 'revenue_online',
            },
        ],
    },
    view_instore: {
        label: 'In Store',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'units_instore',
            },
            {
                label: 'ROAS (%)',
                column: 'instore_roas_percent',
            },
            {
                label: 'ROAS',
                column: 'instore_roas',
            },
            {
                label: 'Revenue',
                column: 'revenue_instore',
            },
        ],
    },
    click_total: {
        label: 'CLICK-THROUGH TOTAL:',
        children: [
            {
                label: 'Units',
                column: 'click_units_total',
            },
            {
                label: 'Revenue',
                column: 'click_revenue_total',
            },
            {
                label: 'ROAS',
                column: 'click_total_roas',
            },
            {
                label: 'ROAS (%)',
                column: 'click_total_roas_percent',
            },
        ],
    },
    click_online: {
        label: 'Online',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'click_units_online',
            },
            {
                label: 'ROAS (%)',
                column: 'click_online_roas_percent',
            },
            {
                label: 'ROAS',
                column: 'click_online_roas',
            },
            {
                label: 'Revenue',
                column: 'click_revenue_online',
            },
        ],
    },
    click_instore: {
        label: 'In Store',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'click_units_instore',
            },
            {
                label: 'ROAS (%)',
                column: 'click_instore_roas_percent',
            },
            {
                label: 'ROAS',
                column: 'click_instore_roas',
            },
            {
                label: 'Revenue',
                column: 'click_revenue_instore',
            },
        ],
    },
    sku_description: {
        label: 'Description',
        column: 'sku_description',
    },
    brand: {
        label: 'Brand',
        column: 'brand',
    },
    dept_id: {
        label: 'Department ID',
        column: 'dept_id',
    },
    dept_name: {
        label: 'Department Name',
        column: 'dept_name',
    },
    class_id: {
        label: 'Class ID',
        column: 'class_id',
    },
    class_name: {
        label: 'Class Name',
        column: 'class_name',
    },
    subclass_id: {
        label: 'SubClass ID',
        column: 'subclass_id',
    },
    subclass_name: {
        label: 'SubClass Name',
        column: 'subclass_name',
    },
    product_view_total: {
        label: 'VIEW-THROUGH TOTAL:',
        children: [
            {
                label: 'Units',
                column: 'units_total',
            },
            {
                label: 'Revenue',
                column: 'revenue_total',
            },
        ],
    },
    product_view_online: {
        label: 'Online',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'units_online',
            },
            {
                label: 'Revenue',
                column: 'revenue_online',
            },
        ],
    },
    product_view_instore: {
        label: 'In Store',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'units_instore',
            },
            {
                label: 'Revenue',
                column: 'revenue_instore',
            },
        ],
    },
    product_click_total: {
        label: 'CLICK-THROUGH TOTAL:',
        children: [
            {
                label: 'Units',
                column: 'click_units_total',
            },
            {
                label: 'Revenue',
                column: 'click_revenue_total',
            },
        ],
    },
    product_click_online: {
        label: 'Online',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'click_units_online',
            },
            {
                label: 'Revenue',
                column: 'click_revenue_online',
            },
        ],
    },
    product_click_instore: {
        label: 'In Store',
        vertical: true,
        children: [
            {
                label: 'Units',
                column: 'click_units_instore',
            },
            {
                label: 'Revenue',
                column: 'click_revenue_instore',
            },
        ],
    },
};
