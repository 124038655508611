// TODO: test file
export const sleep = (ms) =>
    new Promise((resolve) => {
        setTimeout(resolve, ms);
    });

export const required = (value) => (value ? undefined : 'Required');

export const isValidPhone = (value) =>
    value === undefined || value === '' || /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value)
        ? undefined
        : 'Phone number is not valid.';

export const isValidEmail = (value) =>
    /^([a-zA-Z0-9_\-.!#$%&*+/=?^{|}~'`]+)@([a-zA-Z0-9-.]+)\.([a-zA-Z]{2,})$/.test(value)
        ? undefined
        : 'Email is not valid.';

export const minLength = (min) => (value) =>
    value.length > min ? undefined : `Should be greater than ${min} characters.`;

// TODO: should merge with the above minLength soon
export const minLengthEqual = (min) => (value) =>
    value.length >= min ? undefined : `Should be at least ${min} characters.`;

export const minLengthNoWhitespace = (min) => (value) =>
    typeof value === 'string' && value.trim().length >= min
        ? undefined
        : `Should be equal or greater than ${min} characters.`;

export const maxLength = (max) => (value) =>
    value.length <= max ? undefined : `Should be equal or less than ${max} characters.`;

export const maxLengthNoWhitespace = (max) => (value) =>
    typeof value === 'string' && value.trim().length <= max
        ? undefined
        : `Should be equal or less than ${max} characters.`;

export const isNotBlank = (value) => (value.length > 0 ? undefined : 'This field cannot be blank.');

export const isNumeric = (value) => (!Number.isNaN(value) ? undefined : 'Please enter a numeric value.');

export const isNotWhitespace = (value) =>
    typeof value === 'string' && value.trim().length > 0 ? undefined : 'This field should not contain whitespace.';

export const isValidURL = (str) => {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d{0,9}\\d%!_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i',
    ); // fragment locator

    return pattern.test(str) ? undefined : 'Invalid url format';
};

export const composeValidators = (...validators) => (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

export const isValidBBYEmail = /^[a-zA-Z0-9._%+-]+@bestbuy.com$/;

export const isValidForName = (value) => {
    const pattern = /^[a-zA-Z',. -]*$/;

    return pattern.test(value) ? undefined : 'Please enter alphabetic letters.';
};

const aidPattern = /^\b[aA][0-9]{6,7}$/;
export const isValidAID = (value) =>
    aidPattern.test(value)
        ? undefined
        : 'Please enter a valid AID: starting with letter "A" and continue by 6 or 7 numbers.';

export const isValidPasswordForUserCreation = (value) => {
    const pattern = /^([a-zA-Z0-9@*#!$]{8,})$/;

    return pattern.test(value) ? undefined : 'Please enter a valid password: alphabetic letters, numbers, or @*#!$';
};

export const isValidWithOneCapitalCharacter = (value) =>
    atLeastOneCapitalRegex.test(value) ? undefined : 'Please use at least one capital character';

export const isValidWithOneLowerCaseCharacter = (value) =>
    atLeastOneLowerCaseRegex.test(value) ? undefined : 'Please use at least one lower case character';

export const isValidWithOneNumber = (value) =>
    atLeastOneNumberRegex.test(value) ? undefined : 'Please use at least one number';

export const isValidWithOneSpecialCharacter = (value) =>
    atLeastOneSpecialCharacterRegex.test(value) ? undefined : 'Please use at least one special character';

export const eightOrMoreRegex = /^.{8,15}$/;
export const atLeastEight = /^.{8,}$/;
export const atLeastOneCapitalRegex = /[A-Z]/;
export const atLeastOneLowerCaseRegex = /[a-z]/;
// export const atLeastOneSpecialCharacterRegex = /[$&+,:;=?@#|'<>.^*()%!-]/;  verbose special character list
export const atLeastOneSpecialCharacterRegex = /[@*#!$]/;
export const atLeastOneNumberRegex = /\d/;

const passcodeRegex = /^[0-9]{6,8}$/;
export const isValidCode = (code) => (passcodeRegex.test(code) ? undefined : 'Invalid Code');
