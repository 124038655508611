/*
 *
 * PasswordUpdatePage constants
 *
 */

export const PASSWORD_RESET = 'portal/IDAMPasswordUpdatePage/PASSWORD_RESET';
export const PASSWORD_RESET_INIT = 'portal/IDAMPasswordUpdatePage/PASSWORD_RESET_INIT';
export const PASSWORD_RESET_SUCCESS = 'portal/IDAMPasswordUpdatePage/PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'portal/IDAMPasswordUpdatePage/PASSWORD_RESET_ERROR';
