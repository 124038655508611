import { createTheme } from '@material-ui/core/styles';

const colors = {
    humanBlue: '#0046be',
    darkBlue: '#001e73',
    hoverBlue: '#f1f8ff',
    lightYellow: '#ffe000',
    darkYellow: '#ffce00',
    successGreen: '#309900',
    errorRed: '#f44336',
    lightRed: '#C93853',
    valueRed: '#bb0628',
    darkRed: '#960520',
    techBlack: '#1d252c',
    lightGray: '#70757d',
    battleGray: '#d2d8e2',
    grayScale: '#90959E',
    darkGray: '#55555a',
    leftRail: '#38383f',
    techWhite: '#e0e6ef',
    standardWhite: '#ffffff',
    bgWhite: '#f9fafa',
    darkGreen: '#308800',
    shadowGray: '#959aa2',
    borderGray: '#c5cbd5',
    loaderGreen: '#99ffff',
    whiteGray: 'rgba(197, 203, 213, 0.06)',
    activeGreen: '#318000',
    pauseYellow: '#e37222',
    skuRed: '#be0049',
    skuLightRed: 'rgba(190, 0, 73, 0.1)',
    skuGreen: '#318000',
    skuLightGreen: 'rgba(49, 128, 0, 0.12)',
    submitGrey: '#e0e6ef',
    borderGrey: '#e0e0e0',
    techYellow: '#fff200',
    labelGrey: '#9D9DA0',
    disabledYellow: '#AFA712',
    lightPink: '#FCF1F0',
    lightGreen: '#EDF7E5',
    brightGray: '#EBEBEC',
    lightBlue: '#EBF5FC',
};

export const theme = createTheme({
    palette: {
        primary: {
            main: colors.humanBlue,
        },
        secondary: {
            main: colors.darkYellow,
        },
        sidebar: '#F4F6F9',
        navbar: '#E5E5E5',
        destructive: {
            main: colors.valueRed,
            hover: colors.darkRed,
        },
        ...colors,
    },
    typography: {
        fontFamily: ['HumanBBYDigital', 'sans-serif'],
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        fontSize: 14,
        h2: {
            fontSize: 28,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 0.86,
            letterSpacing: 'normal',
            color: colors.techBlack,
        },
        h3: {
            fontSize: 24,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1,
            letterSpacing: 'normal',
            color: colors.techBlack,
        },
        h4: {
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: '19.65px',
            letterSpacing: 'normal',
            color: colors.techBlack,
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    overrides: {
        MuiListSubheader: {
            root: {
                fontFamily: ['HumanBBYDigital', 'sans-serif'],
                fontSize: 12,
                fontWeight: 500,
                fontStyle: 'normal',
                fontStretch: 'normal',
                color: colors.techWhite,
                paddingLeft: 20,
            },
            gutters: {
                paddingLeft: 20,
                paddingRight: 15,
            },
        },
        MuiListItemText: {
            root: {
                fontFamily: ['HumanBBYDigital', 'sans-serif'],
                fontSize: 14,
                fontStyle: 'normal',
                fontStretch: 'normal',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                '& .selectedColor': {
                    fontFamily: ['HumanBBYDigital', 'sans-serif'],
                    fontWeight: 500,
                    color: colors.darkYellow,
                },
            },
        },
        MuiTableSortLabel: {
            root: {
                color: colors.techBlack,
                fontFamily: ['HumanBBYDigital', 'sans-serif'],
                '& h3': {
                    fontWeight: 500,
                    fontSize: 14,
                    letterSpacing: 'normal',
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                },
                width: '100%',
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: colors.standardWhite,
                border: `1px solid ${colors.techWhite}`,
                boxShadow: `2px 2px 4px 0 ${colors.techWhite}`,
                color: colors.techBlack,
                fontWeight: 'bold',
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: colors.leftRail,
                opacity: '0.4 !important',
            },
        },
        MuiFormControlLabel: {
            label: {
                padding: '5px 0px',
                color: '#1d252c',
                fontFamily: ['HumanBBYDigital', 'sans-serif'],
                fontWeight: 400,
                fontStretch: 'normal',
                letterSpacing: 'normal',
                fontSize: 15,
            },
        },
    },
});
