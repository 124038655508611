import PropTypes from 'prop-types';
import { tableFormatPropType } from './utils';

export const ColumnTypes = [
    'string',
    'number',
    'integer',
    'decimal',
    'boolean',
    'currency',
    'percent',
    'percent_decimal',
    'date',
    'description',
    'view_link',
];

export const Aligns = ['inherit', 'left', 'center', 'right', 'justify'];

export const ColumnPropType = {
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    type: PropTypes.oneOf(ColumnTypes),
    align: PropTypes.oneOf(Aligns),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minWidth: PropTypes.number,
    lineBreak: PropTypes.bool,
    sortable: PropTypes.bool,
    filterable: PropTypes.bool,
    visible: PropTypes.bool,
    editable: PropTypes.bool,
    format: tableFormatPropType,
};
