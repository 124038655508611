import { createSelector } from 'reselect';
import { makeSelectCampaignsHashmap } from 'containers/App/selectors';
import { initialState } from './slice';
import { getCampaignsFromIds } from '../StackedCampaignsPage/utils';

/**
 * Direct selector to the recentViewedPage state domain
 */

// amount of recent viewed for sidebar
const NUM_RECENT_VIEWED_SIDEBAR = 10;

const selectRecentViewedPageDomain = (state) => state.recentViewedPage || initialState;

/**
 * Default selector used by RecentViewedPages
 *
 * @returns {function} OutputSelector
 */
const makeSelectRecentViewList = () =>
    createSelector(selectRecentViewedPageDomain, (substate) => substate.recentViewedList);

const makeSelectRecentViewedTruncated = () =>
    createSelector(selectRecentViewedPageDomain, (substate) =>
        substate.recentViewedPages
            .slice(0, NUM_RECENT_VIEWED_SIDEBAR)
            .filter((campaign) => campaign.campaign_id && campaign.status),
    );
const makeSelectRecentViewedPages = () =>
    createSelector(makeSelectRecentViewList(), makeSelectCampaignsHashmap(), (recentViewedList, campaignHash) => {
        const campaignIds = recentViewedList || [];
        return getCampaignsFromIds(campaignIds, campaignHash);
    });

const selectRecentViewedSortOrder = createSelector(selectRecentViewedPageDomain, (substate) => substate.sortOrder);

const selectRecentViewedList = createSelector(selectRecentViewedPageDomain, (substate) => substate.recentViewedList);

const selectRecentViewedListLoadState = createSelector(
    selectRecentViewedPageDomain,
    (substate) => substate.recentViewedListLoadState,
);
const selectRecentViewedListError = createSelector(
    selectRecentViewedPageDomain,
    (substate) => substate.recentViewedListLoadState,
);

const selectPageNumber = createSelector(selectRecentViewedPageDomain, (substate) => substate.pageNumber || 0);

export default makeSelectRecentViewList;
export {
    selectRecentViewedPageDomain,
    makeSelectRecentViewedPages,
    makeSelectRecentViewedTruncated,
    makeSelectRecentViewList,
    selectRecentViewedListLoadState,
    selectRecentViewedListError,
    selectRecentViewedList,
    selectRecentViewedSortOrder,
    selectPageNumber,
};
