import moment from 'moment-timezone';
import { DEFAULT_TIMEZONE } from 'shared/constants';

/**
 * Returns time fixed to the given minute specified honoring offset given.
 * If no date parameter is given it will return the current time IN UTC.
 *
 * @param {number} minute minute interval for current time to be fixed to
 * @param {object} datetime can add custom time to set time to
 * @example <caption>Example usage of formatCurrentTimeToMinute.</caption>
 *  // returns "2020-07-27T21:59:19.177Z"
 * changeDateTimeMinute(59)
 *
 * @example <caption>Example usage of formatCurrentTimeToMinute.</caption>
 * // returns "2020-07-27T21:42:14.000Z"
 * changeDateTimeMinute(42, "Mon Jul 27 2020 14:30:14 GMT-0700 (Pacific Daylight Time)")
 *
 *  @example <caption>Example usage of formatCurrentTimeToMinute error.</caption>
 *  // returns null
 * changeDateTimeMinute(12, 'asdfasdf')
 *
 * @returns {string}    iso string of date
 */
export const changeDateTimeMinute = (minute, datetime) => {
    let formatted;

    if (datetime) {
        formatted = moment(datetime, moment.ISO_8601);
    }

    // convert into moment obj with offset preserved
    const momentDateTime = moment.parseZone(formatted);

    if (!moment.isMoment(momentDateTime) || !momentDateTime.isValid()) {
        return null;
    }

    // change minutes and format to iso string
    const fixedDate = momentDateTime.minute(minute).format();

    return fixedDate;
};

/**
 * Returns time fixed to the given minute specified.
 * If no date parameter is given it will return the current time IN UTC with EST/EDT offset.
 *
 * @param {object} datetime <optional> default: current time - MOMENT OBJECT- can add custom time to set time to
 * @param {string} timezone <optional> default: America/Chicago - timezone to change date to
 * @example <caption>Example usage of formatCurrentTimeToMinute.</caption>
 *  // returns "2020-08-07T19:10:04-04:00"
 * changeOffset('2020-08-07T19:10:04-07:00', 'America/Chicago')
 *
 *  @example <caption>Example usage of formatCurrentTimeToMinute error.</caption>
 *  // returns null
 * changeOffset('asdfasdf', 'asdfasdf')
 *
 * @returns {string}    iso string of date
 */
export const changeOffset = (datetime, timezone = DEFAULT_TIMEZONE) => {
    let paramFormatted;

    if (datetime) {
        paramFormatted = moment(datetime, moment.ISO_8601);
    }

    // Parse datetime passed in perserving offset
    const mDT = moment.parseZone(paramFormatted);

    if (!moment.isMoment(mDT) || !mDT.isValid()) {
        return null;
    }

    // Drop offset
    const formatted = mDT.format('YYYY-MM-DDTHH:mm:ss.SSS');

    // Set / format new offset while preserving exact time
    const tzDT = moment.tz(formatted, moment.ISO_8601, timezone).format();

    return tzDT;
};

/**
 * Returns date time to the specified timezone. If no timezone parameter given default to America/Chicago timezone (EST/EDT)
 *
 * @param {object} datetime date to be translated to timezone
 * @param {object} timezone specific timezone to get abbreviation
 * @example <caption>Example usage of convertToTimezone.</caption>
 *  // returns 2020-08-04T16:22:47-04:00
 * convertToTimezone('2020-08-04T20:22:47.130Z', 'America/Chicago')
 *
 * @example <caption>Example usage of formatDateTimeToTimezone error.</caption>
 *  // returns null
 * convertToTimezone('invalid', 'asdfasdf')
 *
 * @returns {string}    string of date
 */
export const convertToTimezone = (datetime, timezone = DEFAULT_TIMEZONE) => {
    let formatted;

    if (datetime) {
        formatted = moment(datetime, moment.ISO_8601);
    }

    const momentDateTime = moment.parseZone(formatted);

    if (!moment.isMoment(momentDateTime) || !momentDateTime.isValid()) {
        return null;
    }

    const tzDateTime = momentDateTime.tz(timezone).format();

    return tzDateTime;
};

/**
 * Returns date time to the specified timezone abbriviation. If no parameters given default to current time / America/Chicago timezone (EST/EDT)
 *
 * @param {object} datetime date to be translated to timezone abbrivation
 * @param {object} timezone specific timezone to get abbreviation
 * @example <caption>Example usage of formatDateTimeToTimezoneAbbr.</caption>
 *  // returns EST / EDT (depending on current date)
 * formatDateTimeToTimezoneAbbr(new Date(), America/Chicago)
 *
 * @example <caption>Example usage of formatDateTimeToTimezoneAbbr error.</caption>
 *  // returns null
 * formatDateTimeToTimezoneAbbr('invalid', 'asdfasdf')
 *
 * @returns {string}    iso string of date
 */
export const formatDateTimeToTimezoneAbbr = (datetime, timezone = DEFAULT_TIMEZONE) => {
    let formatted;

    if (datetime) {
        formatted = moment(datetime, moment.ISO_8601);
    }

    const momentDateTime = moment.parseZone(formatted);

    if (!moment.isMoment(momentDateTime) || !momentDateTime.isValid()) {
        return null;
    }

    const abbr = momentDateTime.tz(timezone).zoneAbbr();

    return abbr;
};

/**
 * Returns a bool whether a is before b ($1, $2)
 *
 * @param {string} start date to compare
 * @param {string} end date to compare
 *
 * @returns {bool}    whether start is before end
 */
export const checkDateBefore = (start, end) => {
    let startFormatted;
    let endFormatted;

    if (start && end) {
        startFormatted = moment(start, moment.ISO_8601);
        endFormatted = moment(end, moment.ISO_8601);
    }

    const mStart = moment.parseZone(startFormatted);
    const mEnd = moment.parseZone(endFormatted);

    return mStart.isBefore(mEnd);
};

/**
 * Returns a bool whether a is in the future relative to current time in timezone specfied
 *
 * @param {string} date date to compare
 * @param {string} timezone timezone to compare date against
 *
 * @returns {bool}    whether date is in future
 */
export const dateIsFuture = (date, timezone = DEFAULT_TIMEZONE) => {
    let formatted;

    if (date) {
        formatted = moment(date, moment.ISO_8601);
    }

    const currentTime = moment().tz(timezone);
    const isFuture = currentTime.isBefore(formatted);

    return isFuture;
};

export const optimizeDatesForReport = (selectedStartDate, selectedEndDate, campaign) => {
    const dates = {};

    const selectedStartDateFormatted = new Date(selectedStartDate);
    const selectedEndDateFormatted = new Date(selectedEndDate);

    const campaignStartDate = new Date(campaign.startDate);
    const campaignEndDate = new Date(campaign.endDate);

    // if selected start date is before campaign date use campaign date
    if (selectedStartDateFormatted < campaignStartDate) {
        dates.startDate = campaign.startDate;
    } else {
        dates.startDate = selectedStartDate;
    }

    // if selected end date after campaign date use campaign date
    if (selectedEndDateFormatted > campaignEndDate) {
        dates.endDate = campaign.endDate;
    } else {
        dates.endDate = selectedEndDate;
    }

    return dates;
};
