import request, {
    formatPostOptions as postOptions,
    formatGetOptions as getOptions,
    formatPutOptions as putOptions,
    formatDeleteOptions as deleteOptions,
} from 'utils/request';
import { compact } from 'lodash';
import { DEFAULT_WATCH_NAME } from 'containers/StackedCampaignsPage/WatchCampaignsTab/constants';
import constants from 'utils/constants';
import { formatBrandData, organizationFieldsMap, userFieldsMapV2, filterMap } from './api-service/maps';
import { BASE_URL_V1, BASE_URL_V2, BASE_URL_V3 } from './api-service/Url_Constants';

/**
 *
 *
 * @returns {object} login status ???
 */
export const userRefreshSession = async () => {
    const options = postOptions({});
    const reply = await request(`${BASE_URL_V1}/token/refresh`, options);
    return reply;
};

/**
 * Get all campaign group from given group type
 *
 * @param {string} groupType The vehicle of data
 *
 * @returns {object} response data or error based upon status
 */
export const getSharedCampaignGroupListApi = async (groupType = 'all') => {
    const options = getOptions();
    const data = await request(`${BASE_URL_V2}/grouped/list?groupType=${groupType}&shared=true`, options);
    if (data && data.rows) {
        // TODO: Refactor
        // eslint-disable-next-line no-restricted-syntax
        for (const row of data.rows) {
            if (Array.isArray(row.campaignIds)) {
                row.campaignIds = compact(row.campaignIds);
            }
        }
    }

    return data;
};

/**
 * Get all campaign group from given user id and group type
 *
 * @param {string} userId user aid
 * @param {string} groupType The vehicle of data
 *
 * @returns {object} response data or error based upon status
 */
export const getCampaignGroupListByUserApi = async (userId, groupType = 'all') => {
    const options = getOptions();

    const data = await request(`${BASE_URL_V2}/grouped/userGroups?userId=${userId}&groupType=${groupType}`, options);

    if (data && data.rows) {
        data.rows = data.rows.reverse();
        // eslint-disable-next-line no-restricted-syntax
        for (const row of data.rows) {
            if (Array.isArray(row.campaignIds)) {
                row.campaignIds = compact(row.campaignIds);
            }
        }
    }

    return data;
};

/**
 * Create a campaign group
 *
 * @param {object}
 * {
 *  userId: string; user aid
 *  groupName: string group name
 *  groupType: string The vehicle of data to get i.e. 'all', 'display', 'sponsored', or 'proximity'
 *  campaignIds: string[]
 * }
 *
 * @returns {object} response data or error based upon status
 */
export const createCampaignGroupApi = async ({
    userId,
    groupName,
    groupType = constants.DISPLAY_GROUP_TYPE,
    campaignIds = [],
    shared = false,
}) => {
    const options = postOptions({
        userId,
        groupName,
        groupType,
        campaignIds,
        shared,
    });

    const data = await request(`${BASE_URL_V2}/grouped/createGroup`, options);

    if (data && data.campaignIds) {
        data.campaignIds = compact(data.campaignIds);
    }

    return data;
};

/**
 * Update a campaign group
 *
 * @param {object}
 * {
 *  groupId: string; group id
 *  userId: string; user aid
 *  groupName: string group name
 *  groupType: string The vehicle of data to get i.e. 'all', 'display', 'sponsored', or 'proximity'
 *  campaignIds: string[]
 * }
 *
 * @returns {object} response data or error based upon status
 */
export const updateCampaignGroupApi = async ({
    userId,
    groupId,
    groupName,
    groupType,
    campaignIds = [],
    shared = false,
}) => {
    const options = putOptions({
        userId,
        groupId,
        groupName,
        groupType,
        campaignIds,
        shared,
    });

    const data = await request(`${BASE_URL_V2}/grouped/updateGroup`, options);

    if (data && data.campaignIds) {
        data.campaignIds = compact(data.campaignIds);
    }

    return data;
};

/**
 * Delete a campaign group from given group id
 *
 * @param {string} groupId group id
 *
 * @returns {object} response data or error based upon status
 */
export const deleteCampaignGroupApi = (groupId) => {
    const options = deleteOptions();

    return request(`${BASE_URL_V2}/grouped/deleteGroup/${groupId}`, options);
};

export const checkCampaignGroupNameUniqueness = (groupName) => {
    const options = postOptions({ groupName });

    return request(`${BASE_URL_V2}/grouped/checkUniqueName`, options);
};

export const fetchSavedFilters = async (userId) => {
    const options = postOptions({
        userId,
    });

    const resp = await request(`${BASE_URL_V2}/filters/getFilters`, options);

    const { filters } = resp;
    const campaignFilters = filters.find(({ filter_id }) => filter_id === '@@DEFAULT');
    const summaryDashboardFilters = filters.find(({ filter_id }) => filter_id === '@@SUMMARY');

    const campaignFiltersformatted = filterMap(campaignFilters);
    const summaryDashboardFiltersFormatted = filterMap(summaryDashboardFilters);

    return {
        campaignFilters: campaignFiltersformatted,
        summaryDashboardFilters: summaryDashboardFiltersFormatted,
    };
};

export class FilterExistsError extends Error {
    constructor(filterName) {
        super(`Filter ${filterName} already exists.`);
    }
}

export const addFilter = async (userId, filterId, filter) => {
    const options = postOptions({
        userId,
        filter,
        filterId,
    });

    try {
        const resp = await request(`${BASE_URL_V2}/filters/addFilter`, options);
        if (resp.code === 409) {
            throw new FilterExistsError(filterId);
        }
        return resp;
    } catch (e) {
        if (e?.response?.status === 409) {
            throw new FilterExistsError(filterId);
        }
        throw e;
    }
};

export const updateFilter = (userId, filterId, filter) => {
    const options = postOptions({
        userId,
        filter,
        filterId,
    });

    return request(`${BASE_URL_V2}/filters/updateFilter`, options);
};

/**
 * Get all campaign watch from given user id
 *
 * @param {string} userId user aid
 *
 * @returns {object} response data or error based upon status
 */
export const getCampaignWatchListByUserApi = async (userId) => {
    const options = getOptions();

    const data = await request(`${BASE_URL_V1}/watch/userWatches?userId=${userId}`, options);

    if (data && data.rows) {
        // eslint-disable-next-line no-restricted-syntax
        for (const row of data.rows) {
            if (Array.isArray(row.campaignIds)) {
                row.campaignIds = compact(row.campaignIds);
            }
        }
    }

    return data;
};

/**
 * Create a campaign watch
 *
 * @param {object}
 * {
 *  userId: string; user id
 *  watchName: string watch name
 *  campaignIds: string[]
 * }
 *
 * @returns {object} response data or error based upon status
 */
export const createCampaignWatchApi = async ({
    userId,
    watchName = DEFAULT_WATCH_NAME,
    campaignIds = [],
    shared = false,
}) => {
    const options = postOptions({
        userId,
        watchName,
        campaignIds,
        shared,
    });

    const data = await request(`${BASE_URL_V1}/watch/createWatch`, options);

    if (data && data.campaignIds) {
        data.campaignIds = compact(data.campaignIds);
    }

    return data;
};

/**
 * Update a campaign watch
 *
 * @param {object}
 * {
 *  watchId: string; watch id
 *  userId: string; user aid
 *  watchName: string watch name
 *  campaignIds: string[]
 * }
 *
 * @returns {object} response data or error based upon status
 */
export const updateCampaignWatchApi = async ({ userId, watchId, watchName, campaignIds = [], shared = false }) => {
    const options = putOptions({
        userId,
        watchId,
        watchName,
        campaignIds,
        shared,
    });

    const data = await request(`${BASE_URL_V1}/watch/updateWatch`, options);

    if (data && data.campaignIds) {
        data.campaignIds = compact(data.campaignIds);
    }

    return data;
};

/**
 * Delete a campaign watch from given watch id
 *
 * @param {string} watchId watch id
 *
 * @returns {object} response data or error based upon status
 */
export const deleteCampaignWatchApi = (watchId) => {
    const options = deleteOptions();

    return request(`${BASE_URL_V1}/watch/deleteWatch/${watchId}`, options);
};

/**
 * check a campaign watch name is unique
 *
 * @param {string} watchName watch name
 *
 * @returns {object} response data or error based upon status
 */
export const checkCampaignWatchNameUniqueness = (watchName) => {
    const options = postOptions({ watchName });

    return request(`${BASE_URL_V1}/watch/checkUniqueName`, options);
};

/**
 * return a list of roles
 *
 *
 * @returns {object} response data or error based upon status
 */
export const getUserRoles = async () => {
    const options = getOptions();

    const resp = await request(`${BASE_URL_V3}/role/list`, options);

    return resp.payload;
};

export const getAccountBrandsApi = async () => {
    const options = getOptions();

    let brands;
    const data = await request(`${BASE_URL_V2}/accounts/brand/listAdminBrands`, options);
    if (Array.isArray(data.payload)) {
        brands = data.payload;
    } else {
        brands = data.payload?.orgBrands;
    }

    return formatBrandData(brands);
};

export const getUserInfo = async () => {
    const options = getOptions();

    const response = await request(`${BASE_URL_V2}/session/getUser`, options);

    return userFieldsMapV2(response);
};

export const fetchCSRFToken = () => {
    const options = getOptions();
    return request(`${BASE_URL_V1}/token/csrfToken`, options);
};

export const fetchOrganizations = async () => {
    const options = getOptions();
    const response = await request(`${BASE_URL_V2}/accounts/ui/listOrgs`, options);
    return response.payload.map((org) => organizationFieldsMap(org));
};

export const fetchAdminOrgs = async () => {
    const options = getOptions();

    const response = await request(`${BASE_URL_V2}/accounts/ui/listOrgsAndBrands`, options);

    return response.payload.map((org) => ({
        id: org.org_id,
        name: org.organization_name,
        type: org.type,
        brands: org.brands.map((brand) => ({
            id: brand.brand_id,
            name: brand.name,
        })),
    }));
};

export const shareOrgBrands = async ({ sharedFrom, sharedTo, brands }) => {
    const payload = {
        sharedToOrgId: sharedTo.value,
        sharedFromOrgId: sharedFrom.orgId,
        brandIds: brands.map((brand) => brand.value),
    };

    const options = postOptions(payload);

    return request(`${BASE_URL_V2}/accounts/ui/shareBrandsById`, options);
};

export const removeShareOrgBrands = async (sharedData) => {
    const { brands, sharedFrom, sharedTo } = sharedData;
    const payload = {
        sharedToOrgId: sharedTo.orgId,
        sharedFromOrgId: sharedFrom.orgId,
        brandId: brands[0].brandId,
    };
    const options = postOptions(payload);
    return request(`${BASE_URL_V2}/accounts/ui/deactivateSharedBrandById`, options);
};
