import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import BestBuyAdsLogoLink from 'components/@shared/BestBuyLogoLink/BestBuyAdsLogoLink';

const contactUsUrl = 'https://www.bestbuyads.com/contact.html';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
        height: '100%',
        backgroundColor: theme.palette.techBlack,
        overflowX: 'scroll',
    },
    headerLogin: {
        margin: '0px auto',
        display: 'flex',
        alignItems: 'center',
        maxWidth: 1170,
        paddingTop: 24,
        paddingBottom: 8,
        paddingLeft: 20,
        paddingRight: 20,
    },
    headerLogo: {
        position: 'relative',
        top: 8,
        display: ' block',
        fontWeight: 400,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        fontFamily: theme.typography.fontFamily,
    },
    headerImage: {
        height: 32,
        objectFit: 'contain',
    },
    headerItem: {
        height: 'fit-content',
        cursor: 'pointer',
        textAlign: 'right',
        '& span': {
            '& a': {
                outline: 'none',
                color: theme.palette.standardWhite,
                textDecoration: 'none',
                fontFamily: theme.typography.fontFamily,
                fontWeight: 700,
                fontSize: 18,
                height: 17,
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                textAlign: 'right',
                bottom: 3,
                position: 'relative',
                '&:hover': {
                    color: theme.palette.darkYellow,
                },
            },
        },
        '& span:last-child': {
            paddingLeft: '34px',
        },
    },
    bodyContainer: {
        display: 'flex',
        margin: '0px auto',
        height: '100%',
        maxWidth: 1170,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: '150px',
        '@media (max-width: 768px)': {
            display: 'block',
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
    LoginBodyLeft: {
        display: 'block',
        margin: '0px auto',
        textAlign: 'left',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '75px',
        letterSpacing: 'normal',
        paddingRight: '20px',
        '@media (max-width: 768px)': {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
    LoginBodyRight: {
        margin: '0px auto',
        alignItems: 'center',
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        '@media (max-width: 768px)': {
            paddingTop: '50px',
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
    formTitleLine: {
        display: 'block',
    },
    formTitle: {
        width: 'auto',
        position: 'relative',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        fontSize: 88,
        margin: 0,
        textAlign: 'left',
        display: 'inline',
    },
    standardWhite: {
        color: theme.palette.standardWhite,
    },
    techYellow: {
        color: theme.palette.techYellow,
    },
}));

const Login = (props) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} className={classes.headerLogin}>
                <Grid item xs={6}>
                    <div className={classes.headerLogo}>
                        <BestBuyAdsLogoLink />
                    </div>
                </Grid>
                <Grid item xs={6} className={classes.headerItem}>
                    <span>
                        <a rel="noopener" target="_blank" href={contactUsUrl}>
                            Contact Us
                        </a>
                    </span>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.bodyContainer}>
                <Grid item xs={12}>
                    <div className={classes.LoginBodyLeft}>
                        <span className={classes.formTitleLine}>
                            <div className={`${classes.formTitle} ${classes.standardWhite}`}>My</div>
                            <div className={`${classes.formTitle} ${classes.techYellow}`}>.</div>
                            <div className={`${classes.formTitle} ${classes.standardWhite}`}>Ads</div>
                        </span>
                        <div className={`${classes.formTitle} ${classes.standardWhite}`}>sign in.</div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.LoginBodyRight}>{props.children}</div>
                </Grid>
            </Grid>
        </Grid>
    );
};

Login.propTypes = {
    children: PropTypes.element.isRequired,
};

export default Login;
