export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const NOTICES = {
    CREATE_WATCH_SUCCESS: 'Watch has been successfully created!',
    CREATE_WATCH_ERROR: 'Watch has failed to create!',
    ADD_TO_WATCH_SUCCESS: 'Campaign has been successfully added to watching!',
    ADD_TO_WATCH_ERROR: 'Campaign has failed to add to watching!',
    ADD_TO_WATCH_MAX_ERROR: 'Watch wasn’t created. You have reached the maximum allowed limit.',
    REMOVE_FROM_WATCH_SUCCESS: 'Campaign has been successfully removed from watch!',
    REMOVE_FROM_WATCH_ERROR: 'Campaign has failed to remove!',

    CREATE_STACK_REPORT_SUCCESS: 'Stacked report has been successfully created to My Reports!',
    CREATE_STACK_REPORT_ERROR: 'Stacked report has failed to create!',
    DELETE_STACK_REPORT_SUCCESS: 'Stacked report has been successfully removed!',
    DELETE_STACK_REPORT_ERROR: 'Stacked report has failed to remove!',

    ADD_CAMPAIGN_TO_GROUP_SUCCESS: 'Campaign has been successfully added to the build stack!',
    ADD_CAMPAIGN_TO_GROUP_ERROR: 'Campaign has failed to add to the build stack!',
    ADD_TO_STACK_MAX_ERROR: 'Stack wasn’t created. You have reached the maximum allowed limit.',

    SHARE_REPORT_SUCCESS: 'Stacked report shared!',
    SHARE_REPORT_ERROR: 'Stacked report has failed to share!',
    UNSHARE_REPORT_SUCCESS: 'Stacked report unshared!',
    UNSHARE_REPORT_ERROR: 'Stacked report has failed to unshare!',

    REMOVE_CAMPAIGN_FROM_GROUP_SUCCESS: 'Campaign has been successfully removed from  build stack!',
    REMOVE_CAMPAIGN_FROM_GROUP_ERROR: 'Campaign has failed to remove!',

    RENAME_CAMPAIGN_GROUP_SUCCESS: 'Stacked report has been successfully renamed!',
    RENAME_CAMPAIGN_GROUP_ERROR: 'Stacked report has failed to rename!',

    CREATE_USER_SUCCESS: 'User has successfully been created.',
    CREATE_USER_ERROR: 'Sorry, user has not been created.',
    INVITE_USER_SUCCESS: 'Customer invite has been sent.',
    INVITE_USER_ERROR: 'Customer invite failed, sorry try again.',
    INVITE_BEING_PROCESSED_ERROR: 'User invite is being processed, please wait.',
    UPDATE_USER_SUCCESS: 'User was successfully updated.',
    UPDATE_USER_ERROR: 'Sorry, user has not been updated',
    UPDATE_USER_BRANDS_ERROR: 'Sorry, user brands have not been updated',
    UPDATE_PASSWORD_SUCCESS: 'Password was successfully updated.',
    UPDATE_PASSWORD_ERROR: 'Sorry, password has not been updated.',
    UPDATE_PASSWORD_ERROR_IDAM: 'Sorry, your password has not been updated. Please try again or contact support.',
    UPDATE_PASSWORD_ERROR_PREVIOUS_IDAM: 'Sorry, recent passwords cannot be used. Please try again.',

    DELETE_PENDING_CAMPAIGN_SUCCESS: 'Campaign has successfully been removed',
    DELETE_PENDING_CAMPAIGN_ERROR: 'Campaign cannot be removed. Try again',

    DELETE_RECENT_VIEWED_CAMPAIGNS_SUCCESS: 'Recent campaigns have been removed.',
    DELETE_RECENT_VIEWED_CAMPAIGNS_ERROR: 'Couldn’t remove recent campaigns. Try again.',

    SHARE_ORG_CAMPAIGNS_SUCCESS: 'Campaigns have been shared.',
    SHARE_ORG_CAMPAIGNS_ERROR: 'Sharing campaigns failed! Try Again.',

    PROVIDER_UPDATED_SUCCESS: 'Provider has been updated.',
    PROVIDER_UPDATED_ERROR: 'Updating provider failed! Try Again.',

    SEND_TO_KODDI_SUCCESS: 'Organization has been sent to Koddi.',
    SEND_TO_KODDI_ERROR: 'Sending organization failed! Try Again.',

    REMOVE_ORG_CAMPAIGNS_SUCCESS: 'Success! Organization has been successfully removed.',
    REMOVE_ORG_CAMPAIGNS_ERROR: 'Sorry, Remove share organization failed. Please try again.',

    DOWNLOAD_USER_REPORT_SUCCESS: 'Download complete. Follow Best Buy security best practices.',
    DOWNLOAD_USER_REPORT_ERROR: 'Sorry, Download failed. Please try again.',
};

export const NAME_NOT_UNIQUE = (name) => `Sorry this name "${name}" is being used. Try a new name.`;
