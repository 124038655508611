import moment from 'moment-timezone';
import { unauth_path, report_path, findPathEnumByValue } from './const';
import alertDismiss from './alertDismiss';

export function findAlertsToShow(alerts, path) {
    let alertsToShow = [];

    alerts.forEach((alert) => {
        if (checkAuth(alert, path) && !isReportPage(path) && checkPageMatched(alert, path)) {
            let alertToAdd = { ...alert };
            if (isUnAuthedPage(path)) {
                alertToAdd = { ...alert, dismissEnabled: false };
            }
            alertsToShow = [...alertsToShow, alertToAdd];
        }
    });

    alertsToShow.sort(alertSort);
    return alertsToShow;
}

export function findAlertsToShowForReport(alerts, path, campaignType) {
    if (!alerts) return [];
    let alertsToShow = [];

    alerts.forEach((alert) => {
        if (checkPageMatched(alert, path, campaignType)) {
            alertsToShow = [...alertsToShow, alert];
        }
    });
    alertsToShow.sort(alertSort);
    return alertsToShow;
}

export function findMaintenanceAlerts(alerts) {
    if (!alerts) return [];

    let maintenanceAlerts = [];
    const currentTime = moment();

    alerts.forEach((alert) => {
        if (alert && isMaintenanceAlert(alert) && alert.active) {
            const updateAlert = checkNoExpiredMaintenanceAlert(currentTime, alert);
            if (updateAlert) {
                maintenanceAlerts = [...maintenanceAlerts, updateAlert];
            }
        }
    });
    return maintenanceAlerts;
}

const alertSort = (a, b) => a.alertLevel - b.alertLevel;

function isMaintenanceAlert(alert) {
    return alert && alert.alertLevel === 0;
}

export function checkActive(alert) {
    if (!alert || !alert.active) {
        return false;
    }

    const currentTime = moment();
    if (alert.startTime) {
        const start = moment(alert.startTime);
        if (currentTime.isBefore(start)) {
            return false;
        }
    }

    if (alert.endTime) {
        const end = moment(alert.endTime);
        if (currentTime.isAfter(end)) {
            return false;
        }
    }

    return true;
}

// this method does two things: it checks if there are any maintenance alerts that are active now or will be in the future
// and it also decorates the alert with a field that indicates if it is active now.
export function checkNoExpiredMaintenanceAlert(compareMoment, alert) {
    if (hasEndedAt(compareMoment, alert)) return null;

    return { ...alert, maintenanceNow: hasBegunAt(compareMoment, alert) };
}

export function hasEndedAt(compareMoment, alert) {
    if (!alert.endTime) return false;

    const end = moment(alert.endTime);
    return compareMoment.isAfter(end);
}

export function hasBegunAt(compareMoment, alert) {
    if (!alert.startTime) return true;

    const start = moment(alert.startTime);
    return compareMoment.isAfter(start);
}

export function isCurrentAt(compareMoment, alert) {
    return hasBegunAt(alert, compareMoment) && !hasEndedAt(alert, compareMoment);
}

export function checkAuth(alert, pathname) {
    return !(alert.loginRequired && isUnAuthedPage(pathname));
}

export function checkPageMatched(alert, pathname, campaignType) {
    if (alert.page === 0) {
        return true;
    }
    const pathEnum = findPathEnumByValue(alert.page);
    let validPath = false;
    if (pathEnum) {
        const isIncluded = findPath(pathEnum.path);
        validPath = isIncluded(pathname);
        if (validPath && campaignType) {
            return pathEnum.type === campaignType;
        }
    }
    return validPath;
}

export function checkDismissStatus(alert) {
    let repeatAlert = false;
    const dismissStatus = alertDismiss.read();
    if (dismissStatus) {
        const dismiss = dismissStatus.find((status) => status.id === alert.id);
        if (dismiss && dismiss.date) {
            const dismissTime = moment(new Date(dismiss.date));
            if (dismiss.repeatAlertHours) {
                // check difference in time between date and repeatAlertHours
                const timeToRepeat = dismissTime.add(dismiss.repeatAlertHours, 'hours');
                repeatAlert = timeToRepeat.isSameOrBefore(new Date());
                return !repeatAlert;
            }
            // dismiss status is false if either conditional is true
            return dismissTime.isSame(new Date(), 'day');
        }
    }
    return false;
}

export function checkIsMaintenanceNow(maintenanceAlerts) {
    if (!maintenanceAlerts) return false;
    return !!maintenanceAlerts.find((item) => item.maintenanceNow);
}

function findPath(pathList) {
    return (pathname) => {
        const found = pathList.find((path) => pathname.startsWith(path));
        return found !== undefined;
    };
}

const isReportPage = findPath(report_path);
const isUnAuthedPage = findPath(unauth_path);
