/**
 *
 * SummaryDashboardChart
 *
 */

import React, { useState } from 'react';
import { Card as MaterialCard, FormControl, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DetailsChart from '../Chart/DetailsChart';
import SummaryChartStyles from './SummaryChartStyles.json';
import SummaryDashboardCards from '../SummaryDashboardCards';
import { formatTitleData } from '../../utils/formatData';

const useStyles = makeStyles((theme) => ({
    control: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 24,
        alignItems: 'center',
        fontFamily: 'humanBBYDigital',
        fontSize: 14,
    },
    chartContainer: {
        padding: '24px',
    },
    chartTitleL1: {
        fontSize: '28px',
        fontWeight: 'normal',
    },
    chartTitleL2: {
        fontWeight: 'bold',
    },
    menuitemroot: {
        fontFamily: 'humanBBYDigital',
        fontSize: '1em',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '2em',
        letterSpacing: 'normal',
        color: theme.palette.darkGray,
        '&:hover': {
            color: '#959aa2',
        },
    },
    selected: {
        color: `${theme.palette.standardWhite} !important`,
        backgroundColor: `${theme.palette.humanBlue} !important`,
    },
    skeletonChartContainer: {
        padding: '1em',
    },
    buttonActive: {
        backgroundColor: theme.palette.humanBlue,
        color: theme.palette.standardWhite,
    },
}));

export default function SummaryChartWrapper({
    cardData,
    visualizationOptions,
    vehicleLabel,
    isLoading,
    chartData,
    cardsLoading,
    isStyleL1,
}) {
    const [category, setCategory] = useState('impressions');
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (item, index) => {
        setCategory(item.value);
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    return (
        <MaterialCard className={classes.container}>
            {isLoading ? (
                <>
                    <div className={classes.control}>
                        <Skeleton animation="wave" variant="text" width={140} height={50} />
                        <Skeleton animation="wave" variant="text" width={140} height={50} />
                    </div>
                    <div className={classes.skeletonChartContainer}>
                        <Skeleton animation="wave" variant="rect" width="90%" height={400} style={{ margin: 'auto' }} />
                    </div>
                </>
            ) : (
                <>
                    <div className={classes.control}>
                        <Typography
                            variant="h3"
                            component="h3"
                            className={isStyleL1 ? classes.chartTitleL1 : classes.chartTitleL2}
                        >
                            {`${visualizationOptions[selectedIndex].title}: ${formatTitleData(
                                chartData?.totals[category],
                                visualizationOptions[selectedIndex].unit,
                            )}`}
                        </Typography>
                        <FormControl>
                            <IconButton
                                data-testid="menuButton"
                                className={anchorEl ? classes.buttonActive : ''}
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                value={category}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                {visualizationOptions.map((item, index) => (
                                    <MenuItem
                                        key={item.value}
                                        classes={{
                                            root: classes.menuitemroot,
                                            selected: classes.selected,
                                        }}
                                        selected={index === selectedIndex}
                                        onClick={() => handleMenuItemClick(item, index)}
                                    >
                                        {item.title}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </FormControl>
                    </div>
                    <SummaryDashboardCards
                        summaryData={cardData}
                        category={category}
                        visualOptions={visualizationOptions}
                        vehicleLabel={vehicleLabel}
                        isLoading={cardsLoading}
                    />
                    <div className={classes.chartContainer}>
                        <DetailsChart
                            data={chartData}
                            category={category}
                            loading={isLoading}
                            width={1200}
                            padding={75}
                            styles={SummaryChartStyles}
                            includeYears={false}
                        />
                    </div>
                </>
            )}
        </MaterialCard>
    );
}

SummaryChartWrapper.propTypes = {
    chartData: PropTypes.object,
    cardData: PropTypes.array,
    visualizationOptions: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.string,
            unit: PropTypes.string,
        }),
    ),
    vehicleLabel: PropTypes.string,
    isLoading: PropTypes.bool,
    cardsLoading: PropTypes.bool,
    isStyleL1: PropTypes.bool,
};
