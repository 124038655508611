import Button from 'components/Button';
import React, { useState, useRef } from 'react';

import env from 'config/env';
// const ALERT_URL = 'http://localhost:3001/token/alerts';
const ALERT_URL = `${env.GATEWAY_BASE_URL}/token/alerts`;
// const ALERT_URL = `https://ad-tools-api-gateway-akamai-ad-tech-tools-test.test-akamai.aws.containers.bestbuy.com/v1/sse/alerts`;

function SSEDemoPage() {
    const [alerts, setAlerts] = useState('no alert');

    const sseSource = useRef(null);

    const handleSSEConnection = () => {
        sseSource.current = new EventSource(ALERT_URL);
        sseSource.current.addEventListener('message', (e) => {
            // eslint-disable-next-line no-console
            console.log(`listener: ${JSON.stringify(e.data)}`);
            setAlerts(e.data);
        });
        sseSource.current.addEventListener('open', () => {
            // eslint-disable-next-line no-console
            console.log('SSE opened!');
        });

        sseSource.current.addEventListener('error', (e) => {
            // eslint-disable-next-line no-console
            console.error(`state: ${sseSource.current.readyState}: Error: `, e);
        });
    };

    const handleSSEDisconnection = () => {
        if (sseSource.current) {
            sseSource.current.close();
        }
    };

    return (
        <div>
            <div style={{ padding: '3rem' }}>
                <h2>SSE Demo</h2>
                <div>
                    <Button onClick={handleSSEConnection}>Connection</Button>
                </div>
                <div>
                    <Button type="connect" onClick={handleSSEDisconnection}>
                        Disconnection
                    </Button>
                </div>
                {alerts && (
                    <h4
                        style={{
                            fontSize: 48,
                        }}
                    >
                        {alerts}
                    </h4>
                )}
            </div>
            <hr />
        </div>
    );
}

export default SSEDemoPage;
