import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
const LoginHeaderImage = require('assets/bby_ads_logo.svg').default;

const useStyles = makeStyles(() => ({
    headerImage: {
        height: 32,
        objectFit: 'contain',
    },
}));

const LogoSvg = () => {
    const classes = useStyles();
    return <img src={LoginHeaderImage} className={classes.headerImage} height="100%" alt="" />;
};

export default LogoSvg;
