/**
 *
 * ViewAction
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function ViewAction({ vehicle }) {
    const [textDecoration, setTextDecoration] = useState('none');
    const handleViewHoverOpen = () => setTextDecoration('underline');
    const handleViewHoverClose = () => setTextDecoration('none');
    return (
        <Link
            to={`summary/${vehicle}`}
            onMouseEnter={handleViewHoverOpen}
            onMouseLeave={handleViewHoverClose}
            style={{
                display: 'flex',
                justifyContent: 'center',
                color: '#0046BE',
                border: 'none',
                background: 'none',
                cursor: 'pointer',
                textDecoration,
            }}
        >
            View
        </Link>
    );
}

ViewAction.propTypes = {
    vehicle: PropTypes.string,
};

export default ViewAction;
