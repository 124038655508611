/*
 *
 * SummaryDashboardPage actions
 *
 */

import {
    DEFAULT_ACTION,
    UPDATE_SORT_ORDER_LVL_TWO,
    UPDATE_DATE_FILTER,
    GET_FILTERS_SUCCESS,
    UPDATE_FILTER_SUCCESS,
    ADD_FILTER_SUCCESS,
} from './constants';

export function defaultAction() {
    return {
        type: DEFAULT_ACTION,
    };
}

export function updateSortOrderLvlTwo(column, order) {
    return {
        type: UPDATE_SORT_ORDER_LVL_TWO,
        payload: {
            column,
            order,
        },
    };
}

export function updateDateFilter(startDate, endDate) {
    return {
        type: UPDATE_DATE_FILTER,
        payload: {
            startDate,
            endDate,
        },
    };
}

export function getSummaryDashboardFiltersSuccess(filter) {
    return {
        type: GET_FILTERS_SUCCESS,
        filter,
    };
}

export function updateSummaryDashboardFilterSuccess(filterName, filter) {
    return {
        type: UPDATE_FILTER_SUCCESS,
        filterName,
        filter,
    };
}

export function addSummaryDashboardFilterSuccess(filter) {
    return {
        type: ADD_FILTER_SUCCESS,
        filter,
    };
}
