import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import AlertSvg from 'components/Svg/Alert';

const useStyles = makeStyles((theme) => ({
    heading: {
        ...theme.typography.h3,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
    },
    errorContainer: {
        marginTop: '6rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    errorInnerContainer: {
        display: 'flex',
    },
    errorText: {
        ...theme.typography.h4,
        fontFamily: theme.typography.fontFamily,
        width: 400,
    },
    iconContainer: {
        paddingTop: '0.67em',
        paddingRight: 16,
        maxHeight: 48,
        maxWidth: 43,
    },
    icon: {
        width: '100%',
        height: '100%',
    },
}));

const ERROR_HEADING = '500 - Page Error';
const ERROR_CONTENT = () => (
    <>
        <span>We&apos;ve encountered an error.</span>
        <br />
        <span>Try refreshing or come back later.</span>
    </>
);

export function parseErrorMessage(errorMessage) {
    const parsedMessage = {
        title: '',
        message: '',
    };

    if (errorMessage) {
        if (!errorMessage.name.includes('revoked') && !errorMessage.name.includes('403')) {
            parsedMessage.title = 'Oops, something went wrong!';
            parsedMessage.message = 'Please try again later.';
        } else {
            parsedMessage.title = errorMessage.name;
            parsedMessage.message = errorMessage.message;
        }
    }
    return parsedMessage;
}

function ErrorPage({ showError, errorInfo, errorMessage }) {
    const styles = useStyles();
    const errorMessageParsed = parseErrorMessage(errorMessage);

    return (
        <div className={styles.errorContainer}>
            <div className={styles.errorInnerContainer}>
                <div className={styles.iconContainer}>
                    <AlertSvg width={43} height={48} />
                </div>
                <div>
                    <h1 className={styles.heading} data-testid="errorTitle">
                        {errorMessage ? `${errorMessageParsed.title}` : `${ERROR_HEADING}`}
                    </h1>
                    <p className={styles.errorText} data-testid="errorMessage">
                        {errorMessage ? `${errorMessageParsed.message}` : ERROR_CONTENT()}
                    </p>
                </div>
            </div>
            <div>
                {showError ? (
                    <pre>
                        <code>{errorInfo}</code>
                    </pre>
                ) : null}
            </div>
        </div>
    );
}

ErrorPage.propTypes = {
    showError: PropTypes.bool,
    errorInfo: PropTypes.string,
    errorMessage: PropTypes.shape({
        name: PropTypes.string,
        message: PropTypes.string,
    }),
};
export default ErrorPage;
