export const OFFSITE_TAB_DEFAULT_KEY = 'platform';

export const offsiteTabs = {
    platform: {
        label: 'Device',
        key: 'platform',
    },
    publisher: {
        label: 'Publisher',
        key: 'publisher',
    },
    audience: {
        label: 'Audience',
        key: 'audience',
    },
    product: {
        label: 'Product',
        key: 'product',
    },
};
