/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
import classnames from 'classnames';
import { hasOrganizationRoles, hasPermission } from 'Helpers/helpers';
import { AppContext } from 'shared/contexts/app-context';
import { Permissions } from 'shared/constants/user/permissions';
import Footer from 'components/Footer/Loadable';
import { RequestStatus } from 'shared/constants';
import Header from './components/Header';
import ScrollToTop from '../components/ScrollToTop';

const permissionList = Object.keys(Permissions);

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        overflowY: 'auto',
    },
    appFrame: {
        overflowY: 'auto',
        overflowX: 'hidden',
        minHeight: '700px',
    },
    content: {
        padding: `${theme.spacing(5)}px`,
    },
    text: {
        fontFamily: 'HumanBBYDigital-Medium, sans-serif',
    },
}));

const DefaultLayout = ({ children, permissions }) => {
    /**
     * Reset the error state upon navigation
     *
     * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
     */
    // TODO: (Chase) Add root level error handling
    // history.listen(() => {
    //   if (this.state.hasError) {
    //     this.setState({ hasError: false });
    //   }
    // });
    const classes = useStyles();

    const { authedUser } = useContext(AppContext);

    // component did catch
    return (
        <>
            <div className={classnames('layout', classes.root)}>
                {/* HEADER START */}
                <Header />
                {/* HEADER END */}

                <div className={classes.appFrame}>
                    <main className={classes.content}>
                        {/* MAIN CONTENT START */}
                        {/* conditionally render children based if there is an error */}
                        {authedUser.loadState !== RequestStatus.DONE ? (
                            <LinearProgress />
                        ) : hasPermission(authedUser, permissions) || hasOrganizationRoles(authedUser) ? (
                            children
                        ) : (
                            <span className={classes.text}>Permission required.</span>
                        )}
                        {/* MAIN CONTENT START */}
                        <Footer />
                    </main>
                </div>
            </div>
            <ScrollToTop />
        </>
    );
};

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.oneOf(permissionList)),
};

export default DefaultLayout;
