/*
 *
 * PasswordUpdatePage reducer
 *
 */
import produce from 'immer';
import { PASSWORD_RESET, PASSWORD_RESET_INIT, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_ERROR } from './constants';

export const initialState = {
    loading: false,
    done: false,
    error: null,
};

/* eslint-disable default-case, no-param-reassign */
const passwordUpdatePageReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case PASSWORD_RESET:
                draft.loading = true;
                draft.done = false;
                draft.error = null;
                break;
            case PASSWORD_RESET_INIT:
                draft.loading = false;
                draft.done = false;
                draft.error = null;
                break;
            case PASSWORD_RESET_SUCCESS:
                draft.loading = false;
                draft.done = true;
                draft.error = null;
                break;
            case PASSWORD_RESET_ERROR:
                draft.loading = false;
                draft.done = true;
                draft.error = action.error;
                break;
        }
    });

export default passwordUpdatePageReducer;
