/**
 *
 * TextDisabled
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
    text: {
        cursor: 'default',
        color: palette.lightGray,
        opacity: '0.7',
    },
}));

function TextDisabled({ text, ...props }) {
    const classes = useStyles();
    return (
        <Typography className={classes.text} {...props}>
            {text}
        </Typography>
    );
}

TextDisabled.propTypes = {
    text: PropTypes.string,
};

export default TextDisabled;
