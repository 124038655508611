/**
 *
 * Footer
 *
 */

import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useRequest } from 'shared/hooks/use-request';
import classnames from 'classnames';
import { getVersion } from 'api/metadata';

const useStyles = makeStyles((theme) => ({
    footerContainer: ({ resourcesPage }) => ({
        backgroundColor: resourcesPage ? theme.palette.background.white : '',
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexFlow: 'row wrap',
    }),
    listContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    text: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 5,
        letterSpacing: 'normal',
        color: theme.palette.lightGray,
    },
    pipe: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: theme.palette.lightGray,
    },
}));

function Footer({ resourcesPage }) {
    const classes = useStyles({ resourcesPage });
    const currentYear = new Date().getFullYear();

    const [fetchVersion, versionRequestInfo] = useRequest(getVersion);

    let appVersion = '';
    if (process.env.REACT_APP_BBY_ENV === 'local') {
        appVersion = 'Local Environment';
    } else if (versionRequestInfo.data !== null) {
        appVersion = versionRequestInfo.data;
    }

    const items = [
        { type: 'text', label: `© ${currentYear} Best Buy. All rights reserved.` },
        { type: 'text', label: `App Vers: ${appVersion}` },
        {
            type: 'link',
            label: 'Terms and Conditions',
            target: '_blank',
            href:
                'https://www.bestbuy.com/site/help-topics/terms-and-conditions/pcmcat204400050067.c?id=pcmcat204400050067',
        },
    ];

    useEffect(() => {
        if (process.env.REACT_APP_BBY_ENV !== 'local') {
            fetchVersion();
        }
    }, [fetchVersion]);

    return (
        <div className={classes.footerContainer}>
            <div className={classes.listContainer}>
                {items.map((item, index) => (
                    <div key={item.text || item.label || index}>
                        {item.type === 'text' && (
                            <span className={classes.text} key={item.label}>
                                {item.label}
                            </span>
                        )}
                        {item.type === 'link' && (
                            <a className={classes.text} href={item.href} target={item.target}>
                                {item.label}
                            </a>
                        )}
                        {index !== items.length - 1 && (
                            <span className={classnames(classes.text, classes.pipe)}>|</span>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

Footer.propTypes = {
    resourcesPage: PropTypes.bool,
};

export default memo(Footer);
