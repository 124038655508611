/*
 *
 * Summary Dashboard Page reducer
 *
 */
import produce from 'immer';
import { RequestStatus } from 'shared/constants';
import moment from 'moment';
import {
    DEFAULT_ACTION,
    UPDATE_SORT_ORDER_LVL_TWO,
    UPDATE_DATE_FILTER,
    UPDATE_FILTER_SUCCESS,
    ADD_FILTER_SUCCESS,
    GET_FILTERS_SUCCESS,
    DEFAULT_FILTER_NAME,
} from './constants';
import { GET_FILTERS, GET_FILTERS_ERROR } from '../HomePage/constants';

const initialFilterState = {
    loadStatus: RequestStatus.INIT,
    currentFilterName: null,
    filters: {},
};

export const initialState = {
    summaryDashboardFilters: initialFilterState,
    dateFilter: {
        startDate: moment().subtract('30', 'days').format(moment.HTML5_FMT.DATE),
        endDate: moment().format(moment.HTML5_FMT.DATE),
    },
    sortOrderLvlTwo: {
        column: { field: 'startDate', secondaryField: 'endDate' },
        order: 'desc',
    },
};

const SummaryDashboardPageReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case DEFAULT_ACTION:
                break;
            case GET_FILTERS:
                draft.summaryDashboardFilters.loadStatus = RequestStatus.LOADING;
                break;
            case GET_FILTERS_SUCCESS:
                draft.summaryDashboardFilters.currentFilterName = action.filter?.filterId;
                draft.summaryDashboardFilters.filters = action.filter?.savedFilter;
                draft.summaryDashboardFilters.loadStatus = RequestStatus.DONE;
                break;
            case ADD_FILTER_SUCCESS:
                draft.summaryDashboardFilters.currentFilterName = DEFAULT_FILTER_NAME;
                draft.summaryDashboardFilters.filters = action.filter;
                draft.summaryDashboardFilters.loadStatus = RequestStatus.DONE;
                break;
            case UPDATE_FILTER_SUCCESS:
                draft.summaryDashboardFilters.filters = action.filter;
                draft.summaryDashboardFilters.loadStatus = RequestStatus.DONE;
                break;
            case GET_FILTERS_ERROR:
                draft.summaryDashboardFilters.loadStatus = RequestStatus.ERROR;
                break;
            case UPDATE_DATE_FILTER:
                draft.dateFilter.startDate = action.payload.startDate;
                draft.dateFilter.endDate = action.payload.endDate;
                break;
            case UPDATE_SORT_ORDER_LVL_TWO:
                draft.sortOrderLvlTwo.column = action.payload.column;
                draft.sortOrderLvlTwo.order = action.payload.order;
                break;
            default:
                break;
        }
    });

export default SummaryDashboardPageReducer;
