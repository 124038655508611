import { TableFormatter } from 'Helpers/table-formatter';

const comparator = (itemA, itemB, order, column) => {
    const EQUAL = 0;
    const GREATER = 1;
    const LESS = -1;

    let valueA = itemA[column.field];
    let valueB = itemB[column.field];

    const valueASecondary = itemA[column.secondaryField];
    const valueBSecondary = itemB[column.secondaryField];
    let compare = EQUAL;

    // Edge case for Brands that start with lower case letters
    if (typeof valueA === 'string') {
        valueA = valueA.toLowerCase();
    }

    if (typeof valueB === 'string') {
        valueB = valueB.toLowerCase();
    }

    // Sort the values of null to the very end of the table
    // Set the value of compare appropriately for ascending or descending
    if (!valueA) {
        valueA = 'zzz';
        compare = GREATER;
    }
    if (!valueB) {
        valueB = 'zzz';
        compare = LESS;
    }

    // Compare two values in ASCII and determine which should come first
    if (valueB < valueA) {
        compare = LESS;
    } else if (valueB > valueA) {
        compare = GREATER;
    } else if (valueB === valueA && valueASecondary) {
        if (valueBSecondary > valueASecondary) {
            compare = LESS;
        } else if (valueBSecondary < valueASecondary) {
            compare = GREATER;
        } else {
            compare = EQUAL;
        }
    }

    return order === 'desc' ? compare : -compare;
};

export const tableSort = (data, order, column) => {
    // Create a copy data for sorting so that it won't alter the original order
    const sortData = data ? [...data] : [];
    // if the column is last_login and desc order push null values to bottom of data rows
    if (column.field === 'last_login' && order === 'desc') {
        sortData.sort(
            (a, b) =>
                (a.last_login === null) - (b.last_login === null) ||
                -(a.last_login > b.last_login) ||
                +(a.last_login < b.last_login),
        );
    } else {
        sortData.sort((itemA, itemB) => comparator(itemA, itemB, order, column));
    }

    return sortData;
};

export const tableFormatPropType = (props, propName, componentName) => {
    const prop = props[propName];

    if (prop) {
        if (typeof prop !== 'function') {
            return new Error(
                `Invalid prop \`${propName}\` supplied to \`${componentName}\`. This should be a function. Validation failed.`,
            );
        }

        if (!(prop() instanceof TableFormatter)) {
            return new Error(
                `Invalid prop \`${propName}\` supplied to \`${componentName}\`. This function shoudl return an instance of TableFormatter. Validation failed.`,
            );
        }
    }

    return null;
};
