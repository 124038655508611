/*
 *
 * Summary Dashboard Page constants
 *
 */

export const DEFAULT_ACTION = 'portal/SummaryDashboard/DEFAULT_ACTION';
export const DEFAULT_FILTER_NAME = '@@SUMMARY';
export const UPDATE_SORT_ORDER_LVL_TWO = 'portal/SummaryDashboard/UPDATE_SORT_ORDER_LVL_TWO';
export const UPDATE_DATE_FILTER = 'portal/SummaryDashboard/UPDATE_DATE_FILTER';
export const UPDATE_FILTER_SUCCESS = 'portal/SummaryDashboard/UPDATE_FILTER_SUCCESS';
export const GET_FILTERS_SUCCESS = 'portal/SummaryDashboard/GET_FILTERS_SUCCESS';
export const ADD_FILTER_SUCCESS = 'portal/SummaryDashboard/ADD_FILTER_SUCCESS';

export const VISUALIZATION_OPTIONS_L1 = [
    { title: 'Impressions', value: 'impressions', unit: 'integer' },
    { title: 'Clicks', value: 'clicks', unit: 'integer' },
    { title: 'CTR', value: 'ctr', unit: 'percent' },
    { title: 'Delivered Spend', value: 'deliveredSpend', unit: 'currency' },
];

export const VISUALIZATION_OPTIONS_L2 = [
    { title: 'Impressions', value: 'impressions', unit: 'integer' },
    { title: 'Clicks', value: 'clicks', unit: 'integer' },
    { title: 'CTR', value: 'ctr', unit: 'percent' },
    { title: 'Delivered Spend', value: 'deliveredSpend', unit: 'currency' },
    { title: 'Revenue', value: 'revenue', unit: 'currency' },
];

export const RIGHT_ALIGN_FIELDS = ['impressions', 'clicks', 'ctr', 'deliveredSpend', 'revenue', 'roas'];
