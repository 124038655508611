import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
// import HelpCircle from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';

// import { QuestionSvg } from 'components/Svg';
import { Link } from 'react-router-dom';
import QuestionHoverSvg from '../../components/Svg/QuestionHover';
import QuestionDefaultSvg from '../../components/Svg/Question';

const useStyles = makeStyles((theme) => ({
    helpContainer: {
        marginLeft: 'auto',
        zIndex: 1,
    },
    anchor: {
        '&:hover': {
            textDecoration: 'underline',
        },
        color: theme.palette.darkGray,
        fontSize: 12,
        fontWeight: 500,
        textDecoration: 'none',
    },
}));

const HelpMenu = ({ label }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isShown, setIsShown] = useState(false);
    const classes = useStyles();

    const open = Boolean(anchorEl);

    const handleMenu = (event) => setAnchorEl(event.currentTarget);

    return (
        <div className={classes.helpContainer}>
            <IconButton
                aria-label={label}
                aria-owns={open ? 'menu-appbar' : null}
                aria-haspopup
                color="inherit"
                to="/resources"
                component={Link}
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
                onClick={handleMenu}
            >
                {isShown ? <QuestionHoverSvg /> : <QuestionDefaultSvg />}
            </IconButton>
        </div>
    );
};

HelpMenu.propTypes = {
    label: PropTypes.string,
};

HelpMenu.defaultProps = {
    label: 'Help Menu Coming Soon!',
};

export default HelpMenu;
