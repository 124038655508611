/**
 *
 * TitleBar
 *
 * Component contains a Title, DatePicker, Calendar, and Filter Icon
 * It is reusable for the creation of future pages
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import SummaryListFilter from 'components/SummaryListFilter';
import DatePickerWrapper from '../DatePickerWrapper/DatePickerWrapper';

// STYLES
const useStyles = makeStyles(() => ({
    titleBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: 'none',
        marginTop: '-40px',
    },
    headline: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '28px',
        fontFamily: 'HumanBBYDigital, sans-serif',
    },
    rightSide: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        marginTop: '-8px',
    },
}));

function TitleBar({
    title,
    filterMetadata,
    onSummaryFilterChange,
    currentSummaryFilter,
    campaignType,
    dateFilter,
    brandData,
}) {
    const classes = useStyles();

    return (
        <div className={classes.titleBar}>
            <div>
                <Typography component="h2" align="left" className={classes.headline}>
                    {title}
                </Typography>
            </div>
            <div className={classes.rightSide}>
                <DatePickerWrapper />
                <SummaryListFilter
                    currentSummaryFilter={currentSummaryFilter}
                    dateFilter={dateFilter}
                    onSummaryFilterChange={onSummaryFilterChange}
                    filterMetadata={filterMetadata}
                    campaignType={campaignType}
                    brandData={brandData}
                />
            </div>
        </div>
    );
}

TitleBar.propTypes = {
    title: PropTypes.string,
    campaignType: PropTypes.string,
    filterMetadata: PropTypes.shape({
        brand_name: PropTypes.object,
        category_groups: PropTypes.object,
    }),
    onSummaryFilterChange: PropTypes.func,
    currentSummaryFilter: PropTypes.object,
    dateFilter: PropTypes.object,
    brandData: PropTypes.array,
};

export default TitleBar;
