import { filterMatch, updateStat } from './filterUtils';

// Commented this part of code to check whether it handles agency with other organizations
// Example: Microsoft and Carat
const checkOrgNames = (organizations, org) => {
    if (organizations.has(org.name)) {
        return true;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const sharedOrg of org.sharedOrg) {
        if (organizations.has(sharedOrg.name)) {
            return true;
        }
    }
    return false;
};

const checkBrandNames = (organizations, org) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const userBrand of org.brands) {
        if (organizations.has(userBrand)) {
            return true;
        }
    }
    return false;
};

const checkOrgTypes = (orgTypes, org) => {
    if (orgTypes.has(org.type)) {
        return true;
    }
    return false;
};

const checkSharedCampaigns = (sharedCampaigns, org) => {
    if (org.sharedOrg?.length >= 1 && sharedCampaigns.has('Sharing')) {
        return true;
    }
    return org.sharedOrg?.length === 0 && sharedCampaigns.has('Not Sharing');
};

export function filterOrgs(organizations, selectedOrgFilterOptions) {
    let filteredOrgs = organizations;

    const brandStats = {};
    const orgTypeStats = {};
    const orgSharingStats = {};

    if (organizations) {
        const orgs = new Set(selectedOrgFilterOptions?.organizations);
        const brands = new Set(selectedOrgFilterOptions?.brands);
        const orgTypes = new Set(selectedOrgFilterOptions?.orgTypeOptions);
        const sharedCampaigns = new Set(selectedOrgFilterOptions?.orgSharingOptions);

        const hasOrg = (org) => orgs.size === 0 || checkOrgNames(orgs, org);
        const hasBrand = (org) => brands.size === 0 || checkBrandNames(brands, org);
        const hasOrgType = (org) => orgTypes.size === 0 || checkOrgTypes(orgTypes, org);
        const hasSharedCampaign = (org) => sharedCampaigns.size === 0 || checkSharedCampaigns(sharedCampaigns, org);

        filteredOrgs = [];

        organizations.forEach((org) => {
            org.brands?.forEach((brandName) => {
                brandStats[brandName] = updateStat(org, brandStats[brandName], [hasOrg]);
            });

            const orgType = org.type;
            orgTypeStats[orgType] = updateStat(org, orgTypeStats[orgType], [hasOrg, hasBrand, hasSharedCampaign]);

            const sharedCampaign = org.sharedOrg?.length >= 1 ? 'Sharing' : 'Not Sharing';
            orgSharingStats[sharedCampaign] = updateStat(org, orgSharingStats[sharedCampaign], [
                hasOrg,
                hasBrand,
                hasOrgType,
            ]);

            if (filterMatch(org, [hasOrg, hasBrand, hasOrgType, hasSharedCampaign])) {
                filteredOrgs.push(org);
            }
        });
    }
    return { filteredOrgs, brandStats, orgTypeStats, orgSharingStats };
}
