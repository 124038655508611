/*
 *
 * WatchCampaignsTab reducer
 *
 */
import produce from 'immer';
import {
    FETCH_CAMPAIGNS_WATCHING_START,
    FETCH_CAMPAIGNS_WATCHING_SUCCESS,
    FETCH_CAMPAIGNS_WATCHING_ERROR,
    REMOVE_CAMPAIGNS_FROM_WATCH,
    REMOVE_CAMPAIGNS_FROM_WATCH_SUCCESS,
    REMOVE_CAMPAIGNS_FROM_WATCH_ERROR,
    CREATE_CAMPAIGN_WATCH,
    CREATE_CAMPAIGN_WATCH_SUCCESS,
    CREATE_CAMPAIGN_WATCH_ERROR,
    ADD_CAMPAIGNS_TO_WATCH,
    ADD_CAMPAIGNS_TO_WATCH_SUCCESS,
    ADD_CAMPAIGNS_TO_WATCH_ERROR,
} from './constants';

export const initialState = {
    watchlists: [],
    loading: false,
};

/* eslint-disable default-case, no-param-reassign */
const watchCampaignsTabReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FETCH_CAMPAIGNS_WATCHING_START:
            case REMOVE_CAMPAIGNS_FROM_WATCH:
            case ADD_CAMPAIGNS_TO_WATCH:
            case CREATE_CAMPAIGN_WATCH:
                draft.loading = true;
                break;
            case FETCH_CAMPAIGNS_WATCHING_SUCCESS:
                draft.watchlists = action.watchlists;
                draft.loading = false;
                break;
            case REMOVE_CAMPAIGNS_FROM_WATCH_SUCCESS:
                draft.watchlists.find((watch) => watch.watchId === action.data.watchId).campaignIds =
                    action.data.campaignIds;
                draft.loading = false;
                break;
            case FETCH_CAMPAIGNS_WATCHING_ERROR:
            case REMOVE_CAMPAIGNS_FROM_WATCH_ERROR:
            case CREATE_CAMPAIGN_WATCH_ERROR:
            case ADD_CAMPAIGNS_TO_WATCH_ERROR:
                draft.loading = false;
                break;
            case CREATE_CAMPAIGN_WATCH_SUCCESS:
                draft.watchlists = [...draft.watchlists, action.data];
                draft.loading = false;
                break;
            case ADD_CAMPAIGNS_TO_WATCH_SUCCESS:
                draft.loading = false;
                draft.watchlists.find((watch) => watch.watchId === action.data.watchId).campaignIds =
                    action.data.campaignIds;
                break;
            default:
                break;
        }
    });

export default watchCampaignsTabReducer;
