export const displayOptions = (offsiteType) => {
    const baseDisplayOptions = [
        { unit: 'integer', label: 'Impressions', key: 'impressions' },
        { unit: 'percentage', label: 'CTR', key: 'ctr' },
        { unit: 'currency', label: 'Delivered Spend', key: 'ad_spend' },
        { unit: 'currency', label: 'Revenue', key: 'revenue_total' },
        { unit: 'currency', label: 'ROAS ($)', key: 'roas_total' },
    ];

    if (offsiteType === 'display') {
        /// added object for offsite display
        baseDisplayOptions.splice(1, 0, { unit: 'integer', label: 'Clicks', key: 'clicks' });
    } else {
        /// addition for offsite video
        baseDisplayOptions.splice(1, 0, { unit: 'percentage', label: 'VCR', key: 'video_completion_rate_total' });
    }

    return baseDisplayOptions;
};
