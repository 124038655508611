import classNames from 'classnames';
import { Button, Icon, IconButton, TableCell as MuiTableCell, TableRow as MuiTableRow } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { SubdirectoryArrowRight } from '@material-ui/icons';
import { compact } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VerticalMenu from '../VerticalMenu';
import { TableCellText } from './TableCellText';
import { ColumnPropType } from './constants';

export const ParentRowCollapsible = (props) => {
    const {
        item,
        itemIndex,
        itemsCount,
        classes,
        selectedItems,
        columns,
        rightAlignTypes,
        actions,
        childActions,
        onTableRowClick,
        onRowClick,
        filterValues,
    } = props;

    const [open, setOpen] = useState('none');

    const handleToggle = () => {
        const result = open === 'none' ? 'table-row' : 'none';
        setOpen(result);
    };

    const rowColor = itemIndex % 2 === 0 ? classes.rowDark : classes.rowLight;

    const getChildCellStyle = (column, rowIndex, sharedItemIndex, sharedOrgArray) => {
        const isLastRow = rowIndex === itemsCount - 1;
        const isLastNestedRow = sharedItemIndex !== undefined && sharedItemIndex === sharedOrgArray?.length - 1;
        const hasBottomBorder = open === 'none' || (open !== 'none' && isLastNestedRow);
        const isSharedOrgNameCell = column.field === 'name' && sharedItemIndex !== undefined;

        const cellClasses = [
            classes.cell,
            hasBottomBorder ? '' : classes.parentRowCell,
            isLastRow ? classes.parentRowCell : '',
            isSharedOrgNameCell ? classes.textIndent : '',
            rightAlignTypes.includes(column.type) ? classes.textRight : '',
        ];

        return classNames(...cellClasses.filter(Boolean));
    };

    return (
        <React.Fragment key={`fragment-${item.__key}`}>
            <MuiTableRow
                key={item.__key}
                data-testid={`row-${itemIndex + 1}`}
                className={classNames(rowColor)}
                tabIndex={-1}
                selected={Boolean(selectedItems[item.__key]) || false}
                hover
            >
                {columns.map((column, index) =>
                    column.field === 'name' ? (
                        <MuiTableCell
                            align={column.align}
                            key={column.field}
                            className={
                                open !== 'none' || itemIndex === itemsCount - 1
                                    ? classNames(classes.cell, classes.parentRowCell)
                                    : classNames(classes.cell)
                            }
                            data-clickable={onRowClick instanceof Function}
                            data-line-break={column.lineBreak}
                            data-testid={`table-cell-${itemIndex + 1}-${index + 1}`}
                            onClick={onTableRowClick(item)}
                        >
                            <IconButton data-testid="table-cell-toggle" onClick={handleToggle}>
                                {open === 'table-row' ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                            </IconButton>
                            <TableCellText item={item} column={column} filterValue={filterValues[column.field]} />
                        </MuiTableCell>
                    ) : (
                        <MuiTableCell
                            align={column.align}
                            key={column.field}
                            className={getChildCellStyle(column, itemIndex)}
                            data-clickable={onRowClick instanceof Function}
                            data-line-break={column.lineBreak}
                            data-testid={`table-cell-${itemIndex + 1}-${index + 1}`}
                            onClick={onTableRowClick(item)}
                        >
                            <TableCellText item={item} column={column} filterValue={filterValues[column.field]} />
                        </MuiTableCell>
                    ),
                )}
                {actions && (
                    <MuiTableCell
                        className={
                            open === 'none' ? classNames(classes.cell) : classNames(classes.cell, classes.parentRowCell)
                        }
                    >
                        <VerticalMenu
                            buttons={compact(
                                actions(item).map(
                                    (action) =>
                                        action &&
                                        (action.onClick ? (
                                            <Button
                                                className={classes.actionMenu}
                                                key={action.label}
                                                onClick={(event) => {
                                                    action.onClick(event, item);
                                                }}
                                            >
                                                <span>{action.label}</span>
                                            </Button>
                                        ) : (
                                            <div className={classes.noneActionMenu}>{action.label}</div>
                                        )),
                                ),
                            )}
                        />
                    </MuiTableCell>
                )}
            </MuiTableRow>
            {/** *************** nested rows ************** */}
            {item.sharedOrg.map((sharedItem, sharedItemIndex) => (
                <MuiTableRow
                    key={sharedItem.__key}
                    data-testid={`row-${itemIndex + 1}-${itemIndex + 1}`}
                    className={classNames(rowColor)}
                    tabIndex={-1}
                    style={{ display: open }}
                    hover
                >
                    {columns.map((column, index) =>
                        column.field === 'name' ? (
                            <MuiTableCell
                                align={column.align}
                                key={column.field}
                                className={getChildCellStyle(column, itemIndex, sharedItemIndex, item.sharedOrg)}
                                data-clickable={onRowClick instanceof Function}
                                data-line-break={column.lineBreak}
                                data-testid={`table-cell-${itemIndex + 2}-${index + 2}`}
                                onClick={onTableRowClick(item)}
                            >
                                <Icon data-testid="table-cell-subdirectory">
                                    <SubdirectoryArrowRight fontSize="small" />
                                </Icon>
                                <TableCellText
                                    item={sharedItem}
                                    column={column}
                                    filterValue={filterValues[column.field]}
                                />
                            </MuiTableCell>
                        ) : (
                            <MuiTableCell
                                align={column.align}
                                key={column.field}
                                className={getChildCellStyle(column, itemIndex, sharedItemIndex, item.sharedOrg)}
                                data-clickable={onRowClick instanceof Function}
                                data-line-break={column.lineBreak}
                                data-testid={`table-cell-${itemIndex + 2}-${index + 2}`}
                                onClick={onTableRowClick(item)}
                            >
                                <TableCellText
                                    item={sharedItem}
                                    column={column}
                                    filterValue={filterValues[column.field]}
                                />
                            </MuiTableCell>
                        ),
                    )}
                    {childActions && (
                        <MuiTableCell
                            className={
                                open !== 'none' && sharedItemIndex === item.sharedOrg?.length - 1
                                    ? classNames(classes.cell)
                                    : classNames(classes.cell, classes.parentRowCell)
                            }
                        >
                            <VerticalMenu
                                buttons={compact(
                                    childActions(sharedItem).map(
                                        (action) =>
                                            action &&
                                            (action.onClick ? (
                                                <Button
                                                    className={classes.actionMenu}
                                                    key={action.label}
                                                    onClick={(event) => {
                                                        action.onClick(event, {
                                                            sharedFromOrg: item,
                                                            ...sharedItem,
                                                        });
                                                    }}
                                                >
                                                    <span>{action.label}</span>
                                                </Button>
                                            ) : (
                                                <div className={classes.noneActionMenu}>{action.label}</div>
                                            )),
                                    ),
                                )}
                            />
                        </MuiTableCell>
                    )}
                </MuiTableRow>
            ))}
        </React.Fragment>
    );
};

ParentRowCollapsible.propTypes = {
    item: PropTypes.object,
    itemsCount: PropTypes.number,
    classes: PropTypes.object,
    rightAlignTypes: PropTypes.array,
    itemIndex: PropTypes.number,
    columns: PropTypes.arrayOf(PropTypes.shape(ColumnPropType).isRequired).isRequired,
    select: PropTypes.shape({
        type: PropTypes.oneOf(['single', 'multiple']).isRequired,
        onSelect: PropTypes.func.isRequired,
    }),
    sort: PropTypes.shape({
        column: PropTypes.object.isRequired,
        order: PropTypes.oneOf(['desc', 'asc', '']).isRequired,
    }),
    actions: PropTypes.func,
    childActions: PropTypes.func,
    onRowClick: PropTypes.func,
    onTableRowClick: PropTypes.func,
    selectedItems: PropTypes.object,
    filterValues: PropTypes.object,
};

export default ParentRowCollapsible;
