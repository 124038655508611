/**
 *
 * OTPPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { RequestStatus } from 'shared/constants';

import useStyles from '../formStyle';
import Form from './otpForm';

export function OTPPage({ onSubmit, onSendCode, status, type }) {
    const isCodeLandingPage = status.otpSendStatus === RequestStatus.INIT;
    const getFormTitle = () => {
        if (type === 'phone') {
            return 'Mobile';
        }
        if (isCodeLandingPage) {
            return 'Get';
        }
        return 'Security';
    };
    const classes = useStyles();

    return (
        <>
            <Grid item xs={12} className={classes.bodyContainer}>
                <Grid item xs={12}>
                    <div className={classes.bodyLeft}>
                        <span className={classes.formTitleLine}>
                            <div className={classes.formTitle}>{getFormTitle()}</div>
                        </span>
                        <div className={classes.formTitle}>{isCodeLandingPage ? 'started.' : 'Code.'}</div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.bodyRight}>
                        <Form
                            onSubmit={onSubmit}
                            onSendCode={onSendCode}
                            subscription={{
                                submitting: true,
                                pristine: true,
                                submitError: true,
                                hasValidationErrors: true,
                            }}
                            status={status}
                            type={type}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

OTPPage.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onSendCode: PropTypes.func.isRequired,
    status: PropTypes.object,
    type: PropTypes.string,
};
