/**
 *
 * BrandText
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { comparator } from 'utils/sortComparator';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        width: '432px',
    },
    content: {
        whiteSpace: 'pre',
    },
}));

function BrandText({ brands }) {
    const classes = useStyles();
    if (brands) {
        return (
            <div className={classes.container}>
                {brands
                    ?.slice()
                    .sort((a, b) => comparator(a, b))
                    .map((brand, index) => (
                        <span className={classes.content} key={brand.id || index}>
                            {brand}
                            {index === brands.length - 1 ? ' ' : ', '}
                        </span>
                    ))}
            </div>
        );
    }
}

BrandText.propTypes = {
    brands: PropTypes.array,
};

export default BrandText;
