/*
 *
 * UserPage constants
 *
 */

import { UserStatusDisplayInfo } from 'shared/constants/user/user-status';

export const LOAD_ROLES = 'portal/UserListPage/LOAD_ROLES';
export const LOAD_ROLES_SUCCESS = 'portal/UserListPage/LOAD_ROLES_SUCCESS';
export const LOAD_ROLES_ERROR = 'portal/UserListPage/LOAD_ROLES_ERROR';
export const LOAD_BRANDS = 'portal/UserListPage/LOAD_BRANDS';
export const LOAD_BRANDS_SUCCESS = 'portal/UserListPage/LOAD_BRANDS_SUCCESS';
export const LOAD_BRANDS_ERROR = 'portal/UserListPage/LOAD_BRANDS_ERROR';
export const LOAD_IDAM_USERS = 'portal/UserListPage/LOAD_IDAM_USERS';
export const LOAD_IDAM_USERS_SUCCESS = 'portal/UserListPage/LOAD_IDAM_USERS_SUCCESS';
export const LOAD_IDAM_USERS_ERROR = 'portal/UserListPage/LOAD_IDAM_USERS_ERROR';
export const UPDATE_USER_FILTER = 'portal/UserListPage/UPDATE_USER_FILTER';
export const UPDATE_USER_FILTER_ERROR = 'portal/UserListPage/UPDATE_USER_FILTER_ERROR';
export const LOAD_ORG_OPTIONS = 'portal/UserListPage/LOAD_ORG_OPTIONS';
export const LOAD_ORG_OPTIONS_SUCCESS = 'portal/UserListPage/LOAD_ORG_OPTIONS_SUCCESS';
export const LOAD_ORG_OPTIONS_ERROR = 'portal/UserListPage/LOAD_ORG_OPTIONS_ERROR';
export const UPDATE_PAGE_NUMBER = 'portal/UserListPage/UPDATE_PAGE_NUMBER';

export const DEFAULT_FILTER_NAME = '@@DEFAULT';

export const FlyoutAction = {
    NONE: 'none',
    CREATE: 'create',
    UPDATE: 'update',
    DETAIL: 'detail',
    PASSWORD: 'password',
    REINVITE: 'reinvite',
    RESTORE: 'restore',
    SHARE: 'share',
};

export const userStatusOptionArray = [
    { name: 'Active', value: UserStatusDisplayInfo.active.text },
    { name: 'Inactive', value: UserStatusDisplayInfo.inactive.text },
    { name: 'Invite Sent', value: UserStatusDisplayInfo.inviteSent.text },
    { name: 'Invite Expired', value: UserStatusDisplayInfo.inviteExpired.text },
    {
        name: 'Reg. In Process',
        value: UserStatusDisplayInfo.registrationInProgress.text,
    },
    { name: 'Archived', value: UserStatusDisplayInfo.archived.text },
    { name: 'Code Expired', value: UserStatusDisplayInfo.codeExpired.text },
    { name: 'Unknown', value: UserStatusDisplayInfo.unknown.text },
];

export const userTypeOptionsArray = [
    { name: 'Internal', value: 'Internal' },
    { name: 'External', value: 'External' },
];
