// Comparator function
// Used for sorting brands, categories, roles, and organizations

export const comparator = (a, b) => {
    const labelA = a.name?.toLowerCase() || a?.toString().toLowerCase();
    const labelB = b.name?.toLowerCase() || b?.toString().toLowerCase();
    if (labelA < labelB) return -1;
    if (labelA > labelB) return 1;
    return 0;
};
