/*
 *
 * HomePage actions
 *
 */

import {
    DEFAULT_ACTION,
    GET_FILTERS,
    GET_FILTERS_ERROR,
    GET_FILTERS_SUCCESS,
    ADD_FILTER,
    UPDATE_FILTER,
    ADD_FILTER_SUCCESS,
    UPDATE_FILTER_SUCCESS,
    ADD_FILTER_ERROR,
    UPDATE_FILTER_ERROR,
    UPDATE_DATE_FILTER,
    UPDATE_SORT_ORDER,
    UPDATE_PAGE_NUMBER,
} from './constants';

export function defaultAction() {
    return {
        type: DEFAULT_ACTION,
    };
}

export function addFilter(userId, filterName, filter) {
    return {
        type: ADD_FILTER,
        userId,
        filterName,
        filter,
    };
}

export function updateFilter(userId, filterName, filter) {
    return {
        type: UPDATE_FILTER,
        userId,
        filterName,
        filter,
    };
}

export function addFilterSuccess(filter) {
    return {
        type: ADD_FILTER_SUCCESS,
        filter,
    };
}

export function updateFilterSuccess(filterName, filter) {
    return {
        type: UPDATE_FILTER_SUCCESS,
        filterName,
        filter,
    };
}

export function addFilterError(error) {
    return {
        type: ADD_FILTER_ERROR,
        error,
    };
}

export function updateFilterError(error) {
    return {
        type: UPDATE_FILTER_ERROR,
        error,
    };
}

export function getFilters(userId) {
    return {
        type: GET_FILTERS,
        userId,
    };
}

export function getFiltersSuccess(filter) {
    return {
        type: GET_FILTERS_SUCCESS,
        filter,
    };
}

export function getFiltersError(error) {
    return {
        type: GET_FILTERS_ERROR,
        error,
    };
}

export function updateDateFilter(startDate, endDate) {
    return {
        type: UPDATE_DATE_FILTER,
        payload: {
            startDate,
            endDate,
        },
    };
}

export function updateSortOrder(column, order) {
    return {
        type: UPDATE_SORT_ORDER,
        payload: {
            column,
            order,
        },
    };
}

export function updatePageNumber(pageNumber) {
    return {
        type: UPDATE_PAGE_NUMBER,
        payload: pageNumber,
    };
}
