import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        paddingTop: '40px',
    },
    resendCodeButton: {
        float: 'right',
        clear: 'right',
        padding: 0,
        paddingTop: 4,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.labelGrey,
        fontSize: 14,
        fontWeight: 700,
        '&:hover': {
            color: theme.palette.techYellow,
        },
        textTransform: 'none',
    },
    sendCodeButton: {
        padding: 0,
        paddingTop: 4,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.techYellow,
        fontSize: 22,
        fontWeight: 700,
        textTransform: 'none',
    },
    resendButton: {
        float: 'right',
        clear: 'right',
        padding: 0,
        paddingTop: 4,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.labelGrey,
        fontSize: 14,
        fontWeight: 'lighter',
        '&:hover': {
            textDecoration: 'underline',
        },
        textTransform: 'none',
    },
    prompt: {
        outline: 'none',
        color: theme.palette.borderGray,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 700,
        fontSize: 18,
        textAlign: 'left',
        textTransform: 'none',
        marginBottom: 16,
    },
    formLabel: {
        color: theme.palette.labelGrey,
        fontFamily: theme.typography.fontFamily,
        fontSize: 13.5,
        fontWeight: 500,
        margin: '0px 0px 24px 0px',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
    },
    formStaticField: {
        '& .MuiInput-underline:before': {
            visibility: 'hidden',
        },
    },
    formButton: {
        padding: 0,
        backgroundColor: theme.palette.techBlack,
        color: theme.palette.techYellow,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        fontSize: 22,
        textAlign: 'left',
        textTransform: 'none',
        marginLeft: 35,
        '&:hover': {
            color: theme.palette.darkYellow,
        },
        '&:disabled': {
            backgroundColor: theme.palette.techBlack,
            color: theme.palette.disabledYellow,
            opacity: '0.6',
            cursor: 'default',
        },
    },
    formButtonNoMargin: {
        marginLeft: 0,
    },
    errorStyle: {
        color: theme.palette.valueRed,
        borderColor: `${theme.palette.valueRed}`,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        marginBottom: theme.spacing(2),
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 18,
        margin: 0,
        paddingLeft: '8px',
        '& a': {
            color: theme.palette.valueRed,
            fontSize: 18,
        },
    },
    submitError: {
        marginLeft: 35,
        '& .MuiFormControl-root': {
            '& .MuiInput-underline': {
                '&:before': {
                    borderWidth: '2',
                    borderBottomColor: theme.palette.valueRed,
                },
                '&:after': {
                    borderWidth: '2',
                    borderBottomColor: theme.palette.valueRed,
                },
            },
        },
        '& .MuiFormLabel-root': {
            color: theme.palette.valueRed,
        },
    },
    arrowIcon: {
        marginLeft: '10px',
    },
    bodyContainer: {
        display: 'flex',
        margin: '0px auto',
        height: '100%',
        maxWidth: 1170,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: '88px',
        '@media (max-width: 768px)': {
            display: 'inline-block',
        },
    },
    bodyLeft: {
        display: 'block',
        margin: '0px auto',
        paddingTop: '40px',
        textAlign: 'left',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '75px',
        letterSpacing: 'normal',
    },
    bodyRight: {
        margin: '0px auto',
        paddingLeft: 20,
        alignItems: 'center',
        '@media (max-width: 768px)': {
            paddingTop: '16px',
            paddingLeft: 0,
        },
    },
    formTitleLine: {
        display: 'block',
    },
    formTitle: {
        width: 'auto',
        position: 'left',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        fontSize: 88,
        margin: 0,
        textAlign: 'left',
        display: 'inline',
        color: theme.palette.standardWhite,
    },
    standardWhite: {
        color: theme.palette.standardWhite,
    },
    title: {
        fontFamily: 'HumanBBYDigital',
        paddingTop: '40px',
        fontSize: '18px',
        lineHeight: '22px',
        fontWeight: 700,
        color: theme.palette.borderGray,
        paddingBottom: '16px',
    },
    passwordBodyRight: {
        margin: '0px auto',
        alignItems: 'center',
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        '@media (max-width: 768px)': {
            paddingTop: '56px',
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
    passwordFormTitle: {
        width: 'auto',
        position: 'relative',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        fontSize: 88,
        margin: 0,
        textAlign: 'left',
        display: 'inline',
    },
    passwordFormButton: {
        padding: 0,
        backgroundColor: theme.palette.techBlack,
        color: theme.palette.techYellow,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        fontSize: 22,
        textAlign: 'left',
        textTransform: 'none',
        marginLeft: 45,
        marginTop: 16,
        '&:hover': {
            color: theme.palette.darkYellow,
        },
        '&:disabled': {
            backgroundColor: theme.palette.techBlack,
            color: theme.palette.disabledYellow,
            opacity: '0.6',
            cursor: 'default',
        },
    },
    termsAndConditionsBlock: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 18,
        marginBottom: 10,
    },
    termsAndConditionsCheckbox: {
        width: 24,
        height: 24,
        marginRight: '12px !important',
        padding: '0 !important',
        color: '#e0e6ef !important',
        '&.Mui-checked': {
            color: '#fff200 !important',
        },
    },
    checkboxLabel: {
        color: theme.palette.techWhite,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 700,
    },
    link: {
        color: theme.palette.techWhite,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 700,
    },
}));

export default useStyles;
