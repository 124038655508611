export const SPONSORED_TAB_DEFAULT_KEY = 'product';

export const sponsoredTabs = {
    product: {
        label: 'Product',
        key: 'product',
    },
    keyword: {
        label: 'Keyword',
        key: 'keyword',
    },
    placement: {
        label: 'Placement',
        key: 'placement',
    },
    platform: {
        label: 'Platform',
        key: 'platform',
    },
    opportunities: {
        label: 'Opportunities',
        key: 'opportunities',
    },
};
