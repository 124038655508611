module.exports = Object.freeze({
    DEFAULT_TYPE: 'default',
    CONFIRM_TYPE: 'confirm',
    ADD_TYPE: 'add',
    MEMO_TYPE: 'add',
    INPUT_TYPE: 'input',
    MODIFY_TYPE: 'modify',
    BACKUP_TYPE: 'backup',

    DISPLAY_GROUP_TYPE: 'display',

    VALIDATE_ON_SUBMIT: 'submit',
    VALIDATE_ON_MODIFIED: 'modified',
    VALIDATE_ON_TOUCHED: 'touched',
});
