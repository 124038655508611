import React from 'react';

function ErrorWithContact(message, linkText = 'contact support', classes) {
    const [start, end] = message.split(linkText);
    return (
        <div style={{ paddingTop: '16px' }}>
            {start}
            <a className={classes ? classes.title : ''} href="mailto:myadssupport@bestbuy.com">
                {linkText}
            </a>
            {end}
        </div>
    );
}

export default ErrorWithContact;
