/**
 *
 * VehicleType
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CriteoLogo from 'assets/criteo.png';
import { SPONSORED_KEY, SPONSORED_RMP_KEY } from 'Helpers/helpers';

const useStyles = makeStyles(() => ({
    type: {
        outline: 'none',
        boxShadow: 'none',
        paddingRight: 6,
    },
    logo: {
        width: 33,
        verticalAlign: 'middle',
    },
}));

function VehicleType({ vehicle, handleClick }) {
    const classes = useStyles();

    const isSponsored = vehicle.getVehicleKey() === SPONSORED_KEY || vehicle.getVehicleKey() === SPONSORED_RMP_KEY;
    const type = vehicle.getLabel();

    if (handleClick) {
        return (
            <>
                <span
                    role="button"
                    tabIndex={0}
                    onClick={handleClick}
                    onKeyPress={handleClick}
                    className={classes.type}
                >
                    {type}
                </span>
                {isSponsored && <img className={classes.logo} src={CriteoLogo} alt={type} />}
            </>
        );
    }

    return (
        <>
            <span className={classes.type}>{type}</span>
            {isSponsored && <img className={classes.logo} src={CriteoLogo} alt={type} />}
        </>
    );
}

VehicleType.propTypes = {
    vehicle: PropTypes.object.isRequired,
    handleClick: PropTypes.func,
};

export default VehicleType;
