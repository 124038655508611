/**
 *
 * MultiCheckSelect
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { kebabCase, last } from 'lodash';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.standardWhite,
        '& .MuiAutocomplete-inputRoot': {
            padding: '4px',
        },
    },
    chips: {
        marginTop: 4,
        overflowY: 'scroll',
        maxHeight: 156,
        '& .MuiChip-root': {
            borderRadius: 4,
            margin: 4,
        },
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.shadowGray,
            borderRadius: '18px',
        },
    },
    check: {
        '& .MuiIconButton-colorSecondary': {
            color: theme.palette.humanBlue,
        },
    },
    option: {
        padding: '6px 12px',
        '&[aria-selected="true"]': {
            backgroundColor: theme.palette.techWhite,
            color: theme.palette.darkGray,
        },
    },
}));

function MultiCheckSelect({
    multiple,
    checkbox,
    options,
    values,
    placeholder,
    onChange,
    label,
    onBlur,
    onInputChange,
    noOptionsText,
}) {
    const classes = useStyles();

    const handleAutocompleteChange = (event, newValues) => {
        if (multiple) {
            onChange(newValues);
        } else {
            const lastValue = last(newValues);
            onChange(lastValue ? [lastValue] : []);
        }
    };

    const handleDelete = (deletedValue) => {
        const newValues = values.filter(({ value }) => value !== deletedValue);
        onChange(newValues);
    };

    return (
        <div className={classes.root}>
            <Autocomplete
                multiple
                id={`checkboxes-multiselect-component-${kebabCase(placeholder)}`}
                options={options}
                openOnFocus
                disableCloseOnSelect={multiple}
                getOptionLabel={(option) => option.name}
                onChange={handleAutocompleteChange}
                onInputChange={onInputChange}
                onBlur={onBlur}
                blurOnSelect
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={label} placeholder={placeholder} />
                )}
                classes={{
                    option: classes.option,
                }}
                getOptionSelected={(option, value) => option.value === value.value}
                value={values}
                defaultChecked
                noOptionsText={noOptionsText}
                renderTags={() => {}}
                renderOption={(option, { selected }) => (
                    <div className={classes.optionContainer}>
                        {checkbox && multiple && (
                            <Checkbox
                                className={classes.check}
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                        )}
                        {option.name}
                    </div>
                )}
            />
            {Array.isArray(values) && (
                <div className={classes.chips}>
                    {values.map(({ name, value }) => (
                        <Chip key={name + value} variant="outlined" label={name} onDelete={() => handleDelete(value)} />
                    ))}
                </div>
            )}
        </div>
    );
}

MultiCheckSelect.defaultProps = {
    multiple: true,
    checkbox: true,
    onChange: () => {},
};

MultiCheckSelect.propTypes = {
    multiple: PropTypes.bool,
    checkbox: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
        }),
    ),
    values: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
        }),
    ),
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    label: PropTypes.string,
    onBlur: PropTypes.func,
    organizationSelected: PropTypes.bool,
    selectType: PropTypes.string,
    setOrganizationSelected: PropTypes.func,
    noOptionsText: PropTypes.string,
};

export default MultiCheckSelect;
