import env from 'config/env';

const initialState = {
    showDashboard: env.BBY_ENV !== 'production',
    showPendingCampaigns: env.BBY_ENV === 'development' || env.BBY_ENV === 'local',
    showSummaryDashboard: env.BBY_ENV !== 'production',
    showDevPage: env.BBY_ENV !== 'production',
    showIdamPhone: false,
    showUserManagement: true,
    sendToKoddi: true,
    showPaidSearchPtc: env.BBY_ENV !== 'production',
    ...env.FEATURE_FLAGS,
};

function featureFlagReducer(state = initialState) {
    return state;
}

export default featureFlagReducer;
