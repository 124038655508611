/**
 * This component to set customize icon
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
const LoginHeaderImage = require('assets/bby_ads_logo.svg').default;

const useStyles = makeStyles(() => ({
    headerImage: {
        height: 32,
        objectFit: 'contain',
    },
}));

const BestBuyAdsLogoLink = () => {
    const classes = useStyles();

    return (
        <a rel="noopener" target="_blank" href="https://www.bestbuyads.com/">
            <img src={LoginHeaderImage} className={classes.headerImage} height="100%" alt="" />
        </a>
    );
};

export default BestBuyAdsLogoLink;
