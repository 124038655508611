import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classnames from 'classnames';
import { Form, Field } from 'react-final-form';
import { RequestStatus } from 'shared/constants';

import { required, isValidCode, composeValidators } from 'utils/validators';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Input from './Input';
import useStyles from '../formStyle';

const otpForm = ({ onSubmit, onSendCode, subscription, status, type }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const classes = useStyles();
    const loading = status.loading || status.checking;
    const codeSent = status.otpSendStatus !== RequestStatus.INIT;
    const { error } = status;

    let prompt;
    if (type === 'phone') {
        prompt = 'Check your mobile phone for your access code.';
    } else if (codeSent === false) {
        prompt = 'Click "Send Code" to receive the email Security code.';
    } else if (type === 'reset') {
        prompt = 'If email address is found, an email with instructions on how to set a new password will be sent.';
    } else {
        prompt = 'Check your email to receive the verification code.';
    }

    return (
        <div className={classes.formContainer} id="otp-form-container">
            <Form
                onSubmit={onSubmit}
                subscription={subscription}
                render={({ handleSubmit, form: { reset }, pristine, hasValidationErrors }) => (
                    <form
                        onSubmit={handleSubmit}
                        className={error && typeof error.message === 'string' ? classes.submitError : null}
                    >
                        <div className={classnames(classes.prompt)}>
                            <span data-testid="otp-prompt">{prompt}</span>
                        </div>
                        {!codeSent && (
                            <Button
                                className={classes.sendCodeButton}
                                onClick={onSendCode}
                                endIcon={<ArrowForwardIcon />}
                            >
                                Send Code
                            </Button>
                        )}
                        {codeSent && (
                            <div>
                                <div>
                                    <Field
                                        validate={composeValidators(required, isValidCode)}
                                        name="code" // how field is identified
                                        type="code"
                                        inputLabel="Code" // label above input
                                        reset={reset}
                                        disabled={loading}
                                        id="otp-form-code-input"
                                    >
                                        {Input}
                                    </Field>
                                </div>
                                {error && (
                                    <div className={classnames(classes.submitError, classes.errorStyle)}>
                                        <span data-testid="otp-submit-error">{error}</span>
                                    </div>
                                )}
                                <div>
                                    <Button
                                        data-testid="otp-form-button"
                                        className={classnames(classes.formButton)}
                                        disabled={loading || hasValidationErrors || pristine}
                                        type="submit"
                                        endIcon={<ArrowForwardIcon className={classes.arrowIcon} />}
                                    >
                                        {status.loading ? 'Checking ...' : 'Submit'}
                                    </Button>
                                    <Button className={classes.resendCodeButton} onClick={onSendCode}>
                                        Resend Code
                                    </Button>
                                </div>
                            </div>
                        )}
                    </form>
                )}
            />
        </div>
    );
};

otpForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    subscription: PropTypes.shape({
        submitting: PropTypes.bool,
        pristine: PropTypes.bool,
        submitError: PropTypes.bool,
        hasValidationErrors: PropTypes.bool,
    }).isRequired,
    status: PropTypes.object,
    type: PropTypes.string,
};

export default otpForm;
