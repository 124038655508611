import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from 'components/Datepicker/Loadable';
import moment from 'moment-timezone';
import { createStructuredSelector } from 'reselect';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import PropTypes from 'prop-types';
import { selectDateFilter } from '../../containers/SummaryDashboardPage/selectors';
import { updateDateFilter } from '../../containers/SummaryDashboardPage/actions';
import reducer from '../../containers/SummaryDashboardPage/reducer';

const useStyles = makeStyles((theme) => ({
    addToRoot: {
        '&  .DateRangePickerInput': {
            display: 'flex',
        },
        '& .DateRangePicker_picker.DateRangePicker_picker_1.DateRangePicker_picker__directionRight.DateRangePicker_picker__directionRight_2': {
            top: '90px !important',
        },
        '& .DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_2': {
            backgroundColor: 'white',
            height: '46px',
            borderRadius: '50px',
            border: `1px solid ${theme.palette.borderGray}`,
            margin: '2rem 0',
            alignItems: 'center',
            justifyContent: 'space-between',
            '&:hover': {
                backgroundColor: '#f1f8ff',
            },
        },
        '& .DateRangePicker .DateRangePickerInput_arrow ': {
            margin: 7,
            marginRight: -6,
            width: 5,
        },
        '& .DateInput': {
            marginLeft: 15,
        },
        '& .DateInput__small': {
            width: 'auto',
        },
        '& .DateInput_input__small': {
            padding: '5 0',
        },
    },
}));

function DatePickerWrapper({ dateFilter, onDateFilterChange }) {
    useInjectReducer({ key: 'summaryDashboardPage', reducer });

    const classes = useStyles();

    const summaryFilterMinDate = moment('2021-02-01');
    const summaryFilterMaxDate = moment();
    const summaryFilterDefaultStartDate = moment().subtract('30', 'days');
    const summaryFilterDefaultEndDate = moment();

    return (
        <DatePicker
            styles={classes}
            resetStartDate={summaryFilterDefaultStartDate.format(moment.HTML5_FMT.DATE)}
            resetEndDate={summaryFilterDefaultEndDate.format(moment.HTML5_FMT.DATE)}
            startDate={dateFilter.startDate}
            endDate={dateFilter.endDate}
            minDate={summaryFilterMinDate.format(moment.HTML5_FMT.DATE)}
            maxDate={summaryFilterMaxDate.format(moment.HTML5_FMT.DATE)}
            onSubmit={({ startDate, endDate }) => {
                onDateFilterChange(startDate, endDate);
            }}
        />
    );
}

DatePickerWrapper.propTypes = {
    dateFilter: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    }),
    onDateFilterChange: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    dateFilter: selectDateFilter,
});

export function mapDispatchToProps(dispatch) {
    return {
        onDateFilterChange: (startDate, endDate) => {
            dispatch(updateDateFilter(startDate, endDate));
        },
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(DatePickerWrapper);
