import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AlertBanner from 'components/AlertBanner/index';
import env from 'config/env';
import { logout as logoutAction } from 'containers/App/actions';
import { useInjectSaga } from 'redux-injectors';

import { selectAlertsToShow } from './selector';
import { forceBannerUpdate, loadAlertsAction } from './slice';
import alertDismiss from './alertDismiss';
import { alertSaga } from './saga';

const ALERT_URL = `${env.GATEWAY_BASE_URL}/v1/sse/alerts`;

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    body: {
        flex: '1 1 100%',
        overflowX: 'scroll',
    },
}));

const SSE_STATUS_CLOSED = 2;

const RECONNECT_TIME = 30000;

const AlertBannerLayout = ({ alertsToShow, loadAlerts, forceUpdate, children }) => {
    useInjectSaga({ key: 'alertSaga', saga: alertSaga });
    const classes = useStyles();
    const sseSource = useRef(null);

    const onOpen = (event) => {
        // eslint-disable-next-line no-console
        console.log(`SSE opened! -- ${JSON.stringify(event)} `);
    };

    const onError = (event) => {
        const currentSource = sseSource.current;
        if (currentSource) {
            // eslint-disable-next-line no-console
            console.log(`SSE onError with state: ${currentSource.readyState}: Error: ${JSON.stringify(event)}`);

            // Reconnect to SSE server in 30 seconds if the connection is closed
            if (currentSource.readyState === SSE_STATUS_CLOSED) {
                currentSource.close();
                sseSource.current = null;
                setTimeout(() => startSSE(), RECONNECT_TIME);
            }
        }
    };

    const onMessage = (event) => {
        loadAlerts(JSON.parse(event.data));
    };

    const startSSE = () => {
        sseSource.current = new EventSource(ALERT_URL);
        sseSource.current.onopen = onOpen;
        sseSource.current.onerror = onError;
        sseSource.current.onmessage = onMessage;
    };

    useEffect(() => {
        startSSE();
        return () => {
            sseSource.current?.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dismissAlert = (id) => {
        const alertToDismiss = alertsToShow.find((a) => a.id === id);
        alertDismiss.write(alertToDismiss);
        forceUpdate();
    };

    return (
        <div className={classes.root}>
            {alertsToShow.length > 0 && (
                <AlertBanner alertBannerData={alertsToShow} dismissAlert={(id) => dismissAlert(id)} />
            )}

            <div className={classes.body}>{children}</div>
        </div>
    );
};

AlertBannerLayout.propTypes = {
    children: PropTypes.element.isRequired,
    alertsToShow: PropTypes.arrayOf(PropTypes.object),
    loadAlerts: PropTypes.func,
    forceUpdate: PropTypes.func,
};

const mapStateToProps = (state) => ({
    alertsToShow: selectAlertsToShow(state),
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logoutAction()),
    loadAlerts: (alerts) => dispatch(loadAlertsAction(alerts)),
    forceUpdate: () => dispatch(forceBannerUpdate()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withConnect(AlertBannerLayout);
