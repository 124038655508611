import {
    CAMPAIGN_TYPE_DISPLAY,
    CAMPAIGN_TYPE_OFFSITE_DISPLAY,
    CAMPAIGN_TYPE_SPONSORED_RMP,
    CAMPAIGN_TYPE_SPONSORED_RSX,
    CAMPAIGN_TYPE_PAID_SEARCH,
    CAMPAIGN_TYPE_PROXIMITY,
    CAMPAIGN_TYPE_SOCIAL,
    CAMPAIGN_TYPE_OFFSITE_VIDEO,
    CAMPAIGN_TYPE_ONSITE_VIDEO,
} from 'Helpers/helpers';

export const report_path = ['/campaigns/details', '/campaigns/stacked/details'];
export const login_path = ['/login', '/idamlogin'];
export const unauth_path = [
    '/login',
    '/idamlogin',
    '/registration',
    '/otp',
    '/idamcreatepassword',
    '/idamresetpassword',
    '/idam/thankyou',
    '/dev',
];

export const PathEnum = {
    ALL: { path: [''], value: 0 },
    LOGIN: { path: ['/login', '/idamlogin'], value: 1 },
    USER: { path: ['/management/user/all'], value: 2 },
    CAMPAIGN_LIST: { path: ['/campaigns'], value: 3 },
    REPORT_ONSITE_DISPLAY: {
        path: report_path,
        value: 4,
        type: CAMPAIGN_TYPE_DISPLAY,
    },
    REPORT_OFFSITE_DISPLAY: {
        path: report_path,
        value: 5,
        type: CAMPAIGN_TYPE_OFFSITE_DISPLAY,
    },
    REPORT_SPONSORED_RMP: {
        path: report_path,
        value: 6,
        type: CAMPAIGN_TYPE_SPONSORED_RMP,
    },
    REPORT_SPONSORED_RSX: {
        path: report_path,
        value: 7,
        type: CAMPAIGN_TYPE_SPONSORED_RSX,
    },
    REPORT_PAID_SEARCH: {
        path: report_path,
        value: 8,
        type: CAMPAIGN_TYPE_PAID_SEARCH,
    },
    REPORT_PROXIMITY: {
        path: report_path,
        value: 9,
        type: CAMPAIGN_TYPE_PROXIMITY,
    },
    REPORT_SOCIAL: {
        path: report_path,
        value: 10,
        type: CAMPAIGN_TYPE_SOCIAL,
    },
    REPORT_OFFSITE_VIDEO: {
        path: report_path,
        value: 11,
        type: CAMPAIGN_TYPE_OFFSITE_VIDEO,
    },
    REPORT_ONSITE_VIDEO: {
        path: report_path,
        value: 12,
        type: CAMPAIGN_TYPE_ONSITE_VIDEO,
    },
};

export const findPathEnumByValue = (value) => Object.values(PathEnum).find((enumValue) => enumValue.value === value);

export const ALERT_TIMER_ID_KEY = 'alert_timer_id';
