import env from 'config/env';
import { difference, intersection } from 'lodash';

const TEST_USER_AID = 'a0000001';
const TEST_ADMIN_AID = 'a0000002';
const MYADS_ADMIN = 'MyAds_System_Admin';
const INTERNAL_DEVELOPER = 'Internal_Developer';

export const CAMPAIGN_TYPE_DISPLAY = 'Onsite Display';
export const CAMPAIGN_TYPE_OFFSITE_DISPLAY = 'Offsite Display';
export const CAMPAIGN_TYPE_OFFSITE_VIDEO = 'Offsite Video';
export const CAMPAIGN_TYPE_ONSITE_VIDEO = 'Onsite Video';
export const CAMPAIGN_TYPE_SPONSORED_RSX = 'Sponsored Product - RSX';
export const CAMPAIGN_TYPE_SPONSORED_RMP = 'Sponsored Product - RMP';
export const CAMPAIGN_TYPE_PROXIMITY = 'Proximity';
export const CAMPAIGN_TYPE_PAID_SEARCH = 'Paid Search';
export const CAMPAIGN_TYPE_SOCIAL = 'Social';
export const CAMPAIGN_TYPE_UNKNOWN = 'unknownCampaignType';

export const DISPLAY_KEY = 'display';
export const OFFSITE_DISPLAY_KEY = 'displayOffsite';
export const OFFSITE_VIDEO_KEY = 'offsiteVideo';
export const ONSITE_VIDEO_KEY = 'onsiteVideo';
export const SPONSORED_KEY = 'sponsored';
export const PROXIMITY_KEY = 'proximity';
export const SPONSORED_RSX_KEY = 'sponsored';
export const SPONSORED_RMP_KEY = 'sponsoredRmp';
export const PAID_SEARCH_KEY = 'paidSearchSku';
export const SOCIAL_KEY = 'social';

export const CAMPAIGN_STATUS_ACTIVE = 'Active';
export const ANALYTICS_USER_TYPE = {
    EXTERNAL: 'EXTERNAL',
    INTERNAL: 'INTERNAL',
};

export const hasOrganizationRoles = (authedUser) =>
    intersection(authedUser?.roles, [INTERNAL_DEVELOPER, MYADS_ADMIN]).length > 0;

export const hasTestingPermission = (user) =>
    user && typeof user.aid === 'string' && [TEST_USER_AID, TEST_ADMIN_AID].includes(user.aid.toLowerCase());

/**
 * Check if the user has at least 1 permission from given permission list
 */
export const hasPermission = (user, givenPermissions) => intersection(user?.permissions, givenPermissions).length > 0;

/**
 * Check if the user has all permissions from given permission list
 */
export const hasAllPermission = (user, givenPermissions) =>
    difference(givenPermissions, user?.permissions).length === 0;

export const isDisplay = (campaign) => campaign?.campaign_type === CAMPAIGN_TYPE_DISPLAY;

export const isOffsiteDisplay = (campaign) => campaign?.campaign_type === CAMPAIGN_TYPE_OFFSITE_DISPLAY;

// unsure if needed
export const isOffsiteVideo = (campaign) => campaign?.campaign_type === CAMPAIGN_TYPE_OFFSITE_VIDEO;

export const isSponsored = (campaign) =>
    campaign?.campaign_type === CAMPAIGN_TYPE_SPONSORED_RSX || campaign?.campaign_type === CAMPAIGN_TYPE_SPONSORED_RMP;

export const isProximity = (campaign) => campaign?.campaign_type === CAMPAIGN_TYPE_PROXIMITY;

export const isPaidSearch = (campaign) => campaign?.campaign_type === CAMPAIGN_TYPE_PAID_SEARCH;

export const isSponsoredRSX = (campaign) => campaign?.campaign_type === CAMPAIGN_TYPE_SPONSORED_RSX;

export const isSponsoredRMP = (campaign) => {
    const type = campaign?.type ? campaign.type : campaign?.campaign_type;
    return type === CAMPAIGN_TYPE_SPONSORED_RMP;
};

export const isSocial = (campaign) => campaign?.campaign_type === CAMPAIGN_TYPE_SOCIAL;

export const isStackableType = (campaign) => {
    const type = campaign?.type ? campaign.type : campaign?.campaign_type;
    return type === CAMPAIGN_TYPE_DISPLAY || type === CAMPAIGN_TYPE_SPONSORED_RMP;
};

// returns whether the current user is internal or external
export const getUserType = (user) => (userIsExternal(user) ? 'external' : 'internal');

// Add the environment name to the downloaded file.
// Examples:
// Template: <Campaign-Name><environment>.xlsx
// Prod: <Campaign-Name>.xlsx (Only campaign name, no suffix)
// Staging: <Campaign-Name>-stage.xlsx
// Dev: <Campaign-Name>-dev.xlsx

export const generateFileName = (name) => {
    let fileName = `${name}.xlsx`;
    if (env.BBY_ENV === 'development') {
        fileName = `${name}-dev.xlsx`;
    } else if (env.BBY_ENV === 'stage') {
        fileName = `${name}-stage.xlsx`;
    }

    return fileName;
};

export const userIsExternal = (user) => (user.isExtUser !== undefined ? user.isExtUser : !user.aid);
