import { fetchCSRFToken } from 'api/user-management';

let csrfToken = null;

export async function retrieveCSRFToken() {
    csrfToken = fetchCSRFToken().then((tokenInfo) => tokenInfo.csrf);
}

export function getCSRFToken() {
    return csrfToken;
}

export function clearCSRFToken() {
    csrfToken = null;
}
