export const MOCK_ALERT_KEY = 'bestbuy-alert-mock';
export const mockAlerts = [
    {
        id: 1,
        active: true,
        alertLevel: 0,
        title: '#1 Maintenance',
        message: 'Maintenance alert message',
        dismissEnabled: false,
        startTime: '1 JAN 2023 00:00:01 EST',
        endTime: '31 DEC 2023 11:59:59 EST',
        loginRequired: false,
        page: 0,
    },
    {
        id: 2,
        active: true,
        alertLevel: 1,
        title: '#2 Critical',
        message: 'Critical alert message shown on login page',
        dismissEnabled: true,
        startTime: '1 JAN 2023 00:00:01 EST',
        endTime: '31 DEC 2023 11:59:59 EST',
        loginRequired: false,
        page: 1,
    },
    {
        id: 3,
        active: true,
        alertLevel: 2,
        title: '#3 Minor Alert',
        message: 'Minor alert shown on all page',
        dismissEnabled: true,
        startTime: '1 JAN 2023 00:00:01 EST',
        endTime: '31 DEC 2023 11:59:59 EST',
        loginRequired: false,
        page: 0,
    },
    {
        id: 4,
        active: true,
        alertLevel: 2,
        title: '#4 Minor Alert',
        message: 'Minor alert shown on Onsite Display report page',
        dismissEnabled: true,
        startTime: '1 JAN 2023 00:00:01 EST',
        endTime: '31 DEC 2023 11:59:59 EST',
        loginRequired: true,
        page: 4,
    },
    {
        id: 5,
        active: true,
        alertLevel: 3,
        title: '#5 Improvement Message',
        message: 'Improvement alert message on all auth pages',
        dismissEnabled: true,
        startTime: '1 JAN 2023 00:00:01 EST',
        endTime: '31 DEC 2023 11:59:59 EST',
        loginRequired: true,
        page: 0,
    },
    {
        id: 6,
        active: true,
        alertLevel: 4,
        title: '#6 General Message',
        message: 'General alert message on all pages',
        dismissEnabled: true,
        startTime: '1 JAN 2023 00:00:01 EST',
        endTime: '31 DEC 2023 11:59:59 EST',
        loginRequired: false,
        page: 0,
    },
    {
        id: 7,
        active: true,
        alertLevel: 1,
        title: '#7 Critical',
        message: 'Critical alert message shown on all pages without dismiss',
        dismissEnabled: false,
        startTime: '1 JAN 2023 00:00:01 EST',
        endTime: '31 DEC 2023 11:59:59 EST',
        loginRequired: false,
        page: 0,
        url: 'resources',
    },
    {
        id: 8,
        active: false,
        alertLevel: 1,
        title: '#8 Critical',
        message: 'Inactive alert should not shown on all pages',
        dismissEnabled: true,
        startTime: '1 JAN 2023 00:00:01 EST',
        endTime: '31 DEC 2023 11:59:59 EST',
        loginRequired: false,
        page: 0,
        url: 'resources',
    },
    {
        id: 9,
        active: true,
        alertLevel: 1,
        title: '#9 Critical',
        message: 'Critical Scheduled maintenance Thursday, 4/16 from 00:00 am - 11:59 am EST.',
        dismissEnabled: true,
        startTime: '16 APR 2023 00:00:01 EST',
        endTime: '16 APR 2023 11:59:59 EST',
        loginRequired: false,
        page: 0,
        url: 'resources',
    },
];
