export function filterMatch(item, filterChecks) {
    return filterChecks.every((check) => check(item));
}

export function updateStat(item, prevStat, filterChecks = []) {
    if (filterMatch(item, filterChecks)) {
        return prevStat ? prevStat + 1 : 1;
    }

    return prevStat;
}

export function activeFilterStyling(currentCampaignListFilter, filterActiveStyleClass, filterNonActiveStyling) {
    let isActive = false;

    if (currentCampaignListFilter) {
        Object.values(currentCampaignListFilter).forEach((value) => {
            if (value?.length > 0) {
                isActive = true;
            }
        });
    }

    if (isActive) {
        return filterActiveStyleClass;
    }

    return filterNonActiveStyling;
}
