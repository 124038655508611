/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import {
    LOAD_IDAM_USERS,
    LOAD_IDAM_USERS_ERROR,
    LOAD_IDAM_USERS_SUCCESS,
} from 'containers/IDAMUserManagement/UserListPage/constants';
import produce from 'immer';
import { RequestStatus } from 'shared/constants';
import { REINVITE_IDAM_USERS_SUCCESS } from 'containers/IDAMUserManagement/ReInviteUserContent/actions';
import {
    LOAD_CAMPAIGNS,
    LOAD_CAMPAIGNS_SUCCESS,
    LOAD_CAMPAIGNS_ERROR,
    LOAD_AUTHENTICATED_USER,
    UNLOAD_AUTHENTICATED_USER,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    CREATE_CAMPAIGN_GROUP,
    CREATE_CAMPAIGN_GROUP_SUCCESS,
    CREATE_CAMPAIGN_GROUP_ERROR,
    DELETE_CAMPAIGN_GROUP,
    DELETE_CAMPAIGN_GROUP_SUCCESS,
    DELETE_CAMPAIGN_GROUP_ERROR,
    GET_CAMPAIGN_GROUPS,
    GET_CAMPAIGN_GROUPS_SUCCESS,
    GET_CAMPAIGN_GROUPS_ERROR,
    ADD_CAMPAIGNS_TO_GROUP,
    ADD_CAMPAIGNS_TO_GROUP_SUCCESS,
    ADD_CAMPAIGNS_TO_GROUP_ERROR,
    REMOVE_CAMPAIGNS_FROM_GROUP,
    REMOVE_CAMPAIGNS_FROM_GROUP_SUCCESS,
    REMOVE_CAMPAIGNS_FROM_GROUP_ERROR,
    UPDATE_CAMPAIGN_GROUP,
    UPDATE_CAMPAIGN_GROUP_SUCCESS,
    UPDATE_CAMPAIGN_GROUP_ERROR,
    SHARE_CAMPAIGN_GROUP,
    SHARE_CAMPAIGN_GROUP_SUCCESS,
    SHARE_CAMPAIGN_GROUP_ERROR,
    GET_SHARED_CAMPAIGN_GROUPS,
    GET_SHARED_CAMPAIGN_GROUPS_SUCCESS,
    GET_SHARED_CAMPAIGN_GROUPS_ERROR,
    RESET_DONE,
    GET_USER_INFO,
    GET_USER_ERROR,
} from './constants';

// The initial state of the App
export const initialState = {
    loading: false,
    error: false,
    campaignLoadStatus: RequestStatus.INIT,
    campaigns: [],
    campaignFilters: {},

    authedUser: { loadState: RequestStatus.INIT },
    createdUser: undefined,

    users: [],
    usersLoading: false,
    usersError: undefined,

    createUserLoading: false,
    createUserError: undefined,

    updateUserLoading: false,
    updateUserError: undefined,
    updateUserDone: false,
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case LOAD_CAMPAIGNS:
                draft.loading = true;
                draft.error = false;
                draft.campaignLoadStatus = RequestStatus.LOADING;
                draft.campaigns = [];
                break;
            case LOAD_CAMPAIGNS_SUCCESS:
                draft.campaigns = action.campaigns;
                draft.campaignFilters = action.campaignFilters;
                draft.campaignLoadStatus = RequestStatus.DONE;
                draft.loading = false;
                break;
            case LOAD_CAMPAIGNS_ERROR:
                draft.error = action.error;
                draft.campaignLoadStatus = RequestStatus.ERROR;
                draft.loading = false;
                break;
            case GET_USER_INFO:
                draft.authedUser.loadState = RequestStatus.LOADING;
                break;
            case GET_USER_ERROR:
                draft.authedUser.loadState = RequestStatus.ERROR;
                break;
            case LOAD_AUTHENTICATED_USER:
                draft.authedUser = {
                    ...action.authedUser,
                    loadState: RequestStatus.DONE,
                };
                break;
            case UNLOAD_AUTHENTICATED_USER:
                draft.authedUser = { loadState: RequestStatus.INIT };
                break;
            case LOAD_IDAM_USERS:
                draft.usersLoading = true;
                draft.usersError = undefined;
                draft.users = [];
                break;
            case LOAD_IDAM_USERS_SUCCESS:
                draft.users = action.users;
                draft.usersLoading = false;
                draft.usersError = undefined;
                break;
            case LOAD_IDAM_USERS_ERROR:
                draft.usersLoading = false;
                draft.usersError = action.error;
                break;

            case CREATE_USER:
                draft.createUserLoading = true;
                draft.createUserError = undefined;
                break;
            case CREATE_USER_SUCCESS:
                draft.createdUser = action.user;
                draft.users = (() => {
                    const { users } = draft;
                    users.unshift(action.user);
                    return users;
                })();
                draft.createUserLoading = false;
                draft.createUserError = undefined;
                break;
            case CREATE_USER_ERROR:
                draft.createUserLoading = false;
                draft.createUserError = action.error;
                break;
            case UPDATE_USER:
                draft.updateUserLoading = true;
                draft.updateUserError = undefined;
                draft.updateUserDone = false;
                break;
            case UPDATE_USER_ERROR:
                draft.updateUserLoading = false;
                draft.updateUserError = action.error;
                draft.updateUserDone = true;
                break;
            case REINVITE_IDAM_USERS_SUCCESS:
            case UPDATE_USER_SUCCESS:
                draft.updateUserLoading = false;
                draft.updateUserError = undefined;
                draft.updateUserDone = true;
                draft.users = draft.users.map((user) =>
                    user.uuid === action.user.uuid ? { ...user, ...action.user } : user,
                );

                break;
            case CREATE_CAMPAIGN_GROUP:
                draft.campaignGroupsLoading = true;
                draft.removeCampaignFromGroupDone = false;
                draft.createCampaignGroupDone = false;
                break;
            case DELETE_CAMPAIGN_GROUP:
                draft.campaignGroupsLoading = true;
                draft.deleteCampaignGroupDone = false;
                break;
            case GET_CAMPAIGN_GROUPS:
            case GET_SHARED_CAMPAIGN_GROUPS:
                draft.campaignGroupsLoading = true;
                draft.removeCampaignFromGroupDone = false;
                break;
            case REMOVE_CAMPAIGNS_FROM_GROUP:
                draft.campaignGroupsLoading = true;
                draft.removeCampaignFromGroupDone = false;
                break;
            case UPDATE_CAMPAIGN_GROUP:
                draft.campaignGroupsLoading = true;
                draft.updateCampaignGroupDone = false;
                break;
            case ADD_CAMPAIGNS_TO_GROUP:
                draft.addCampaignsToGroupLoading = true;
                draft.addCampaignsToGroupDone = false;
                break;
            case SHARE_CAMPAIGN_GROUP:
                draft.campaignGroupsUpdateLoading = true;
                draft.shareCampaignGroupDone = false;
                draft.unshareCampaignGroupDone = false;
                break;
            case CREATE_CAMPAIGN_GROUP_ERROR:
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = action.error;
                draft.createCampaignGroupDone = true;
                break;
            case DELETE_CAMPAIGN_GROUP_ERROR:
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = action.error;
                draft.deleteCampaignGroupDone = true;
                break;
            case GET_CAMPAIGN_GROUPS_ERROR:
            case REMOVE_CAMPAIGNS_FROM_GROUP_ERROR:
            case GET_SHARED_CAMPAIGN_GROUPS_ERROR:
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = action.error;
                draft.removeCampaignFromGroupDone = true;
                draft.updateCampaignGroupDone = true;
                break;
            case UPDATE_CAMPAIGN_GROUP_ERROR:
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = action.error;
                draft.updateCampaignGroupDone = true;
                break;
            case ADD_CAMPAIGNS_TO_GROUP_ERROR:
                draft.addCampaignsToGroupError = action.error;
                draft.addCampaignsToGroupLoading = false;
                draft.addCampaignsToGroupDone = true;
                break;
            case SHARE_CAMPAIGN_GROUP_ERROR:
                draft.campaignGroupUpdateError = action.error;
                draft.campaignGroupsUpdateLoading = false;
                draft.shareCampaignGroupDone = true;
                draft.unshareCampaignGroupDone = true;
                break;
            case CREATE_CAMPAIGN_GROUP_SUCCESS:
                draft.campaignGroups.push(action.data);
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = undefined;
                draft.createCampaignGroupDone = true;
                break;
            case DELETE_CAMPAIGN_GROUP_SUCCESS:
                draft.campaignGroups = draft.campaignGroups.filter((group) => group.groupId !== action.groupId);
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = undefined;
                draft.deleteCampaignGroupDone = true;
                break;
            case UPDATE_CAMPAIGN_GROUP_SUCCESS:
                draft.campaignGroups = draft.campaignGroups.map((group) =>
                    group.groupId === action.data.groupId ? action.data : group,
                );
                draft.sharedCampaignGroups = draft.sharedCampaignGroups.map((group) =>
                    group.groupId === action.data.groupId ? action.data : group,
                );
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = undefined;
                draft.updateCampaignGroupDone = true;
                break;
            case GET_CAMPAIGN_GROUPS_SUCCESS:
                draft.campaignGroups = action.data;
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = undefined;
                break;
            case ADD_CAMPAIGNS_TO_GROUP_SUCCESS:
                draft.campaignGroups.find((group) => group.groupId === action.data.groupId).campaignIds =
                    action.data.campaignIds;
                draft.addCampaignsToGroupLoading = false;
                draft.addCampaignsToGroupError = undefined;
                draft.addCampaignsToGroupDone = true;
                break;
            case REMOVE_CAMPAIGNS_FROM_GROUP_SUCCESS:
                draft.campaignGroups.find((group) => group.groupId === action.data.groupId).campaignIds =
                    action.data.campaignIds;
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = undefined;
                draft.removeCampaignFromGroupDone = true;
                break;
            case SHARE_CAMPAIGN_GROUP_SUCCESS:
                draft.campaignGroupsUpdateLoading = false;
                draft.campaignGroupUpdateError = undefined;
                draft.campaignGroups.find((group) => group.groupId === action.data.groupId).shared = action.data.shared;
                if (action.data.shared) {
                    draft.sharedCampaignGroups.push(action.data);
                    draft.shareCampaignGroupDone = true;
                    draft.unshareCampaignGroupDone = false;
                } else {
                    draft.sharedCampaignGroups = draft.sharedCampaignGroups.filter(
                        (group) => group.groupId !== action.data.groupId,
                    );
                    draft.shareCampaignGroupDone = false;
                    draft.unshareCampaignGroupDone = true;
                }
                break;
            case GET_SHARED_CAMPAIGN_GROUPS_SUCCESS:
                draft.sharedCampaignGroups = action.data;
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = undefined;
                break;
            case RESET_DONE:
                draft.removeCampaignFromGroupDone = false;
                draft.addCampaignsToGroupDone = false;
                draft.updateCampaignGroupDone = false;
                draft.createCampaignGroupDone = false;
                draft.shareCampaignGroupDone = false;
                draft.unshareCampaignGroupDone = false;
                draft.deleteCampaignGroupDone = false;
                break;
            default:
                break;
        }
    });

export default appReducer;
