import { logout } from 'containers/App/actions';
import session from 'services/session';
import { selectAlerts, selectCurrentAlerts } from './selector';
import { loadAlertsAction, setCurrentAlerts } from './slice';
import { checkActive, checkDismissStatus } from './util';

const { call, delay, put, select, takeLatest } = require('redux-saga/effects');

export function* updateActiveBanners() {
    const oldCurrentAlerts = yield select(selectCurrentAlerts);
    const alerts = yield select(selectAlerts);

    const newCurrent = alerts.filter((alert) => checkActive(alert) && !checkDismissStatus(alert));

    const oldIds = new Set(oldCurrentAlerts.map((a) => a.id));
    const newAlerts = newCurrent.filter((alert) => !oldIds.has(alert.id));

    if (newAlerts.length > 0 || oldCurrentAlerts.length !== newCurrent.length) {
        yield put(setCurrentAlerts(newCurrent));
    }
}

export function* maintenanceAlertLogout() {
    const currentAlerts = yield select(selectCurrentAlerts);
    const maintenanceAlerts = currentAlerts.filter((alert) => alert.alertLevel === 0);

    if (maintenanceAlerts.length > 0 && session.isAuthenticated()) {
        yield put(logout());
    }
}

export function* alertSaga() {
    yield takeLatest([setCurrentAlerts().type, loadAlertsAction().type], maintenanceAlertLogout);
    while (true) {
        yield call(updateActiveBanners);
        yield delay(60000); // 1 minute
    }
}
