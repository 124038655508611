import { SidebarTabView, SidebarTab } from 'components/TabSidebar';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Footer from 'components/Footer';
import TermsContent from './content/terms';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        left: '0px',
        display: 'flex',
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.standardWhite,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        justifyContent: 'start',
        minWidth: '240px',
        maxWidth: '240px',
        backgroundColor: '#F0F2F4',
    },
    item: {
        height: 48,
        paddingLeft: '32px',
        color: theme.palette.techBlack,
        fontSize: '14px',
        fontWeight: theme.typography.fontWeightMedium,
        opacity: 1,
        backgroundColor: theme.palette.standardWhite,
        fill: theme.palette.humanBlue,
        textTransform: 'none',
        '&.active, &:hover, &.Mui-selected': {
            color: theme.palette.standardWhite,
            backgroundColor: theme.palette.humanBlue,
            '& path': {
                fill: theme.palette.standardWhite,
            },
        },
        '&.Mui-selected:hover': {
            backgroundColor: theme.palette.darkGray,
        },
        justifyContent: 'start',
        textAlign: 'left',
        '& span': {
            'align-items': 'flex-start',
        },
    },
    tabPanel: {
        flexGrow: 1,
        overflowY: 'auto',
        height: 'calc(100% - 155px)',

        '& .MuiTypography-root, & .MuiTypography-body1': {
            lineHeight: 1.65,
            maxWidth: '976px',
        },

        '& .MuiBox-root': {
            padding: '0 32px',
        },
    },
    customizedHtml: {
        '& h3': {
            fontSize: '28px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            margin: 0,
        },
        '& p': {
            margin: 0,
        },
        '& ul, & ol, & ol ul': {
            margin: 0,
            paddingLeft: '1em',
        },
        '& ol ul': {
            listStyleType: 'disc',
        },
        '& ol li::marker': {
            fontWeight: 'bold',
        },
        '& video': {
            width: '90%',
        },
    },
}));

function TabPanel(props) {
    return (
        <div role="tabpanel" {...props}>
            <Box p={4}>
                <Typography>{props.children}</Typography>
            </Box>
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
};

function Terms() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <SidebarTabView startingValue="My Ads Usage Terms">
                <SidebarTab label="My Ads Usage Terms">
                    <TabPanel className={classNames(classes.tabPanel, classes.customizedHtml)}>
                        <TermsContent />
                        <Footer />
                    </TabPanel>
                </SidebarTab>
            </SidebarTabView>
        </div>
    );
}

export default Terms;
