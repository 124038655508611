/**
 *
 * PageError
 *
 * This is the page we show when the user visits a url that has an error
 */

import React from 'react';

export default function PageError() {
    return (
        <article>
            <h1>There was a page error.</h1>
        </article>
    );
}
