import { getUserInfo as fetchUserInfo } from 'api/user-management';
import env from 'config/env';
import { isEmpty } from 'lodash';
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import session from 'services/session';
import { clearCSRFToken } from 'utils/csrf';
import { getItemFromStorage, removeNoRepeatAlerts } from 'utils/localStorage';
import { ALERT_TIMER_ID_KEY } from 'layouts/AlertBanner/const';
import { getUserError, loadAuthedUser, unloadAuthedUser } from './actions';
import { GET_USER_INFO, LOGOUT } from './constants';

const ALERT_DISMISS_KEY = 'bestbuy-alert-dismiss';

export function* logout() {
    const alertTimerID = getItemFromStorage(ALERT_TIMER_ID_KEY);
    if (alertTimerID) {
        clearTimeout(alertTimerID);
    }
    session.clear();
    clearCSRFToken();
    removeNoRepeatAlerts(ALERT_DISMISS_KEY);

    window.location.href = `${env.GATEWAY_BASE_URL}/v1/auth/idam/logout`;
    yield put(unloadAuthedUser());
}

export function* getUserInfo() {
    try {
        let userInfo = yield call(fetchUserInfo);
        if (isEmpty(userInfo)) {
            yield put(getUserError(new Error('User info empty')));
        } else {
            const { brandIds, active, deleted } = userInfo;
            if (active && !deleted && brandIds && brandIds.length > 0) {
                userInfo = { ...userInfo, brandNames: userInfo.brandNames };
            }

            yield put(loadAuthedUser(userInfo));
        }
        return userInfo;
    } catch (e) {
        yield put(getUserError(e));
        return null;
    }
}

export function* appSaga() {
    yield takeLeading(LOGOUT, logout);
    yield takeLatest(GET_USER_INFO, getUserInfo);
}
