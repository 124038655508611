import React from 'react';
import env from 'config/env';
import ReactGA from 'react-ga4';

const initializeAnalyticsContext = (history) => {
    try {
        const GAID = env.GA_ID;
        ReactGA.initialize(GAID);
        history.listen((location) => {
            ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
        });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Unable to intialize google analytics');
    }

    return {
        analytics: ReactGA,
    };
};

const AnalyticsContext = React.createContext();

const AnalyticsContextProvider = AnalyticsContext.Provider;

export { initializeAnalyticsContext, AnalyticsContextProvider, AnalyticsContext };
