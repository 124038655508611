/**
 *
 * PasswordUpdatePage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import PasswordUpdateForm from 'components/PasswordUpdateForm';
import reducer from './reducer';
import saga from './saga';
import { passwordUpdate } from './actions';

export function IDAMPasswordUpdateContent({ userId, uuid, onPasswordUpdate, onExit }) {
    useInjectReducer({ key: 'passwordUpdatePage', reducer });
    useInjectSaga({ key: 'passwordUpdatePage', saga });
    let content = null;

    if (uuid) {
        content = (
            <PasswordUpdateForm onSubmit={(password) => onPasswordUpdate({ userId, uuid, password })} onExit={onExit} />
        );
    } else {
        content = <span>Not able to load user</span>;
    }

    return <div>{content}</div>;
}

IDAMPasswordUpdateContent.propTypes = {
    userId: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    onPasswordUpdate: PropTypes.func.isRequired,
    onExit: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    onPasswordUpdate: (payload) => dispatch(passwordUpdate(payload)),
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(IDAMPasswordUpdateContent);
