import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the idamLoginSuccessPage state domain
 */

const selectIDAMLoginSuccessPageDomain = (state) => state.idamLoginSuccessPage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by IDAMLoginSuccessPage
 */

const makeSelectIDAMLoginSuccessPage = () => createSelector(selectIDAMLoginSuccessPageDomain, (substate) => substate);

export default makeSelectIDAMLoginSuccessPage;
export { selectIDAMLoginSuccessPageDomain };
