import { FormControl, Grid, makeStyles } from '@material-ui/core';
import { searchProductCatalogBrands } from 'api/brands';
import MultiCheckSelect from 'components/MultiCheckSelect';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { useRequest } from 'shared/hooks/use-request';
import { composeValidators, isNotBlank, required } from 'utils/validators';

const PLACEHOLDER = 'Search product catalog...';

const useStyles = makeStyles((theme) => ({
    chip: {
        borderRadius: 4,
        margin: '8px 4px',
    },
    error: {
        color: theme.palette.errorRed,
        margin: '0 14px 0 14px',
        fontSize: 12,
    },
    placeholder: {
        opacity: '0.435',
    },
    loadingContainer: {
        textAlign: 'center',
        paddingTop: theme.spacing(2),
    },
    inputLabel: {
        height: 14,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
    },
}));

export function ProductCatalogSelect() {
    const classes = useStyles();

    const [searchCatalog, catalogSearchRequest, resetSearch] = useRequest(searchProductCatalogBrands, {
        debounceMs: 300,
    });
    const [searchInput, setSearchInput] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        if (searchInput === '') {
            resetSearch();
        } else {
            searchCatalog(searchInput);
        }
    }, [searchInput, searchCatalog, resetSearch]);

    let productCatalogBrands = [];
    if (searchInput !== '' && catalogSearchRequest.data) {
        productCatalogBrands = catalogSearchRequest.data.map(({ brandName, brandId }) => ({
            name: brandName,
            value: brandId,
        }));
    }

    const options = [...selectedOptions, ...productCatalogBrands];

    let noOptionsText;
    if (searchInput === '') {
        noOptionsText = 'Type to search catalog';
    } else if (catalogSearchRequest.isLoading) {
        noOptionsText = 'Loading...';
    }

    return (
        <Field
            name="productCatalogBrands"
            component="select"
            type="text"
            validate={composeValidators(required, isNotBlank)}
        >
            {({ input, meta }) => (
                <Grid item xs={12}>
                    <div>
                        <FormControl margin="dense" variant="outlined" fullWidth>
                            <MultiCheckSelect
                                {...input}
                                multiple
                                checkbox={false}
                                placeholder={PLACEHOLDER}
                                values={input.value}
                                options={options}
                                onBlur={(event) => input.onBlur(event)}
                                onInputChange={(event, newValue) => setSearchInput(newValue)}
                                onChange={(newValue) => {
                                    setSelectedOptions(newValue);
                                    input.onChange(newValue);
                                }}
                                noOptionsText={noOptionsText}
                            />
                        </FormControl>
                        {meta.error && meta.submitFailed && (
                            <span className={classes.error}>Please select a Product Catalog Brand.</span>
                        )}
                    </div>
                </Grid>
            )}
        </Field>
    );
}
