import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { removeSnackbar } from './actions';

let displayed = [];

const useNotifier = () => {
    const dispatch = useDispatch();
    const notifications = useSelector((store) => store.notificationState.notifications || []);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter((key) => id !== key)];
    };

    React.useEffect(() => {
        notifications.forEach((notification) => {
            const {
                key,
                message,
                options = {},
                viewLink = null,
                dismissed = false,
                isExternalLink = false,
            } = notification;
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            let snackbarOptions = options;

            // If link is not null and internal link is provided
            if (viewLink && !isExternalLink) {
                snackbarOptions = {
                    ...options,
                    action: (
                        <Button>
                            <Link to={viewLink}>View</Link>
                        </Button>
                    ),
                };
            } else if (viewLink && isExternalLink) {
                snackbarOptions = {
                    ...options,
                    action: (
                        <Button>
                            <a href={viewLink} target="_blank">
                                View
                            </a>
                        </Button>
                    ),
                };
            }

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...snackbarOptions,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeSnackbar(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useNotifier;
