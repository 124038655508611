import axios from 'axios';
import session from 'services/session';
// import { getCSRFToken } from 'utils/csrf';

const tokenExpireCallbacks = [];

class TokenExpiredError extends Error {
    constructor(message, response) {
        super(message);
        this.response = response;
    }
}

function onTokenExpiration(fn) {
    tokenExpireCallbacks.push(fn);
}

function isTokenExpireError(e) {
    return e instanceof TokenExpiredError;
}

const instance = axios.create();
instance.interceptors.request.use(async (config) => {
    // eslint-disable-next-line no-param-reassign
    config.withCredentials = true;
    // if (config.method !== 'get' && config.method !== 'options') {
    //     const csrfToken = await getCSRFToken();
    //     // eslint-disable-next-line no-param-reassign
    //     config.data = {
    //         ...config.data,
    //         csrf: csrfToken,
    //     };
    // }

    return config;
});

instance.interceptors.response.use(
    (res) => res,
    async (error) => {
        if (error.response?.status === 401 && session.isAuthenticated()) {
            session.clear();
            window.location.href = '/login';
        } else {
            throw error;
        }
    },
);

export default instance;

export { onTokenExpiration, isTokenExpireError };
