import { makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    bold: {
        fontWeight: 500,
    },
}));

export function DefaultTooltipContent(props) {
    const classes = useStyles();
    return (
        <div>
            <span className={classes.bold}>Attribution complete</span> -{' '}
            <span>
                {props.campaignTypeName} Campaign attribution is considered complete 14 days after the campaign end
                date.
            </span>
        </div>
    );
}

DefaultTooltipContent.propTypes = {
    campaignTypeName: PropTypes.string,
};

export const renderDefaultTooltipContent = (campaignTypeName) => (
    <DefaultTooltipContent campaignTypeName={campaignTypeName} />
);
