// This defines all of the ad formats that the snippet builder supports.
// This is just a look up table and should be extended not modified.

// name: Human-readable name of ad type
// width: width in pixels
// heigh: height in pixels
// image: absolute path from /public/
// position: configuration data for snippet generated
/* istanbul ignore file */

export const adformats = [
    {
        name: 'Pencil',
        width: 970,
        height: 66,
        image: '/img/pencil-wire-copy-2.svg',
        position: {
            x: 756,
            y: 16,
        },
    },
    {
        name: 'Medium Rectangle',
        width: 300,
        height: 250,
        image: '/img/medium-rectangle.svg',
        position: {
            x: 35,
            y: 120,
        },
    },
    {
        name: 'Wide Skyscraper',
        width: 300,
        height: 600,
        image: '/img/wide-skyscraper.svg',
        position: {
            x: 90,
            y: 500,
        },
    },
    {
        name: 'Skyscraper',
        width: 160,
        height: 600,
        image: '/img/skyscraper.svg',
        position: {
            x: 20,
            y: 474,
        },
    },
    {
        name: 'Mobile High Impact',
        width: 320,
        height: 100,
        image: '/img/mobile-high-impact.svg',
        position: {
            x: 105,
            y: 72,
        },
    },
    {
        name: 'Leaderboard',
        width: 728,
        height: 90,
        image: '/img/leaderboard.svg',
        position: {
            x: 500,
            y: 46,
        },
    },
];
