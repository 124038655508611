/*
 *
 * HomePage constants
 *
 */

export const DEFAULT_ACTION = 'portal/HomePage/DEFAULT_ACTION';
export const ADD_FILTER = 'portal/HomePage/ADD_FILTER';
export const UPDATE_FILTER = 'portal/HomePage/UPDATE_FILTER';
export const ADD_FILTER_SUCCESS = 'portal/HomePage/ADD_FILTER_SUCCESS';
export const UPDATE_FILTER_SUCCESS = 'portal/HomePage/UPDATE_FILTER_SUCCESS';
export const ADD_FILTER_ERROR = 'portal/HomePage/ADD_FILTER_ERROR';
export const UPDATE_FILTER_ERROR = 'portal/HomePage/UPDATE_FILTER_ERROR';
export const GET_FILTERS = 'portal/HomePage/GET_FILTERS';
export const GET_FILTERS_SUCCESS = 'portal/HomePage/GET_FILTERS_SUCCESS';
export const GET_FILTERS_ERROR = 'portal/HomePage/GET_FILTERS_ERROR';
export const UPDATE_DATE_FILTER = 'portal/HomePage/UPDATE_DATE_FILTER';
export const UPDATE_SORT_ORDER = 'portal/HomePage/UPDATE_SORT_ORDER';
export const UPDATE_PAGE_NUMBER = 'portal/HomePage/UPDATE_PAGE_NUMBER';

export const DEFAULT_FILTER_NAME = '@@DEFAULT';
