import request, { formatGetOptions } from 'utils/request';
import { BASE_URL_V1 } from './api-service/Url_Constants';

export async function searchProductCatalogBrands(brandName) {
    const options = formatGetOptions();

    const { body } = await request(`${BASE_URL_V1}/catalog/brands/search?filter=${brandName}`, options);

    const rows = body?.rows;

    if (Array.isArray(rows)) {
        return rows
            .filter((row) => typeof row === 'object' && row.brandName && row.brandId)
            .map((row) => ({
                brandName: row.brandName,
                brandId: row.brandId,
            }));
    }

    return [];
}
