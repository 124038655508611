/*
 *
 * StackedCampaignsPage reducer
 *
 */
import produce from 'immer';
import {
    CREATE_CAMPAIGN_GROUP,
    CREATE_CAMPAIGN_GROUP_SUCCESS,
    CREATE_CAMPAIGN_GROUP_ERROR,
    DELETE_CAMPAIGN_GROUP,
    DELETE_CAMPAIGN_GROUP_SUCCESS,
    DELETE_CAMPAIGN_GROUP_ERROR,
    GET_CAMPAIGN_GROUPS,
    GET_CAMPAIGN_GROUPS_SUCCESS,
    GET_CAMPAIGN_GROUPS_ERROR,
    ADD_CAMPAIGNS_TO_GROUP,
    ADD_CAMPAIGNS_TO_GROUP_SUCCESS,
    ADD_CAMPAIGNS_TO_GROUP_ERROR,
    REMOVE_CAMPAIGNS_FROM_GROUP,
    REMOVE_CAMPAIGNS_FROM_GROUP_SUCCESS,
    REMOVE_CAMPAIGNS_FROM_GROUP_ERROR,
    SHARE_CAMPAIGN_GROUP,
    SHARE_CAMPAIGN_GROUP_SUCCESS,
    SHARE_CAMPAIGN_GROUP_ERROR,
    GET_SHARED_CAMPAIGN_GROUPS,
    GET_SHARED_CAMPAIGN_GROUPS_SUCCESS,
    GET_SHARED_CAMPAIGN_GROUPS_ERROR,
    RENAME_CAMPAIGN_GROUP,
    RENAME_CAMPAIGN_GROUP_SUCCESS,
    RENAME_CAMPAIGN_GROUP_ERROR,
    RESET_DONE,
    CHECK_CAMPAIGN_GROUP_NAME_ERROR,
} from './constants';

export const initialState = {
    campaignGroups: [],
    sharedReports: [],
    campaignGroupsLoading: false,
    campaignGroupsError: undefined,
    campaignGroupsDone: false,
};

/* eslint-disable default-case, no-param-reassign */
const stackedCampaignsPageReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_CAMPAIGN_GROUPS:
            case GET_SHARED_CAMPAIGN_GROUPS:
            case CREATE_CAMPAIGN_GROUP:
            case DELETE_CAMPAIGN_GROUP:
            case ADD_CAMPAIGNS_TO_GROUP:
            case REMOVE_CAMPAIGNS_FROM_GROUP:
            case SHARE_CAMPAIGN_GROUP:
            case RENAME_CAMPAIGN_GROUP:
                draft.campaignGroupsLoading = true;
                draft.campaignGroupsError = undefined;
                draft.campaignGroupsDone = false;
                break;
            case GET_CAMPAIGN_GROUPS_SUCCESS:
                draft.campaignGroups = action.data;
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = undefined;
                draft.campaignGroupsDone = true;
                break;
            case GET_SHARED_CAMPAIGN_GROUPS_SUCCESS:
                draft.sharedReports = action.data;
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = undefined;
                draft.campaignGroupsDone = true;
                break;
            case CREATE_CAMPAIGN_GROUP_SUCCESS:
                draft.campaignGroups.unshift(action.data);
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = undefined;
                draft.campaignGroupsDone = true;
                break;
            case DELETE_CAMPAIGN_GROUP_SUCCESS:
                draft.campaignGroups = draft.campaignGroups.filter((group) => group.groupId !== action.groupId);
                draft.sharedReports = draft.sharedReports.filter((group) => group.groupId !== action.groupId);
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = undefined;
                draft.campaignGroupsDone = true;
                break;
            case ADD_CAMPAIGNS_TO_GROUP_SUCCESS:
            case REMOVE_CAMPAIGNS_FROM_GROUP_SUCCESS:
                draft.campaignGroups.find((group) => group.groupId === action.data.groupId).campaignIds =
                    action.data.campaignIds;
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = undefined;
                draft.campaignGroupsDone = true;
                break;
            case SHARE_CAMPAIGN_GROUP_SUCCESS:
                draft.campaignGroups.find((group) => group.groupId === action.data.groupId).shared = action.data.shared;
                if (action.data.shared) {
                    draft.sharedReports.push(action.data);
                } else {
                    draft.sharedReports = draft.sharedReports.filter((group) => group.groupId !== action.data.groupId);
                }
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = undefined;
                draft.campaignGroupsDone = true;
                break;
            case RENAME_CAMPAIGN_GROUP_SUCCESS:
                draft.campaignGroups = draft.campaignGroups.map((group) =>
                    group.groupId === action.data.groupId ? action.data : group,
                );
                draft.sharedReports = draft.sharedReports.map((group) =>
                    group.groupId === action.data.groupId ? action.data : group,
                );
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = undefined;
                draft.campaignGroupsDone = true;
                break;
            case GET_CAMPAIGN_GROUPS_ERROR:
            case GET_SHARED_CAMPAIGN_GROUPS_ERROR:
            case CREATE_CAMPAIGN_GROUP_ERROR:
            case DELETE_CAMPAIGN_GROUP_ERROR:
            case ADD_CAMPAIGNS_TO_GROUP_ERROR:
            case REMOVE_CAMPAIGNS_FROM_GROUP_ERROR:
            case SHARE_CAMPAIGN_GROUP_ERROR:
            case RENAME_CAMPAIGN_GROUP_ERROR:
            case CHECK_CAMPAIGN_GROUP_NAME_ERROR:
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = action.error;
                draft.campaignGroupsDone = true;
                break;
            case RESET_DONE:
                draft.campaignGroupsLoading = false;
                draft.campaignGroupsError = undefined;
                draft.campaignGroupsDone = false;
                break;
            default:
                break;
        }
    });

export default stackedCampaignsPageReducer;
