import React, { useRef } from 'react';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import env from 'config/env';
import useStyles from './formStyle';

function PasswordUpdated() {
    const classes = useStyles();
    const INTERNAL = 'internal';
    const EXTERNAL = 'external';
    const userType = useRef(EXTERNAL);

    const getURLFromQueryString = (key) => {
        const params = new URLSearchParams(window.location.search);
        if (params.get(key) && params.get(key).toLowerCase() === INTERNAL) {
            return INTERNAL;
        }
        return EXTERNAL;
    };

    const goToLogin = () => {
        if (env.BBY_ENV === 'production' && !env.GATEWAY_BASE_URL.includes('bestbuyads')) {
            userType.current = INTERNAL;
        } else {
            // Add query params for testing
            const userTypeValue = getURLFromQueryString('userType');
            userType.current = userTypeValue;
        }
        const bbyAuthUrl = `${env.GATEWAY_BASE_URL}/v1/auth/idam/${userType.current}/login`;
        window.location.replace(bbyAuthUrl);
    };

    return (
        <>
            <Grid item xs={12} className={classes.bodyContainer}>
                <Grid item xs={12}>
                    <div className={classes.bodyLeft}>
                        <span className={classes.formTitleLine}>
                            <div className={classes.formTitle}>Password</div>
                        </span>
                        <div className={classes.formTitle}>updated.</div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.bodyRight}>
                        <div className={classes.title}>Your password was reset. You can now login.</div>
                        <Button
                            data-testid="login-form-button"
                            className={classNames(classes.formButton, classes.button, classes.formButtonNoMargin)}
                            type="submit"
                            onClick={goToLogin}
                            endIcon={<ArrowForwardIcon className={classes.arrowIcon} />}
                        >
                            Login
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default PasswordUpdated;
