/**
 *
 * Brand for Share Campaign Flyout
 *
 */

import React, { memo } from 'react';
import { CircularProgress, FormControl, Grid } from '@material-ui/core';
import { required, isNotBlank, composeValidators } from 'utils/validators';
import { Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import MultiCheckSelect from 'components/MultiCheckSelect/Loadable';
import { comparator } from 'utils/sortComparator';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    chip: {
        borderRadius: 4,
        margin: '8px 4px',
    },
    error: {
        color: theme.palette.errorRed,
        margin: '0 14px 0 14px',
        fontSize: 12,
    },
    placeholder: {
        opacity: '0.435',
    },
    loadingContainer: {
        textAlign: 'center',
        paddingTop: theme.spacing(2),
    },
    bulkButton: {
        color: theme.palette.humanBlue,
        textTransform: 'initial',
        fontWeight: 'normal',
        '&:hover': {
            backgroundColor: 'white',
            textDecoration: 'underline',
        },
    },
    inputLabel: {
        height: 14,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
    },
}));

const PLACEHOLDER = 'Search by Brand...';

const Brand = ({ loading, brands }) => {
    const classes = useStyles();
    const brandOptions = brands.slice().sort((a, b) => comparator(a, b));

    return loading ? (
        <div className={classes.loadingContainer}>
            <CircularProgress size={22} />
        </div>
    ) : (
        <Field name="brands" component="select" type="text" validate={composeValidators(required, isNotBlank)}>
            {({ input, meta }) => (
                <Grid item xs={12}>
                    <div>
                        <FormControl margin="dense" variant="outlined" fullWidth>
                            <MultiCheckSelect
                                {...input}
                                multiple
                                checkbox={false}
                                placeholder={PLACEHOLDER}
                                values={input.value || []}
                                options={brandOptions}
                                onBlur={(event) => input.onBlur(event)}
                            />
                        </FormControl>
                        {meta.error && meta.submitFailed && (
                            <span className={classes.error}>Please select a brand.</span>
                        )}
                    </div>
                </Grid>
            )}
        </Field>
    );
};

export default memo(Brand);

Brand.propTypes = {
    loading: PropTypes.bool,
    brands: PropTypes.array,
    assignedBrands: PropTypes.array,
};
