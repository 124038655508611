/* eslint-disable no-console */

const inDevelopment = process.env.NODE_ENV === 'development';

/**
 * To handle console log in dev environment only
 */
export const debug = {
    log(...payload) {
        if (inDevelopment) {
            console.log(...payload);
        }
    },

    info(...payload) {
        if (inDevelopment) {
            console.info(...payload);
        }
    },

    warn(...payload) {
        if (inDevelopment) {
            console.warn(...payload);
        }
    },

    error(...payload) {
        if (inDevelopment) {
            console.error(...payload);
        }
    },

    group(...payload) {
        if (inDevelopment) {
            console.group(...payload);
        }
    },

    groupEnd(...payload) {
        if (inDevelopment) {
            console.groupEnd(...payload);
        }
    },

    time(...payload) {
        if (inDevelopment) {
            console.time(...payload);
        }
    },

    timeEnd(...payload) {
        if (inDevelopment) {
            console.timeEnd(...payload);
        }
    },
};
