export const offsiteSheets = (definition) => {
    const prefix = definition === 'display' ? 'display-offsite' : 'offsite-video';

    return [
        { type: `${prefix}-summary` },
        { type: `${prefix}-platform`, filtersName: 'platform' },
        { type: `${prefix}-publisher`, filtersName: 'publisher' },
        { type: `${prefix}-audience`, filtersName: 'audience' },
        { type: `${prefix}-product`, filtersName: 'product' },
    ];
};
