import { createSelector } from 'reselect';

import { makeSelectCampaignsHashmap } from 'containers/App/selectors';

import { initialState } from './reducer';

import { DEFAULT_WATCH_NAME } from './constants';

import { getCampaignsFromIds } from '../utils';

/**
 * Direct selector to the watchCampaignsTab state domain
 */

const selectWatchCampaignsTabDomain = (state) => state.watchCampaignsTab || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by WatchCampaignsTab
 */

const makeSelectWatchCampaignsTab = () => createSelector(selectWatchCampaignsTabDomain, (substate) => substate);

const makeSelectDefaultWatch = () =>
    createSelector(selectWatchCampaignsTabDomain, (substate) => {
        const watchlist = substate.watchlists.filter((list) => list.watchName === DEFAULT_WATCH_NAME);
        const defaultWatch = watchlist.length > 0 ? watchlist[0] : null;
        return defaultWatch;
    });

const makeSelectCampaignWatchlists = () =>
    createSelector(makeSelectDefaultWatch(), makeSelectCampaignsHashmap(), (defaultWatch, campaignHash) => {
        const campaignIds = defaultWatch && defaultWatch.campaignIds ? defaultWatch.campaignIds : [];
        return getCampaignsFromIds(campaignIds, campaignHash);
    });

const makeSelectLoading = () => createSelector(selectWatchCampaignsTabDomain, (substate) => substate.loading);

const makeSelectWatchlistCount = () =>
    createSelector(makeSelectCampaignWatchlists(), (watchlists) => (watchlists?.length > 0 ? watchlists?.length : 0));

export default makeSelectWatchCampaignsTab;
export {
    selectWatchCampaignsTabDomain,
    makeSelectDefaultWatch,
    makeSelectCampaignWatchlists,
    makeSelectLoading,
    makeSelectWatchlistCount,
};
