import { FormControl, Grid, makeStyles } from '@material-ui/core';
import MultiCheckSelect from 'components/MultiCheckSelect';
import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators, isNotBlank, required } from 'utils/validators';
import PropTypes from 'prop-types';

const PLACEHOLDER = 'Search MyAds Brands';

const useStyles = makeStyles((theme) => ({
    chip: {
        borderRadius: 4,
        margin: '8px 4px',
    },
    error: {
        color: theme.palette.errorRed,
        margin: '0 14px 0 14px',
        fontSize: 12,
    },
    placeholder: {
        opacity: '0.435',
    },
    loadingContainer: {
        textAlign: 'center',
        paddingTop: theme.spacing(2),
    },
    inputLabel: {
        height: 14,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
    },
}));

export function MyadsBrandSelect({ brands, orgId, onBrandChange }) {
    const classes = useStyles();

    return (
        <Field name="myadsBrands" component="select" type="text" validate={composeValidators(required, isNotBlank)}>
            {({ input, meta }) => (
                <Grid item xs={12}>
                    <div>
                        <FormControl margin="dense" variant="outlined" fullWidth>
                            <MultiCheckSelect
                                {...input}
                                multiple={false}
                                checkbox={false}
                                placeholder={PLACEHOLDER}
                                values={input.value || []}
                                options={brands}
                                onBlur={(event) => {
                                    input.onBlur(event);
                                }}
                                onChange={(newValue) => {
                                    input.onChange(newValue);
                                    onBrandChange({ orgId, myadsBrandId: newValue[0]?.value });
                                }}
                            />
                        </FormControl>
                        {meta.error && meta.submitFailed && (
                            <span className={classes.error}>Please select a Brand.</span>
                        )}
                    </div>
                </Grid>
            )}
        </Field>
    );
}

MyadsBrandSelect.propTypes = {
    brands: PropTypes.array,
    onBrandChange: PropTypes.func,
    orgId: PropTypes.string,
};
