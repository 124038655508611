import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the columnFilter state domain
 *
 * @param {object} state redux state
 *
 * @returns {object} memoized selector
 */
const selectColumnFilterDomain = (state) => state.columnFilter || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ColumnFilter
 *
 * @returns {object} memoized selector
 */
const makeSelectAllFilters = () => createSelector(selectColumnFilterDomain, (substate) => substate);

export { selectColumnFilterDomain, makeSelectAllFilters };
