import { get } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { debug } from 'services/debug';
import { v4 as uuid } from 'uuid';
import { RequestStatus as Status } from '../constants';
export class RequestObject {
    d = null;

    e = null;

    s = Status.INIT;

    get data() {
        return this.d;
    }

    get error() {
        return this.e;
    }

    get status() {
        return this.s;
    }

    get isInit() {
        return this.status === Status.INIT;
    }

    get isLoading() {
        return this.status === Status.LOADING;
    }

    get isDone() {
        return this.status === Status.DONE;
    }

    get isError() {
        return this.status === Status.ERROR;
    }

    constructor(data = null, error = null, status = Status.INIT) {
        this.d = data;
        this.e = error;
        this.s = status;
    }
}

async function handleError(error) {
    let err = error;
    const errorStatus = get(error, 'response.status');
    if (errorStatus === 404 || errorStatus === 400) {
        if (get(error, 'response.bodyUsed')) {
            err = error.response.body;
        } else {
            err = await error.toJSON();
        }
    }
    return err;
}

// Function that can be called to cancel the previous timeout when the next request comes in
let cancelPreviousTimeout = null;

/**
 * Request hook
 *
 * @param {Function} api an api request as promise
 * @returns {[Function, RequestObject]} a list consists 2 elements: an async function to proceed the request and request object
 */
export const useRequest = (api, options = {}) => {
    const isMounted = useRef(true);
    const lastRequestId = useRef('');

    useEffect(
        () => () => {
            isMounted.current = false;
        },
        [],
    );

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(Status.INIT);

    if (!(api instanceof Function)) {
        throw new Error('given api must be a function');
    }

    const { rejectOnError, debounceMs } = options;

    const sendRequest = useCallback(
        async (...payload) => {
            const requestId = uuid();
            lastRequestId.current = requestId;
            let requestData = null;
            const runTimeStart = performance.now();
            let runTime = null;

            debug.group('%cRequest', 'color: grey', `${api.name}()`);
            debug.info(Status.LOADING);
            debug.groupEnd();

            try {
                requestData = await api(...payload);
                runTime = performance.now() - runTimeStart;

                if (isMounted.current && lastRequestId.current === requestId) {
                    setData(requestData);
                    setStatus(Status.DONE);
                }

                debug.group('%cRequest', 'color: grey', `${api.name}()`);
                debug.info('%cSUCCESS', 'color: green');
                debug.info('response', { data: requestData });
                debug.info('run time', runTime);
                debug.groupEnd();
            } catch (e) {
                const err = await handleError(e);
                runTime = performance.now() - runTimeStart;

                if (isMounted.current) {
                    setError(err);
                    setStatus(Status.ERROR);
                }

                debug.group('%cRequest', 'color: grey', `${api.name}()`);
                debug.info('%cERROR', 'color: crimson');
                debug.info('error', { error: err });
                debug.info('run time', runTime);
                debug.groupEnd();

                if (rejectOnError) {
                    throw e;
                }
            }

            if (isMounted.current) {
                return requestData;
            }

            return null;
        },
        [api, rejectOnError],
    );

    const doRequest = useCallback(
        (...payload) => {
            if (isMounted.current) {
                setError(null);
                setStatus(Status.LOADING);
            }

            if (!debounceMs) {
                return sendRequest(...payload);
            }

            if (cancelPreviousTimeout) {
                cancelPreviousTimeout();
            }

            return new Promise((resolve, reject) => {
                const runFunc = () => {
                    cancelPreviousTimeout = null;
                    sendRequest(...payload)
                        .then((result) => resolve(result))
                        .catch((err) => reject(err));
                };

                const debounceTimeout = setTimeout(runFunc, debounceMs);

                cancelPreviousTimeout = () => {
                    clearTimeout(debounceTimeout);
                    resolve(null);
                };
            });
        },
        [sendRequest, debounceMs],
    );

    const resetRequest = useCallback(() => {
        if (cancelPreviousTimeout) {
            cancelPreviousTimeout();
        }
        setData(null);
        setError(null);
        setStatus(Status.INIT);
    }, []);

    return [doRequest, new RequestObject(data, error, status), resetRequest];
};
