import { makeSelectCampaignFilters, makeSelectCampaigns, selectCampaignLoadStatus } from 'containers/App/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { filterByDate, filterCampaigns } from 'utils/filterCampaigns';

export default function useCampaigns(campaignFilter = {}, dateFilter = {}) {
    const campaigns = useSelector(makeSelectCampaigns());
    const filterMetadata = useSelector(makeSelectCampaignFilters());
    const loadStatus = useSelector(selectCampaignLoadStatus);

    const withinDateRange = useMemo(() => filterByDate(campaigns, dateFilter), [campaigns, dateFilter]);

    const results = useMemo(() => filterCampaigns(withinDateRange, campaignFilter, filterMetadata), [
        withinDateRange,
        campaignFilter,
        filterMetadata,
    ]);

    return { ...results, loadStatus };
}
