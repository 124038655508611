import { takeLatest, call, put, select } from 'redux-saga/effects';
import { isEmpty } from 'lodash';

import { getUserInfo } from 'containers/App/saga';
import { unloadAuthedUser } from 'containers/App/actions';
import session from 'services/session';
import { clearCSRFToken } from 'utils/csrf';
import { clearStorage } from 'utils/localStorage';

import { IDAM_POST_LOGIN_SUCCESS } from './constants';
import { IDAMPostLoginError } from './actions';
import { selectFeatureFlags } from '../../utils/featureFlags/selectors';

// Individual exports for testing
export function* idamLoginSuccessPageSaga({ history }) {
    try {
        const flags = yield select(selectFeatureFlags);
        const user = yield call(getUserInfo, flags);

        if (isEmpty(user)) {
            throw new Error('User info empty');
        }
        // if the backend sends the authenticated user down we can establish the
        // session on the frontened
        session.write({
            isLoggedIn: true,
        });
    } catch (error) {
        session.clear();
        clearStorage();
        clearCSRFToken();

        yield put(unloadAuthedUser());
        yield put(IDAMPostLoginError(error));

        history.push('/login');
    }
}

// Individual exports for testing
export default function* loginPageSaga() {
    yield takeLatest(IDAM_POST_LOGIN_SUCCESS, idamLoginSuccessPageSaga);
}
