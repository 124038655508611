import React from 'react';
import { Helmet } from 'react-helmet';

function DashboardPage() {
    return (
        <div>
            <Helmet>
                <title>My Ads Portal</title>
                <meta name="description" content="Campaigns Dashboard" />
            </Helmet>
        </div>
    );
}

export default DashboardPage;
