import { ResourceParam } from 'containers/ResourcePage';
import { CAMPAIGN_TYPE_UNKNOWN } from '../../Helpers/helpers';

export const baseDefinitions = {
    label: 'Unknown Campaign Type',
    vehicleKey: CAMPAIGN_TYPE_UNKNOWN,
    campaignType: CAMPAIGN_TYPE_UNKNOWN,

    // Permissions
    viewPermissions: [],
    downloadPermissions: [],

    // Campaign Details Page
    detailsApiCall: undefined,
    useNewColumnFilter: true,
    columnMap: undefined,
    columnFilterMap: undefined,
    cards: undefined,
    defaultTabKey: undefined,
    tabs: undefined,
    columns: undefined,
    visualizationOptions: undefined,
    modifyColumns: undefined,
    showViewability: false,

    // Attribution
    attributionWindowDays: 14,
    tooltipRenderFn: () => null,
    resourcesPageUrl: `/resources/${ResourceParam.ABOUT}`,

    // Download Report
    reportDownloadApiCall: undefined,
    sheets: undefined,
};
