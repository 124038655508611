import { Button, TableCell as MuiTableCell, TableRow as MuiTableRow } from '@material-ui/core';
import classNames from 'classnames';
import { compact } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import VerticalMenu from '../VerticalMenu';
import { ColumnPropType } from './constants';
import { TableCellText } from './TableCellText';

export function TableRowWithActions(props) {
    const {
        item,
        itemIndex,
        classes,
        selectedItems,
        columns,
        rightAlignTypes,
        actions,
        onTableRowClick,
        onRowClick,
        filterValues,
        parentNodesExist,
        disabled,
        styledFields,
    } = props;

    let rowColor;

    if (parentNodesExist) {
        rowColor = itemIndex % 2 === 0 ? classes.rowDark : classes.rowLight;
    }
    return (
        <MuiTableRow
            key={item.__key}
            className={parentNodesExist ? classNames(rowColor) : classNames(classes.row)}
            data-testid={`row-${itemIndex}`}
            tabIndex={-1}
            selected={Boolean(selectedItems[item.__key]) || false}
            hover
        >
            {columns.map((column, index) =>
                column.field === 'name' ? (
                    <MuiTableCell
                        align={column.align}
                        key={column.field + column.label}
                        className={
                            parentNodesExist ? classNames(classes.cell, classes.textIndent) : classNames(classes.cell)
                        }
                        data-clickable={onRowClick instanceof Function}
                        data-line-break={column.lineBreak}
                        data-testid={`table-cell-${itemIndex + 1}-${index + 1}`}
                        onClick={onTableRowClick(item)}
                    >
                        <TableCellText item={item} column={column} filterValue={filterValues[column.field]} />
                    </MuiTableCell>
                ) : (
                    <MuiTableCell
                        align={column.align}
                        key={column.field + column.label}
                        className={
                            styledFields.includes(column.field) || rightAlignTypes.includes(column.type)
                                ? classNames(classes.cell, classes.textRight)
                                : classNames(classes.cell)
                        }
                        data-clickable={onRowClick instanceof Function}
                        data-line-break={column.lineBreak}
                        data-testid={`table-cell-${itemIndex + 1}-${index + 1}`}
                        onClick={onTableRowClick(item)}
                    >
                        <TableCellText item={item} column={column} filterValue={filterValues[column.field]} />
                    </MuiTableCell>
                ),
            )}
            {actions && (
                <MuiTableCell key={item.__key} className={classNames(classes.cell)}>
                    <VerticalMenu
                        buttons={compact(
                            actions(item).map(
                                (action) =>
                                    action &&
                                    (action.onClick ? (
                                        <Button
                                            className={classes.actionMenu}
                                            key={action.label}
                                            aria-label={`${action.label} button`}
                                            disabled={
                                                disabled &&
                                                (action.label === 'Update User' || action.label === 'Resend Invite')
                                            }
                                            onClick={(event) => action.onClick(event, item)}
                                        >
                                            <span>{action.label}</span>
                                        </Button>
                                    ) : (
                                        <div className={classes.noneActionMenu}>{action.label}</div>
                                    )),
                            ),
                        )}
                    />
                </MuiTableCell>
            )}
        </MuiTableRow>
    );
}

TableRowWithActions.propTypes = {
    parentNodesExist: PropTypes.bool,
    item: PropTypes.object,
    classes: PropTypes.object,
    rightAlignTypes: PropTypes.array,
    itemIndex: PropTypes.number,
    columns: PropTypes.arrayOf(PropTypes.shape(ColumnPropType).isRequired).isRequired,
    select: PropTypes.shape({
        type: PropTypes.oneOf(['single', 'multiple']).isRequired,
        onSelect: PropTypes.func.isRequired,
    }),
    sort: PropTypes.shape({
        column: PropTypes.object.isRequired,
        order: PropTypes.oneOf(['desc', 'asc', '']).isRequired,
    }),
    actions: PropTypes.func,
    onRowClick: PropTypes.func,
    onTableRowClick: PropTypes.func,
    selectedItems: PropTypes.object,
    filterValues: PropTypes.object,
    disabled: PropTypes.bool,
    styledFields: PropTypes.array,
};
