export const dzPaidSearchSkuOptions = [
    // Impressions
    { unit: 'integer', label: 'Impressions', key: 'impressions' },
    // Clicks
    { unit: 'integer', label: 'Clicks', key: 'clicks' },
    // Spend
    { unit: 'currency', label: 'Delivered Spend', key: 'spend' },
    // Revenue (sum of Total Online Revenue and Total In-Store Revenue)
    { unit: 'currency', label: 'Revenue', key: 'revenue_total' },
];
