export const proximityColumns = {
    day: [
        // Visit Date
        'date',
        // Total Unique Reach
        'total_unique_reach',
        // Notification Taps
        'notification_taps',
        // Banner Impressions
        'banner_impressions',
        // Banner Reach
        'banner_reach',
        // Banner Taps
        'banner_taps',
        // Banner Tap Rate
        'banner_tap_rate',
        // Banner Dismissals
        'banner_dismissals',
        // Banner Dismissal Rate
        'banner_dismissal_rate',
        // Interstitial Impressions
        'interstitial_impressions',
        // Interstitial Reach
        'interstitial_reach',
        // Interstitial Taps
        'interstitial_taps',
        // Interstitial Tap Rate
        'interstitial_tap_rate',
        // PDP Views
        'pdp_views',
        // Add to Cart
        'add_to_cart',
        // Total Online Revenue ($)
        'total_online_revenue',
        // Total In Store Revenue ($)
        'total_instore_revenue',
        // Online Units
        'total_online_units',
        // In Store Units
        'total_instore_units',
        // Tap Online Revenue ($)
        'tap_online_revenue',
        // Tap In Store Revenue ($)
        'tap_instore_revenue',
        // Tap Online Units
        'tap_online_units',
        // Tap In Store Units
        'tap_instore_units',
    ],
    device: [
        // Device Type
        'device_type',
        // Total Unique Reach
        'total_unique_reach',
        // Notification Taps
        'notification_taps',
        // Banner Impressions
        'banner_impressions',
        // Banner Reach
        'banner_reach',
        // Banner Taps
        'banner_taps',
        // Banner Tap Rate
        'banner_tap_rate',
        // Banner Dismissals
        'banner_dismissals',
        // Banner Dimissal Rate
        'banner_dismissal_rate',
        // Interstitial Impressions
        'interstitial_impressions',
        // Interstitial Reach
        'interstitial_reach',
        // Interstitial Taps
        'interstitial_taps',
        // Interstitial Tap Rate
        'interstitial_tap_rate',
        // PDP Views
        'pdp_views',
        // Add to Cart
        'add_to_cart',
        // Total Online Revenue ($)
        'total_online_revenue',
        // Total In Store Revenue ($)
        'total_instore_revenue',
        // Online Units
        'total_online_units',
        // In Store Units
        'total_instore_units',
        // Tap Online Revenue ($)
        'tap_online_revenue',
        // Tap In Store Revenue ($)
        'tap_instore_revenue',
        // Tap Online Units
        'tap_online_units',
        // Tap In Store Units
        'tap_instore_units',
    ],
    product: [
        // SKU
        'sku_id',
        // Description
        'sku_description',
        // Brand
        'brand',
        // Department ID
        'dept_id',
        // Department Name
        'dept_name',
        // Class ID
        'class_id',
        // Class Name
        'class_name',
        // SubClass ID
        'subclass_id',
        // SubClass Name
        'subclass_name',
        // PDP Views
        'pdp_views',
        // Add to Cart
        'add_to_cart',
        // Total Online Revenue ($)
        'total_online_revenue',
        // Total In Store Revenue ($)
        'total_instore_revenue',
        // Online Units
        'online_units',
        // In Store Units
        'instore_units',
        // Tap Online Revenue ($)
        'tap_online_revenue',
        // Tap In Store Revenue ($)
        'tap_instore_revenue',
        // Tap Online Units
        'tap_online_units',
        // Tap In Store Units
        'tap_instore_units',
    ],
    creative: [
        // Creative ID
        'creative_id',
        // Creative Name
        'creative_name',
        // Total Unique Reach
        'total_unique_reach',
        // Notification Taps
        'notification_taps',
        // Banner Impressions
        'banner_impressions',
        // Banner Reach
        'banner_reach',
        // Banner Taps
        'banner_taps',
        // Banner Tap Rate
        'banner_tap_rate',
        // Banner Dismissals
        'banner_dismissals',
        // Banner Dismissal Rate
        'banner_dismissal_rate',
        // Interstitial Impressions
        'interstitial_impressions',
        // Interstitial Reach
        'interstitial_reach',
        // Interstitial Taps
        'interstitial_taps',
        // Interstitial Tap Rate
        'interstitial_tap_rate',
        // PDP Views
        'pdp_views',
        // Add to Cart
        'add_to_cart',
        // Total Online Revenue ($)
        'total_online_revenue',
        // Total In Store Revenue ($)
        'total_instore_revenue',
        // Online Units
        'total_online_units',
        // In Store Units
        'total_instore_units',
        // Tap Online Revenue ($)
        'tap_online_revenue',
        // Tap In Store Revenue ($)
        'tap_instore_revenue',
        // Tap Online Units
        'tap_online_units',
        // Tap In Store Units
        'tap_instore_units',
    ],
};

export const defaultColumns = {
    day: [
        // Visit Date
        'date',
        // Total Unique Reach
        'total_unique_reach',
        // Notification Taps
        'notification_taps',
        // Banner Impressions
        'banner_impressions',
        // Banner Reach
        'banner_reach',
        // Banner Taps
        'banner_taps',
        // Banner Tap Rate
        'banner_tap_rate',
        // Banner Dismissals
        'banner_dismissals',
        // Banner Dismissal Rate
        'banner_dismissal_rate',
        // Interstitial Impressions
        'interstitial_impressions',
        // Interstitial Reach
        'interstitial_reach',
        // Interstitial Taps
        'interstitial_taps',
        // Interstitial Tap Rate
        'interstitial_tap_rate',
        // PDP Views
        'pdp_views',
        // Add to Cart
        'add_to_cart',
        // Total Online Revenue ($)
        'total_online_revenue',
        // Total In Store Revenue ($)
        'total_instore_revenue',
        // Online Units
        'total_online_units',
        // In Store Units
        'total_instore_units',
        // Tap Online Revenue ($)
        'tap_online_revenue',
        // Tap In Store Revenue ($)
        'tap_instore_revenue',
        // Tap Online Units
        'tap_online_units',
        // Tap In Store Units
        'tap_instore_units',
    ],
    device: [
        // Device Type
        'device_type',
        // Total Unique Reach
        'total_unique_reach',
        // Notification Taps
        'notification_taps',
        // Banner Impressions
        'banner_impressions',
        // Banner Reach
        'banner_reach',
        // Banner Taps
        'banner_taps',
        // Banner Tap Rate
        'banner_tap_rate',
        // Banner Dismissals
        'banner_dismissals',
        // Banner Dimissal Rate
        'banner_dismissal_rate',
        // Interstitial Impressions
        'interstitial_impressions',
        // Interstitial Reach
        'interstitial_reach',
        // Interstitial Taps
        'interstitial_taps',
        // Interstitial Tap Rate
        'interstitial_tap_rate',
        // PDP Views
        'pdp_views',
        // Add to Cart
        'add_to_cart',
        // Total Online Revenue ($)
        'total_online_revenue',
        // Total In Store Revenue ($)
        'total_instore_revenue',
        // Online Units
        'total_online_units',
        // In Store Units
        'total_instore_units',
        // Tap Online Revenue ($)
        'tap_online_revenue',
        // Tap In Store Revenue ($)
        'tap_instore_revenue',
        // Tap Online Units
        'tap_online_units',
        // Tap In Store Units
        'tap_instore_units',
    ],
    product: [
        // SKU
        'sku_id',
        // Description
        'sku_description',
        // PDP Views
        'pdp_views',
        // Add to Cart
        'add_to_cart',
        // Total Online Revenue ($)
        'total_online_revenue',
        // Total In Store Revenue ($)
        'total_instore_revenue',
        // Online Units
        'online_units',
        // In Store Units
        'instore_units',
        // Tap Online Revenue ($)
        'tap_online_revenue',
        // Tap In Store Revenue ($)
        'tap_instore_revenue',
        // Tap Online Units
        'tap_online_units',
        // Tap In Store Units
        'tap_instore_units',
    ],
    creative: [
        // Creative ID
        'creative_id',
        // Creative Name
        'creative_name',
        // Total Unique Reach
        'total_unique_reach',
        // Notification Taps
        'notification_taps',
        // Banner Impressions
        'banner_impressions',
        // Banner Reach
        'banner_reach',
        // Banner Taps
        'banner_taps',
        // Banner Tap Rate
        'banner_tap_rate',
        // Banner Dismissals
        'banner_dismissals',
        // Banner Dismissal Rate
        'banner_dismissal_rate',
        // Interstitial Impressions
        'interstitial_impressions',
        // Interstitial Reach
        'interstitial_reach',
        // Interstitial Taps
        'interstitial_taps',
        // Interstitial Tap Rate
        'interstitial_tap_rate',
        // PDP Views
        'pdp_views',
        // Add to Cart
        'add_to_cart',
        // Total Online Revenue ($)
        'total_online_revenue',
        // Total In Store Revenue ($)
        'total_instore_revenue',
        // Online Units
        'total_online_units',
        // In Store Units
        'total_instore_units',
        // Tap Online Revenue ($)
        'tap_online_revenue',
        // Tap In Store Revenue ($)
        'tap_instore_revenue',
        // Tap Online Units
        'tap_online_units',
        // Tap In Store Units
        'tap_instore_units',
    ],
};

export const modifyColumns = {
    day: [
        // Visit Date
        // 'date',
        // Total Unique Reach
        'total_unique_reach',
        // Notification Taps
        'notification_taps',
        // Banner Impressions
        'banner_impressions',
        // Banner Reach
        'banner_reach',
        // Banner Taps
        'banner_taps',
        // Banner Tap Rate
        'banner_tap_rate',
        // Banner Dismissals
        'banner_dismissals',
        // Banner Dismissal Rate
        'banner_dismissal_rate',
        // Interstitial Impressions
        'interstitial_impressions',
        // Interstitial Reach
        'interstitial_reach',
        // Interstitial Taps
        'interstitial_taps',
        // Interstitial Tap Rate
        'interstitial_tap_rate',
        // PDP Views
        'pdp_views',
        // Add to Cart
        'add_to_cart',
        // Total Online Revenue ($)
        'total_online_revenue',
        // Total In Store Revenue ($)
        'total_instore_revenue',
        // Online Units
        'total_online_units',
        // In Store Units
        'total_instore_units',
        // Tap Online Revenue ($)
        'tap_online_revenue',
        // Tap In Store Revenue ($)
        'tap_instore_revenue',
        // Tap Online Units
        'tap_online_units',
        // Tap In Store Units
        'tap_instore_units',
    ],
    device: [
        // Device Type
        // 'device_type',
        // Total Unique Reach
        'total_unique_reach',
        // Notification Taps
        'notification_taps',
        // Banner Impressions
        'banner_impressions',
        // Banner Reach
        'banner_reach',
        // Banner Taps
        'banner_taps',
        // Banner Tap Rate
        'banner_tap_rate',
        // Banner Dismissals
        'banner_dismissals',
        // Banner Dimissal Rate
        'banner_dismissal_rate',
        // Interstitial Impressions
        'interstitial_impressions',
        // Interstitial Reach
        'interstitial_reach',
        // Interstitial Taps
        'interstitial_taps',
        // Interstitial Tap Rate
        'interstitial_tap_rate',
        // PDP Views
        'pdp_views',
        // Add to Cart
        'add_to_cart',
        // Total Online Revenue ($)
        'total_online_revenue',
        // Total In Store Revenue ($)
        'total_instore_revenue',
        // Online Units
        'total_online_units',
        // In Store Units
        'total_instore_units',
        // Tap Online Revenue ($)
        'tap_online_revenue',
        // Tap In Store Revenue ($)
        'tap_instore_revenue',
        // Tap Online Units
        'tap_online_units',
        // Tap In Store Units
        'tap_instore_units',
    ],
    product: [
        // SKU
        // 'sku_id',
        // Description
        'sku_description',
        // Brand
        'brand',
        // Department ID
        'dept_id',
        // Department Name
        'dept_name',
        // Class ID
        'class_id',
        // Class Name
        'class_name',
        // SubClass ID
        'subclass_id',
        // SubClass Name
        'subclass_name',
        // PDP Views
        'pdp_views',
        // Add to Cart
        'add_to_cart',
        // Total Online Revenue ($)
        'total_online_revenue',
        // Total In Store Revenue ($)
        'total_instore_revenue',
        // Online Units
        'online_units',
        // In Store Units
        'instore_units',
        // Tap Online Revenue ($)
        'tap_online_revenue',
        // Tap In Store Revenue ($)
        'tap_instore_revenue',
        // Tap Online Units
        'tap_online_units',
        // Tap In Store Units
        'tap_instore_units',
    ],
    creative: [
        // Creative ID
        'creative_id',
        // Creative Name
        'creative_name',
        // Total Unique Reach
        'total_unique_reach',
        // Notification Taps
        'notification_taps',
        // Banner Impressions
        'banner_impressions',
        // Banner Reach
        'banner_reach',
        // Banner Taps
        'banner_taps',
        // Banner Tap Rate
        'banner_tap_rate',
        // Banner Dismissals
        'banner_dismissals',
        // Banner Dismissal Rate
        'banner_dismissal_rate',
        // Interstitial Impressions
        'interstitial_impressions',
        // Interstitial Reach
        'interstitial_reach',
        // Interstitial Taps
        'interstitial_taps',
        // Interstitial Tap Rate
        'interstitial_tap_rate',
        // PDP Views
        'pdp_views',
        // Add to Cart
        'add_to_cart',
        // Total Online Revenue ($)
        'total_online_revenue',
        // Total In Store Revenue ($)
        'total_instore_revenue',
        // Online Units
        'total_online_units',
        // In Store Units
        'total_instore_units',
        // Tap Online Revenue ($)
        'tap_online_revenue',
        // Tap In Store Revenue ($)
        'tap_instore_revenue',
        // Tap Online Units
        'tap_online_units',
        // Tap In Store Units
        'tap_instore_units',
    ],
};

export const columnMap = {
    date: {
        label: 'Visit Date',
        type: 'date',
        field: 'date',
        sortable: true,
        lineBreak: false,
    },
    total_unique_reach: {
        label: 'Total Unique Reach',
        type: 'number',
        field: 'total_unique_reach',
        sortable: true,
        lineBreak: false,
    },
    notification_taps: {
        label: 'Notification Taps',
        type: 'number',
        field: 'notification_taps',
        sortable: true,
        lineBreak: false,
    },
    banner_impressions: {
        label: 'Banner Impressions',
        type: 'number',
        field: 'banner_impressions',
        sortable: true,
        lineBreak: false,
    },
    banner_reach: {
        label: 'Banner Reach',
        type: 'number',
        field: 'banner_reach',
        sortable: true,
        lineBreak: false,
    },
    banner_taps: {
        label: 'Banner Taps',
        type: 'number',
        field: 'banner_taps',
        sortable: true,
        lineBreak: false,
    },
    banner_tap_rate: {
        label: 'Banner Tap Rate',
        type: 'percent',
        field: 'banner_tap_rate',
        sortable: true,
        lineBreak: false,
    },
    banner_dismissals: {
        label: 'Banner Dismissals',
        type: 'number',
        field: 'banner_dismissals',
        sortable: true,
        lineBreak: false,
    },
    banner_dismissal_rate: {
        label: 'Banner Dismissal Rate',
        type: 'percent',
        field: 'banner_dismissal_rate',
        sortable: true,
        lineBreak: false,
    },
    interstitial_impressions: {
        label: 'Interstitial Impressions',
        type: 'number',
        field: 'interstitial_impressions',
        sortable: true,
        lineBreak: false,
    },
    interstitial_reach: {
        label: 'Interstitial Reach',
        type: 'number',
        field: 'interstitial_reach',
        sortable: true,
        lineBreak: false,
    },
    interstitial_taps: {
        label: 'Interstitial Taps',
        type: 'number',
        field: 'interstitial_taps',
        sortable: true,
        lineBreak: false,
    },
    interstitial_tap_rate: {
        label: 'Interstitial Tap Rate',
        type: 'percent',
        field: 'interstitial_tap_rate',
        sortable: true,
        lineBreak: false,
    },
    pdp_views: {
        label: 'PDP Views',
        type: 'number',
        field: 'pdp_views',
        sortable: true,
        lineBreak: false,
    },
    add_to_cart: {
        label: 'Add to Cart',
        type: 'number',
        field: 'add_to_cart',
        sortable: true,
        lineBreak: false,
    },
    total_online_revenue: {
        label: 'Total Online Revenue ($)',
        type: 'currency',
        field: 'total_online_revenue',
        sortable: true,
        lineBreak: false,
    },
    total_instore_revenue: {
        label: 'Total In Store Revenue ($)',
        type: 'currency',
        field: 'total_instore_revenue',
        sortable: true,
        lineBreak: false,
    },
    total_online_units: {
        label: 'Online Units',
        type: 'number',
        field: 'total_online_units',
        sortable: true,
        lineBreak: false,
    },
    total_instore_units: {
        label: 'In Store Units',
        type: 'number',
        field: 'total_instore_units',
        sortable: true,
        lineBreak: false,
    },
    tap_online_revenue: {
        label: 'Tap Online Revenue ($)',
        type: 'currency',
        field: 'tap_online_revenue',
        sortable: true,
        lineBreak: false,
    },
    tap_instore_revenue: {
        label: 'Tap In Store Revenue ($)',
        type: 'currency',
        field: 'tap_instore_revenue',
        sortable: true,
        lineBreak: false,
    },
    tap_online_units: {
        label: 'Tap Online Units',
        type: 'number',
        field: 'tap_online_units',
        sortable: true,
        lineBreak: false,
    },
    tap_instore_units: {
        label: 'Tap In Store Units',
        type: 'number',
        field: 'tap_instore_units',
        sortable: true,
        lineBreak: false,
    },
    device_type: {
        label: 'Device Type',
        type: 'description',
        field: 'device_type',
        sortable: true,
        lineBreak: false,
    },
    online_units: {
        label: 'Online Units',
        type: 'number',
        field: 'online_units',
        sortable: true,
        lineBreak: false,
        fiterable: true,
    },
    instore_units: {
        label: 'In Store Units',
        type: 'number',
        field: 'instore_units',
        sortable: true,
        lineBreak: false,
        fiterable: true,
    },
    sku_id: {
        label: 'SKU',
        type: 'string',
        field: 'sku_id',
        sortable: true,
        lineBreak: false,
    },
    sku_description: {
        label: 'Description',
        type: 'description',
        field: 'sku_description',
        sortable: true,
        lineBreak: false,
    },
    brand: {
        label: 'Brand',
        type: 'description',
        field: 'brand',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    dept_id: {
        label: 'Department ID',
        type: 'description',
        field: 'dept_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    dept_name: {
        label: 'Department Name',
        type: 'description',
        field: 'dept_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    class_id: {
        label: 'Class ID',
        type: 'description',
        field: 'class_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    class_name: {
        label: 'Class Name',
        type: 'description',
        field: 'class_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    subclass_id: {
        label: 'SubClass ID',
        type: 'description',
        field: 'subclass_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    subclass_name: {
        label: 'SubClass Name',
        type: 'description',
        field: 'subclass_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    creative_id: {
        label: 'Creative ID',
        type: 'string',
        field: 'creative_id',
        sortable: true,
        lineBreak: false,
    },
    creative_name: {
        label: 'Creative Name',
        type: 'description',
        field: 'creative_name',
        sortable: true,
        lineBreak: false,
    },
};
