import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { Button, Grid } from '@material-ui/core';
import {
    required,
    isNotBlank,
    minLengthEqual,
    isValidPasswordForUserCreation,
    composeValidators,
    isValidWithOneCapitalCharacter,
    isValidWithOneLowerCaseCharacter,
    isValidWithOneNumber,
    isValidWithOneSpecialCharacter,
} from 'utils/validators';
import Password from 'components/Password';
import { ArrowForwardSharp } from '@material-ui/icons';
import useStyles from 'containers/IDAMInvite/formStyle';

const usePasswordFieldStyles = makeStyles((theme) => ({
    circle: {
        width: 23,
        height: 23,
        backgroundColor: theme.palette.techYellow,
        display: 'block',
        position: 'relative',
        borderRadius: '100%',
        marginTop: 27,
        marginRight: 12,
    },
    formFieldRow: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 12,
    },
    submitError: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 45,
        fontWeight: 300,
        fontSize: 18,
        paddingTop: 30,
        color: theme.palette.valueRed,
        fontFamily: theme.typography.fontFamily,
    },
    formFieldTitle: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 12,
        fontWeight: 700,
        fontSize: 18,
        paddingTop: 40,
        color: theme.palette.borderGray,
        fontFamily: theme.typography.fontFamily,
    },
}));

export function CreatePasswordForm({ onSubmit, submitError, loading }) {
    const passwordFieldClasses = usePasswordFieldStyles();
    const classes = useStyles();

    const onCreatePasswordFormSubmit = ({ password }) => {
        onSubmit(password);
    };

    return (
        <Grid item xs={6} className={classes.bodyContainer}>
            <Grid item xs={12}>
                <div className={classes.bodyLeft}>
                    <span className={classes.formTitleLine}>
                        <div className={`${classes.passwordFormTitle} ${classes.standardWhite}`}>Strong</div>
                    </span>
                    <span>
                        <div className={`${classes.passwordFormTitle} ${classes.standardWhite}`}>Password.</div>
                    </span>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.passwordBodyRight}>
                    <Form
                        onSubmit={onCreatePasswordFormSubmit}
                        validate={(values) => {
                            const errors = {};
                            if (values.confirmPassword !== values.password) {
                                errors.confirmPassword = 'Confirm password must match.';
                            }
                            return errors;
                        }}
                        render={({ handleSubmit, pristine, hasValidationErrors }) => (
                            <form onSubmit={handleSubmit}>
                                <div className={passwordFieldClasses.formFieldTitle}>Create a strong password</div>
                                <div className={passwordFieldClasses.formFieldRow}>
                                    <span className={passwordFieldClasses.circle} />
                                    <Field
                                        name="password"
                                        component="input"
                                        type="text"
                                        validate={composeValidators(
                                            required,
                                            isNotBlank,
                                            minLengthEqual(8),
                                            isValidPasswordForUserCreation,
                                            isValidWithOneCapitalCharacter,
                                            isValidWithOneLowerCaseCharacter,
                                            isValidWithOneNumber,
                                            isValidWithOneSpecialCharacter,
                                        )}
                                    >
                                        {(props) => (
                                            <Password
                                                inputLabel="Password"
                                                fullWidth
                                                margin="dense"
                                                variant="standard"
                                                disabled={loading}
                                                inputProps={{
                                                    'data-testid': 'create-user-password-input',
                                                    maxLength: 255,
                                                }}
                                                {...props}
                                                idam
                                                tooltipPlacement="top-end"
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div className={passwordFieldClasses.formFieldRow}>
                                    <span className={passwordFieldClasses.circle} />
                                    <Field
                                        name="confirmPassword"
                                        component="input"
                                        type="text"
                                        validate={composeValidators(required)}
                                    >
                                        {(props) => (
                                            <Password
                                                inputLabel="Confirm Password"
                                                fullWidth
                                                margin="dense"
                                                variant="standard"
                                                disabled={loading}
                                                inputProps={{
                                                    'data-testid': 'create-user-password-input',
                                                    maxLength: 255,
                                                }}
                                                {...props}
                                                idam
                                            />
                                        )}
                                    </Field>
                                </div>
                                {submitError && (
                                    <div className={classnames(classes.submitError, classes.errorStyle)}>
                                        {submitError}
                                    </div>
                                )}
                                <Button
                                    data-testid="submit-user-form-button"
                                    className={classnames(
                                        classes.passwordFormButton,
                                        (hasValidationErrors || pristine) && classes.disabled,
                                    )}
                                    disabled={hasValidationErrors || pristine}
                                    type="submit"
                                    variant="text"
                                    endIcon={<ArrowForwardSharp />}
                                >
                                    Submit
                                </Button>
                            </form>
                        )}
                    />
                </div>
            </Grid>
        </Grid>
    );
}

CreatePasswordForm.propTypes = {
    loading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onExit: PropTypes.func,
    submitError: PropTypes.string,
};

export default CreatePasswordForm;
