import React from 'react';
import useFeatureFlags from '../../../utils/featureFlags/useFeatureFlags';

export const ResourcePaidSearch = () => {
    const flags = useFeatureFlags();
    if (flags.showPaidSearchPtc) {
        return (
            <>
                <br />
                <br />
                <h3>Search.Ads - Paid Search</h3>
                <br />
                <p>Move to the front of the line and capture demand with personalized Paid Search ads.</p>
                <br />
                <br />
                <h3>Report Views </h3>
                <br />
                <p>
                    <b>Summary Page </b>
                    <ul>
                        <li>
                            This page shows the daily performance of the campaign and includes line graphs of common
                            KPIs to show trends over time.
                        </li>
                        <li>
                            This is also where you will see total campaign recaps for the time period the report was
                            pulled.
                        </li>
                        <li>
                            Most common questions can be answered from this page such as what is the total ROAS, CTR,
                            and what happened on a particular day&#47;week.
                        </li>
                    </ul>
                </p>
                <br />
                <p>
                    <b>Campaign View </b>
                    <ul>
                        <li>
                            This view shows the performance by Campaign Type &#40;Product Listing Ads or Non-Product
                            Listing Ads&#41; and by Publisher.
                        </li>
                        <li>
                            This can be used to understand the budget mix between text and shopping tactics. This view
                            provides full performance and attribution details as well including cost per click
                            &#40;CPC&#41; and return on ad spend &#40;ROAS&#41;.
                        </li>
                    </ul>
                </p>
                <br />
                <p>
                    <b>Query View </b>
                    <ul>
                        <li>
                            Depending on the type of paid search campaign, query level information is not available, so
                            this tab may be blank. If there is data, this view shows the top 100 searched phrases by
                            delivered spend. This also provides an average cost per click metric and click through rate
                            for each search phrase.
                        </li>
                        <li>
                            You can use this view to understand what phrases people are searching for that match the
                            SKUs active for paid search and their relative significance to the campaign.
                        </li>
                    </ul>
                </p>
                <br />
                <p>
                    <b>Device View </b>
                    <ul>
                        <li>
                            By device view shows how the campaign is performing across various devices. This includes
                            desktop, mobile and tablet.
                        </li>
                        <li>
                            This view is useful in understanding which devices customers are using most often to
                            interact with your products.
                        </li>
                        <li>
                            Attribution metrics are only available in this view for campaigns that ended before 9/18/23.
                        </li>
                    </ul>
                </p>
                <br />
                <p>
                    <b>Product View </b>
                    <ul>
                        <li>By Product view shows which SKUs customers are purchasing related to your campaign.</li>
                        <li>
                            This view is useful in understanding which products customers ultimately purchased after
                            clicking on your paid search campaign. This view provides attribution details for online and
                            instore revenue by SKU.
                        </li>
                        <li>
                            Attributes related to the Best Buy Product hierarchy, such as Brand, Department, Class and
                            Subclass are additional columns you may add to reporting using the Modify Columns feature
                            when viewing a campaign.
                        </li>
                    </ul>
                </p>
                <br />
                <br />
                <h3>Report Glossary </h3>
                <br />
                <p>
                    <b>Impressions:</b> An impression counts each time your ad is shown on a search engine results page
                    or other site on an Ad Network. Each time your ad appears, counts as an impression.
                </p>
                <br />
                <p>
                    <b>Clicks:</b> A click occurs when a user sees your ad and clicks on it, leading them to a
                    corresponding landing page on the Best Buy website.
                </p>
                <br />
                <p>
                    <b>CTR:</b> Click-through rate is the number of clicks your ad receives divided by the number of
                    times your ad is shown: clicks &divide; impressions &#61; CTR.
                </p>
                <br />
                <p>
                    <b>CPC:</b> Average cost per click indicates the average amount paid for each click on your ads:
                    total cost of clicks &divide; total number of clicks &#61; CPC.
                </p>
                <br />
                <p>
                    <b>Delivered Spend:</b> Cost of clicks on your ads &#40;does not include the service&#47;management
                    fee&#41;.
                </p>
                <br />
                <p>
                    <b>Revenue - Online:</b> Total online revenue resulting from clicks on your ads for SKUs associated
                    with your campaigns.
                </p>
                <br />
                <p>
                    <b>Revenue - In Store:</b> Total in store revenue resulting from clicks on your ads for SKUs
                    associated with your campaigns.
                </p>
                <br />
                <p>
                    <b>Total Revenue:</b> Total online revenue &#43; total in store revenue for SKUs associated with
                    your campaigns.
                </p>
                <br />
                <p>
                    <b>ROAS - Online:</b> Online return on ad spend: online revenue &divide; delivered spend &#61; ROAS
                    &#40;&#36;&#41;.
                </p>
                <br />
                <p>
                    <b>ROAS - In Store:</b> In store return on ad spend: in store revenue &divide; delivered spend &#61;
                    ROAS &#40;&#36;&#41;.
                </p>
                <br />
                <p>
                    <b>Total ROAS:</b> Total return on ad spend: total revenue &divide; delivered spend &#61; ROAS
                    &#40;&#36;&#41;.
                </p>
                <br />
                <p>
                    <b>Online Units:</b> Total online units resulting from clicks on your ads for SKUs associated with
                    your campaigns.
                </p>
                <br />
                <p>
                    <b>In Store Units:</b> Total in store units resulting from clicks on your ads for SKUs associated
                    with your campaigns.
                </p>
                <br />
                <p>
                    <b>Total Units:</b> Total online units &#43; total in store units for SKUs associated with your
                    campaigns.
                </p>
                <br />
                <br />
                <h3>Other</h3>
                <br />
                <p>
                    <b>Campaign Budget:</b> On the Summary worksheet, this is the total budget allocated to your Paid
                    Search campaign.
                </p>
                <br />
                <p>
                    <b>Service/Management Fee:</b> On the Summary worksheet, this fee covers the management of your
                    campaign.
                </p>
                <br />
                <p>
                    <b>Campaign Name:</b> The internal name used on your campaign&#40;s&#41;.
                </p>
                <br />
                <p>
                    <b>Campaign Type:</b> Type of paid search campaign - can be Product Listing Ads or Non-Product
                    Listing Ads. Note: there are several different types of shopping campaigns.
                </p>
                <br />
                <p>
                    <b>Publisher:</b> Search engine or ad network - such as Google AdWords, Bing Ads, etc.
                </p>
                <br />
                <p>
                    <b>Search Query:</b> What search engine users typed in search query box to find the paid search ad.
                </p>
                <br />
                <p>
                    <b>Device:</b> The type of device the search engine user saw/clicked on your ad.
                </p>
                <br />
                <p>
                    <b>Attribution:</b> For revenue attributed to paid search campaigns, attribution is revenue, last
                    click, 14 day attribution window, click only &#40;not view through&#41;, and revenue is presented in
                    reporting on day of conversion. All revenue and related attribution metrics are for specific SKUs
                    associated with your campaign.
                </p>
                <br />
                <br />
                <h3>Attribution </h3>
                <br />
                <p>
                    <ul>
                        <li>
                            For revenue attributed to paid search campaigns, attribution is revenue, last click (before
                            9/19/23) or multi-touch (9/19/23 and after), 14-day attribution window, click only &#40;not
                            view through&#41;, and revenue is presented in reporting on day of conversion. All revenue
                            and related attribution metrics are for specific SKUs associated with your campaign.
                        </li>
                        <li>
                            For In Store attribution, we take the impression level data from our search partners and
                            utilize a third-party clean room to match up customer level details between our impression
                            details and internal sales transactions. We then take those matched transactions and filter
                            for your specific SKUs only to provide you a closed loop omni channel revenue attribution on
                            your paid search campaigns with Best Buy Ads.
                        </li>
                        <li>
                            Attribution data for the device view is not available for campaigns that ended after
                            9/18/23.
                        </li>
                        <li>
                            Due to the change in attribution methodology from last click to multi-touch on 9/19/23, you
                            may notice fluctuations in performance if comparing the two side by side. Last click will
                            give credit to one campaign, whereas performance could be split with multiple campaigns now
                            that we are utilizing multi-touch, giving a more accurate view of each of your campaigns
                            impact.
                        </li>
                    </ul>
                </p>
                <br />
                <br />
                <h3>Reporting FAQs </h3>
                <br />
                <p>
                    <b>My campaign is not showing any query data, is something wrong?</b>
                    <br />
                    Depending on the setup of the paid search campaigns, query data may not be available. Campaigns
                    setup through Google on Performance Max utilize Smart Bidding, and no longer provide data on
                    queries.
                </p>
                <br />
                <p>
                    <b>What metrics align to what goals? </b>
                    <br />
                    <ul>
                        <li>Awareness &#61; Impressions</li>
                        <li>Consideration &#61; Clicks, CTR, CPC</li>
                        <li>Conversion &#61; Revenue, ROAS</li>
                    </ul>
                </p>
                <br />
                <p>
                    <b>Can I see other metrics not listed in reporting? </b>
                    <br />
                    Many additional metrics can be calculated manually by using the metrics available in the existing
                    reports. If you wish to see additional metrics, you can request support from your BBY contact and we
                    will do our best to assist.
                </p>
                <br />
                <p>
                    <b>Can I see custom reporting timeframes or views? </b>
                    <br />
                    We do not allow changes to our standard reporting deliverables to ensure consistency, quality, and
                    speed. However, you have the ability to view and download campaign performance through My Ads.
                </p>
                <br />
                <p>
                    <b>How often can I expect reporting?</b>
                    <br />
                    R&I Team will provide monthly reports for any campaign over 8 weeks in length, by the end of the 3rd
                    fiscal week each month. Wrap-Up reports will be sent out 3 weeks after campaign end date.
                </p>
                <br />
                <p>
                    <b>What CTR or ROAS can we expect to see from our campaign? </b>
                    <br />
                    Each campaign performance can vary due to a variety of factors. Here are some things to consider:
                    <br />
                    <br />
                    <ol>
                        <li>
                            <b>Dates the campaign is live</b>
                            <br />
                            Secondary holiday timeframes &#40;Memorial Day, Labor Day, etc.&#41;, or product launch
                            dates for example may impact campaign performance.
                        </li>
                        <br />
                        <li>
                            <b>Reporting SKUs selected</b>
                            <br />

                            <ul>
                                <li>Fewer SKUs will typically result in lower ROAS.</li>
                                <li>Choosing popular SKUs for Paid Search will typically result in higher ROAS.</li>
                            </ul>
                        </li>
                        <br />
                        <li>
                            <b>ASP of reporting SKUs </b>
                            <br />
                            Lower priced SKUs will typically result in lower ROAS.
                        </li>
                        <br />
                        <li>
                            <b>Size of potential purchaser audience of products </b>
                            <br />
                            Accessory SKUs typically require ownership of specific hardware to use them which may result
                            in lower ROAS and CTR, i.e., you need a certain DSLR camera to use a certain lens, or an
                            iPhone 13 to use an iPhone 13 case.
                        </li>
                    </ol>
                </p>
                <br />
                <br />
            </>
        );
    }
    return (
        <>
            <br />
            <br />
            <h3>Search.Ads - Paid Search</h3>
            <br />
            <p>Move to the front of the line and capture demand with personalized Paid Search ads.</p>
            <br />
            <br />
            <h3>Report Views </h3>
            <br />
            <p>
                <b>Summary Page </b>
                <ul>
                    <li>
                        This page shows the daily performance of the campaign and includes line graphs of common KPIs to
                        show trends over time.
                    </li>
                    <li>
                        This is also where you will see total campaign recaps for the time period the report was pulled.
                    </li>
                    <li>
                        Most common questions can be answered from this page such as what is the total ROAS, CTR, and
                        what happened on a particular day&#47;week.
                    </li>
                </ul>
            </p>
            <br />
            <p>
                <b>Campaign </b>
                <ul>
                    <li>
                        This view shows the performance by Campaign Type &#40;Product Listing Ads or Non-Product Listing
                        Ads&#41; and by Publisher.
                    </li>
                    <li>
                        This can be used to understand the budget mix between text and shopping tactics. This view
                        provides full performance and attribution details as well including cost per click &#40;CPC&#41;
                        and return on ad spend &#40;ROAS&#41;.
                    </li>
                </ul>
            </p>
            <br />
            <p>
                <b>Query </b>
                <ul>
                    <li>
                        Depending on the type of paid search campaign, query level information is not available, so this
                        tab may be blank. If there is data, this view shows the top 100 searched phrases by delivered
                        spend. This also provides an average cost per click metric and click through rate for each
                        search phrase.
                    </li>
                    <li>
                        You can use this view to understand what phrases people are searching for that match the SKUs
                        active for paid search and their relative significance to the campaign.
                    </li>
                </ul>
            </p>
            <br />
            <p>
                <b>Device </b>
                <ul>
                    <li>
                        By device view shows how the campaign is performing across various devices. This includes
                        desktop, mobile and tablet.
                    </li>
                    <li>
                        This view is useful in understanding which devices customers are using most often to interact
                        with your products. This view provides full performance and attribution details as well
                        including cost per click &#40;CPC&#41; and return on ad spend &#40;ROAS&#41;.
                    </li>
                </ul>
            </p>
            <br />
            <p>
                <b>Product </b>
                <ul>
                    <li>By Product view shows which SKUs customers are purchasing related to your campaign.</li>
                    <li>
                        This view is useful in understanding which products customers ultimately purchased after
                        clicking on your paid search campaign. This view provides attribution details for online and
                        instore revenue by SKU.
                    </li>
                    <li>
                        Attributes related to the Best Buy Product hierarchy, such as Brand, Department, Class and
                        Subclass are additional columns you may add to reporting using the Modify Columns feature when
                        viewing a campaign.
                    </li>
                </ul>
            </p>
            <br />
            <br />
            <h3>Report Glossary </h3>
            <br />
            <p>
                <b>Impressions:</b> An impression counts each time your ad is shown on a search engine results page or
                other site on an Ad Network. Each time your ad appears, counts as an impression.
            </p>
            <br />
            <p>
                <b>Clicks:</b> A click occurs when a user sees your ad and clicks on it, leading them to a corresponding
                landing page on the Best Buy website.
            </p>
            <br />
            <p>
                <b>CTR:</b> Click-through rate is the number of clicks your ad receives divided by the number of times
                your ad is shown: clicks &divide; impressions &#61; CTR.
            </p>
            <br />
            <p>
                <b>CPC:</b> Average cost per click indicates the average amount paid for each click on your ads: total
                cost of clicks &divide; total number of clicks &#61; CPC.
            </p>
            <br />
            <p>
                <b>Delivered Spend:</b> Cost of clicks on your ads &#40;does not include the service&#47;management
                fee&#41;.
            </p>
            <br />
            <p>
                <b>Revenue - Online:</b> Total online revenue resulting from clicks on your ads for SKUs associated with
                your campaigns.
            </p>
            <br />
            <p>
                <b>Revenue - In Store:</b> Total in store revenue resulting from clicks on your ads for SKUs associated
                with your campaigns.
            </p>
            <br />
            <p>
                <b>Total Revenue:</b> Total online revenue &#43; total in store revenue for SKUs associated with your
                campaigns.
            </p>
            <br />
            <p>
                <b>ROAS - Online:</b> Online return on ad spend: online revenue &divide; delivered spend &#61; ROAS
                &#40;&#36;&#41;.
            </p>
            <br />
            <p>
                <b>ROAS - In Store:</b> In store return on ad spend: in store revenue &divide; delivered spend &#61;
                ROAS &#40;&#36;&#41;.
            </p>
            <br />
            <p>
                <b>Total ROAS:</b> Total return on ad spend: total revenue &divide; delivered spend &#61; ROAS
                &#40;&#36;&#41;.
            </p>
            <br />
            <p>
                <b>Online Units:</b> Total online units resulting from clicks on your ads for SKUs associated with your
                campaigns.
            </p>
            <br />
            <p>
                <b>In Store Units:</b> Total in store units resulting from clicks on your ads for SKUs associated with
                your campaigns.
            </p>
            <br />
            <p>
                <b>Total Units:</b> Total online units &#43; total in store units for SKUs associated with your
                campaigns.
            </p>
            <br />
            <br />
            <h3>Other</h3>
            <br />
            <p>
                <b>Campaign Budget:</b> On the Summary worksheet, this is the total budget allocated to your Paid Search
                campaign.
            </p>
            <br />
            <p>
                <b>Service/Management Fee:</b> On the Summary worksheet, this fee covers the management of your
                campaign.
            </p>
            <br />
            <p>
                <b>Campaign Name:</b> The internal name used on your campaign&#40;s&#41;.
            </p>
            <br />
            <p>
                <b>Campaign Type:</b> Type of paid search campaign - can be Product Listing Ads or Non-Product Listing
                Ads. Note: there are several different types of shopping campaigns.
            </p>
            <br />
            <p>
                <b>Publisher:</b> Search engine or ad network - such as Google AdWords, Bing Ads, etc.
            </p>
            <br />
            <p>
                <b>Search Query:</b> What search engine users typed in search query box to find the paid search ad.
            </p>
            <br />
            <p>
                <b>Device:</b> The type of device the search engine user saw/clicked on your ad.
            </p>
            <br />
            <p>
                <b>Attribution:</b> For revenue attributed to paid search campaigns, attribution is revenue, last click,
                14 day attribution window, click only &#40;not view through&#41;, and revenue is presented in reporting
                on day of conversion. All revenue and related attribution metrics are for specific SKUs associated with
                your campaign.
            </p>
            <br />
            <br />
            <h3>Attribution </h3>
            <br />
            <p>
                <ul>
                    <li>
                        For revenue attributed to paid search campaigns, attribution is revenue, last click, 14-day
                        attribution window, click only &#40;not view through&#41;, and revenue is presented in reporting
                        on day of conversion. All revenue and related attribution metrics are for specific SKUs
                        associated with your campaign.
                    </li>
                    <li>
                        For In Store attribution, we take the impression level data from our search partners and utilize
                        a third-party clean room to match up customer level details between our impression details and
                        internal sales transactions. We then take those matched transactions and filter for your
                        specific SKUs only to provide you a closed loop omni channel revenue attribution on your paid
                        search campaigns with Best Buy Ads.
                    </li>
                </ul>
            </p>
            <br />
            <br />
            <h3>Reporting FAQs </h3>
            <br />
            <p>
                <b>
                    For campaigns that have query data, do we only show the top 100 search queries? Can we see all
                    queries?{' '}
                </b>
                <br />
                Reporting purposely limits search queries to top 100 to focus on meaningful results. Queries outside of
                the top 100 have little to no impact to campaign performance and should not be the focus of our review.
                If you do need more than top 100 queries for a special use case BBY is able to access all results.
            </p>
            <br />
            <p>
                <b>What metrics align to what goals? </b>
                <br />
                <ul>
                    <li>Awareness &#61; Impressions </li>
                    <li>Consideration &#61; Clicks, CTR, CPC </li>
                    <li>Conversion &#61; Revenue, ROAS </li>
                </ul>
            </p>
            <br />
            <p>
                <b>Can I see other metrics not listed in reporting? </b>
                <br />
                Many additional metrics can be calculated manually by using the metrics available in the existing
                reports. If you wish to see additional metrics, you can request support from your BBY contact and we
                will do our best to assist.
            </p>
            <br />
            <p>
                <b>Can I see custom reporting timeframes or views? </b>
                <br />
                We do not allow changes to our standard reporting deliverables to ensure consistency, quality, and
                speed. However, you can always reach out to BBY contact if you have questions.
            </p>
            <br />
            <p>
                <b>How often can I expect reporting?</b>
                <br />
                R&I Team will provide monthly reports for any campaign over 8 weeks in length, by the end of the 3rd
                fiscal week each month. Wrap-Up reports will be sent out 3 weeks after campaign end date.
            </p>
            <br />
            <p>
                <b>What CTR or ROAS can we expect to see from our campaign? </b>
                <br />
                Each campaign performance can vary due to a variety of factors. Here are some things to consider:
                <br />
                <br />
                <ol>
                    <li>
                        <b>Dates the campaign is live</b>
                        <br />
                        Secondary holiday timeframes &#40;Memorial Day, Labor Day, etc.&#41;, or product launch dates
                        for example may impact campaign performance.
                    </li>
                    <br />
                    <li>
                        <b>Reporting SKUs selected</b>
                        <br />

                        <ul>
                            <li>Fewer SKUs will typically result in lower ROAS.</li>
                            <li>Choosing popular SKUs for Paid Search will typically result in higher ROAS.</li>
                        </ul>
                    </li>
                    <br />
                    <li>
                        <b>ASP of reporting SKUs </b>
                        <br />
                        Lower priced SKUs will typically result in lower ROAS.
                    </li>
                    <br />
                    <li>
                        <b>Size of potential purchaser audience of products </b>
                        <br />
                        Accessory SKUs typically require ownership of specific hardware to use them which may result in
                        in lower ROAS and CTR, i.e., you need a certain DSLR camera to use a certain lens, or an use an
                        iPhone 13 case.
                    </li>
                </ol>
            </p>
            <br />
            <br />
        </>
    );
};
