import { currencyFormatter, numberFormatter, percentFormatter, stringFormatter } from 'Helpers/table-formatter';

export const campaignTypeSummaryColumns = [
    {
        field: 'name',
        label: 'Campaign Name',
        sortable: true,
    },
    {
        field: 'brandName',
        label: 'Brand',
        type: 'string',
        sortable: true,
        format: stringFormatter,
    },
    {
        field: 'status',
        label: 'Status',
        type: 'string',
        sortable: true,
        format: stringFormatter,
    },
    {
        field: 'impressions',
        label: 'Impressions',
        type: 'number',
        sortable: true,
        format: numberFormatter,
    },
    {
        field: 'clicks',
        label: 'Clicks',
        type: 'number',
        sortable: true,
        format: numberFormatter,
    },
    {
        field: 'ctr',
        label: 'CTR',
        type: 'percent',
        sortable: true,
        format: percentFormatter,
    },
    {
        field: 'deliveredSpend',
        label: 'Delivered Spend',
        type: 'currency',
        sortable: true,
        format: currencyFormatter,
    },
    {
        field: 'revenue',
        label: 'Revenue',
        type: 'currency',
        sortable: true,
        format: currencyFormatter,
    },
    {
        field: 'roas',
        label: 'ROAS',
        type: 'currency',
        sortable: true,
        format: currencyFormatter,
    },
    {
        field: 'startDate',
        label: 'Start Date',
        type: 'string',
        sortable: true,
        format: stringFormatter,
    },
    {
        field: 'endDate',
        label: 'End Date',
        type: 'string',
        sortable: true,
        format: stringFormatter,
    },
];
