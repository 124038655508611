import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton, Typography, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import classNames from 'classnames';

import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const paginationStyles = makeStyles(({ palette }) => ({
    root: {
        padding: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: palette.standardWhite,
        border: `1px solid ${palette.borderGrey}`,
        borderTop: 'none',
    },
    iconButton: {
        width: '40px',
        height: '40px',
        margin: '0 2px',

        '&[data-active="true"]': {
            color: 'white',
            backgroundColor: palette.humanBlue,
        },
    },
    pageButton: {
        borderRadius: '50%',
        minWidth: 'initial',
        fontWeight: 'normal',
    },
    pageStats: {
        fontSize: '0.8125rem',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    paginationRight: {
        display: 'flex',
        alignItems: 'center',
    },
    pageSizeSelect: {
        margin: ' 0 25px 0 5px',

        '& > .MuiInputBase-formControl': {
            height: '40px',
        },

        '& .MuiInputBase-input': {
            fontSize: '0.8125rem',
            background: 'none',
        },
    },
    pageSizeSelectLabel: {
        fontSize: '0.8125rem',
    },
    pageSizeSelectItem: {
        fontSize: '0.8125rem',
    },
}));

const MAX_PAGE_SIZE_LIST = 10;

const pageSizeList = [5, 10, 25, 50, 100];

const TablePagination = ({ pageNumber, pageSize, itemTotal, onPageChange, onPageSizeChange }) => {
    const classes = paginationStyles();
    const pageTotal = Math.ceil(itemTotal / pageSize);
    const paginationStart = pageNumber - (pageNumber % MAX_PAGE_SIZE_LIST);

    const paginationSize =
        pageNumber < pageTotal - (pageTotal % MAX_PAGE_SIZE_LIST) ? MAX_PAGE_SIZE_LIST : pageTotal % MAX_PAGE_SIZE_LIST;

    const onChange = (newPage) => () => onPageChange(newPage);

    const onPageSizeSelect = (event) => onPageSizeChange(event.target.value);

    return (
        <div className={classes.root}>
            <div>
                <IconButton className={classes.iconButton} onClick={onChange(0)} disabled={pageNumber === 0}>
                    <SkipPreviousIcon fontSize="small" />
                </IconButton>
                <IconButton
                    className={classes.iconButton}
                    disabled={pageNumber === 0}
                    onClick={onChange(pageNumber - 1)}
                >
                    <PlayArrowIcon style={{ fontSize: 16, transform: 'rotate(180deg)' }} />
                </IconButton>
                {paginationStart >= MAX_PAGE_SIZE_LIST && (
                    <IconButton className={classes.iconButton} onClick={onChange(paginationStart - MAX_PAGE_SIZE_LIST)}>
                        <MoreHorizIcon style={{ fontSize: 16 }} />
                    </IconButton>
                )}
                {[...Array(paginationSize)].map((v, index) => (
                    <Button
                        // We're not going to modify the order of these items, so index key is fine
                        // eslint-disable-next-line react/no-array-index-key
                        key={index + pageNumber}
                        size="small"
                        data-testid={`page-button-${index + 1}`}
                        data-active={pageNumber === index + paginationStart}
                        className={classNames(classes.pageButton, classes.iconButton)}
                        onClick={onChange(index + paginationStart)}
                    >
                        {index + paginationStart + 1}
                    </Button>
                ))}
                {paginationStart + MAX_PAGE_SIZE_LIST < pageTotal && (
                    <IconButton className={classes.iconButton} onClick={onChange(paginationStart + MAX_PAGE_SIZE_LIST)}>
                        <MoreHorizIcon style={{ fontSize: 16 }} />
                    </IconButton>
                )}
                <IconButton
                    className={classes.iconButton}
                    disabled={pageNumber === pageTotal - 1}
                    onClick={onChange(pageNumber + 1)}
                >
                    <PlayArrowIcon style={{ fontSize: 16 }} />
                </IconButton>
                <IconButton
                    className={classes.iconButton}
                    disabled={pageNumber === pageTotal - 1}
                    onClick={onChange(pageTotal - 1)}
                >
                    <SkipNextIcon fontSize="small" />
                </IconButton>
            </div>
            <div className={classes.paginationRight}>
                <InputLabel className={classes.pageSizeSelectLabel}>Page Limit:</InputLabel>
                <FormControl className={classes.pageSizeSelect}>
                    <Select value={pageSize} variant="outlined" onChange={onPageSizeSelect}>
                        {pageSizeList.map((pageSizeItem) => (
                            <MenuItem key={pageSizeItem} className={classes.pageSizeSelectItem} value={pageSizeItem}>
                                {pageSizeItem}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Typography className={classes.pageStats}>
                    {pageNumber * pageSize + 1} - {Math.min(pageNumber * pageSize + pageSize, itemTotal)} of {itemTotal}{' '}
                    items
                </Typography>
            </div>
        </div>
    );
};

TablePagination.propTypes = {
    pageNumber: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    itemTotal: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
};

export default memo(TablePagination);
