export const onsiteSheets = (definition) => {
    const prefix = definition === 'display' ? 'display-onsite' : 'onsite-video';

    return [
        { type: `${prefix}-summary` },
        { type: `${prefix}-creative`, filtersName: 'creative' },
        { type: `${prefix}-placement`, filtersName: 'placement' },
        { type: `${prefix}-product`, filtersName: 'product' },
    ];
};
