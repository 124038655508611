/**
 *
 * IDAM account created thank you page
 *
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from '../formStyle';

function IDAMThankYouPage() {
    const classes = useStyles();

    return (
        <>
            <Grid item xs={12} className={classes.bodyContainer}>
                <Grid item xs={12}>
                    <div className={classes.bodyLeft}>
                        <span className={classes.formTitleLine}>
                            <div className={classes.formTitle}>Password</div>
                        </span>
                        <div className={classes.formTitle}>created.</div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.bodyRight}>
                        <div className={classes.title}>
                            Account created, please check your email for login instructions. If you do not receive an
                            email within 5 minutes,&nbsp;
                            <a href="mailto:MyAdsSupport@bestbuy.com" className={classes.title}>
                                Contact Us
                            </a>
                            .
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default IDAMThankYouPage;
