/* eslint-disable no-param-reassign */
import moment from 'moment-timezone';

// Pass a type here and allow multiple formats + currency values.
// Type can be currency, percent, string or number
export const numberWithCommas = (x = 0, type = 'number') => {
    if (x === null) {
        return '--';
    }

    if (type === 'boolean') {
        return String(x).toUpperCase();
    }

    // Do nothing for a string
    if (type === 'string' || type === 'description') {
        return String(x);
    }

    if (type === 'date') {
        // return x;
        return moment.utc(x).format('YYYY-MM-DD');
    }

    // Do nothing if it's not a number
    if (Number.isNaN(x)) {
        return x;
    }

    if (type === 'percent_decimal') {
        const percentFormat = Intl.NumberFormat('en-US', {
            style: 'percent',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
        return percentFormat.format(x / 100);
    }

    if (type === 'percent') {
        const percentFormat = Intl.NumberFormat('en-US', {
            style: 'percent',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
        return x === 0 ? '0%' : percentFormat.format(x / 100);
    }

    if (type === 'currency') {
        const currencyFormat = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
        return currencyFormat.format(x);
    }

    if (type === 'time') {
        return moment.utc(x).format('HH:mm');
    }

    if (type === 'decimal') {
        const decimalFormat = Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return decimalFormat.format(x);
    }

    const decimalFormat = Intl.NumberFormat('en-US', { style: 'decimal' });
    return decimalFormat.format(x);
};

export const nFormatter = (num, digits) => {
    const si = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'K' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'B' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ];

    // regex matches if all numbers after the decimal are zeros
    const rx = /\.0+$|(\.\d0)0+$/;
    let i;
    for (i = si.length - 1; i > 0; i -= 1) {
        if (num >= si[i].value) {
            break;
        }
    }

    // formats the number to two digits after decimal, then removes trailing zeros if only zeros after the decimal, adds correct symbol
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};
