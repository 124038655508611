// Total Unique Reach* (no deicimals)
// Total Taps* - Sum of Notification Taps plus Banner Taps.  (no decimals)
// Total Tap Rate - Total Taps divided by Total Unique Reach  X 100  (%)  (two decimals)
// Interstitial Reach* - (no decimals)
// Total Revenue*   (total "view" revenue - Total Online Revenue plus Total In Store Revenue) (two decimals)
// Total ROAS %   (If we are able to pull in the proximity budget from Salesforce, we present the ROAS for the campaign at the summary level since this is sold on a flat fee basis, vs a cpc or cpm like Display or Sponsored Products where we are able to present ROAS by line item in the different views).  (two decimals)

export const proximityCards = [
    {
        key: 'unique_reach',
        title: 'Unique Reach',
        type: 'number',
    },
    {
        key: 'total_taps',
        title: 'Taps',
        type: 'number',
    },
    {
        key: 'tap_rate',
        title: 'Tap Rate',
        type: 'percent_decimal',
    },
    {
        key: 'ad_spend',
        title: 'Ad Spend',
        type: 'currency',
    },
    {
        key: 'total_revenue',
        title: 'Revenue',
        type: 'currency',
    },
    {
        key: 'total_roas',
        title: 'ROAS (%)',
        type: 'percent_decimal',
    },
];
