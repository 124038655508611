import { isEmpty } from 'lodash';
import { storeItem, getItemFromStorage, removeItemFromStorage } from 'utils/localStorage';

const SESSION_KEY = 'bestbuy-session';

export default {
    write(session) {
        storeItem(SESSION_KEY, session);
    },

    read() {
        const session = getItemFromStorage(SESSION_KEY);

        if (!session) {
            return {};
        }
        return session;
    },

    clear() {
        removeItemFromStorage(SESSION_KEY);
    },

    isAuthenticated() {
        const session = this.read();

        return !isEmpty(session);
    },
};
